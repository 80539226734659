import React from 'react'
import {
  ButtonBox,
  Caption,
  CloseButton,
  CloseButtonMobile,
  ErrorBox,
  FormBox,
  LeftBox,
  LoginBox,
  LoginContainer,
  RightBox,
  SubTitle,
  Title,
} from './styles'
import CloseIcon from '@mui/icons-material/Close'
import {Button} from '../../components/button'
import {Form, Formik} from 'formik'
import TextFieldFormik from '../../components/TextFieldFormik'
import * as Yup from 'yup'
import {Box} from '@mui/material'
import {Link} from 'react-router-dom'

export type ResetPasswordForm = {
  password: string
  confirmPassword: string
}

type Props = {
  onSubmit: (password: string) => void
  error?: string
  isSubmitting: boolean
  isComplete: boolean
  isInvalidCode: boolean
}

const View = ({
  onSubmit,
  error,
  isSubmitting,
  isComplete,
  isInvalidCode,
}: Props) => {
  const initialValues = {
    password: '',
    confirmPassword: '',
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d@$!%*?&]/,
        'Password must contain one uppercase letter, one lowerecase letter, one number, and one special character.',
      )
      .min(8, 'Password must be at least 8 characters.')
      .required('Password is required'),

    confirmPassword: Yup.mixed().test(
      'match',
      'Passwords do not match',
      function () {
        return this.parent.password === this.parent.confirmPassword
      },
    ),
  })

  const handleSubmit = (values: ResetPasswordForm) => {
    onSubmit(values.password)
  }

  return (
    <LoginContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {() => (
          <Form>
            <LoginBox>
              <LeftBox>
                <CloseButtonMobile>
                  <a href="https://www.blackspectacles.com/en-us/">
                    <CloseIcon fontSize="large" />
                  </a>
                </CloseButtonMobile>
                <Caption>Turbocharge your way to a kickass career</Caption>
              </LeftBox>
              <RightBox>
                <CloseButton>
                  <a href="https://www.blackspectacles.com/en-us/">
                    <CloseIcon fontSize="large" />
                  </a>
                </CloseButton>
                {isInvalidCode ? (
                  <SubTitle>
                    The reset code is no longer valid. Please{' '}
                    <Link to={'/forgot-password'}>try again.</Link>
                  </SubTitle>
                ) : (
                  <>
                    <Title variant={'h1'}>Set a Password</Title>
                    <SubTitle>
                      {isComplete ? (
                        <>
                          Your password has been reset. You can now{' '}
                          <Link to={'/login'}>log in.</Link>
                        </>
                      ) : (
                        <>
                          Please set a password for your account so you can
                          access the site.
                        </>
                      )}
                    </SubTitle>
                    {!isComplete && (
                      <>
                        <FormBox>
                          <Box>
                            <TextFieldFormik
                              placeholder="Password"
                              name="password"
                              type="password"
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                          <Box>
                            <TextFieldFormik
                              placeholder="Confirm Password"
                              name="confirmPassword"
                              type="password"
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                        </FormBox>
                        <span
                          style={{
                            fontSize: '14px',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          * Password must contain a minimum of 8 characters - at
                          least one uppercase character, one lowercase character
                          and one number.
                        </span>
                        <ErrorBox>{error ?? ''}</ErrorBox>
                        <ButtonBox>
                          <Button
                            children={'Reset my password'}
                            color={'primary'}
                            disabled={isSubmitting}
                            type="submit"
                          />
                        </ButtonBox>
                      </>
                    )}
                  </>
                )}
              </RightBox>
            </LoginBox>
          </Form>
        )}
      </Formik>
    </LoginContainer>
  )
}

export default View
