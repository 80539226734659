import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {RootState} from './rootReducer'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
type ExamResult = {
  attemptId: string
  courseId: string
  courseTitle:
    | 'Practice Management'
    | 'Project Management'
    | 'Programming & Analysis'
    | 'Project Planning & Design'
    | 'Project Development & Documentation'
    | 'Construction & Evaluation'
  examDate: string
  passed: boolean
}

export type AttemptsBody = {
  ncarbExamId: number
  fails: number
  passes: number
  totalAttempts: number
  passRate: number
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  currentEntry?: Attempt
  reportedExams?: GroupedResults
  attempts?: Attempt[]
  status: LoadingStatuses
  error?: string | null | undefined
}

//#region api
type GetExamResultsPayload = {
  auth: AuthContextInterface
}

export const getReportedExamResults = createAsyncThunk<
  any,
  GetExamResultsPayload
>('/getReportedExamResults', async ({auth}) => {
  const endpoint = `/v1/are/ncarb/details`

  return useApi(auth, endpoint, {
    method: 'GET',
  }).then(res => res.json())
})

type SubmitExamResultsPayload = {
  auth: AuthContextInterface
  entryId: string
  examDate: string
  passed: boolean | null
  surveyResources: string
  surveyHoursStudied: string
}

export const submitExamResults = createAsyncThunk<
  any,
  SubmitExamResultsPayload
>(
  '/submitExamResults',
  async ({
    auth,
    entryId,
    examDate,
    passed,
    surveyResources,
    surveyHoursStudied,
  }) => {
    const endpoint = `/v1/are/ncarb/exam/report`
    const fields = {
      entryId,
      examDate,
      passed,
      surveyResources,
      surveyHoursStudied,
    }

    return useApi(auth, endpoint, {
      method: 'POST',
      body: JSON.stringify(fields),
    }).then(res => res.status === 200)
  },
)

type SubmitExamDatePayload = {
  auth: AuthContextInterface
  courseId: string
  examDate: string
  pass: boolean | null
}

export const submitExamDate = createAsyncThunk<any, SubmitExamDatePayload>(
  '/submitExamDate',
  async ({auth, courseId, examDate, pass}) => {
    const endpoint = `/v1/are/ncarb/exam/date`
    const fields = {courseId, examDate, pass}

    return useApi(auth, endpoint, {
      method: 'POST',
      body: JSON.stringify(fields),
    }).then(res => res.json())
  },
)

type UpdateExamDatePayload = {
  auth: AuthContextInterface
  entryId: string
  examDate: string
}

export const updateExamDate = createAsyncThunk<any, UpdateExamDatePayload>(
  '/updateExamDate',
  async ({auth, entryId, examDate}) => {
    const endpoint = `/v1/are/ncarb/exam/date`
    const fields = {entryId, examDate}

    return useApi(auth, endpoint, {
      method: 'PUT',
      body: JSON.stringify(fields),
    }).then(res => res.text())
  },
)

type CompleteAREPayload = {
  auth: AuthContextInterface
  attempts: AttemptsBody[]
}

export const completeARE = createAsyncThunk<any, CompleteAREPayload>(
  '/ncarb/complete',
  async ({auth, attempts}) => {
    const endpoint = `/v1/are/ncarb/complete`

    return useApi(auth, endpoint, {
      method: 'POST',
      body: JSON.stringify(attempts),
    }).then(res => res.text())
  },
)

type DeleteExamDatePayload = {
  auth: AuthContextInterface
  entry_id: string
}

export const deleteExamDate = createAsyncThunk<any, DeleteExamDatePayload>(
  '/deleteExamDate',
  async ({auth, entry_id}) => {
    const endpoint = `/v1/are/ncarb/exam/${entry_id}`

    return useApi(auth, endpoint, {
      method: 'DELETE',
    }).then(res => res.status === 200)
  },
)
//#endregion

//#region slice
const initialState: SliceState = {
  currentEntry: undefined,
  reportedExams: undefined,
  attempts: [],
  status: LoadingStatuses.Idle,
  error: undefined,
}

export type GroupedResults = {
  'Practice Management': Results
  'Project Management': Results
  'Programming & Analysis': Results
  'Project Planning & Design': Results
  'Project Development & Documentation': Results
  'Construction & Evaluation': Results
}

type Results = {
  attempts: Attempt[]
  passed: boolean
  scheduled: string | null
  courseAbbreviation: 'PcM' | 'PjM' | 'PA' | 'PPD' | 'PDD' | 'CE'
}

export type Attempt = {
  attemptId: string
  courseId: string
  courseTitle: string
  examDate: string
  passed: boolean | null
  ncarbExamId?: number
}

export default createSlice({
  name: 'reportedExams',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getReportedExamResults.fulfilled, (state, action) => {
      state.attempts = action.payload
      const groupedResults: GroupedResults = {
        'Practice Management': {
          attempts: [],
          passed: false,
          scheduled: null,
          courseAbbreviation: 'PcM',
        },
        'Project Management': {
          attempts: [],
          passed: false,
          scheduled: null,
          courseAbbreviation: 'PjM',
        },
        'Programming & Analysis': {
          attempts: [],
          passed: false,
          scheduled: null,
          courseAbbreviation: 'PA',
        },
        'Project Planning & Design': {
          attempts: [],
          passed: false,
          scheduled: null,
          courseAbbreviation: 'PPD',
        },
        'Project Development & Documentation': {
          attempts: [],
          passed: false,
          scheduled: null,
          courseAbbreviation: 'PDD',
        },
        'Construction & Evaluation': {
          attempts: [],
          passed: false,
          scheduled: null,
          courseAbbreviation: 'CE',
        },
      }

      action.payload.map(
        ({attemptId, courseId, courseTitle, examDate, passed}: ExamResult) => {
          const attempt = {attemptId, courseId, courseTitle, examDate, passed}
          groupedResults[courseTitle]?.attempts.push(attempt)

          if (groupedResults[courseTitle]) {
            groupedResults[courseTitle].passed = passed

            // Set scheduled exam date to any upcoming date or to passed date if none were set
            if (
              new Date(examDate) >= new Date() ||
              (groupedResults[courseTitle]?.scheduled === null &&
                passed === null)
            ) {
              groupedResults[courseTitle].scheduled = examDate
            }
          }
        },
      )

      state.reportedExams = groupedResults
      state.status = LoadingStatuses.Succeeded
      state.error = undefined
    })
    builder.addCase(getReportedExamResults.pending, state => {
      state.attempts = []
      state.status = LoadingStatuses.Loading
    })
    builder.addCase(getReportedExamResults.rejected, (state, action) => {
      state.attempts = []
      state.status = LoadingStatuses.Failed
      state.error = action.error.message
    })
    builder.addCase(submitExamDate.fulfilled, (state, action) => {
      state.currentEntry = action.payload[0]
      state.status = LoadingStatuses.Succeeded
      state.error = undefined
    })
    builder.addCase(submitExamDate.pending, state => {
      state.currentEntry = undefined
      state.status = LoadingStatuses.Loading
    })
    builder.addCase(submitExamDate.rejected, (state, action) => {
      state.currentEntry = undefined
      state.status = LoadingStatuses.Failed
      state.error = action.error.message
    })
    builder.addCase(updateExamDate.fulfilled, state => {
      state.status = LoadingStatuses.Succeeded
      state.error = undefined
    })
    builder.addCase(updateExamDate.pending, state => {
      state.status = LoadingStatuses.Loading
    })
    builder.addCase(updateExamDate.rejected, (state, action) => {
      state.status = LoadingStatuses.Failed
      state.error = action.error.message
    })
  },
})
//#endregion

//#region selectors
export const selectExamReports = ({examReports}: RootState) =>
  examReports.reportedExams
export const selectNcarbAttempts = ({examReports}: RootState) =>
  examReports.attempts
export const selectCurrentEntry = ({examReports}: RootState) =>
  examReports.currentEntry
//#endregion
