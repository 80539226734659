import React, {useState} from 'react'
import View from './view'
import {Helmet} from 'react-helmet'
import {useAppDispatch} from '../../redux/configureStore'
import {login, selectError} from '../../redux/auth'
import {useSelector} from 'react-redux'
import RouteChangeTracker from '../../components/RouteChangeTracker'
import {useLocation} from 'react-router-dom'

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const location = useLocation()
  const {state} = location

  const dispatch = useAppDispatch()

  const error = useSelector(selectError)

  const handleLogin = async () => {
    setIsSubmitting(true)
    const {type} = await dispatch(login({username, password}))
    if (type === login.fulfilled.type) {
      if (state) {
        window.location.href = state.from
      } else {
        window.location.href = '/'
      }
    }
    setIsSubmitting(false)
  }

  return (
    <>
      <RouteChangeTracker />
      <Helmet>
        <title>Log In | Black Spectacles</title>
      </Helmet>
      <View
        onChangeUsername={(value: string) => setUsername(value)}
        onChangePassword={(value: string) => setPassword(value)}
        onLogin={handleLogin}
        error={error}
        isSubmitting={isSubmitting}
      />
    </>
  )
}

export default Login
