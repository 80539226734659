import {Box} from '@mui/material'
import styled from 'styled-components'
import theme, {colors} from '../../styles/theme'
import {Link} from 'react-router-dom'

export const SectionWrapper = styled(Box)`
  border-bottom: 2px ${colors.border} solid;
  max-width: 66.667%;
`

export const SectionProgressContainer = styled(Box)`
  display: flex;
  min-width: 100%;
  margin-top: 20px;

  && p {
    padding-right: 20px;
  }

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`

export const SectionProgress = styled(Box)`
  min-width: 240px;
  display: flex;
  align-items: center;

  && .MuiLinearProgress-root {
    min-width: 80%;
    height: 6px;
    border-radius: 6px;
    margin-right: 10px;
    background-color: #f5f5f5;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

    span {
      background-color: ${colors.blue1};
    }
  }

  && p {
    color: ${colors.blue1};
  }

  @media (max-width: 1100px) {
    min-width: 100%;

    && .MuiLinearProgress-root {
      min-width: 80%;
    }
  }

  ${theme.breakpoints.down('md')} {
    margin-top: 10px !important;
    width: 80% !important;
    min-width: 80% !important;
    align-self: center !important;
  }
`

export const SectionTextLink = styled(Link)<{$isDisabled?: boolean}>`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 26px;

  ${({$isDisabled}) =>
    $isDisabled
      ? `
    color: #ccc !important;
    cursor: default;
  `
      : null}

  :not(.ignore) {
    margin-top: 15px !important;
  }

  ${theme.breakpoints.down('md')} {
    justify-content: center;
  }
`
