import {Box} from '@mui/material'
import styled from 'styled-components'
import theme, {colors} from '../../styles/theme'

export const DashboardContentContainer = styled(Box)`
  width: 66.667%;
  margin: 0 0 30px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const Title = styled(Box)`
  border-bottom: 2px ${colors.border} solid;
  margin-bottom: 30px;

  h1 {
    margin-bottom: 30px;
  }

  ${theme.breakpoints.down('md')} {
    margin-top: 40px;
    text-align: center;

    h1 {
      margin-bottom: 40px;
    }
  }
`

export const SectionContainer = styled(Box)`
  border-bottom: 2px ${colors.border} solid;
  padding-bottom: 1.5em;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;

  && div > div:not('.ignore'):first-child {
    width: 66.667%;
  }

  && div:first-child {
    width: 66.667%;
  }

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    text-align: center;

    && div:first-child {
      width: 100%;
    }
  }
`
