import {Box} from '@mui/material'
import styled from 'styled-components'

export const CheckallRow = styled(Box)<{
  $isReviewing?: boolean
  $isCorrectAnswer?: boolean
  $isActive?: boolean
}>`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  width: fit-content;

  && span {
    ${({$isReviewing, $isActive, $isCorrectAnswer}) =>
      $isReviewing
        ? $isActive
          ? `font-weight: bold; ${
              $isCorrectAnswer
                ? `color:#50ad3d;`
                : `color: #f44336; text-decoration: line-through;`
            }`
          : $isCorrectAnswer
          ? `color:#50ad3d;`
          : `color: #f44336; text-decoration: line-through;`
        : null}
  }
`
