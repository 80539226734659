import React from 'react'
import {Button} from '../button'

import {
  ButtonBox,
  DialogBox,
  DialogHeader,
  DialogText,
  BreakDialog,
} from './styles'

export type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}

const View = ({isOpen, onClose, onSubmit}: Props) => {
  return (
    <BreakDialog maxWidth={'md'} open={isOpen} onClose={onClose}>
      <DialogBox>
        <DialogHeader>Warning</DialogHeader>
        <DialogText>
          <span>You have chosen to exit this exam.</span>
          <span>
            Would you like to end this exam and submit your results for scoring?
          </span>
        </DialogText>
        <ButtonBox>
          <Button
            onClick={onSubmit}
            children={'YES. END EXAM AND SUBMIT'}
            color={'black'}
            size={'medium'}
          />
          <Button
            onClick={onClose}
            children={'NO. RETURN TO EXAM SUMMARY'}
            color={'primary'}
            size={'medium'}
          />
        </ButtonBox>
      </DialogBox>
    </BreakDialog>
  )
}

export default View
