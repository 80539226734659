import image1 from '../images/backgrounds/login/1.jpg'
import image2 from '../images/backgrounds/login/2.jpg'
import image3 from '../images/backgrounds/login/3.jpg'
import image4 from '../images/backgrounds/login/4.jpg'
import image5 from '../images/backgrounds/login/5.jpg'
import image6 from '../images/backgrounds/login/6.jpg'
import image7 from '../images/backgrounds/login/7.jpg'
import image8 from '../images/backgrounds/login/8.jpg'
import image9 from '../images/backgrounds/login/9.jpg'
import image10 from '../images/backgrounds/login/10.jpg'
import image11 from '../images/backgrounds/login/11.jpg'
import image12 from '../images/backgrounds/login/12.jpg'
import image13 from '../images/backgrounds/login/13.jpg'
import image14 from '../images/backgrounds/login/14.jpg'
import image15 from '../images/backgrounds/login/15.jpg'
import image16 from '../images/backgrounds/login/16.jpg'

export const loginBackgrounds = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
]
