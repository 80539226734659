import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {RootState} from './rootReducer'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
export type Subscription = {
  id: string
  name: string
  price_id: string
  base_price: number
  cancel_at?: string
  cancel_at_period_end?: boolean
  current_period_end?: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  activeSubscriptions: Subscription[]
  inactiveSubscriptions: Subscription[]
  status: LoadingStatuses
  error?: string | null | undefined
}
//#endregion

//#region api
type ActiveSubscriptionPayload = {
  auth: AuthContextInterface
}

export const getActiveSubscriptions = createAsyncThunk<
  any,
  ActiveSubscriptionPayload
>('/getActiveSubscriptions', async ({auth}) => {
  const endpoint = `/v1/network/member/active_subscriptions`

  return useApi(
    auth,
    endpoint,
    {
      method: 'GET',
    },
    'network',
  ).then(res => res.json())
})

type CancelSubscriptionPayload = {
  auth: AuthContextInterface
  subscriptionId: string
}

export const cancelSubscription = createAsyncThunk<
  any,
  CancelSubscriptionPayload
>('/cancelSubscription', async ({auth, subscriptionId}) => {
  const endpoint = `/v1/network/subscription/${subscriptionId}/cancel`

  return useApi(
    auth,
    endpoint,
    {
      method: 'PUT',
    },
    'network',
  ).then(res => res.status === 200)
})
//#endregion

//#region slice
const initialState: SliceState = {
  activeSubscriptions: [],
  inactiveSubscriptions: [],
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getActiveSubscriptions.fulfilled, (state, action) => {
        state.activeSubscriptions = action.payload.subscriptions
        state.inactiveSubscriptions = action.payload.inactive
        state.status = LoadingStatuses.Succeeded
        state.error = undefined
      })
      .addCase(getActiveSubscriptions.pending, state => {
        state.activeSubscriptions = []
        state.status = LoadingStatuses.Loading
        state.error = undefined
      })
      .addCase(getActiveSubscriptions.rejected, state => {
        state.activeSubscriptions = []
        state.status = LoadingStatuses.Failed
        state.error = undefined
      })
  },
})
//#endregion

//#region selectors
export const selectSubscriptions = ({subscription}: RootState) =>
  subscription.activeSubscriptions
export const selectInactiveSubscriptions = ({subscription}: RootState) =>
  subscription.inactiveSubscriptions
//#endregion
