import React, {useState} from 'react'
import {Box} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import {
  DialogBox,
  DialogTitle,
  ErrorDialog,
  ExpandedBox,
  ExpandedHeader,
  ExpandedText,
  ExpandedTitle,
} from './styles'
import {InvoiceItem, InvoicesHeader} from '../../pages/GroupBilling/styles'
import {UserTitle, UsersGroup} from '../../pages/GroupManage/styles'
import {OrgReport} from '../../redux/organizations'
import hoursMinutes from '../../utils/hoursMinutes'

export type Props = {
  report: OrgReport
  isOpen: boolean
  type: string
  onClose: () => void
}

const View = ({isOpen, type, report, onClose}: Props) => {
  const renderData = () => {
    if (type === 'ALL ACTIVE USERS') {
      return (
        <DialogBox>
          <InvoicesHeader>
            <UserTitle width={'50%'}>User</UserTitle>
            <UserTitle width={'50%'}>Usage</UserTitle>
          </InvoicesHeader>
          <Box>
            <UsersGroup>
              {report.overall.usersData.map((data, key) => {
                const [isExpanded, setIsExpanded] = useState(false)
                const bgColor = key % 2 === 0 ? '#ececec' : '#fff'
                return (
                  <React.Fragment key={`overall-${key}`}>
                    <InvoiceItem
                      $bgColor={bgColor}
                      $isLink={true}
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      <Box width={'50%'}>{data.name}</Box>
                      <Box width={'50%'}>{hoursMinutes(data.time || 0)}</Box>
                    </InvoiceItem>
                    {isExpanded ? (
                      <ExpandedBox>
                        <ExpandedHeader>
                          <ExpandedTitle width={'50%'}>Module</ExpandedTitle>
                          <ExpandedTitle width={'50%'}>Usage</ExpandedTitle>
                        </ExpandedHeader>
                        {data.moduleData?.map((module, key) => {
                          return (
                            <ExpandedHeader
                              marginTop={'10px'}
                              key={`module-${key}`}
                            >
                              <ExpandedText width={'50%'}>
                                {module.module}
                              </ExpandedText>
                              <ExpandedText width={'50%'}>
                                {hoursMinutes(module.time || 0) || '0 minutes'}
                              </ExpandedText>
                            </ExpandedHeader>
                          )
                        })}
                      </ExpandedBox>
                    ) : null}
                  </React.Fragment>
                )
              })}
            </UsersGroup>
          </Box>
        </DialogBox>
      )
    } else if (type === 'EXAM ATTEMPTS/PASSED') {
      return (
        <DialogBox>
          <InvoicesHeader>
            <UserTitle width={'33%'}>User</UserTitle>
            <UserTitle width={'33%'}>Attempts</UserTitle>
            <UserTitle width={'33%'}>Passed</UserTitle>
          </InvoicesHeader>
          <Box>
            <UsersGroup>
              {report.ncarb.usersData.map((data, key) => {
                const bgColor = key % 2 === 0 ? '#ececec' : '#fff'
                return (
                  <React.Fragment key={`passed-exams-${key}`}>
                    <InvoiceItem $bgColor={bgColor} $isLink={false}>
                      <Box width={'33%'}>{data.name}</Box>
                      <Box width={'33%'}>{data.attempts}</Box>
                      <Box width={'33%'}>{data.passed}</Box>
                    </InvoiceItem>
                  </React.Fragment>
                )
              })}
            </UsersGroup>
          </Box>
        </DialogBox>
      )
    } else if (type === 'ACTIVE VIRTUAL WORKSHOPS USERS') {
      return (
        <DialogBox>
          <InvoicesHeader>
            <UserTitle width={'50%'}>User</UserTitle>
            <UserTitle width={'50%'}>Workshops Attended</UserTitle>
          </InvoicesHeader>
          <Box>
            <UsersGroup>
              {report.workshops.usersData.map((data, key) => {
                const bgColor = key % 2 === 0 ? '#ececec' : '#fff'
                return (
                  <React.Fragment key={`workshops-${key}`}>
                    <InvoiceItem $bgColor={bgColor} $isLink={false}>
                      <Box width={'50%'}>{data.name}</Box>
                      <Box width={'50%'}>{data.workshops}</Box>
                    </InvoiceItem>
                  </React.Fragment>
                )
              })}
            </UsersGroup>
          </Box>
        </DialogBox>
      )
    } else if (type === 'ACTIVE PRACTICE EXAM USERS') {
      return (
        <DialogBox>
          <InvoicesHeader>
            <UserTitle width={'33%'}>User</UserTitle>
            <UserTitle width={'33%'}>Attempts</UserTitle>
            <UserTitle width={'33%'}>Usage</UserTitle>
          </InvoicesHeader>
          <Box>
            <UsersGroup>
              {report.exams.usersData.map((data, key) => {
                const bgColor = key % 2 === 0 ? '#ececec' : '#fff'
                return (
                  <React.Fragment key={`exams-${key}`}>
                    <InvoiceItem $bgColor={bgColor} $isLink={false}>
                      <Box width={'33%'}>{data.name}</Box>
                      <Box width={'33%'}>{data.attempts}</Box>
                      <Box width={'33%'}>
                        {hoursMinutes(data.time || 0) || '0 minutes'}
                      </Box>
                    </InvoiceItem>
                  </React.Fragment>
                )
              })}
            </UsersGroup>
          </Box>
        </DialogBox>
      )
    } else if (type === 'ACTIVE DIGITAL FLASHCARD USERS') {
      return (
        <DialogBox>
          <InvoicesHeader>
            <UserTitle width={'50%'}>User</UserTitle>
            <UserTitle width={'50%'}>Usage</UserTitle>
          </InvoicesHeader>
          <Box>
            <UsersGroup>
              {report.flashcards.usersData.map((data, key) => {
                const bgColor = key % 2 === 0 ? '#ececec' : '#fff'
                return (
                  <React.Fragment key={`flashcards-${key}`}>
                    <InvoiceItem $bgColor={bgColor} $isLink={false}>
                      <Box width={'50%'}>{data.name}</Box>
                      <Box width={'50%'}>
                        {hoursMinutes(data.time || 0) || '0 minutes'}
                      </Box>
                    </InvoiceItem>
                  </React.Fragment>
                )
              })}
            </UsersGroup>
          </Box>
        </DialogBox>
      )
    } else if (type === 'ACTIVE VIDEO LECTURE USERS') {
      return (
        <DialogBox>
          <InvoicesHeader>
            <UserTitle width={'50%'}>User</UserTitle>
            <UserTitle width={'50%'}>Usage</UserTitle>
          </InvoicesHeader>
          <Box>
            <UsersGroup>
              {report.videos.usersData.map((data, key) => {
                const [isExpanded, setIsExpanded] = useState(false)
                const bgColor = key % 2 === 0 ? '#ececec' : '#fff'
                return (
                  <React.Fragment key={`videos-${key}`}>
                    <InvoiceItem
                      $bgColor={bgColor}
                      $isLink={true}
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      <Box width={'50%'}>{data.name}</Box>
                      <Box width={'50%'}>
                        {hoursMinutes(data.time || 0) || '0 minutes'}
                      </Box>
                    </InvoiceItem>
                    {isExpanded ? (
                      <ExpandedBox>
                        <ExpandedHeader>
                          <ExpandedTitle width={'50%'}>Course</ExpandedTitle>
                          <ExpandedTitle width={'50%'}>Usage</ExpandedTitle>
                        </ExpandedHeader>
                        {data.courseData?.map((course, key) => {
                          return (
                            <ExpandedHeader
                              marginTop={'10px'}
                              key={`course-${key}`}
                            >
                              <ExpandedText width={'50%'}>
                                {course.course}
                              </ExpandedText>
                              <ExpandedText width={'50%'}>
                                {hoursMinutes(course.time || 0) || '0 minutes'}
                              </ExpandedText>
                            </ExpandedHeader>
                          )
                        })}
                      </ExpandedBox>
                    ) : null}
                  </React.Fragment>
                )
              })}
            </UsersGroup>
          </Box>
        </DialogBox>
      )
    } else if (type === 'COURSES VIEWED') {
      return (
        <DialogBox>
          <InvoicesHeader>
            <UserTitle width={'50%'}>Course</UserTitle>
            <UserTitle width={'50%'}>Usage</UserTitle>
          </InvoicesHeader>
          <Box>
            <UsersGroup>
              {report.videos.coursesData.map((data, key) => {
                const [isExpanded, setIsExpanded] = useState(false)
                const bgColor = key % 2 === 0 ? '#ececec' : '#fff'
                return (
                  <React.Fragment key={`videos-${key}`}>
                    <InvoiceItem
                      $bgColor={bgColor}
                      $isLink={true}
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      <Box width={'50%'}>{data.course}</Box>
                      <Box width={'50%'}>
                        {hoursMinutes(data.time || 0) || '0 minutes'}
                      </Box>
                    </InvoiceItem>
                    {isExpanded ? (
                      <ExpandedBox>
                        <ExpandedHeader>
                          <ExpandedTitle width={'50%'}>User</ExpandedTitle>
                          <ExpandedTitle width={'50%'}>Usage</ExpandedTitle>
                        </ExpandedHeader>
                        {data.userData?.map((user, key) => {
                          return (
                            <ExpandedHeader
                              marginTop={'10px'}
                              key={`course-${key}`}
                            >
                              <ExpandedText width={'50%'}>
                                {user.name}
                              </ExpandedText>
                              <ExpandedText width={'50%'}>
                                {hoursMinutes(user.time || 0) || '0 minutes'}
                              </ExpandedText>
                            </ExpandedHeader>
                          )
                        })}
                      </ExpandedBox>
                    ) : null}
                  </React.Fragment>
                )
              })}
            </UsersGroup>
          </Box>
        </DialogBox>
      )
    }
  }

  return (
    <ErrorDialog maxWidth={'md'} open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Box>{type}</Box>

        <CloseIcon
          onClick={onClose}
          className="x-button"
          style={{
            height: 32,
            width: 32,
            cursor: 'pointer',
          }}
        />
      </DialogTitle>
      {renderData()}
    </ErrorDialog>
  )
}

export default View
