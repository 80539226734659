import React, {useState, useEffect} from 'react'
import {getSignedUrl} from '../../utils/getSignedUrl'
import PDFViewer from '../PDFViewer'

type Props = {
  filename: string
}

function CaseStudyPDF({filename}: Props) {
  const [url, setUrl] = useState('')

  useEffect(() => {
    const asyncCall = async () => {
      const result = (await getSignedUrl(
        `practice_exam_files/${filename}`,
        'pdf',
      )) as string
      setUrl(result)
    }

    asyncCall()
  }, [])

  if (url) {
    return <PDFViewer file={url} height="600px" />
  }

  return <></>
}

export default CaseStudyPDF
