import React, {useState} from 'react'
import {CaseStudy, Question} from '../../redux/exams'
import {Content, Header, HeaderItem} from '../References/styles'
import {Box} from '@mui/material'
import renderHTML from '../../utils/renderHTML'
import CaseStudyPDF from '../CaseStudyPDF'
import {CaseStudyLabel} from './styles'

type Props = {
  caseStudies?: CaseStudy[]
  question?: Question
}

const CaseStudyTabs = ({caseStudies, question}: Props) => {
  const [currentTab, setCurrentTab] = useState(0)

  const currentCaseStudy = caseStudies?.findIndex(
    row => row.examId === question?.examId,
  )
  const currentQuestion =
    caseStudies && currentCaseStudy !== undefined
      ? caseStudies[currentCaseStudy].questions.findIndex(
          row => row.id === question?.id,
        )
      : 0
  const totalQuestions =
    caseStudies && currentCaseStudy !== undefined
      ? caseStudies[currentCaseStudy].questions.length
      : 0

  return (
    <>
      <Box>
        <CaseStudyLabel>
          <span>Case Study {(currentCaseStudy ?? 0) + 1}:</span> Question{' '}
          {currentQuestion + 1} of {totalQuestions}
        </CaseStudyLabel>
      </Box>
      <Box>
        <Header>
          <HeaderItem
            onClick={() => setCurrentTab(0)}
            $isActive={currentTab === 0}
          >
            Scenario
          </HeaderItem>
          {question?.caseStudyAssets?.map((asset, key) => {
            return (
              <React.Fragment key={`asset-${asset.id}`}>
                <HeaderItem
                  onClick={() => setCurrentTab(key + 1)}
                  $isActive={currentTab === key + 1}
                >
                  {asset.title}
                </HeaderItem>
              </React.Fragment>
            )
          })}
        </Header>
        <Content style={{minHeight: '600px'}}>
          {currentTab === 0 && <>{renderHTML(question?.caseStudyScenario!)}</>}
          {question?.caseStudyAssets?.map((asset, key) => {
            return (
              <React.Fragment key={`asset-${asset.id}`}>
                {currentTab === key + 1 && (
                  <CaseStudyPDF filename={asset.filename} />
                )}
              </React.Fragment>
            )
          })}
        </Content>
      </Box>
    </>
  )
}

export default CaseStudyTabs
