import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../../hooks/useApi'

//#region types
export type SubscriptionInfo = {
  subscriptions: Subscription[]
}

type Subscription = {
  id: string
  name: string
}
//#endregion

//#region api
type ActiveSubScriptionPayload = {
  jwt: string
  id?: string
}

export const getActiveSubscriptions = createAsyncThunk<
  any,
  ActiveSubScriptionPayload
>('/getActiveSubscriptions', async ({jwt}) => {
  return useApi(
    null,
    `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/network/member/active_subscriptions`,
    {
      method: 'GET',
      headers: {Authorization: `Bearer ${jwt}`},
    },
  ).then(res => res.json())
})
//#endregion
