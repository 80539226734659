import React, {useState} from 'react'
import View from './view'
import {useAppDispatch} from '../../redux/configureStore'
import {useAuth} from '../AuthProvider'
import {toggleErrorDialog} from '../../redux/config'
import {Workshop, rsvpWorkshop} from '../../redux/workshop'

type Props = {
  open: boolean
  setOpen: (val: boolean) => void
  conflictedWorkshop?: Workshop
  workshop?: Workshop
}

const ConflictedWorkshopDialog = ({
  open,
  setOpen,
  conflictedWorkshop,
  workshop,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const auth = useAuth()
  const dispatch = useAppDispatch()

  const handleSubmit = async () => {
    if (workshop?.id) {
      try {
        setIsSubmitting(true)
        const {type} = await dispatch(
          rsvpWorkshop({
            auth,
            workshopId: workshop?.id,
            conflictedWorkshopId: conflictedWorkshop?.id,
            action: 'replace',
          }),
        )

        if (type === rsvpWorkshop.rejected.type) {
          setIsSubmitting(false)
          throw new Error('Unable to RSVP')
        } else {
          setOpen(false)
          setIsSubmitting(false)
        }
      } catch (error) {
        await dispatch(
          toggleErrorDialog({
            opened: true,
            error:
              'We were unable to RSVP, please retry. If issues persist contact our support team',
          }),
        )
      }
    }
  }

  return (
    <View
      open={open}
      setOpen={setOpen}
      conflictedWorkshop={conflictedWorkshop}
      workshop={workshop}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    />
  )
}

export default ConflictedWorkshopDialog
