import React from 'react'
import {
  Content,
  FooterBox,
  LeftBox,
  LinkBox,
  List,
  RightBox,
  SocialBox,
  StyledLogo,
  Text,
  TextBox,
} from './styles'
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  WrapperBox,
  YoutubeIcon,
} from '../../styles/global'

import Logo from '../../images/logo-white.svg'
import {Link, useLocation} from 'react-router-dom'
import {useWindowDimensions} from '../../hooks/useWindowDimensions'
import theme from '../../styles/theme'

const View = () => {
  const {width} = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  type Links = {
    url: string
    text: string
    isRoute?: boolean
  }

  const linkLists = [
    [
      {
        url: '/',
        text: 'Course Library',
        isRoute: true,
      },
      {
        url: 'https://go.blackspectacles.com/are-prep/study-materials',
        text: 'ARE Prep',
      },
      {
        url: 'https://go.blackspectacles.com/software-learning',
        text: 'Software Learning',
      },
      {
        url: 'https://go.blackspectacles.com/about',
        text: 'About',
      },
    ],
    [
      {
        url: 'https://www.blackspectacles.com/pricing',
        text: 'Pricing',
      },
      {
        url: 'https://go.blackspectacles.com/blog',
        text: 'Blog',
      },
      {
        url: 'https://go.blackspectacles.com/are-live',
        text: 'Podcasts',
      },
      {
        url: 'https://go.blackspectacles.com/downloads',
        text: 'Resources',
      },
    ],
    [
      {
        url: 'https://go.blackspectacles.com/careers',
        text: 'Careers',
      },
      {
        url: 'https://go.blackspectacles.com/press',
        text: 'Press',
      },
      {
        url: 'https://help.blackspectacles.com/',
        text: 'Support',
      },
      {
        url: 'https://go.blackspectacles.com/terms-and-conditions',
        text: 'Terms & Privacy',
      },
    ],
  ]

  const location = useLocation()

  const isActivationPage = location.pathname.includes('/profile/activation')

  return (
    <FooterBox id="bks-footer">
      <WrapperBox
        style={isMobile ? {padding: '0'} : {}}
        $removeMarginTop={isActivationPage}
      >
        <Content>
          <LeftBox>
            <StyledLogo src={Logo} alt="Black Spectacles" />

            <SocialBox>
              <a
                href="https://www.facebook.com/blackspectacles/"
                target="_blank"
              >
                <FacebookIcon $isSmall={true} />
              </a>
              <a href="https://twitter.com/blackspectacles" target="_blank">
                <TwitterIcon $isSmall={true} />
              </a>
              <a
                href="https://www.youtube.com/user/iloveblackspectacles"
                target="_blank"
              >
                <YoutubeIcon $isSmall={true} />
              </a>
              <a
                href="https://www.instagram.com/black.spectacles/"
                target="_blank"
              >
                <InstagramIcon $isSmall={true} />
              </a>
              <a
                href="https://www.linkedin.com/company/1932680"
                target="_blank"
              >
                <LinkedinIcon $isSmall={true} />
              </a>
            </SocialBox>

            <TextBox>
              <LinkBox>
                <a href="mailto:hello@blackspectacles.com">
                  hello@blackspectacles.com
                </a>
              </LinkBox>
              <Text>2093 Philadelphia Pike #8864, Claymont, DE 19703</Text>
              <Text>All rights reserved © 2010–2024 Black Spectacles.</Text>
            </TextBox>
          </LeftBox>
          <RightBox>
            {linkLists.map((linkList: Links[], key) => {
              return (
                <List key={`f-list-${key}`}>
                  {linkList.map((link, key) => {
                    return (
                      <LinkBox key={`f-list-2-${key}`}>
                        <em>
                          {link.isRoute ? (
                            <Link to={link.url}>{link.text}</Link>
                          ) : (
                            <a href={link.url}>{link.text}</a>
                          )}
                        </em>
                      </LinkBox>
                    )
                  })}
                </List>
              )
            })}
          </RightBox>
        </Content>
      </WrapperBox>
    </FooterBox>
  )
}

export default View
