import {combineReducers} from '@reduxjs/toolkit'
import demo from './demo/getTest'
import login from './demo/cart/guest/login'
import flashcard from './flashcard'
import courses from './courses'
import lessons from './lessons'
import videos from './videos'
import config from './config'
import exams from './exams'
import auth from './auth'
import members from './members'
import dashboard from './dashboard'
import examReports from './reportExamStatus'
import organizations from './organizations'
import password from './password'
import subscription from './subscription'
import billingHistory from './billingHistory'
import workshops from './workshop'
import search from './search'
import expireSurvey from './expireSurvey'

const rootReducer = combineReducers({
  demo: demo.reducer,
  testJWT: login.reducer,
  flashcards: flashcard.reducer,
  course: courses.reducer,
  lesson: lessons.reducer,
  video: videos.reducer,
  exam: exams.reducer,
  config: config.reducer,
  auth: auth.reducer,
  member: members.reducer,
  dashboard: dashboard.reducer,
  examReports: examReports.reducer,
  organization: organizations.reducer,
  password: password.reducer,
  subscription: subscription.reducer,
  billingHistory: billingHistory.reducer,
  workshop: workshops.reducer,
  search: search.reducer,
  expireSurvey: expireSurvey.reducer
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
