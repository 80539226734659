import {videoBackgrounds} from '../constants/video-background'
import {loginBackgrounds} from '../constants/login-background'

export function getRandomBackground() {
  return videoBackgrounds[Math.floor(Math.random() * videoBackgrounds.length)]
}

export function getRandomLoginBackground() {
  return loginBackgrounds[Math.floor(Math.random() * loginBackgrounds.length)]
}
