import {Box} from '@mui/material'
import styled from 'styled-components'
import theme from '../../../styles/theme'

export const ExamStatusContainer = styled(Box)`
  display: flex;
  width: 100% !important;
  background-color: red;

  div:first-child { {
    background-color: green;
    width: 66.667%;
  }

  ${theme.breakpoints.down('md')} {
    flex-direction: column;

    h2,
    p {
      text-align: center;
    }
  }
`

export const InputBox = styled(Box)`
  margin-top: 20px;
  display: flex;
  gap: 8px;

  && .MuiInputBase-root {
    width: 100% !important;
    max-width: 180px;
  }

  && .MuiFormControl-root {
    max-width: 180px;
  }

  ${theme.breakpoints.down('md')} {
    && .MuiFormControl-root {
      width: 100% !important;
    }
    && .MuiInputBase-root {
      width: 100% !important;
      max-width: 100%;
    }
    && button {
      width: 100% !important;
    }
  }
`

export const EditLink = styled.span`
  color: #1377d4;
  cursor: pointer;

  &: hover {
    text-decoraton: underline;
  }
`
