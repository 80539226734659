import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {RootState} from './rootReducer'
import {LoadingStatuses} from '../components/types/LoadingStatusses'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
export type LessonPlan = {
  id: string
  active: boolean
  assets: Lesson[]
  author: string
  authorBio: string
  description: string
  duration: number
  version: number
}

export type Lesson = {
  id: string
  assetTypeId: string
  orderNum: number
  assetTypeAbbr:
    | 'lesson_header'
    | 'video'
    | 'quiz'
    | 'practice_exam'
    | 'flashcard_deck'
  shortDescription: string
  videoDuration?: string
  isFree?: number
  brightcoveId?: string
  questions?: number
  isComplete?: boolean
  percentage?: number
  isPracticalApplication?: boolean
}

type SliceState = {
  lesson?: LessonPlan
  lessonStatus: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type GetLessonsPayload = {
  auth: AuthContextInterface
  lessonId?: string
}
export const getLessons = createAsyncThunk<any, GetLessonsPayload>(
  'lessons/get',
  async ({auth, lessonId}) => {
    const endpoint = `/v1/are/courses/lessons/${lessonId}`

    return useApi(auth, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

type GetGuestLessonsPayload = {
  lessonId?: string
}
export const getGuestLessons = createAsyncThunk<any, GetGuestLessonsPayload>(
  'guest/lessons/get',
  async ({lessonId}) => {
    const endpoint = `/v1/guest/are/courses/lessons/${lessonId}`

    return useApi(null, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

type ResetQuizPayload = {
  auth: AuthContextInterface
  examId: string
}
export const resetExam = createAsyncThunk<any, ResetQuizPayload>(
  'quizzes/reset',
  async ({auth, examId}) => {
    const endpoint = `/v1/are/exams/exam/${examId}/reset`
    return useApi(auth, endpoint, {
      method: 'PUT',
    }).then(res => res.text())
  },
)

type ResetVideoProgressPayload = {
  auth: AuthContextInterface
  lessonPlanId: string
}
export const resetVideoProgress = createAsyncThunk<
  any,
  ResetVideoProgressPayload
>('videos/reset', async ({auth, lessonPlanId}) => {
  const endpoint = `/v1/are/video/progress/reset/lesson/${lessonPlanId}`
  return useApi(auth, endpoint, {
    method: 'DELETE',
  }).then(res => res.text())
})

//#endregion

//#region slice
const initialState: SliceState = {
  lesson: undefined,
  lessonStatus: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'lesson',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getLessons.pending, state => {
      state.lesson = undefined
      state.lessonStatus = LoadingStatuses.Loading
    })
    builder.addCase(getLessons.fulfilled, (state, action) => {
      state.lesson = action.payload
      state.lessonStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(getLessons.rejected, state => {
      state.lesson = undefined
      state.lessonStatus = LoadingStatuses.Failed
    })
    builder.addCase(getGuestLessons.pending, state => {
      state.lesson = undefined
      state.lessonStatus = LoadingStatuses.Loading
    })
    builder.addCase(getGuestLessons.fulfilled, (state, action) => {
      state.lesson = action.payload
      state.lessonStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(getGuestLessons.rejected, state => {
      state.lesson = undefined
      state.lessonStatus = LoadingStatuses.Failed
    })
    builder.addCase(resetVideoProgress.fulfilled, state => {
      if (state.lesson) {
        const lessons = [...state.lesson.assets]
        lessons.map(lesson => {
          lesson.isComplete = false
          return lesson
        })

        state.lesson.assets = lessons
      }
    })
    builder.addCase(resetExam.fulfilled, (state, action) => {
      const examId = action.meta.arg.examId

      if (state.lesson) {
        const lessons = [...state.lesson.assets]
        lessons.map(lesson => {
          if (examId === lesson.id) {
            lesson.percentage = undefined
          }

          return lesson
        })

        state.lesson.assets = lessons
      }
    })
  },
})
//#endregion

//#region selectors
export const selectLesson = ({lesson}: RootState) => lesson.lesson
export const selectLessonStatus = ({lesson}: RootState) => lesson.lessonStatus
//#endregion
