import styled from 'styled-components'
import theme from '../../styles/theme'
import {Box, Typography} from '@mui/material'

export const VideosBox = styled(Box)`
  display: flex;
  flex-flow: nowrap;
`
export const RightBox = styled(Box)`
  width: 100%;
  box-sizing: border-box;
`

export const VideoContent = styled(Box)`
  padding: 32px 24px;
`

export const VideoTitleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`

export const VideoTitle = styled(Typography)`
  font-family: Public Sans !important;
  font-size: 24px !important;
  text-transform: none !important;
  color: #000 !important;
  ${theme.breakpoints.down('md')} {
    font-size: 20px !important;
  }
`

export const VideoTime = styled(Box)`
  font-family: Public Sans !important;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 85px;
`

export const Tabs = styled(Box)`
  display: flex;
  gap: 20px;
  margin-top: 40px;

  ${theme.breakpoints.down('md')} {
    flex-wrap: wrap;

    && button {
      flex: 40%;
      font-size: 12px !important;
    }
  }
`

export const ContentBox = styled(Box)`
  display: flex;
  border: 2px #eff2f3 solid;
  margin-top: 20px;
  place-items: flex-start;
  box-sizing: border-box;

  ${theme.breakpoints.down('md')} {
    flex-flow: column-reverse;
  }
`

export const CourseContent = styled(Box)`
  padding: 20px;
  width: 25%;
  box-sizing: border-box;
  font-family: Public Sans !important;
  font-size: 16px;
  line-height: 22px;

  && a {
    text-decoration: underline !important;
  }

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const TabContent = styled(Box)`
  padding: 20px;
  width: 75%;
  border-left: 2px #eff2f3 solid;
  height: -webkit-fill-available;
  height: -moz-available;
  font-size: 14px;
  line-height: 22px;
  box-sizing: border-box;

  && a {
    font-size: 16px !important;
    font-style: italic !important;
    text-decoration: underline !important;
  }

  height: 400px;
  overflow: overlay;

  &::-webkit-scrollbar {
    background: #ffffff;
    width: 8px;
    border: 0;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 100px;
    background: #d8d8d8;
    -webkit-box-shadow: none;

    &:hover {
      background: #d8d8d8;
    }
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  ${theme.breakpoints.down('md')} {
    min-height: 189px;
    max-height: 350px;
    height: auto;
    width: 100%;
    border-left: 0;
    border-bottom: 2px #eff2f3 solid;
  }
`

export const SocialBox = styled(Box)`
  display: flex;
  gap: 8px;
`

export const MobileBox = styled(Box)`
  display: none;
  background-color: #221f1f;
  width: 100%;
  padding: 0 20px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  box-sizing: border-box;
  height: 60px;

  ${theme.breakpoints.down('md')} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 2;
  }
`

export const PlayerBox = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  background-color: #000;

  ${theme.breakpoints.down('md')} {
    margin-top: 60px;
  }
`

export const LoggedOutBox = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  background-color: #151515;
  color: #fff;
  font-family: Public Sans;
  height: 50%;
  max-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  gap: 32px;

  && span {
    text-align: center;
  }

  ${theme.breakpoints.down('md')} {
    padding: 80px 20px 0;
    margin-top: 60px;
    height: 100%;
    align-items: center;
    justify-content: normal;
  }
`
