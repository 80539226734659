import React from 'react'
import {Box} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import {Button} from '../button'

import {
  ButtonBox,
  DialogBox,
  DialogText,
  DialogTitle,
  ErrorDialog,
} from './styles'

export type Props = {
  isOpen: boolean
  message: string
  title?: string
  onClose: () => void
}

const View = ({isOpen, title, message, onClose}: Props) => {
  const handleClick = () => {
    onClose()
  }

  return (
    <ErrorDialog maxWidth={'md'} open={isOpen} onClose={onClose}>
      <DialogTitle>
        {title ? title : 'Error'}

        <CloseIcon
          onClick={onClose}
          className="x-button"
          style={{
            height: 32,
            width: 32,
            cursor: 'pointer',
          }}
        />
      </DialogTitle>
      <DialogBox>
        <Box>
          <DialogText>{message}</DialogText>
        </Box>
        <ButtonBox>
          <Button
            onClick={handleClick}
            children={'Close'}
            color={'primary'}
            size={'medium'}
          />
        </ButtonBox>
      </DialogBox>
    </ErrorDialog>
  )
}

export default View
