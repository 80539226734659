import React, {useState, useEffect, useCallback} from 'react'
import SideMenu from '../../components/StudyContent/FlashCards/SideMenu'
import FlashCardContent from '../../components/StudyContent/FlashCards/FlashCardContent'
import CustomCard from '../../components/StudyContent/FlashCards/CustomCard'
import {FlashCardPageContainer} from './styles'
import {useAppDispatch} from '../../redux/configureStore'
import {
  changeDeckState,
  getCourseDecks,
  getDeck,
  resetMastered,
  selectAllCards,
  selectDeckId,
} from '../../redux/flashcard'
import {useSelector} from 'react-redux'
import {Flashcard} from '../../redux/flashcard'
import {useAuth} from '../../components/AuthProvider'
import {useParams} from 'react-router-dom'
import {getCourse, selectCourse} from '../../redux/courses'
import {toggleErrorDialog} from '../../redux/config'
import {Helmet} from 'react-helmet'
import {selectMemberAccess} from '../../redux/members'

export type CardNumber = {
  mastered: number
  unmastered: number
  all: number
}

const View = () => {
  const {courseId, state} = useParams()
  const auth = useAuth()
  const dispatch = useAppDispatch()

  const [flashCardFormOpen, setFlashCardFormToggle] = useState(
    state === 'create' ? true : false,
  )
  const [dropDownSelection, setDropDownSelection] = useState('all')
  const [filterBySelection, setFilterBySelection] = useState(
    state === 'review' ? 'Unmastered' : 'All',
  )
  const [searchInput, setSearchInput] = useState('')

  const [cardsToDisplay, setCardsToDisplay] = useState<Flashcard[]>([])
  const [cardNumbers, setCardNumbers] = useState<CardNumber>({
    mastered: 0,
    unmastered: 0,
    all: 0,
  })
  const [editMode, setEditModeToggle] = useState(false)
  const [cardId, setCardId] = useState('')
  const [currentFlashCardNumber, setFlashCardNumber] = useState(1)

  const deckId = useSelector(selectDeckId)
  const currentDeck = useSelector(selectAllCards)
  const course = useSelector(selectCourse)
  const access = useSelector(selectMemberAccess)

  const hasAccess =
    access && Object.keys(access).length !== 0
      ? Number(access.are?.flash_cards?.value) === 1
      : false

  const areCardsFlipped = cardsToDisplay?.every(
    element => element.isFlipped === true,
  )

  const handleUpdateFlashcardNumber = (value: number) => {
    setFlashCardNumber(value)
  }

  const loadCourse = useCallback(async () => {
    try {
      const {type} = await dispatch(getCourse({courseId}))
      if (type === getCourse.rejected.type) {
        throw new Error('Unable to get course')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the course, please retry. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch, courseId])

  const loadFlashcardDecks = useCallback(async () => {
    try {
      // TODO: update to get courseId from redux store later
      const {payload, type}: any = await dispatch(getCourseDecks({courseId}))

      if (type === getCourseDecks.rejected.type) {
        throw new Error('Unable to update flashcards')
      }

      const lessons = [...payload.decks]
      lessons?.sort((a: any, b: any) => {
        return Number(b.lessonVersion) - Number(a.lessonVersion)
      })

      handleDeckRetrieval(lessons[0].id)
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to update flashcards, please retry. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch, courseId])

  const handleDeckRetrieval = async (deckId: string) => {
    try {
      if (auth.isAuthenticated && hasAccess) {
        const resp = await dispatch(getDeck({auth, deckId}))

        if (resp.type === getDeck.rejected.type) {
          throw new Error('Unable to retrieve deck')
        }
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to retrieve deck, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const updateMenuAction = async (action: string) => {
    switch (action) {
      case 'shuffle':
        await dispatch(
          changeDeckState({
            shuffled: true,
            flipped: areCardsFlipped ? 'back' : 'front',
          }),
        )
        return
      case 'randomize':
        await dispatch(changeDeckState({randomize: true}))
        return
      case 'flip':
        await dispatch(
          changeDeckState({flipped: areCardsFlipped ? 'front' : 'back'}),
        )
        return
      case 'reset':
        handleReset()
        return
      default:
        return
    }
  }

  const handleReset = async () => {
    if (!deckId) {
      throw new Error('Unable to retrieve deck')
    }

    try {
      const {type} = await dispatch(resetMastered({auth, deckId}))
      if (type === resetMastered.rejected.type) {
        throw new Error('Unable to reset mastered')
      }

      if (type === resetMastered.fulfilled.type) {
        const updatedDeck = cardsToDisplay.map(card => ({
          ...card,
          mastered: false,
        }))

        setCardsToDisplay(updatedDeck)
        setCardNumbers({
          mastered: 0,
          unmastered: updatedDeck.length,
          all: updatedDeck.length,
        })
      }
    } catch (error) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to reset the mastered cards, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  useEffect(() => {
    loadFlashcardDecks()
    loadCourse()
  }, [loadFlashcardDecks, loadCourse])

  // Filter through current deck
  useEffect(() => {
    if (currentDeck) {
      let finalDeck = [...currentDeck]
      if (searchInput) {
        const query = searchInput.toLocaleLowerCase()

        finalDeck = finalDeck.filter(
          (card: any) =>
            card?.title?.toLowerCase().indexOf(query) >= 0 ||
            card?.side1?.toLowerCase().indexOf(query) >= 0 ||
            card?.side2?.toLowerCase().indexOf(query) >= 0,
        )
      }

      if (dropDownSelection === 'custom') {
        finalDeck = finalDeck.filter(card => card.custom)
      } else if (dropDownSelection === 'default') {
        finalDeck = finalDeck.filter(card => !card.custom)
      } else if (dropDownSelection !== 'all') {
        finalDeck = finalDeck.filter(card => {
          const cardObjective = card.title?.split('-')[1]
          return dropDownSelection === cardObjective
        })
      }

      setCardNumbers({
        all: finalDeck.length,
        mastered: finalDeck.filter(card => card.mastered).length,
        unmastered: finalDeck.filter(card => !card.mastered).length,
      })

      if (filterBySelection === 'Mastered') {
        finalDeck = finalDeck.filter(card => card.mastered)
      } else if (filterBySelection === 'Unmastered') {
        finalDeck = finalDeck.filter(card => !card.mastered)
      }

      setCardsToDisplay(finalDeck)
    }
  }, [searchInput, filterBySelection, dropDownSelection, currentDeck])

  return (
    <>
      <Helmet>
        <title>{`Flashcards: ARE 5.0 ${course?.title} | Black Spectacles`}</title>
      </Helmet>
      <FlashCardPageContainer>
        <SideMenu
          setFlashCardFormToggle={setFlashCardFormToggle}
          dropDownSelection={dropDownSelection}
          setDropDownSelection={setDropDownSelection}
          filterBySelection={filterBySelection}
          setFilterBySelection={setFilterBySelection}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          updateMenuAction={updateMenuAction}
          setFlashCardNumber={handleUpdateFlashcardNumber}
          cardNumbers={cardNumbers}
          areCardsFlipped={areCardsFlipped}
          hasAccess={hasAccess}
        />
        <FlashCardContent
          course={course}
          deck={cardsToDisplay}
          filterBySelection={filterBySelection}
          toggleForm={setFlashCardFormToggle}
          toggleEditMode={setEditModeToggle}
          setFlashCardNumber={handleUpdateFlashcardNumber}
          updateCardId={setCardId}
          hasAccess={hasAccess}
          currentFlashCardNumber={currentFlashCardNumber}
          searchInput={searchInput}
          flipCard={cardPosition => {
            if (cardsToDisplay) {
              const tempDeck = [...cardsToDisplay]

              const flipped = !tempDeck[cardPosition].isFlipped

              tempDeck[cardPosition] = {
                ...tempDeck[cardPosition],
                isFlipped: flipped,
              }

              setCardsToDisplay(tempDeck)
            }
          }}
        />
        {flashCardFormOpen ? (
          <CustomCard
            open={flashCardFormOpen}
            closeForm={() => {
              setFlashCardFormToggle(false)
              setEditModeToggle(false)
            }}
            updateCardId={setCardId}
            editMode={editMode}
            cardId={cardId}
          />
        ) : null}
      </FlashCardPageContainer>
    </>
  )
}

export default View
