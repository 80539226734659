import {useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'

import View, {ReferralSource} from './view'

const ReferralDialog = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [open, setOpen] = useState(false)
  const [source, setSource] = useState<ReferralSource | null>(null)

  useEffect(() => {
    if (
      searchParams.has('showReferralDialog') &&
      searchParams.get('showReferralDialog') === 'true' &&
      searchParams.has('source')
    ) {
      const referralSource = searchParams.get('source')

      if (referralSource === 'sign-up' || referralSource === 'exam-pass') {
        setOpen(true)
        setSource(referralSource)
      }
    }
  }, [searchParams])

  const handleClose = () => {
    setOpen(false)
    setSource(null)

    setSearchParams(prev => {
      prev.delete('showReferralDialog')
      prev.delete('source')
      return prev
    })
  }

  if (!source) {
    return null
  }

  return (
    source && <View open={open} handleClose={handleClose} source={source} />
  )
}

export default ReferralDialog
