import React, {useState} from 'react'
import {useAppDispatch, AppState} from '../../../redux/configureStore'
import {useSelector} from 'react-redux'
import {Box, List, ListItem, ListItemText, Button, Divider} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import {v4 as uuidv4} from 'uuid'

////////////////////////////////////////////////////////////////
// Cart Endpoints
// --- Guest Endpoints
import {getTestData} from '../../../redux/demo/getTest'
import {validateEmail} from '../../../redux/demo/cart/guest/validateEmail'
import {checkCoupon} from '../../../redux/demo/cart/guest/validateCoupon'
import {createNewAccount} from '../../../redux/demo/cart/guest/createIndividualAccount'
import {getProductsInfo} from '../../../redux/demo/cart/guest/product'
import {guestReprice} from '../../../redux/demo/cart/guest/reprice'
import {getPaymentMethodDetails} from '../../../redux/demo/cart/guest/paymentMethod'
import {login} from '../../../redux/demo/cart/guest/login'
import {guestCreateSubscription} from '../../../redux/demo/cart/guest/subscription'

// --- Network Endpoints
import {getBillingAddress} from '../../../redux/demo/cart/network/billing'
import {getActiveSubscriptions} from '../../../redux/demo/cart/network/activeSubscription'
import {
  getAllPaymentMethods,
  getPaymentMethod,
  getSetupIntent,
} from '../../../redux/demo/cart/network/paymentMethod'
import {networkReprice} from '../../../redux/demo/cart/network/reprice'
import {networkCreateSubscription} from '../../../redux/demo/cart/network/subscription'

// --- Other
import {validateJWT} from '../../../redux/demo/cart/validate'
////////////////////////////////////////////////////////////////

// Platform Services
import {getCourseDecks} from '../../../redux/flashcard'

type Props = {
  updateData: (data: string) => void
}

const View = ({updateData}: Props) => {
  const dispatch = useAppDispatch()

  const jwt = useSelector((state: AppState) => state.testJWT.jwt)

  const apiList = [
    {
      name: 'Public API - GET',
      route: 'https://www.boredapi.com/api/activity?',
      script: () => getTestData({}),
    },
    {
      name: 'Check ARE/Network status of email - True/True',
      route: '/v1/guest/are/member/email',
      script: (data: any) => validateEmail(data),
      requestData: {email: 'henrytan@blackspectacles.com'},
    },
    {
      name: 'Check ARE/Network status of email - False/False',
      route: '/v1/guest/are/member/email',
      script: (data: any) => validateEmail(data),
      requestData: {email: 'test@blackspectacles.com'},
    },
    {
      name: 'Validate Coupon - ABSVALUETEST - Only returns response code',
      route: '/v1/guest/payment/coupon/${couponCode}/valid',
      script: (data: any) => checkCoupon(data),
      requestData: {
        couponCode: 'ABSVALUETEST',
        id: 'price_1MFLQ9267oeCP8Ytd8MPRm6S',
      },
    },
    {
      name: 'Create individual account',
      route: '/v1/guest/are/member',
      script: (data: any) => createNewAccount(data),
      requestData: {
        individualAccountDetails: {
          email: 'henrytan+postman-test-2@blackspectacles.com',
          password: 'asdf1234A!',
          firstName: 'Henry',
          lastName: 'Tan',
        },
      },
    },
    {
      name: 'Get data for list of products',
      route: '/v1/guest/payment/products',
      script: (data: any) => getProductsInfo(data),
      requestData: {productIds: ['price_1MFLQ9267oeCP8Ytd8MPRm6S']},
    },
    {
      name: 'Reprice cart through guest endpoint',
      route: '/v1/guest/payment/cart/reprice',
      script: (data: any) => guestReprice(data),
      requestData: {
        items: [
          {
            id: 'price_1MFLQ9267oeCP8Ytd8MPRm6S',
            price: '179.00',
            qty: '1',
            name: 'ARE 5.0 Premium 1-Month Subscription Plan',
          },
        ],
        coupon: '',
        address: {
          address1: '444 N Michigan Ave',
          address2: '',
          city: 'Chicago',
          state: 'IL',
          postal_code: '60611',
          country: 'US',
        },
      },
    },
    {
      name: 'Get details of a Payment Method',
      route: '/v1/guest/payment/payment_method/${pmId}',
      script: (data: any) => getPaymentMethodDetails(data),
      requestData: {pmId: 'pm_1NJNGL267oeCP8YtkzIQslUy'},
    },
    {
      name: 'Log in user',
      route: '/v1/guest/are/log_in',
      script: (data: any) => login(data),
      requestData: {
        loginCredentials: {
          // email: 'henrytan+postman-test-2@blackspectacles.com',
          // password: 'asdf1234A!',
          email: 'henrytan+20@blackspectacles.com',
          password: 'asdf1234A!',
        },
      },
    },
    {
      name: 'Create payment method through guest endpoint',
      route: '/v1/guest/payment/subscription',
      script: (data: any) => guestCreateSubscription(data),
      requestData: {
        paymentDetails: {
          member: {memberId: '5c64a626143d11ee866a0a3aa9dad974'},
          address1: '444 N Michigan Ave',
          address2: '',
          city: 'Chicago',
          state: 'IL',
          country: 'US',
          postalCode: '60611',
          phoneNumber: '1234567890',
          phoneExtension: '',
        },
        products: [
          {
            id: 'price_1MFLQ9267oeCP8Ytd8MPRm6S',
            amount: '17900',
            qty: '1',
            name: 'ARE 5.0 Premium 1-Month Subscription Plan',
          },
        ],
        coupon: '',
        taxId: '',
      },
    },
    {name: '', script: () => getTestData({})},
    {
      name: 'Get Billing Address',
      route: '/v1/network/member/billing_address',
      script: (data: any) => getBillingAddress(data),
      requestData: {jwt},
    },
    {
      name: 'Get active subscriptions',
      route: '/v1/network/member/active_subscriptions',
      script: (data: any) => getActiveSubscriptions(data),
      // requestData: {jwt},
      requestData: {jwt: '123'},
    },
    {
      name: 'Get payment method',
      route: '/v1/network/member/payment_method/${subscriptionId}',
      script: (data: any) => getPaymentMethod(data),
      requestData: {jwt, subscriptionId: 'sub_1NMD0X267oeCP8YtsuR0hbPf'},
    },
    {
      name: 'Get all payment methods',
      route: '/v1/network/member/payment_methods',
      script: (data: any) => getAllPaymentMethods(data),
      requestData: {jwt},
    },
    {
      name: 'Get setup intent',
      route: '/v1/network/payment/setup_intent',
      script: (data: any) => getSetupIntent(data),
      requestData: {jwt},
    },
    {
      name: 'Reprice cart through network endpoint',
      route: '/v1/network/cart/reprice',
      script: (data: any) => networkReprice(data),
      requestData: {
        jwt,
        items: [
          {
            id: 'price_1MMejG267oeCP8YtRicUXYVg',
            price: '329.00',
            qty: '1',
            name: 'ARE 5.0 Expert 1-Month Subscription Plan',
            target_subscription_id: 'sub_1NMD0X267oeCP8YtsuR0hbPf',
          },
        ],
        coupon: '',
        address: {
          address1: '444 N Michigan Ave',
          address2: '',
          city: 'Chicago',
          state: 'IL',
          postalCode: '60611',
          country: 'US',
        },
      },
    },
    {
      name: 'Create subscription with network endpoint',
      route: '/v1/payment/subscription',
      script: (data: any) => networkCreateSubscription(data),
      requestData: {
        jwt,
        paymentDetails: {
          address1: '444 N Michigan Ave',
          address2: '',
          city: 'Chicago',
          state: 'IL',
          country: 'US',
          postalCode: '60611',
          phoneNumber: '1234567890',
          phoneExtension: '',
        },
        products: [
          {
            id: 'price_1MFLQ9267oeCP8Ytd8MPRm6S',
            amount: '17900',
            qty: '1',
            name: 'ARE 5.0 Premium 1-Month Subscription Plan',
          },
        ],
        coupon: '',
        taxId: '',
      },
    },
    {name: '', script: () => getTestData({})},
    {
      name: 'Validate JWT',
      route: '/jwt/validate',
      script: (data: any) => validateJWT(data),
      requestData: {jwt},
    },
    {
      name: '/getCourseDecks',
      route: '/v1/guest/are/flashcards/course/{courseId}',
      script: (data: any) => getCourseDecks(data),
      requestDate: {courseId: 'b85a0df6-524b-11ee-b662-06b3578c997d'},
    },
    {name: '/api20', script: () => getTestData({})},
  ]

  const [selectedIndex, updateSelectedIndex] = useState(-1)
  //   const [body, updateBody] = useState('')

  const handleClick = (script: any, idx: number, requestData: any) => {
    updateSelectedIndex(idx)

    const handleAsyncClick = async () => {
      try {
        const {payload, error} = await dispatch(script(requestData))

        if (error) {
          updateData(JSON.stringify(error))
        } else {
          updateData(JSON.stringify(payload))
        }
      } catch (error) {
        console.error(error)
      }
    }

    handleAsyncClick()
  }

  return (
    <Box sx={{width: '50%', display: 'flex'}}>
      <Box sx={{width: '100%'}}>
        {/* <Box sx={{width: '50%'}}> */}
        <h1>API List</h1>
        <Divider />

        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            overflow: 'auto',
            maxHeight: 800,
          }}
        >
          <List component="nav" aria-label="main mailbox folders">
            {apiList.map((api, idx) => {
              const {name, route, script, requestData = {}} = api
              return (
                <ListItem
                  key={uuidv4()}
                  selected={selectedIndex === idx}
                  divider={true}
                  // onClick={() => updateSelectedIndex(idx)}
                  // onClick={() => handleClick(script, idx)}
                >
                  {name ? (
                    <>
                      <ListItemText primary={name} secondary={route} />
                      <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<SendIcon />}
                        onClick={() => handleClick(script, idx, requestData)}
                        // onClick={() => handleClick(script, idx, body)}
                      >
                        Call API
                      </Button>
                    </>
                  ) : (
                    <>
                      <ListItemText primary={''} />
                    </>
                  )}
                </ListItem>
              )
            })}
          </List>
        </Box>
      </Box>

      {/* <Box sx={{width: '100%'}}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            maxHeight: 800,
          }}
        >
          <h1>Request Body</h1>
        
          <TextField
            id="outlined-multiline-static"
            label="Body"
            multiline
            rows={25}
            defaultValue={''}
            fullWidth
            onChange={e => {
              updateBody(e.target.value)
            }}
          />
        </Box>
      </Box> */}
    </Box>
  )
}

export default View
