import React from 'react'
import {Box} from '@mui/material'
import Navbar from '../../components/navbar'
import Hero from '../../components/Hero'
import {CompleteTitle, WrapperBox} from '../../styles/global'
import Footer from '../Footer'
import RouteChangeTracker from '../RouteChangeTracker'
import {useSelector} from 'react-redux'
import {selectMember} from '../../redux/members'
import ReferralBanner from '../ReferralBanner'

type Props = {
  children: React.ReactNode
  showHero?: boolean
  hideNavbar?: boolean
  hideFooter?: boolean
  showCompleteText?: boolean
}

const View = ({
  children,
  showHero,
  hideNavbar,
  hideFooter,
  showCompleteText,
}: Props) => {
  const member = useSelector(selectMember)

  return (
    <>
      <RouteChangeTracker />
      {!hideNavbar && <Navbar />}
      {showHero ? (
        <>
          <Hero />
          {member?.isAREComplete && showCompleteText ? (
            <CompleteTitle>
              6 against 1 and you beat em all! Superhero status achieved!
            </CompleteTitle>
          ) : null}
          <WrapperBox>{children}</WrapperBox>
        </>
      ) : (
        <Box>{children}</Box>
      )}
      {!hideFooter && (
        <>
          <ReferralBanner />
          <Footer />
        </>
      )}
    </>
  )
}

export default View
