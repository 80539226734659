import React, {useState} from 'react'
import {Typography, Box} from '@mui/material'
import {Course} from '../../redux/courses'
import {LessonPlan} from '../../redux/lessons'
import {
  CourseBox,
  FlexBox,
  LessonBody,
  LessonHeader,
  LessonsBox,
} from './styles'
import {NCARBAPPROVEDIMAGEABBR} from '../../constants/divisions'
import ResetQuizIcon from '../../images/icons/icon-reset-quiz.svg'
import {Link} from 'react-router-dom'
import {LeftBox, RightBox, StyledTitle} from '../../styles/global'
import {useWindowDimensions} from '../../hooks/useWindowDimensions'
import theme from '../../styles/theme'
import FollowUs from '../../components/FollowUs'
import ConfirmDialog from '../../components/ConfirmDialog'
import {useAuth} from '../../components/AuthProvider'
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined'
import {ImageBox} from '../Courses/styles'

type Props = {
  course: Course
  lesson: LessonPlan
  onResetQuiz: (examId: string) => void
}

const View = ({course, lesson, onResetQuiz}: Props) => {
  const auth = useAuth()
  const {width} = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  return (
    <CourseBox id="content">
      <LeftBox>
        <StyledTitle variant="h2">ARE 5.0 {course.title} EXAM PREP</StyledTitle>
        <Box margin={'1em 0'}>
          <Typography variant={'body1'}>
            Here you will find a variety of quizzes relating to the ARE 5.0{' '}
            {course.title} Exam Prep course. By quizzing yourself as you study,
            you’ll be teaching your mind how to recall the information you’ve
            just learned. Every time you take a quiz, your latest score will be
            saved so you can see if you’ve improved since your last attempt.
            Take the quizzes independently here, or let Black Spectacles' video
            course guide you with quizzes incorporated into our lecture series.
            Either way, you can rest assured that these practice quizzes will
            help you recall and retain information, so you’ll feel prepared for
            the ARE 5.0 {course.title} Exam.
          </Typography>
        </Box>
        <ImageBox>
          {course?.abbreviation && (
            <img
              width="146"
              src={NCARBAPPROVEDIMAGEABBR[course.abbreviation]}
            />
          )}
        </ImageBox>
        <LessonsBox>
          <LessonHeader>
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={'8px'}
              width={isMobile ? '85%' : '70%'}
            >
              <HistoryToggleOffOutlinedIcon fontSize="large" /> Quiz
            </Box>
            {!isMobile && (
              <Box width={'10%'} textAlign={'center'}>
                Questions
              </Box>
            )}
            <Box width={isMobile ? '15%' : '10%'} textAlign={'center'}>
              Score
            </Box>
            {!isMobile && (
              <Box width={'10%'} textAlign={'center'}>
                Reset
              </Box>
            )}
          </LessonHeader>
          {lesson.assets.map((asset, key) => {
            const [isDialogOpen, setIsDialogOpen] = useState(false)
            return (
              <React.Fragment key={key}>
                {asset.assetTypeAbbr === 'quiz' && (
                  <LessonBody $isAuthenticated={auth.isAuthenticated}>
                    <FlexBox width={isMobile ? '85%' : '70%'}>
                      <Link to={`/courses/${course.id}/quiz/${asset.id}`}>
                        {asset.shortDescription}
                      </Link>
                    </FlexBox>
                    {!isMobile && (
                      <FlexBox width={'10%'} justifyContent={'center'}>
                        {asset.questions}
                      </FlexBox>
                    )}
                    <FlexBox
                      width={isMobile ? '15%' : '10%'}
                      justifyContent={'center'}
                    >
                      {asset.percentage ?? '-'}
                    </FlexBox>
                    {!isMobile && (
                      <FlexBox width={'10%'} justifyContent={'center'}>
                        {asset.percentage ? (
                          <img
                            onClick={() => setIsDialogOpen(true)}
                            src={ResetQuizIcon}
                            alt="Reset Quiz"
                            style={{
                              width: '24px',
                              cursor: 'pointer',
                              display: 'flex',
                            }}
                          />
                        ) : (
                          '-'
                        )}
                      </FlexBox>
                    )}
                  </LessonBody>
                )}
                <ConfirmDialog
                  title="Reset Quiz"
                  message="Are you sure you want to reset this quiz?"
                  isOpen={isDialogOpen}
                  onConfirm={() => {
                    onResetQuiz(asset.id)
                    setIsDialogOpen(false)
                  }}
                  onClose={() => setIsDialogOpen(false)}
                />
              </React.Fragment>
            )
          })}
        </LessonsBox>
      </LeftBox>
      <RightBox>
        <FollowUs />
      </RightBox>
    </CourseBox>
  )
}

export default View
