import React from 'react'
import {InvoiceDetails} from '../../redux/organizations'
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from '@react-pdf/renderer'

import Logo from '../../images/logo-black.png'
import {invoiceItems} from '../../constants/invoiceItems'
import {subMonths} from 'date-fns'

type Props = {
  invoice: InvoiceDetails
}

const PDFView = ({invoice}: Props) => {
  let subTotal = 0
  let discount = 0
  let tax = 0

  let lastGroup = ''

  const styles = StyleSheet.create({
    viewer: {
      width: '100%',
      height: '100vh',
      border: 0,
      padding: 0,
      margin: 0,
    },
    page: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    logo: {
      width: '100px',
      marginVertical: 15,
      marginHorizontal: 215,
    },
    section: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    title: {
      fontSize: '10px',
      fontFamily: 'Helvetica-Bold',
    },
    text: {
      fontSize: '10px',
      fontFamily: 'Helvetica',
    },
    right: {
      alignSelf: 'flex-end',
    },
    itemsHeader: {
      fontSize: '10px',
      color: '#fff',
      fontFamily: 'Helvetica-Bold',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      backgroundColor: '#000',
      padding: '8px',
    },
    itemsTitle: {
      fontSize: '10px',
      fontFamily: 'Helvetica-Bold',
      backgroundColor: '#ddd',
      padding: '8px',
    },
  })

  return (
    // <PDFViewer style={styles.viewer}>
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={Logo} style={styles.logo} />
        <View style={styles.section}>
          <View>
            <Text style={styles.title}>BLACK SPECTACLES, LLC</Text>
            <Text style={styles.text}>P.O. Box 23054</Text>
            <Text style={styles.text}>New York, NY 10087-3054</Text>
          </View>
          <View>
            <Text style={{...styles.title, ...styles.right, fontSize: '13px'}}>
              {invoice.paymentStatus === 'paid' ? 'Receipt' : 'Invoice'}
            </Text>
            <Text style={{...styles.text, ...styles.right, fontSize: '12px'}}>
              #{String(invoice.id).padStart(String(invoice.id).length + 4, '0')}
            </Text>
          </View>
        </View>

        <View
          style={{
            ...styles.section,
            paddingTop: '10px',
            alignItems: 'center',
          }}
        >
          <View>
            <Text style={{...styles.text, fontSize: '9px'}}>
              Tax ID: 27-3552413
            </Text>
          </View>
          <View>
            <Text style={{...styles.text, fontSize: '9px'}}>
              {invoice.paymentStatus === 'paid' ? 'Total Paid' : 'Balance Due'}
            </Text>
            <Text style={{...styles.title, ...styles.right, fontSize: '12px'}}>
              {(invoice.paymentDetails.amount / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
              })}
            </Text>
          </View>
        </View>

        <View style={{...styles.section, paddingTop: '10px'}}>
          <View>
            <Text style={{...styles.text, fontSize: '12px'}}>
              {invoice.paymentStatus === 'paid' ? 'Billed To:' : 'Bill To:'}
            </Text>
            <Text style={{...styles.title, fontSize: '10px'}}>
              {invoice.orgName}
            </Text>
            {invoice.address.address1 && invoice.address.city ? (
              <>
                <Text style={{...styles.text, fontSize: '10px'}}>
                  {invoice.address.address1} {invoice.address.address2}
                </Text>
                <Text style={{...styles.text, fontSize: '10px'}}>
                  {invoice.address.city}, {invoice.address.state}{' '}
                  {invoice.address.postalCode}
                </Text>
              </>
            ) : null}
          </View>
          <View style={{paddingTop: '12px'}}>
            <View style={{...styles.section, width: '200px'}}>
              <Text style={{...styles.text, ...styles.right, fontSize: '11px'}}>
                Invoice Date:
              </Text>
              <Text style={{...styles.text, ...styles.right, fontSize: '11px'}}>
                {subMonths(
                  new Date(invoice.periodStartDate),
                  1,
                ).toLocaleDateString('en-US')}
              </Text>
            </View>
            <View style={{...styles.section, width: '200px'}}>
              <Text style={{...styles.text, ...styles.right, fontSize: '11px'}}>
                Terms:
              </Text>
              <Text style={{...styles.text, ...styles.right, fontSize: '11px'}}>
                Custom
              </Text>
            </View>
            <View style={{...styles.section, width: '200px'}}>
              <Text style={{...styles.text, ...styles.right, fontSize: '11px'}}>
                {invoice.paymentStatus === 'paid' ? 'Date Paid:' : 'Due Date:'}
              </Text>
              <Text
                style={{
                  ...styles.text,
                  ...styles.right,
                  fontSize: '11px',
                  backgroundColor:
                    invoice.paymentStatus === 'pending' ? '#ffea91' : '',
                  fontFamily:
                    invoice.paymentStatus === 'pending'
                      ? 'Helvetica-Bold'
                      : 'Helvetica',
                }}
              >
                {invoice.paymentStatus === 'paid'
                  ? new Date(invoice.invoiceDate).toLocaleDateString('en-US')
                  : new Date(invoice.periodStartDate).toLocaleDateString(
                      'en-US',
                    )}
              </Text>
            </View>
          </View>
        </View>

        <View style={{paddingTop: '12px'}}>
          <View style={styles.itemsHeader}>
            <Text style={{...styles.title, fontSize: '10px'}}>
              Item + Description
            </Text>
            <Text style={{...styles.title, fontSize: '10px'}}>Amount</Text>
          </View>
          {invoice.items.map((item, key) => {
            subTotal = subTotal + item.price
            discount = discount + item.discount
            tax = tax + item.tax

            const isCap = item.name.includes('__cap')
            const currentGroup = isCap ? 'content' : 'cap'

            const borderStyle =
              lastGroup !== currentGroup
                ? {}
                : {
                    borderTop: '1px solid #f1f1f1',
                    marginTop: '1px',
                  }

            const invoiceItem = invoiceItems.find(row => row.item === item.name)

            const Component = !(isCap && item.altName === '0') && (
              <React.Fragment key={`item-${key}`}>
                {lastGroup !== currentGroup ? (
                  <View
                    style={{
                      ...styles.itemsTitle,
                      marginTop: key === 0 ? '2px' : '',
                    }}
                  >
                    <Text style={{...styles.title, fontSize: '10px'}}>
                      {isCap ? 'License Cap' : 'Content'}
                    </Text>
                  </View>
                ) : null}
                <View
                  style={{
                    ...styles.section,
                    ...borderStyle,
                    alignItems: 'center',
                    borderBottom: '1px solid #f1f1f1',
                  }}
                >
                  <View style={{padding: '8px', width: '100%'}}>
                    <Text style={{...styles.text, fontSize: '10px'}}>
                      {isCap ? (
                        <>{item.altName} Simultaneous User Cap</>
                      ) : (
                        <>{invoiceItem?.title}</>
                      )}
                    </Text>
                    <Text style={{...styles.text, fontSize: '7px'}}>
                      {isCap ? (
                        <>
                          License limit of up to {item.altName} simultaneous
                          users
                        </>
                      ) : (
                        <>{invoiceItem?.description}</>
                      )}
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: '8px',
                      minWidth: '90px',
                      borderLeft: '1px solid #f1f1f1',
                    }}
                  >
                    <Text
                      style={{
                        ...styles.text,
                        ...styles.right,
                        fontSize: '11px',
                      }}
                    >
                      {(item.price / 100).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                      })}
                    </Text>
                  </View>
                </View>
              </React.Fragment>
            )

            if (lastGroup !== currentGroup) {
              lastGroup = currentGroup
            }

            return Component
          })}

          <View
            style={{
              ...styles.section,
              marginTop: '1px',
              alignItems: 'center',
              borderBottom: '1px solid #f1f1f1',
              borderTop: '1px solid #f1f1f1',
            }}
          >
            <View style={{padding: '8px', display: 'flex', width: '100%'}}>
              <Text
                style={{
                  ...styles.title,
                  alignSelf: 'flex-end',
                  fontSize: '12px',
                }}
              >
                Subtotal
              </Text>
              {discount > 0 ? (
                <Text
                  style={{
                    ...styles.text,
                    alignSelf: 'flex-end',
                    fontSize: '8px',
                    marginTop: '10px',
                  }}
                >
                  Discount
                </Text>
              ) : null}
              {tax > 0 ? (
                <Text
                  style={{
                    ...styles.text,
                    alignSelf: 'flex-end',
                    fontSize: '8px',
                    marginTop: '10px',
                  }}
                >
                  Sales Tax
                </Text>
              ) : null}
            </View>
            <View
              style={{
                padding: '8px',
                minWidth: '90px',
                borderLeft: '1px solid #f1f1f1',
              }}
            >
              <Text
                style={{...styles.title, ...styles.right, fontSize: '12px'}}
              >
                {(subTotal / 100).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                })}
              </Text>
              {discount > 0 ? (
                <Text
                  style={{
                    ...styles.text,
                    ...styles.right,
                    fontSize: '12px',
                    marginTop: '10px',
                  }}
                >
                  -{' '}
                  {(discount / 100).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                  })}
                </Text>
              ) : null}
              {tax > 0 ? (
                <Text
                  style={{
                    ...styles.text,
                    ...styles.right,
                    fontSize: '12px',
                    marginTop: '10px',
                  }}
                >
                  {(tax / 100).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                  })}
                </Text>
              ) : null}
            </View>
          </View>
          <View
            style={{
              ...styles.section,
              marginTop: '1px',
              alignItems: 'center',
              borderTop: '1px solid #f1f1f1',
            }}
          >
            <View style={{padding: '8px', display: 'flex', width: '100%'}}>
              <Text
                style={{
                  ...styles.title,
                  alignSelf: 'flex-end',
                  fontSize: '12px',
                }}
              >
                TOTAL
              </Text>
            </View>
            <View
              style={{
                padding: '8px',
                minWidth: '90px',
                borderLeft: '1px solid #f1f1f1',
              }}
            >
              <Text
                style={{...styles.title, ...styles.right, fontSize: '12px'}}
              >
                {((subTotal - discount + tax) / 100).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                })}
              </Text>
            </View>
          </View>
        </View>
        {invoice.paymentStatus === 'paid' ? (
          <View style={{...styles.section, paddingTop: '30px'}}>
            <View>
              <Text style={{...styles.title, fontSize: '11px'}}>
                Payment Method:
              </Text>
              <Text style={{...styles.text, fontSize: '11px', color: '#bbb'}}>
                {invoice.paymentMethod === 'upfront_no_cc' ? (
                  <>ACH or Wire Transfer</>
                ) : (
                  <>Credit Card</>
                )}
              </Text>
            </View>
          </View>
        ) : (
          <View style={{...styles.section, paddingTop: '30px'}}>
            <View style={{marginTop: 'auto'}}>
              <Text style={{...styles.title, fontSize: '11px'}}>
                Payment Options:
              </Text>
              <Text style={{...styles.text, fontSize: '11px', color: '#bbb'}}>
                {invoice.paymentType === 'recurring' ? (
                  <>
                    {invoice.paymentMethod === 'upfront_no_cc' ? (
                      <>ACH or Wire Transfer</>
                    ) : (
                      <>Credit Card</>
                    )}
                  </>
                ) : (
                  <>Credit Card, ACH, or Wire Transfer</>
                )}
              </Text>
            </View>
            <View>
              <Text style={{...styles.title, fontSize: '11px'}}>
                Wire Transfer/ACH Information
              </Text>
              <Text style={{...styles.text, fontSize: '11px', color: '#bbb'}}>
                Business Name: Black Spectacles, LLC
              </Text>
              <Text style={{...styles.text, fontSize: '11px', color: '#bbb'}}>
                Account Number: 817683623
              </Text>
              <Text style={{...styles.text, fontSize: '11px', color: '#bbb'}}>
                Routing Number: 071000013
              </Text>
              <Text style={{...styles.text, fontSize: '11px', color: '#bbb'}}>
                Wire Routing Number: 0210 000 21
              </Text>
            </View>
          </View>
        )}
      </Page>
    </Document>
    // </PDFViewer>
  )
}

export default PDFView
