import React from 'react'
import View from './view'

type Props = {
  open: boolean
  setOpen: (val: boolean) => void
  courseId?: string
  division: string
}

const ExamStatusForm = ({open, setOpen, courseId, division}: Props) => {
  return (
    <View
      open={open}
      setOpen={setOpen}
      courseId={courseId}
      division={division}
    />
  )
}

export default ExamStatusForm
