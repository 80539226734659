import React, {useEffect, useCallback} from 'react'
import {Typography} from '@mui/material'
import {DashboardContentContainer, Title, SectionContainer} from './styles'
import ExamStatus from './ExamStatus'
import VirtualWorkshop from './VirtualWorkshop'
import StudyMaterials from './StudyMaterials'
import VideoLectures from './VideoLectures'
import Quizzes from './Quizzes'
import PracticeExams from './PracticeExams'
import FlashCards from './FlashCards'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useAppDispatch} from '../../redux/configureStore'
import {
  getDashboard,
  getGuestDashboard,
  selectDashboard,
} from '../../redux/dashboard'
import {useAuth} from '../AuthProvider'
import {useSelector} from 'react-redux'
import {updateCourseBookmark, selectOrganization} from '../../redux/courses'
import {getReportedExamResults} from '../../redux/reportExamStatus'
import {Helmet} from 'react-helmet'
import {toggleErrorDialog} from '../../redux/config'
import {selectMember, updateBookmarkState} from '../../redux/members'
import ReferralDialog from '../ReferralDialog'

const View = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {courseId} = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const dashboard = useSelector(selectDashboard)
  const member = useSelector(selectMember)
  const organization = useSelector(selectOrganization)

  const currentCourse = organization?.courses.filter(course => {
    if (courseId && courseId !== 'undefined') {
      return course.id === courseId
    } else {
      return ['PcM', 'PjM', 'PA', 'PPD', 'PDD', 'CE'].includes(
        course.abbreviation,
      )
    }
  })[0]

  const defaultCourse = organization?.courses.filter(
    course => course.abbreviation === 'PcM',
  )[0]

  const currentCourseId =
    courseId ??
    ((member?.courseBookmarks &&
      member?.courseBookmarks?.length > 0 &&
      member.courseBookmarks[0].courseId) ||
      currentCourse?.id!)

  const loadDashboard = useCallback(async () => {
    try {
      if (auth.isAuthenticated) {
        const {type} = await dispatch(
          getDashboard({courseId: currentCourseId, auth}),
        )
        await dispatch(getReportedExamResults({auth}))

        if (type === getDashboard.fulfilled.type && currentCourse?.lessons) {
          const lessons = [...currentCourse.lessons]
          lessons?.sort((a: any, b: any) => {
            return Number(b.version) - Number(a.version)
          })

          const version = lessons ? lessons[0].version : undefined
          if (version && currentCourse) {
            await dispatch(
              updateCourseBookmark({
                auth,
                version,
                courseId: currentCourse?.id,
              }),
            )

            await dispatch(updateBookmarkState({courseId: currentCourse?.id}))
          }
        } else if (type === getDashboard.rejected.type) {
          throw new Error('Unable to get dashboard')
        }
      } else {
        const {type} = await dispatch(
          getGuestDashboard({courseId: currentCourseId}),
        )

        if (type === getDashboard.rejected.type) {
          throw new Error('Unable to get dashboard')
        }
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the dashboard data, please retry reloading the page. If issue persists please contact our support team',
        }),
      )
    }
  }, [dispatch, courseId])

  const courseIndex = organization?.courses.findIndex(
    data => data.id === currentCourseId,
  )

  useEffect(() => {
    if (courseIndex !== -1) {
      loadDashboard()
    }
  }, [loadDashboard])

  useEffect(() => {
    const currentSearchParams = searchParams.toString()

    if (!courseId) {
      const newCourseId =
        member?.courseBookmarks && member?.courseBookmarks?.length > 0
          ? member.courseBookmarks[0].courseId
          : currentCourseId

      navigate(
        `/profile/dashboard/${newCourseId}${
          currentSearchParams ? `?${currentSearchParams}` : ''
        }`,
        {
          replace: true,
        },
      )
    } else {
      if (courseIndex === -1) {
        navigate(
          `/profile/dashboard/${defaultCourse?.id}${
            currentSearchParams ? `?${currentSearchParams}` : ''
          }`,
          {
            replace: true,
          },
        )
      }
    }
  }, [])

  const pageTitle = `ARE 5.0 ${currentCourse?.title} V2.0 Dashboard | Black Spectacles`

  useEffect(() => {
    if (
      member &&
      !member.lastLogin &&
      // prevents users that logged in for the first time to keep seeing the
      // dialog every time they refresh the page, close/reopen the browser
      // (while the last session is still alive), etc.
      localStorage.getItem('signUpReferralDialogShowed') !== 'true'
    ) {
      setSearchParams({showReferralDialog: 'true', source: 'sign-up'})
      localStorage.setItem('signUpReferralDialogShowed', 'true')
    }
  }, [member])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <DashboardContentContainer>
        <Title>
          <Typography
            variant={'h2'}
            style={{marginBottom: '16px', fontSize: '32px', color: '#2d2d2d'}}
          >
            {currentCourse?.title} Progress
          </Typography>
        </Title>

        <SectionContainer>
          {currentCourse && (
            <ExamStatus courseAbbreviation={currentCourse.abbreviation} />
          )}
        </SectionContainer>

        <SectionContainer style={{display: 'block'}}>
          <StudyMaterials course={currentCourse!} />
        </SectionContainer>

        <SectionContainer>
          <VideoLectures
            course={currentCourse!}
            videoData={dashboard?.video}
            paData={dashboard?.practicalApplications}
          />
        </SectionContainer>

        <SectionContainer>
          <Quizzes course={currentCourse!} quizData={dashboard?.quizzes} />
        </SectionContainer>

        <SectionContainer>
          <PracticeExams course={currentCourse!} examsData={dashboard?.exams} />
        </SectionContainer>

        <SectionContainer>
          <FlashCards
            course={currentCourse!}
            flashcardData={dashboard?.flashcards}
          />
        </SectionContainer>

        <SectionContainer>
          {currentCourse && (
            <VirtualWorkshop courseAbbreviation={currentCourse.abbreviation} />
          )}
        </SectionContainer>
      </DashboardContentContainer>
      <ReferralDialog />
    </>
  )
}

export default View
