import React from 'react'
import {Box, Typography, LinearProgress} from '@mui/material'
import {
  SectionProgress,
  SectionProgressContainer,
  SectionTextLink,
} from '../../SectionContainer/styles'
import EditIcon from '../../../images/icons/edit.svg'
import FlashCardIcon from '../../../images/icons/flashcard.svg'
import {DashboardFlashcards} from '../../../redux/dashboard'
import {Course} from '../../../redux/courses'
import {PrimaryLinkButton} from '../../../styles/global'
import {Link as RouterLink} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {ButtonBox} from '../StudyMaterials/styles'
import {selectMemberAccess} from '../../../redux/members'

type Props = {
  course?: Course
  flashcardData?: DashboardFlashcards
}

const View = ({course, flashcardData}: Props) => {
  const access = useSelector(selectMemberAccess)

  const percentagePlaceholderValue = flashcardData
    ? (flashcardData.mastered / flashcardData.total) * 100
    : 0
  const cardsNumberPlaceholder = flashcardData
    ? flashcardData.total - flashcardData.mastered
    : 0

  const hasAccess =
    access && Object.keys(access).length !== 0
      ? Number(access.are?.flash_cards?.value) === 1
      : false

  return (
    <>
      <Box>
        <Typography variant={'h3'}>Flashcards</Typography>

        <SectionProgressContainer marginTop={'20px'}>
          <Typography variant={'body1'}>Mastered</Typography>

          <SectionProgress>
            <Box sx={{width: '100%'}}>
              <LinearProgress
                variant="determinate"
                value={percentagePlaceholderValue}
              />
            </Box>

            <Typography variant={'body2'}>
              {isNaN(percentagePlaceholderValue)
                ? 0
                : Math.round(percentagePlaceholderValue)}
              %
            </Typography>
          </SectionProgress>
        </SectionProgressContainer>

        <Typography variant={'body2'} marginTop={'10px'}>
          <em>
            {cardsNumberPlaceholder}{' '}
            {cardsNumberPlaceholder === 1 ? 'card' : 'cards'} unmastered
          </em>
        </Typography>
        <Box marginTop={'16px'}>
          <SectionTextLink
            $isDisabled={!hasAccess}
            to={
              hasAccess ? `/profile/deck/flashcards/${course?.id}/create` : '#'
            }
          >
            <img src={EditIcon} className="ignore" />
            <span className="ignore">Create custom flashcard</span>
          </SectionTextLink>
        </Box>
        <Box marginTop={'16px'}>
          <SectionTextLink
            $isDisabled={!hasAccess}
            to={
              hasAccess ? `/profile/deck/flashcards/${course?.id}/review` : '#'
            }
          >
            <img src={FlashCardIcon} className="ignore" />
            <span className="ignore">Review unmastered flashcards</span>
          </SectionTextLink>
        </Box>
      </Box>

      <ButtonBox>
        <PrimaryLinkButton
          component={RouterLink}
          to={`/profile/deck/flashcards/${course?.id}`}
        >
          Study
        </PrimaryLinkButton>
      </ButtonBox>
    </>
  )
}

export default View
