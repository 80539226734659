import {Box} from '@mui/material'
import styled from 'styled-components'

export const LoadingBox = styled(Box)`
  padding: 80px 0;
  display: flex;
  flex-flow: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
`
