import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {RootState} from './rootReducer'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
export type Workshop = {
  id: string
  title: string
  date: string
  description: string
  link: string
  prerequisites: string
  coach: string
  rsvp?: boolean
  isLaunchEnabled?: boolean
}

type Question = {
  question?: string
  attachment?: File
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  workshops: Workshop[]
  conflictedWorkshop?: Workshop
  status: LoadingStatuses
  error?: string | null | undefined
}

//#region api
type GetWorkshopsPayload = {
  auth: AuthContextInterface
  courseId: string
  startTime: number
  endTime: number
}

export const getWorkshops = createAsyncThunk<any, GetWorkshopsPayload>(
  '/workshops/get',
  async ({auth, courseId, startTime, endTime}) => {
    const endpoint = `/v1/are/workshops/course/${courseId}/${startTime}/${endTime}`

    return useApi(auth, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

type GetGuestWorkshopsPayload = {
  courseId: string
  startTime: number
  endTime: number
}

export const getGuestWorkshops = createAsyncThunk<
  any,
  GetGuestWorkshopsPayload
>('/guest/workshops/get', async ({courseId, startTime, endTime}) => {
  const endpoint = `/v1/guest/are/workshops/course/${courseId}/${startTime}/${endTime}`

  return useApi(null, endpoint, {
    method: 'GET',
  }).then(res => res.json())
})

type submitWorkshopQuestionPayload = {
  auth: AuthContextInterface
  workshopId: string
  questions: Question[]
}

export const submitWorkshopQuestion = createAsyncThunk<
  any,
  submitWorkshopQuestionPayload
>('/submitWorkshopQuestion', async ({auth, workshopId, questions}) => {
  const endpoint = `/v1/are/workshops/question`

  const formData = new FormData()
  formData.append('workshop_id', workshopId)

  questions.map((ele, idx) => {
    if (ele.question) {
      formData.append(`question[${idx}]`, ele.question)
    }
    if (ele.attachment) {
      formData.append('question_files', ele.attachment)
    }
  })

  return useApi(auth, endpoint, {
    method: 'POST',
    body: formData,
    headers: {
      'Content-Type': 'Automatic',
    },
  }).then(res => res.json())
})

type AttendWorkshopPayload = {
  auth: AuthContextInterface
  workshopId: string
}

export const attendWorkshop = createAsyncThunk<any, AttendWorkshopPayload>(
  '/submitExamDate',
  async ({auth, workshopId}) => {
    const endpoint = `/v1/are/workshops/${workshopId}/attend`

    return useApi(auth, endpoint, {
      method: 'POST',
    }).then(res => res.json())
  },
)

type RSVPWorkshopPayload = {
  auth: AuthContextInterface
  workshopId: string
  conflictedWorkshopId?: string
  action: 'reserve' | 'cancel' | 'status' | 'replace'
}

export const rsvpWorkshop = createAsyncThunk<any, RSVPWorkshopPayload>(
  '/rsvpWorkshop',
  async ({auth, workshopId, conflictedWorkshopId, action}) => {
    const body = {conflictedWorkshopId}

    const endpoint = `/v1/are/workshops/${workshopId}/rsvp/${action}`

    return useApi(auth, endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then(res => res.json())
  },
)
//#endregion

//#region slice
const initialState: SliceState = {
  workshops: [],
  conflictedWorkshop: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'workshops',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getWorkshops.fulfilled, (state, action) => {
      const options = {timeZone: 'America/Chicago', timeZoneName: 'short'}
      const centralCurrentTime = new Date().toLocaleString(
        'en-US',
        options as Intl.DateTimeFormatOptions,
      )
      const currentTime = new Date(centralCurrentTime).getTime()
      let workshops = [...action.payload.workshops]
      workshops = workshops.map(workshop => {
        const centralWorkshopTime = new Date(workshop.date).toLocaleString(
          'en-US',
          options as Intl.DateTimeFormatOptions,
        )
        const workshopTime = new Date(centralWorkshopTime).getTime()

        workshop.isLaunchEnabled = currentTime >= workshopTime

        return workshop
      })

      state.workshops = workshops
      state.status = LoadingStatuses.Succeeded
      state.error = undefined
    })
    builder.addCase(getWorkshops.pending, state => {
      state.workshops = []
      state.conflictedWorkshop = undefined
      state.status = LoadingStatuses.Loading
    })
    builder.addCase(getWorkshops.rejected, (state, action) => {
      state.workshops = []
      state.status = LoadingStatuses.Failed
      state.error = action.error.message
    })
    builder.addCase(getGuestWorkshops.fulfilled, (state, action) => {
      state.workshops = action.payload.workshops
      state.status = LoadingStatuses.Succeeded
      state.error = undefined
    })
    builder.addCase(getGuestWorkshops.pending, state => {
      state.workshops = []
      state.conflictedWorkshop = undefined
      state.status = LoadingStatuses.Loading
    })
    builder.addCase(getGuestWorkshops.rejected, (state, action) => {
      state.workshops = []
      state.status = LoadingStatuses.Failed
      state.error = action.error.message
    })
    builder.addCase(rsvpWorkshop.fulfilled, (state, action) => {
      state.status = LoadingStatuses.Succeeded
      state.error = undefined

      if (state.workshops && !action.payload.conflict?.id) {
        let workshops = [...state.workshops]
        workshops = workshops.map(workshop => {
          if (workshop.id === action.meta.arg.workshopId) {
            if (['replace', 'status'].includes(action.meta.arg.action)) {
              workshop.rsvp = action.payload.rsvp ?? false
            } else {
              workshop.rsvp = action.meta.arg.action === 'reserve'
            }
          }

          return workshop
        })
        state.workshops = workshops
      }
      state.conflictedWorkshop = action.payload.conflict
    })
    builder.addCase(rsvpWorkshop.pending, state => {
      state.conflictedWorkshop = undefined
      state.status = LoadingStatuses.Loading
    })
    builder.addCase(rsvpWorkshop.rejected, (state, action) => {
      state.status = LoadingStatuses.Failed
      state.error = action.error.message
    })
  },
})
//#endregion

//#region selectors
export const selectWorkshops = ({workshop}: RootState) => workshop.workshops
export const selectConflictedWorkshop = ({workshop}: RootState) =>
  workshop.conflictedWorkshop
//#endregion
