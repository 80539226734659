import React, {useState} from 'react'
import {Link as RouterLink, useLocation, useParams} from 'react-router-dom'
import {Box} from '@mui/material'
import {SideNavWrapper, SideNavContainer} from './styles'
import {settingsNav, admingNavSettings} from '../../constants/settingsNav'
import {Button} from '../button'
import {LinkButton} from '../../styles/global'
import {useAuth} from '../AuthProvider'
import {useSelector} from 'react-redux'
import {selectMember} from '../../redux/members'

const View = () => {
  const [isSuperAdmin] = useState(false)
  const {setting} = useParams()

  const location = useLocation()

  const member = useSelector(selectMember)

  const auth = useAuth()

  const isGroupAccount =
    member?.groupAccounts && member.groupAccounts.length > 0

  return (
    <SideNavWrapper>
      <SideNavContainer>
        {member && member.isGroupAdmin ? (
          <Box sx={{marginBottom: '15px'}}>
            {admingNavSettings.map(({name, url}, key) => {
              return (
                <LinkButton
                  key={`AdminNav-${key}`}
                  component={RouterLink}
                  to={url}
                  $isActive={url === location.pathname}
                >
                  {name}
                </LinkButton>
              )
            })}
          </Box>
        ) : null}

        {settingsNav.map(({name, url, slug}, key) => {
          if (isGroupAccount && !member.isGroupAdmin && slug === 'billing') {
            return null
          } else {
            return (
              <LinkButton
                key={`Nav-${key}`}
                $isActive={slug === setting}
                component={RouterLink}
                to={url}
              >
                {name}
              </LinkButton>
            )
          }
        })}

        {isSuperAdmin ? (
          <Button color={'secondary'} children={'quality assurance'}></Button>
        ) : null}

        <Button
          className="logout-btn"
          color={'black'}
          children={'Log out'}
          onClick={auth.logout}
        ></Button>
      </SideNavContainer>
    </SideNavWrapper>
  )
}

export default View
