import * as React from 'react'
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  SectionContent,
  SectionHeader,
  SocialBox,
  TwitterIcon,
  YoutubeIcon,
} from '../../styles/global'

export default function FollowUs() {
  return (
    <>
      <SectionHeader>Follow us</SectionHeader>
      <SectionContent>
        Follow us to be the first to know of course catalog updates, tutorials,
        and architecture / design news and events.
      </SectionContent>
      <SocialBox>
        <a href="https://www.facebook.com/blackspectacles/" target="_blank">
          <FacebookIcon />
        </a>
        <a href="https://twitter.com/blackspectacles" target="_blank">
          <TwitterIcon />
        </a>
        <a
          href="https://www.youtube.com/user/iloveblackspectacles"
          target="_blank"
        >
          <YoutubeIcon />
        </a>
        <a href="https://www.instagram.com/black.spectacles/" target="_blank">
          <InstagramIcon />
        </a>
        <a href="https://www.linkedin.com/company/1932680" target="_blank">
          <LinkedinIcon />
        </a>
      </SocialBox>
    </>
  )
}
