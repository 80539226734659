import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../../hooks/useApi'
import {Product} from './product'

//#region types
export type Member = {
  memberId?: string
}

export type PaymentDetails = {
  cardName?: string
  address1: string
  address2?: string
  city: string
  state: string
  postalCode: string
  country: string
  phoneNumber: string
  phoneExtension?: string
  saveCreditCard?: boolean
  token?: string
  products?: Product[]
  member?: Member
}
//#endregion

//#region api
type CreateNewPaymentPayload = {
  paymentDetails: PaymentDetails
  products: Product[]
  coupon?: string
  taxId: string
}
export const guestCreateSubscription = createAsyncThunk<
  any,
  CreateNewPaymentPayload
>('/createSubscription', async ({paymentDetails, products, coupon, taxId}) => {
  const filteredProducts: any = []

  products.forEach(product => filteredProducts.push({price_id: product.id}))

  const fields = {
    customer: {
      member_id: paymentDetails.member?.memberId
        ? paymentDetails.member.memberId
        : null,
      phone: paymentDetails.phoneNumber,
      phone_ext: paymentDetails.phoneExtension
        ? paymentDetails.phoneExtension
        : null,
      address: {
        address_1: paymentDetails.address1,
        address_2: paymentDetails.address2 ? paymentDetails.address2 : null,
        city: paymentDetails.city,
        state: paymentDetails.state,
        postal_code: paymentDetails.postalCode,
        country: paymentDetails.country,
      },
    },
    products: filteredProducts,
    // Stripe is case sensitive so coupons must be converted uppercase before sending to api
    coupon: coupon?.toUpperCase(),
    txr: taxId,
  }

  return useApi(
    null,
    `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/guest/payment/subscription`,
    {
      method: 'POST',
      body: JSON.stringify(fields),
    },
  ).then(res => res.json())
})
