import React, {useState, useEffect} from 'react'
import {Box, Typography, TextField} from '@mui/material'
import {Button} from '../../button'
import {useAuth} from '../../AuthProvider'
import {Link, useParams} from 'react-router-dom'
import {Link as RouterLink} from 'react-router-dom'
import {
  getReportedExamResults,
  submitExamDate,
  updateExamDate,
  selectExamReports,
  deleteExamDate,
} from '../../../redux/reportExamStatus'
import {useAppDispatch} from '../../../redux/configureStore'
import {useSelector} from 'react-redux'
import {toggleErrorDialog} from '../../../redux/config'
import {PrimaryLinkButton} from '../../../styles/global'
import {EditLink} from './styles'
import {ButtonBox} from '../StudyMaterials/styles'

type Props = {
  courseAbbreviation: 'PcM' | 'PjM' | 'PA' | 'PPD' | 'PDD' | 'CE'
}

type Division =
  | 'Practice Management'
  | 'Project Management'
  | 'Programming & Analysis'
  | 'Project Planning & Design'
  | 'Project Development & Documentation'
  | 'Construction & Evaluation'

const View = ({courseAbbreviation}: Props) => {
  const auth = useAuth()
  const {courseId} = useParams()
  const [dateValue, setDateValue] = useState('')
  const dispatch = useAppDispatch()
  const examReports = useSelector(selectExamReports)
  const [scheduled, setScheduled] = useState(false)
  const [schedule, setSchedule] = useState('')
  const [passed, setPassed] = useState(false)
  const [editing, setEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [attemptId, setAttemptId] = useState('')

  const handleSubmit = async (update?: boolean) => {
    if (courseId && dateValue) {
      setIsSubmitting(true)

      const submitFields = {
        auth,
        courseId: courseId,
        examDate: dateValue,
        pass: null,
      }

      const updateFields = {
        auth,
        entryId: attemptId,
        examDate: dateValue,
      }

      try {
        if (update) {
          const {type} = await dispatch(updateExamDate(updateFields))

          if (type === updateExamDate.rejected.type) {
            setIsSubmitting(false)
            throw new Error('Failed to update exam schedule')
          }
        } else {
          const {type} = await dispatch(submitExamDate(submitFields))
          if (type === submitExamDate.rejected.type) {
            setIsSubmitting(false)
            throw new Error('Failed to submit exam schedule')
          }
        }

        await dispatch(getReportedExamResults({auth}))
        setIsSubmitting(false)
        setEditing(false)
      } catch (e) {
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to submit or update your exam schedule, please retry. If issue persists please contact our support team',
        })
      }
    }
  }

  const calculateDays = () => {
    let days =
      (new Date(schedule).getTime() - new Date().getTime()) /
      (1000 * 60 * 60 * 24)

    days = days > Math.round(days) ? Math.round(days) + 1 : Math.round(days)

    return days
  }

  const formatScheduleDate = () => {
    const date = schedule.split('T')[0].split('-')
    const formattedDate = date[1] + '/' + date[2] + '/' + date[0]

    const scheduledDate = new Date(schedule).setHours(0, 0, 0, 0)
    const todaysDate = new Date().setHours(0, 0, 0, 0)

    if (scheduledDate > todaysDate) {
      return (
        <>
          <Typography variant={'body1'}>
            You have this exam scheduled for {formattedDate}.{' '}
          </Typography>

          {editing ? (
            <>
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="date"
                sx={{
                  marginRight: '15px',
                  marginTop: '10px !important',
                  width: '180px !important',
                  '&& div': {
                    width: '100% !important',
                    maxWidth: '180px',
                    height: '40px',
                  },
                }}
                onChange={e => setDateValue(e.target.value)}
              />

              <Button
                style={{marginRight: 15, marginTop: 10}}
                color={'primary'}
                children={'Submit'}
                onClick={() => (editing ? handleSubmit(true) : handleSubmit())}
                disabled={isSubmitting}
              />

              <Button
                style={{marginTop: 10}}
                color={'grey'}
                children={'Delete'}
                onClick={handleDelete}
                disabled={isSubmitting}
              />
            </>
          ) : (
            <EditLink onClick={() => setEditing(true)}>Edit</EditLink>
          )}
        </>
      )
    } else if (scheduledDate === todaysDate) {
      return `Your exam is sheduled for today. Good luck!`
    } else {
      return (
        <>
          You took this exam on {formattedDate}.{' '}
          <Link
            to={`/profile/settings/are_progress/${courseAbbreviation.toLowerCase()}`}
          >
            Let us know how you did!
          </Link>
        </>
      )
    }
  }

  const handleScheduledDisplay = () => {
    const days = calculateDays()
    let message = ''

    if (days > 1) {
      message = `${days} days to go`
    } else if (days === 1) {
      message = `${days} day to go`
    } else {
      message = 'How did you do?'
    }

    return message
  }

  const handleDelete = async () => {
    setIsSubmitting(true)
    try {
      const {type} = await dispatch(deleteExamDate({auth, entry_id: attemptId}))
      if (type === deleteExamDate.rejected.type) {
        setIsSubmitting(false)
        throw new Error('Unable to delete exam attempt')
      }

      setEditing(false)
      setScheduled(false)
      setIsSubmitting(false)
    } catch (e) {
      toggleErrorDialog({
        opened: true,
        error:
          'We were unable to delete this exam attempt, please retry. If issue persists please contact our support team',
      })
    }
  }

  useEffect(() => {
    if (examReports) {
      Object.keys(examReports).map(key => {
        const exam = examReports[key as Division]

        if (exam?.attempts[exam?.attempts.length - 1]?.courseId === courseId) {
          if (exam?.passed) {
            setPassed(true)
          }

          if (exam?.scheduled) {
            setScheduled(true)
            setSchedule(exam?.scheduled)

            // set attempt id for if user wants to delete a schedule exam
            setAttemptId(exam.attempts[exam?.attempts.length - 1].attemptId)
          }
        }
      })
    }
  }, [examReports])

  const showDialog = async () => {
    await dispatch(
      toggleErrorDialog({
        opened: true,
        title: `Report ${courseAbbreviation} results`,
        ctaType: 'login',
        error: `YOU'RE ONE STEP AWAY FROM UPDATING YOUR RESULTS!`,
      }),
    )
  }

  return (
    <>
      <Box>
        {passed || scheduled ? (
          <Typography variant={'h3'}>
            {passed ? 'Congratulations!' : handleScheduledDisplay()}
          </Typography>
        ) : (
          <Typography variant={'h3'}>Exam Status</Typography>
        )}

        {auth.isAuthenticated ? (
          <>
            {!passed && !scheduled ? (
              <>
                <Typography variant={'body1'} marginTop={'20px'}>
                  Enter your scheduled exam date to start your countdown.
                </Typography>

                <Box style={{marginTop: '20px', display: 'flex', gap: '8px'}}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="date"
                    sx={{
                      width: '180px !important',
                      '&& div': {
                        width: '100% !important',
                        maxWidth: '180px',
                        height: '40px',
                      },
                    }}
                    onChange={e => setDateValue(e.target.value)}
                  />

                  <Button
                    color={'primary'}
                    children={'Submit'}
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  />
                </Box>
              </>
            ) : (
              <Typography variant={'body1'} marginTop={'20px'}>
                {passed ? "You've passed this exam!" : formatScheduleDate()}
              </Typography>
            )}
          </>
        ) : (
          <>
            <Typography variant={'body1'} marginTop={'20px'}>
              You haven't taken this exam yet.
            </Typography>
          </>
        )}
      </Box>

      <ButtonBox>
        {auth.isAuthenticated ? (
          <PrimaryLinkButton
            component={RouterLink}
            to={`/profile/settings/are_progress/${courseAbbreviation.toLowerCase()}`}
          >
            Report {courseAbbreviation} results
          </PrimaryLinkButton>
        ) : (
          <Button
            color="primary"
            children={`Report ${courseAbbreviation} results`}
            onClick={showDialog}
          />
        )}
      </ButtonBox>
    </>
  )
}

export default View
