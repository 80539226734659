import {Box, Typography} from '@mui/material'
import styled from 'styled-components'

export const ModalContainer = styled(Box)`
  border: 8px solid #ebeff0;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  max-height: 85vh;
  overflow-y: scroll;
  padding: 40px;
`

export const Title = styled(Typography)`
  text-align: center;
  padding-bottom: 30px;
`

export const Description = styled(Typography)`
  text-align: center;
  padding-bottom: 60px;
  border-bottom: 8px solid #ebeff0;
`

export const ImagesContainer = styled(Box)`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ARE5Image = styled('img')`
  margin: 0 auto 30px;
  width: 280px;
  max-width: 500px;
`

export const NCARBImage = styled('img')`
  margin: 0 auto 30px;
  max-width: 500px;
`

export const GuaranteeText = styled(Typography)`
  text-align: center;
  padding-bottom: 30px;
`
