import styled from 'styled-components'
import {Box, Typography} from '@mui/material'

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
`

export const LeftHeader = styled(Box)``

export const RightHeader = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const HeaderTitle = styled(Typography)`
  text-transform: none !important;
  font-size: 32px !important;
  color: #2d2d2d !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
`

export const ItemTitle = styled(Typography)`
  text-transform: none !important;
  font-size: 24px !important;
  color: #2d2d2d !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
`

export const SubTitle = styled(Typography)`
  font-family: Public Sans !important;
  text-transform: none !important;
  font-size: 16px !important;
`

export const DateButton = styled(Box)`
  background-color: #f1f4f6;
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition:
    color 0.15s,
    background-color 0.15s;
  font-weight: bold;

  &:hover {
    background-color: #1377d4;
    color: #fff;
  }
`

export const NumbersBox = styled(Box)`
  display: flex;
  justify-content: space-around;
`

export const NumberItem = styled(Box)<{$isLink?: boolean}>`
  display: flex;
  flex-flow: column;
  gap: 8px;
  align-items: center;
  width: 200px;

  ${({$isLink}) =>
    $isLink
      ? `
		cursor: pointer;
		color: #1377D4;

		&:hover {
			color: #23527c;
		}
	`
      : null}
`

export const NumberTitle = styled(Box)`
  text-transform: uppercase;
  font-size: 14px;
`

export const NumberSubTitle = styled(Box)`
  font-size: 36px;
`

export const SpacingBox = styled(Box)`
  margin-top: 32px;
`

export const LeftLegend = styled.span`
  font-size: 14px;
  position: absolute;
  left: -70px;
  top: 50%;
  transform: translateY(-50%) rotate(270deg);
`

export const RightLegend = styled.span`
  font-size: 14px;
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
`

export const BottomLegend = styled.span`
  font-size: 14px;
  display: flex;
  width: 100%;
  justify-content: center;
`
