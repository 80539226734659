import React, {useEffect} from 'react'
import {Box, Typography} from '@mui/material'
import {List, ListHeader, DataContainer, DataRow} from './styles'
import {v4 as uuidv4} from 'uuid'
import {useAppDispatch} from '../../../../redux/configureStore'
import {useAuth} from '../../../../components/AuthProvider'
import {
  getBillingHistory,
  selectPayments,
} from '../../../../redux/billingHistory'
import {toggleErrorDialog} from '../../../../redux/config'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {StyledTitle} from '../../../../styles/global'

const View = () => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const payments = useSelector(selectPayments)

  const fetchBillingHistory = async () => {
    try {
      const {type} = await dispatch(getBillingHistory({auth}))

      if (type === getBillingHistory.rejected.type) {
        throw new Error('Unable to fetch billing history')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to fetch your billing history, please try reloading the page. If the issue persists contact our support team.',
        }),
      )
    }
  }

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000)

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const monthName = monthNames[date.getUTCMonth()]
    const day = date.getUTCDate()
    const year = date.getUTCFullYear()

    const formattedDate = `${monthName} ${day}, ${year}`
    return formattedDate
  }

  const formatPricing = (amount: number) => {
    const formattedAmount = (amount / 100).toFixed(2)
    return `$${formattedAmount}`
  }

  useEffect(() => {
    fetchBillingHistory()
  }, [])

  return (
    <Box>
      <StyledTitle variant={'h2'} style={{marginBottom: '32px'}}>
        Billing History
      </StyledTitle>

      {payments && payments.length > 0 ? (
        <List>
          <ListHeader>
            <Typography sx={{width: '25%'}} variant={'body1'}>
              Date
            </Typography>
            <Typography sx={{width: '50%'}} variant={'body1'}>
              Description
            </Typography>
            <Typography
              sx={{width: '25%', textAlign: 'right'}}
              variant={'body1'}
            >
              Amount
            </Typography>
          </ListHeader>

          <DataContainer>
            {payments.map(({date, description, amount, invoice}) => (
              <DataRow className="billing-row" key={uuidv4()}>
                <Typography sx={{width: '25%'}} variant={'body1'}>
                  {formatDate(date)}
                </Typography>

                <Typography sx={{width: '50%'}} variant={'body1'}>
                  <Link to={invoice}>{description}</Link>
                </Typography>

                <Typography
                  sx={{width: '25%', textAlign: 'right'}}
                  variant={'body1'}
                >
                  {formatPricing(amount)}
                </Typography>
              </DataRow>
            ))}
          </DataContainer>
        </List>
      ) : (
        <Typography variant={'body1'}>
          There are no invoices for you to view.
        </Typography>
      )}
    </Box>
  )
}

export default View
