import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../../hooks/useApi'

export type AccountDetails = {
  email: string
}

type validateEmailPayload = {
  email: string
}

export const validateEmail = createAsyncThunk<any, validateEmailPayload>(
  '/validateEmail',
  async ({email}) => {
    const fields = {
      email,
    }

    // uses GO network services endpoint
    return useApi(
      null,
      `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/guest/are/member/email`,
      {
        method: 'POST',
        body: JSON.stringify(fields),
      },
    ).then(async res => {
      const accounts = await res.json()

      return {
        email,
        accounts,
      }
    })
  },
)
