import React from 'react'
import View from './view'
import {VideoGroup} from '../../redux/videos'
import {Course} from '../../redux/courses'

type Props = {
  id: string
  course: Course
  currentGroup?: VideoGroup
  previousGroup?: VideoGroup
  nextGroup?: VideoGroup
  mobileOpen: boolean
  currentIndex?: number
}

const VideoSideBar = ({
  id,
  course,
  currentGroup,
  previousGroup,
  nextGroup,
  mobileOpen,
  currentIndex,
}: Props) => {
  return (
    <View
      id={id}
      course={course}
      currentGroup={currentGroup}
      previousGroup={previousGroup}
      nextGroup={nextGroup}
      mobileOpen={mobileOpen}
      currentIndex={currentIndex}
    />
  )
}

export default VideoSideBar
