import React from 'react'
import {useSelector} from 'react-redux'
import {Box, Typography, LinearProgress} from '@mui/material'
import {Button} from '../../button'
import {Link as RouterLink} from 'react-router-dom'
import {
  SectionProgress,
  SectionProgressContainer,
  SectionTextLink,
} from '../../SectionContainer/styles'

import VideoIcon from '../../../images/icons/video.svg'
import PersonIcon from '../../../images/icons/person.svg'
import {LectureSlides} from '../../../constants/lectureSlides'
import {SubTitle, VideoLecturesContainer} from './styles'
import {ButtonBox, Row} from '../StudyMaterials/styles'
import {DashboardVideo} from '../../../redux/dashboard'
import formatSeconds from '../../../utils/formatSeconds'
import {Course} from '../../../redux/courses'
import {useAuth} from '../../AuthProvider'
import {PrimaryLinkButton} from '../../../styles/global'
import {selectMemberAccess} from '../../../redux/members'

type Props = {
  course?: Course
  videoData?: DashboardVideo
  paData?: DashboardVideo
}

const View = ({course, videoData, paData}: Props) => {
  const auth = useAuth()

  const access = useSelector(selectMemberAccess)

  let percentagePlaceholderValue = 0
  let timeRemainingPlaceholderValue = '00:00:00'

  let paPercentagePlaceholderValue = 0
  let paTimeRemainingPlaceholderValue = '00:00:00'

  if (videoData) {
    percentagePlaceholderValue = Math.round(
      (videoData.totalTimeViewedSec / videoData.totalVideoTimeSec) * 100,
    )

    const remainingTime =
      videoData.totalVideoTimeSec - videoData.totalTimeViewedSec
    timeRemainingPlaceholderValue = formatSeconds(
      remainingTime > 0 ? remainingTime : 0,
    )
  }

  if (paData) {
    paPercentagePlaceholderValue = Math.round(
      (paData.totalTimeViewedSec / paData.totalVideoTimeSec) * 100,
    )
    const paRemainingTime = paData.totalVideoTimeSec - paData.totalTimeViewedSec
    paTimeRemainingPlaceholderValue = formatSeconds(
      paRemainingTime > 0 ? paRemainingTime : 0,
    )
  }

  const goToLectureSlide = () => {
    if (auth.isAuthenticated && course?.abbreviation) {
      const url = LectureSlides[course.abbreviation]
      window.open(url)
    }
  }

  const hasAccess =
    access && Object.keys(access).length !== 0
      ? Number(access.are?.practical_apps?.value) === 1
      : false

  return (
    <VideoLecturesContainer className="ignore">
      <Row>
        <Box>
          <Typography variant={'h3'}>Video Lectures</Typography>

          <SectionProgressContainer>
            <Typography variant={'body1'}>Viewed</Typography>

            <SectionProgress>
              <Box sx={{width: '100%'}}>
                <LinearProgress
                  variant="determinate"
                  value={percentagePlaceholderValue}
                />
              </Box>

              <Typography variant={'body2'} style={{padding: 0}}>
                {isNaN(percentagePlaceholderValue)
                  ? 0
                  : percentagePlaceholderValue}
                %
              </Typography>
            </SectionProgress>
          </SectionProgressContainer>

          <Typography variant={'body2'} marginTop={'10px'}>
            <em>{timeRemainingPlaceholderValue} remaining</em>
          </Typography>
          <SectionTextLink
            to={`/courses/${course?.id}/video/${videoData?.nextVideoId}`}
            style={{display: 'flex', alignItems: 'center', marginTop: '16px'}}
          >
            <img src={VideoIcon} className="ignore" />
            <span className="ignore">Watch next video</span>
          </SectionTextLink>
        </Box>
        <ButtonBox>
          <PrimaryLinkButton
            component={RouterLink}
            to={`/courses/${course?.id}`}
          >
            Watch
          </PrimaryLinkButton>
        </ButtonBox>
      </Row>

      <Row>
        <Typography variant={'h3'}>Lecture Slides</Typography>
        <ButtonBox>
          <Button
            color={'primary'}
            children={'Download'}
            onClick={goToLectureSlide}
            event="file_download"
          />
        </ButtonBox>
      </Row>

      {(Number(paData?.totalTimeViewedSec) > 0 ||
        Number(paData?.totalVideoTimeSec) > 0) && (
        <Row>
          <Box>
            <SubTitle>Practical Applications</SubTitle>

            <SectionProgressContainer marginTop={'20px'}>
              <Typography variant={'body1'}>Viewed</Typography>

              <SectionProgress>
                <Box sx={{width: '100%'}}>
                  <LinearProgress
                    variant="determinate"
                    value={paPercentagePlaceholderValue}
                  />
                </Box>

                <Typography variant={'body2'}>
                  {isNaN(paPercentagePlaceholderValue)
                    ? 0
                    : paPercentagePlaceholderValue}
                  %
                </Typography>
              </SectionProgress>
            </SectionProgressContainer>
            <Typography variant={'body2'} marginTop={'10px'}>
              <em>{paTimeRemainingPlaceholderValue} remaining</em>
            </Typography>
            <SectionTextLink
              to={`/practical-applications`}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '16px',
                cursor: 'pointer',
              }}
              $isDisabled={!hasAccess}
            >
              <img src={PersonIcon} className="ignore" />
              <span className="ignore">View All</span>
            </SectionTextLink>
          </Box>
        </Row>
      )}
    </VideoLecturesContainer>
  )
}

export default View
