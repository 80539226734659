import React, {useState} from 'react'
import {Box} from '@mui/material'
import {
  BreakBox,
  BreakText,
  BreakTitle,
  CheckBox,
  ColorPicker,
  ColorPickerRow,
  ColoredBox,
  ColoredBoxBig,
  ColumnBox,
  ColumnItem,
  ExamBox,
  ExamHeader,
  FixedBottom,
  LeftBox,
  LeftButtonBox,
  LogoBox,
  MarkReviewBox,
  RightBox,
  StyledLogo,
  TitleBox,
  Toolbar,
  ToolbarItem,
  ToolbarSubItem,
  UpperBox,
  WhiteboardBox,
  WhiteboardHeader,
} from './styles'
import {Button} from '../button'
import {Exam} from '../../redux/exams'
import renderHTML from '../../utils/renderHTML'
import ExamHotspot from '../ExamHotspot'
import Loading from '../Loading'
import ExamDrag from '../ExamDrag'
import BksLogo from '../../images/black-spectacles.svg'
import Flag from '../../images/icons/flag.png'
import BksWhite from '../../images/icons/bks-white-icon.svg'
import PreExam from './PreExam'
import ExamCheckall from '../ExamCheckall'
import ExamMultiple from '../ExamMultiple'
import Summary from './Summary'
import Countdown from 'react-countdown'
import {ExamBreakDialog} from '../ExamBreakDialog'
import {EndExamDialog} from '../EndExamDialog'
import ExamBlank from '../ExamBlank'
import {useWindowDimensions} from '../../hooks/useWindowDimensions'
import theme from '../../styles/theme'
import {useSelector} from 'react-redux'
import {selectMember} from '../../redux/members'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS'
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import {highlight, removeHighlights} from '../../utils/textHighlighter'
import References from '../References'
import Calculator from '../Calculator'
import CaseStudyTabs from './CaseStudyTabs'

type Props = {
  onStartExam: () => void
  exam?: Exam
  currentQuestion: number
  onChangeCurrentQuestion: (
    value: number,
    orientation?: string,
    newState?: string,
  ) => void
  answers: any
  onChangeAnswers: (value: any) => void
  isSubmitting: boolean
  onSubmit: (questionId: string) => void
  onChangeForReview: (questionId: string, value: boolean) => void
  seenQuestions: string[]
  onUpdateReviewState: (value: string) => void
  onUpdateActivePage: (value: string) => void
  activePage: string
  onChangeIsExamEnded: (value: string) => void
  onStartBreak: () => void
  onEndBreak: () => void
  attemptId?: string | null
}

const View = ({
  onStartExam,
  exam,
  currentQuestion,
  onChangeCurrentQuestion,
  answers,
  onChangeAnswers,
  isSubmitting,
  onSubmit,
  onChangeForReview,
  seenQuestions,
  onUpdateReviewState,
  onUpdateActivePage,
  activePage,
  onChangeIsExamEnded,
  onStartBreak,
  onEndBreak,
  attemptId,
}: Props) => {
  const {width} = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  const member = useSelector(selectMember)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isEndExamDialogOpen, setIsEndExamDialogOpen] = useState(false)
  const [activeHighlightClass, setActiveHighlightClass] =
    useState('HighlightColor1')
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [isWhiteboardOpen, setIsWhiteboardOpen] = useState(false)
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false)
  const [isReferencesOpen, setIsReferencesOpen] = useState(false)

  const question = exam?.questions[currentQuestion]

  const questionTitle = question?.title.split('-')
  const questionId = questionTitle && questionTitle[3] ? questionTitle[3] : ''

  const handleSubmit = () => {
    const questionId = question?.id

    const examBox = document.getElementById('exam-box')
    if (examBox) {
      examBox.style.cursor = ''
    }

    if (questionId) {
      if (!question.isBlocked) {
        onSubmit(questionId)
      }
      onChangeCurrentQuestion(currentQuestion, 'next')
      setIsWhiteboardOpen(false)
      setIsCalculatorOpen(false)
      setIsReferencesOpen(false)
    }
  }

  const goToReview = (state: string) => {
    if (state === 'marked') {
      const filteredAnswers = exam?.questions.filter(item => item.forReview)

      if (filteredAnswers?.length === 0) {
        alert('There are no marked questions to review.')
        return
      }
    } else if (state === 'unanswered') {
      const filteredAnswers = exam?.questions.filter(
        item => !item.currentAnswer,
      )

      if (filteredAnswers?.length === 0) {
        alert('There are no unanswered questions to review.')
        return
      }
    }
    onUpdateReviewState(state)
    onChangeCurrentQuestion(0, 'first', state)
    onUpdateActivePage('question')
  }

  const goToSummary = () => {
    const questionId = question?.id
    if (questionId) {
      onSubmit(questionId)
      onUpdateActivePage('summary')
    }
  }

  const goToQuestion = (value: number) => {
    onUpdateReviewState('all')
    onUpdateActivePage('question')
    onChangeCurrentQuestion(value, 'current', 'all')
  }

  const handlePaginate = (value: number, orientation: string) => {
    onChangeCurrentQuestion(value, orientation)
  }

  const selectForReview = (questionId: string, value: boolean) => {
    onChangeForReview(questionId, value)
  }

  const selectAnswer = (value: any) => {
    const stateAnswers = [...answers]
    stateAnswers[currentQuestion] = value
    onChangeAnswers(stateAnswers)
  }

  const startBreak = () => {
    onUpdateActivePage('break')
    localStorage.setItem('examActivePage', 'break')
    setIsDialogOpen(false)
    onStartBreak()
  }

  const openWhiteboard = () => {
    setIsWhiteboardOpen(true)
  }

  const windowWidth = window.innerWidth
  const positionX = windowWidth - 875
  const calculatorPositionX = windowWidth - 741

  const renderQuestion = (type: string) => {
    if (type === 'checkall') {
      return (
        <ExamCheckall
          exam={exam}
          currentQuestion={currentQuestion}
          answer={answers[currentQuestion]}
          currentAnswer={question?.currentAnswer}
          isReviewing={false}
          onSelect={selectAnswer}
        />
      )
    } else if (type === 'hotspot') {
      return (
        <ExamHotspot
          exam={exam}
          currentQuestion={currentQuestion}
          answer={answers[currentQuestion]}
          currentAnswer={question?.currentAnswer}
          isReviewing={false}
          onSelect={selectAnswer}
        />
      )
    } else if (type === 'drag') {
      return (
        <ExamDrag
          exam={exam}
          currentQuestion={currentQuestion}
          answer={answers[currentQuestion]}
          currentAnswer={question?.currentAnswer}
          isReviewing={false}
          onSelect={selectAnswer}
        />
      )
    } else if (type === 'multiple') {
      return (
        <ExamMultiple
          exam={exam}
          currentQuestion={currentQuestion}
          answer={answers[currentQuestion]}
          currentAnswer={question?.currentAnswer}
          isReviewing={false}
          onSelect={selectAnswer}
        />
      )
    } else if (type === 'blank') {
      return (
        <ExamBlank
          exam={exam}
          currentQuestion={currentQuestion}
          answer={answers[currentQuestion]}
          currentAnswer={question?.currentAnswer}
          isReviewing={false}
          onSelect={selectAnswer}
        />
      )
    }
  }

  const courseTitle = localStorage.getItem('currentCourseTitle')

  const renderer = ({total, hours, minutes, seconds, completed}: any) => {
    if (completed) {
      onChangeIsExamEnded('expired')
    } else {
      localStorage.setItem('timerProgress', String(total / 1000))
      return (
        <span>
          {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:
          {String(seconds).padStart(2, '0')}
        </span>
      )
    }
  }

  const pickColor = (className: string) => {
    setActiveHighlightClass(className)
    setIsColorPickerOpen(false)
  }

  const breakRenderer = ({total, hours, minutes, seconds, completed}: any) => {
    if (completed) {
      onUpdateActivePage('summary')
    } else {
      localStorage.setItem('remaningBreakTime', String(total / 1000))
      return (
        <span>
          Break remaining: {String(hours).padStart(2, '0')}:
          {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
        </span>
      )
    }
  }

  if (activePage === 'break') {
    return (
      <BreakBox>
        <BreakTitle>On Break</BreakTitle>
        <BreakText>
          <Countdown
            date={
              Date.now() +
              (localStorage.getItem('remaningBreakTime')
                ? parseInt(localStorage.getItem('remaningBreakTime')!) * 1000
                : 0)
            }
            renderer={breakRenderer}
          />
          <span>
            Click <b>Return to test</b> to end the break and continue your exam.
          </span>

          <Button
            children={'RETURN TO TEST'}
            onClick={() => {
              onUpdateActivePage('summary')
              localStorage.setItem('examActivePage', 'summary')
              onEndBreak()
            }}
            color="black"
          />
        </BreakText>
      </BreakBox>
    )
  }

  return (
    <>
      <ExamHeader>
        <LogoBox>
          <img src={BksWhite} />
        </LogoBox>
        <TitleBox>
          <UpperBox>
            {((exam && !isMobile) || !exam) && (
              <LeftBox>
                <h1>ARE 5.0 {courseTitle} Practice Exam</h1>
                <span>{`${member?.firstName} ${member?.lastName}`}</span>
              </LeftBox>
            )}
            {exam && (
              <RightBox>
                <ColumnBox>
                  <ColumnItem>Time Remaining</ColumnItem>
                  <ColumnItem>
                    {localStorage.getItem('timerProgress') ||
                    exam.examTimeRemaining ? (
                      <Countdown
                        date={
                          Date.now() +
                          (localStorage.getItem('timerProgress')
                            ? parseInt(localStorage.getItem('timerProgress')!) *
                              1000
                            : 0)
                        }
                        renderer={renderer}
                      />
                    ) : null}
                  </ColumnItem>
                </ColumnBox>
                <ColumnBox>
                  {activePage !== 'summary' && (
                    <>
                      <ColumnItem>Question</ColumnItem>
                      <ColumnItem>
                        {currentQuestion + 1} of {exam?.questionCount}
                      </ColumnItem>
                    </>
                  )}
                </ColumnBox>
              </RightBox>
            )}
          </UpperBox>
          <Toolbar>
            {exam && (
              <>
                {activePage === 'summary' ? (
                  <ToolbarItem
                    style={isMobile ? {} : {marginLeft: 'auto'}}
                    onClick={() => setIsDialogOpen(true)}
                  >
                    <AccessTimeIcon fontSize="small" />{' '}
                    {isMobile ? '' : 'Take a Break'}
                  </ToolbarItem>
                ) : (
                  <>
                    <ToolbarItem>
                      <ColoredBox
                        className={activeHighlightClass}
                        onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                      />
                      <ColorPicker $isOpen={isColorPickerOpen}>
                        <ColorPickerRow>
                          <ColoredBoxBig
                            className={'HighlightColor1'}
                            onClick={() => pickColor('HighlightColor1')}
                          />
                          <ColoredBoxBig
                            className={'HighlightColor2'}
                            onClick={() => pickColor('HighlightColor2')}
                          />
                          <ColoredBoxBig
                            className={'HighlightColor3'}
                            onClick={() => pickColor('HighlightColor3')}
                          />
                          <ColoredBoxBig
                            className={'HighlightColor4'}
                            onClick={() => pickColor('HighlightColor4')}
                          />
                          <ColoredBoxBig
                            className={'HighlightColor5'}
                            onClick={() => pickColor('HighlightColor5')}
                          />
                        </ColorPickerRow>
                        <ColorPickerRow>
                          <ColoredBoxBig
                            className={'HighlightColor0'}
                            onClick={() => pickColor('HighlightColor0')}
                          />
                          <span
                            onClick={() => {
                              removeHighlights()
                              setIsColorPickerOpen(false)
                            }}
                          >
                            Remove Highlight
                          </span>
                        </ColorPickerRow>
                      </ColorPicker>
                      <ToolbarSubItem
                        onClick={() =>
                          highlight(activeHighlightClass, currentQuestion)
                        }
                      >
                        <DriveFileRenameOutlineIcon fontSize="small" />{' '}
                        {isMobile ? '' : 'Highlight'}
                      </ToolbarSubItem>
                    </ToolbarItem>
                    <ToolbarItem
                      onClick={() => highlight('exam_strike', currentQuestion)}
                    >
                      <StrikethroughSIcon fontSize="small" />{' '}
                      {isMobile ? '' : 'Strikethrough'}
                    </ToolbarItem>
                    <ToolbarItem onClick={() => setIsCalculatorOpen(true)}>
                      <CalculateOutlinedIcon fontSize="small" />{' '}
                      {isMobile ? '' : 'Calculator'}
                    </ToolbarItem>
                    <ToolbarItem onClick={() => openWhiteboard()}>
                      <LaptopChromebookIcon fontSize="small" />{' '}
                      {isMobile ? '' : 'Whiteboard'}
                    </ToolbarItem>
                    <ToolbarItem onClick={() => setIsReferencesOpen(true)}>
                      <ArticleOutlinedIcon fontSize="small" />{' '}
                      {isMobile ? '' : 'References'}
                    </ToolbarItem>
                    <ToolbarItem
                      style={isMobile ? {} : {marginLeft: 'auto'}}
                      onClick={goToSummary}
                    >
                      <AccessTimeIcon fontSize="small" />{' '}
                      {isMobile ? '' : 'Break'}
                    </ToolbarItem>
                  </>
                )}
              </>
            )}
          </Toolbar>
        </TitleBox>
      </ExamHeader>
      {isSubmitting ? (
        <Loading />
      ) : (
        <>
          {activePage === 'summary' ? (
            <Summary
              exam={exam}
              seenQuestions={seenQuestions}
              selectForReview={selectForReview}
              goToQuestion={goToQuestion}
              goToReview={goToReview}
              toggleDialog={value => setIsEndExamDialogOpen(value)}
              isExamEnded={false}
              onUpdateActivePage={onUpdateActivePage}
            />
          ) : (
            <>
              {!exam ? (
                <PreExam onStartExam={onStartExam} />
              ) : (
                <>
                  <Box position={'relative'} marginTop={isMobile ? 0 : '20px'}>
                    <ExamBox id="exam-box">
                      {question?.examType === 'case_study' && (
                        <CaseStudyTabs
                          caseStudies={exam.caseStudies}
                          question={question}
                        />
                      )}

                      {question?.questionTypeName !== 'drag' &&
                        question?.questionTypeName !== 'hotspot' &&
                        question?.backgroundImage && (
                          <img
                            src={question?.backgroundImage}
                            alt="Question"
                            style={{maxWidth: '100%'}}
                          />
                        )}

                      <Box id="question-stem">
                        {renderHTML(
                          localStorage.getItem(
                            `questionStem${currentQuestion}`,
                          ) ??
                            question?.stem ??
                            '',
                        )}
                      </Box>
                      <Box id="question-body">
                        {renderHTML(
                          localStorage.getItem(
                            `questionBody${currentQuestion}`,
                          ) ??
                            question?.body ??
                            '',
                        )}
                      </Box>

                      {renderQuestion(question?.questionTypeName!)}

                      {isWhiteboardOpen && (
                        <WhiteboardBox
                          default={{
                            x: positionX,
                            y: 0,
                            width: 850,
                            height: 625,
                          }}
                        >
                          <WhiteboardHeader>
                            Whiteboard
                            <span onClick={() => setIsWhiteboardOpen(false)}>
                              Close
                            </span>
                          </WhiteboardHeader>
                          <iframe
                            src={`/whiteboard/${attemptId}`}
                            frameBorder={0}
                          />
                        </WhiteboardBox>
                      )}

                      {isCalculatorOpen && (
                        <WhiteboardBox
                          default={{
                            x: calculatorPositionX,
                            y: 0,
                            width: 716,
                            height: 316,
                          }}
                        >
                          <WhiteboardHeader>
                            Calculator
                            <span onClick={() => setIsCalculatorOpen(false)}>
                              Close
                            </span>
                          </WhiteboardHeader>
                          <Calculator />
                        </WhiteboardBox>
                      )}

                      {isReferencesOpen && (
                        <WhiteboardBox
                          default={{
                            x: positionX,
                            y: -50,
                            width: 850,
                            height: 721,
                          }}
                          enableResizing={false}
                        >
                          <WhiteboardHeader>
                            References
                            <span onClick={() => setIsReferencesOpen(false)}>
                              Close
                            </span>
                          </WhiteboardHeader>

                          <References />
                        </WhiteboardBox>
                      )}
                    </ExamBox>
                  </Box>
                  <FixedBottom style={{paddingLeft: isMobile ? '8px' : '20px'}}>
                    {activePage === 'question' && (
                      <Button
                        children={'PREVIOUS'}
                        color="grey"
                        disabled={currentQuestion === 0}
                        onClick={() =>
                          handlePaginate(currentQuestion, 'previous')
                        }
                      />
                    )}
                    <Button
                      children={'NEXT'}
                      onClick={() => handleSubmit()}
                      color="ncarbExamBlue"
                    />
                    {activePage === 'question' && (
                      <MarkReviewBox
                        onClick={() =>
                          selectForReview(
                            question?.id ?? '',
                            question?.forReview ? false : true,
                          )
                        }
                      >
                        <CheckBox>
                          {question?.forReview ? (
                            <img src={Flag} alt="Flag" />
                          ) : null}
                        </CheckBox>{' '}
                        {isMobile ? 'Review' : 'Mark for Review'}
                      </MarkReviewBox>
                    )}
                    <LeftButtonBox>
                      <Button
                        children={'EXAM SUMMARY'}
                        onClick={goToSummary}
                        color="black"
                      />
                      <span>{questionId}</span>
                    </LeftButtonBox>
                    <StyledLogo src={BksLogo} alt="Black Spectacles" />
                  </FixedBottom>
                </>
              )}
            </>
          )}
        </>
      )}

      <ExamBreakDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSubmit={startBreak}
      />

      <EndExamDialog
        isOpen={isEndExamDialogOpen}
        onClose={() => setIsEndExamDialogOpen(false)}
        onSubmit={() => {
          onChangeIsExamEnded('score')
          setIsEndExamDialogOpen(false)
        }}
      />
    </>
  )
}

export default View
