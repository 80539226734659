import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../../hooks/useApi'

//#region types
export type CreditCardInfo = {
  payment_methods: PaymentMethod[]
  phone: string
  phone_ext?: string
}

type PaymentMethod = {
  id: string
  brand: string
  last_4: string
  exp_month: string
  exp_year: string
  address: AddressDetails
}

type AddressDetails = {
  address_1: string
  address_2: string
  city: string
  country: string
  postal_code: string
  state: string
  phone: string
}
//#endregion

//#region api
type getActiveSubscriptionsPayload = {
  id?: string
  jwt: string
}

export const getAllPaymentMethods = createAsyncThunk<
  any,
  getActiveSubscriptionsPayload
>('/getPaymentMethods', async ({jwt}) => {
  return useApi(
    null,
    `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/network/member/payment_methods`,
    {
      method: 'GET',
      headers: {Authorization: `Bearer ${jwt}`},
    },
  ).then(res => res.json())
})

type getPaymentMethodPayload = {
  id?: string
  jwt: string
  subscriptionId: string
}

export const getPaymentMethod = createAsyncThunk<any, getPaymentMethodPayload>(
  '/getPaymentMethods',
  async ({jwt, subscriptionId}) => {
    return useApi(
      null,
      `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/network/member/payment_method/${subscriptionId}`,
      {
        method: 'GET',
        headers: {Authorization: `Bearer ${jwt}`},
      },
    ).then(res => res.json())
  },
)

type SetupIntentPayload = {
  id?: string
  jwt: string
}

export const getSetupIntent = createAsyncThunk<any, SetupIntentPayload>(
  '/getSetupIntent',
  async ({jwt}) => {
    return useApi(
      null,
      `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/network/payment/setup_intent`,
      {
        method: 'POST',
        headers: {Authorization: `Bearer ${jwt}`},
      },
    ).then(res => res.json())
  },
)

type PaymentMethodPayload = {
  id?: string
  pmId: string
  jwt: string
}

export const updatePaymentMethod = createAsyncThunk<any, PaymentMethodPayload>(
  '/updatePaymentMethod',
  async ({pmId, jwt}) => {
    return useApi(null, `/v1/network/member/payment_method/default/${pmId}`, {
      method: 'PUT',
      headers: {Authorization: `Bearer ${jwt}`},
    }).then(res => {
      return {
        status: res.ok,
      }
    })
  },
)
//#endregion
////////////////////////////////////////////////////////////////
