import React from 'react'
import {Box, Modal} from '@mui/material'
import SubscriptionsMatrix from '../SubscriptionsMatrix'
import {
  ModalContainer,
  Title,
  Description,
  ImagesContainer,
  ARE5Image,
  NCARBImage,
  GuaranteeText,
} from './styles'
import AREImage from '../../../images/icons/are-5.0-black.svg'

type Props = {
  open: boolean
  setOpen: (value: boolean) => void
}

const View = ({open, setOpen}: Props) => {
  const handleClose = () => setOpen(false)

  return (
    <Box sx={{height: '100%', overFlow: 'scroll'}}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <Title variant={'h3'}>Change Subscriptions</Title>

          <Description variant={'body1'}>
            Select the subscription you want to switch to. You'll have an
            opportunity to review the change before your account is charged or
            credited.
          </Description>

          <ImagesContainer>
            <ARE5Image src={AREImage} />
            <NCARBImage src="https://go.blackspectacles.com/hubfs/bks-theme/miscellaneous/image__NCARB-badges.svg" />
          </ImagesContainer>

          <GuaranteeText variant={'body1'}>
            We are the first NCARB-approved test prep provider with{' '}
            <b>a guarantee* to pass the ARE 5.0.</b>
          </GuaranteeText>
          <SubscriptionsMatrix />
        </ModalContainer>
      </Modal>
    </Box>
  )
}

export default View
