import {Box, Typography} from '@mui/material'
import styled from 'styled-components'
import theme from '../../styles/theme'

export const TopicsBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 24px;
  width: 100%;
`

export const TopicsItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;

  && a {
    width: 240px;
  }

  ${theme.breakpoints.down('md')} {
    && a {
      width: 40%;
    }
  }
`

export const Title = styled(Typography)`
  color: #2d2d2d !important;
  font-size: 24px !important;

  ${theme.breakpoints.down('md')} {
    width: 60%;
  }
`
