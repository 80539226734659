export const setupWootric = (email: string, createdAt: string) => {
  const dateString = createdAt
  const dateObject = new Date(dateString + ' UTC')

  const createdAtTimestamp = Math.floor(dateObject.getTime() / 1000)

  const setupScript = document.createElement('script')
  setupScript.type = 'text/javascript'
  setupScript.id = 'wootric-settings'
  setupScript.async = true
  setupScript.innerHTML = `
      window.wootricSettings = {
        email: '${email}',
        created_at: ${createdAtTimestamp},
        account_token: '${process.env.REACT_APP_WOOTRIC_TOKEN}'
      };
    `
  if (document.getElementById('wootric-settings') == null) {
    document.body.appendChild(setupScript)
  }

  const beacon = document.createElement('script')
  beacon.type = 'text/javascript'
  beacon.id = 'wootric-beacon'
  beacon.async = true

  beacon.src = 'https://cdn.wootric.com/wootric-sdk.js'
  beacon.onload = function () {
    // We ignore this due to wootric being initialized in the window object by it's beacon.
    // @ts-ignore
    window.wootric('run')
  }
  if (document.getElementById('wootric-beacon') == null) {
    document.body.appendChild(beacon)
  }
}
