import React from 'react'
import {Box, Typography, Link} from '@mui/material'
import styled from 'styled-components'
import theme from '../../styles/theme'

export const PreExamBox = styled(Box)`
  margin: 40px;
  padding: 40px;
  border: 1px #eee solid;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Public Sans;
  min-height: 70vh;
  flex-flow: column;
  gap: 20px;

  ${theme.breakpoints.down('md')} {
    border: 1px #eee solid;
    margin: 10px;
    padding: 10px;
  }
`

export const ButtonsBox = styled(Box)`
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-top: 40px;

  ${theme.breakpoints.down('sm')} {
    flex-flow: column;
    width: 100%;

    && button {
      width: 100% !important;
    }
  }
`

export const ExamBox = styled(Box)`
  min-height: 70vh;
  font-family: Public Sans;
  font-size: 16px;
  box-sizing: border-box;
  margin: 40px;
  padding: 40px;
  border: 1px #eee solid;
  border-radius: 8px;
  position: relative;

  && table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  ${theme.breakpoints.down('md')} {
    border: 1px #eee solid;
    margin: 10px;
    padding: 10px 10px;
    margin-bottom: 60px;
  }
`

export const Title = styled(Typography)`
  font-family:
    Freight Disp Pro,
    Helvetica,
    Arial,
    sans-serif;
  font-size: 36px !important;
  letter-spacing: 0.05em;
  line-height: 44px;
  color: #000 !important;
`

export const FlexBox = styled(Box)`
  display: flex;
  position: relative;
  flex-flow: column;
  height: calc(100% - 24px);
  padding-bottom: 40px;

  ${theme.breakpoints.down('md')} {
    height: calc(100% - 102px);
  }
`

export const FixedBottom = styled(Box)<{$isSticked?: boolean}>`
  width: -webkit-fill-available;
  width: -moz-available;
  height: 40px;
  background: #eee;
  display: flex;
  justify-content: space-between;
  margin-top: auto;

  ${({$isSticked}) =>
    $isSticked
      ? `
    position: fixed;
    bottom: 0;
  `
      : `
    position: absolute;
    bottom: 88px;
    
    ${theme.breakpoints.down('md')} {
      bottom: 40px;
    }
  `}

  && button {
    border-radius: 0 !important;
  }

  && .MuiLinearProgress-root {
    background-color: #eee;
  }

  && .MuiLinearProgress-bar {
    background-color: #ddd;
  }
`

export const ExplanationBox = styled(Box)`
  background: #eee;
  padding: 10px 20px;
  margin: 10px 0 20px;
`

export const QuestionId = styled(Box)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 10.5px;
  color: #ccc;
  font-family: Freight Disp Pro;
`

export const LinkButton = styled(({...props}) => <Link {...props} />)`
  width: 100%;
  display: flex;
  justify-content: center;
  text-decoration: none !important;
  align-items: center;
  padding: 12px;
  font-size: 14px !important;
  letter-spacing: 0.05em;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #f6f6f6 !important;
  color: #1377d4 !important;
  transition: 0.2s;

  &: hover {
    color: #000 !important;
    text-decoration: none !important;
  }
`

export const LinearProgressBox = styled(Box)`
  width: 100%;
  height: 40px;
  position: relative;
`
