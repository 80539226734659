// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import rangy from 'rangy'
import 'rangy/lib/rangy-classapplier'
import 'rangy/lib/rangy-highlighter'

const init = () => {
  const highlighter = rangy.createHighlighter()
  highlighter.addClassApplier(rangy.createClassApplier('HighlightColor0'))
  highlighter.addClassApplier(rangy.createClassApplier('HighlightColor1'))
  highlighter.addClassApplier(rangy.createClassApplier('HighlightColor2'))
  highlighter.addClassApplier(rangy.createClassApplier('HighlightColor3'))
  highlighter.addClassApplier(rangy.createClassApplier('HighlightColor4'))
  highlighter.addClassApplier(rangy.createClassApplier('HighlightColor5'))
  highlighter.addClassApplier(rangy.createClassApplier('strike'))
}

export const highlight = (className: string, currentQuestion: number) => {
  init()
  const applier = rangy.createClassApplier(className)
  applier.toggleSelection()

  const newStem = document.getElementById('question-stem')?.innerHTML
  const newBody = document.getElementById('question-body')?.innerHTML

  localStorage.setItem(`questionStem${currentQuestion}`, newStem)
  localStorage.setItem(`questionBody${currentQuestion}`, newBody)
}

export const removeHighlights = () => {
  init()
  const colors = [
    'HighlightColor0',
    'HighlightColor1',
    'HighlightColor2',
    'HighlightColor3',
    'HighlightColor4',
    'HighlightColor5',
  ]
  for (let i = 0; i < colors.length; i++) {
    const applier = rangy.createClassApplier(colors[i])
    applier.undoToSelection()
  }
}
