import React from 'react'
import {SettingsContentContainer} from './styles'
import ManageSubscription from './settings/manageSubscription'
import PaymentMethod from './settings/paymentMethod'
import BillingHistory from './settings/billingHistory'
import UpdateProfile from './settings/updateProfile'
import ChangePassword from './settings/changePassword'
import ReportExamResults from './settings/reportExamResults'
import {useParams} from 'react-router-dom'

const View = () => {
  const {setting} = useParams()

  const renderSettingsContent = () => {
    switch (setting) {
      case 'manage':
        return <ManageSubscription />
      case 'billing':
        return <PaymentMethod />
      case 'history':
        return <BillingHistory />
      case 'update':
        return <UpdateProfile />
      case 'change-password':
        return <ChangePassword />
      default:
        return <ReportExamResults />
    }
  }

  return (
    <SettingsContentContainer
      style={setting === 'billing' ? {marginTop: 0} : {}}
    >
      {renderSettingsContent()}
    </SettingsContentContainer>
  )
}

export default View
