import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {RootState} from './rootReducer'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
export type DeckCollection = {
  id: string
  active: boolean
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  deck: Deck | undefined
  allCards: Flashcard[] | undefined
  dropDown: any
  status: LoadingStatuses
  error?: string | null | undefined
}

export type Deck = {
  id: string | null | undefined
  active: boolean
  title: string
  description: string
  flashcards: Flashcard[]
  custom: Flashcard[]
}

export type Flashcard = {
  id: string | null
  title: string
  side1: string
  side2: string
  custom?: boolean
  mastered: boolean
  masteredDate?: string
  isFlipped?: boolean
}
//#endregion

//#region api
type getCourseDecksPayload = {
  courseId?: string
}

export const getCourseDecks = createAsyncThunk<any, getCourseDecksPayload>(
  '/getCourseDecks',
  async ({courseId}) => {
    const endpoint = `/v1/guest/are/flashcards/course/${courseId}`

    return useApi(null, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

type getDeckPayload = {
  auth: AuthContextInterface
  deckId: string
}

export const getDeck = createAsyncThunk<any, getDeckPayload>(
  '/getDeck',
  async ({auth, deckId}) => {
    return useApi(auth, `/v1/are/flashcards/deck/${deckId}`, {
      method: 'GET',
    }).then(res => res.json())
  },
)

type putToggleStatusPayload = {
  auth: AuthContextInterface
  status: 1 | 0
  cardId?: string | null
  deckId?: string | null
  source: 'website' | 'app'
  isFlipped?: boolean
}

export const toggleMastered = createAsyncThunk<any, putToggleStatusPayload>(
  '/toggleMastered',
  async ({auth, status, cardId, deckId, source}) => {
    return useApi(
      auth,
      `/v1/are/flashcards/status/${status}/${cardId}/${deckId}/${source}`,
      {
        method: 'PUT',
      },
    ).then(res => res.status === 200)
  },
)

type putResetMasteredPayload = {
  auth: AuthContextInterface
  deckId: string
}

export const resetMastered = createAsyncThunk<any, putResetMasteredPayload>(
  '/resetMastered',
  async ({auth, deckId}) => {
    return useApi(auth, `/v1/are/flashcards/deck/${deckId}/reset`, {
      method: 'PUT',
    }).then(res => res.status === 200)
  },
)

type postCustomCardPayload = {
  auth: AuthContextInterface
  deckId?: string | null
  side1: string
  side2: string
  objective: string
}

export const createCustomCard = createAsyncThunk<any, postCustomCardPayload>(
  '/createCustomCard',
  async ({auth, deckId, side1, side2, objective}) => {
    const endpoint = '/v1/are/flashcards/custom'
    const fields = {deckId, side1, side2, objective}

    return useApi(auth, endpoint, {
      method: 'POST',
      body: JSON.stringify(fields),
    }).then(res => res.json())
  },
)

type putCustomCardPayload = {
  auth: AuthContextInterface
  deckId?: string | null
  side1: string
  side2: string
  objective: string
  cardId: string
}

export const updateCustomFlashcard = createAsyncThunk<
  any,
  putCustomCardPayload
>(
  '/updateCustomFlashcard',
  async ({auth, deckId, side1, side2, objective, cardId}) => {
    const endpoint = '/v1/are/flashcards/custom'
    const fields = {deckId, side1, side2, objective, cardId}

    return useApi(auth, endpoint, {
      method: 'PUT',
      body: JSON.stringify(fields),
    }).then(res => res.status === 200)
  },
)

type deleteCustomCardPayload = {
  auth: AuthContextInterface
  cardId: string
}

export const deleteCustomFlashcard = createAsyncThunk<
  any,
  deleteCustomCardPayload
>('/deleteCustomFlashcard', async ({auth, cardId}) => {
  const endpoint = `/v1/are/flashcards/custom/${cardId}`

  return useApi(auth, endpoint, {
    method: 'DELETE',
  }).then(res => res.status === 200)
})

type ChangeDeckStatePayload = {
  shuffled?: boolean
  randomize?: boolean
  flipped?: string
}

export const changeDeckState = createAsyncThunk<any, ChangeDeckStatePayload>(
  '/changeDeckState',
  async () => {},
)
//#endregion

//#region slice
const initialState: SliceState = {
  deck: undefined,
  allCards: undefined,
  dropDown: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'flashcards',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDeck.fulfilled, (state, action) => {
      const defaultDeck: Flashcard[] = action.payload.flashcards.filter(
        (item: Flashcard) => item.id,
      )
      const customDeck = action.payload.custom.map((card: Flashcard) => {
        card.custom = true
        return card
      })

      const allCards = defaultDeck.concat(customDeck)

      const dropDownList = getObjectivesList(allCards)

      state.deck = {...action.payload, flashcards: defaultDeck}
      state.allCards = allCards
      state.dropDown = dropDownList
      state.status = LoadingStatuses.Succeeded
      state.error = undefined
    })
    builder.addCase(toggleMastered.fulfilled, (state, action) => {
      const {status, cardId} = action.meta.arg

      const defaultDeck = state.deck?.flashcards || []
      const customDeck = state.deck?.custom || []

      let newDeck = defaultDeck.concat(customDeck)

      const isFlipped = action.meta.arg.isFlipped

      newDeck = newDeck.map(card => {
        if (card.id === cardId) {
          card.mastered = status === 1
          card.isFlipped = isFlipped
        }
        return card
      })

      const dropDownList = getObjectivesList(newDeck)

      state.allCards = newDeck
      state.dropDown = dropDownList
      state.status = LoadingStatuses.Succeeded
      state.error = undefined
    })
    builder.addCase(resetMastered.fulfilled, state => {
      const defaultDeck = state.deck?.flashcards || []
      const customDeck = state.deck?.custom || []

      const newDeck = defaultDeck.concat(customDeck)

      newDeck.map(card => {
        card.mastered = false
        return card
      })

      state.allCards = newDeck
      state.status = LoadingStatuses.Succeeded
      state.error = undefined
    })
    builder.addCase(createCustomCard.fulfilled, (state, action) => {
      state.status = LoadingStatuses.Succeeded
      state.error = undefined

      const newCard = {
        id: action.payload.id,
        title: action.payload.objective,
        side1: action.payload.side1,
        side2: action.payload.side2,
        custom: true,
        mastered: false,
      }

      const defaultDeck = state.deck?.flashcards || []
      const customDeck = state.deck?.custom || []
      customDeck.push(newCard)

      const newDeck = defaultDeck.concat(customDeck)

      const dropDownList = getObjectivesList(newDeck)

      state.allCards = newDeck
      state.dropDown = dropDownList
    })
    builder.addCase(updateCustomFlashcard.fulfilled, (state, action) => {
      if (state.deck) {
        const cardId = action.meta.arg.cardId
        const side1 = action.meta.arg.side1
        const side2 = action.meta.arg.side2
        const title = action.meta.arg.objective

        let customDeck = [...state.deck.custom]
        customDeck = customDeck.map(card => {
          if (card.id === cardId) {
            return {
              ...card,
              side1,
              side2,
              title,
            }
          }

          return card
        })

        const defaultDeck = state.deck?.flashcards || []

        const newDeck = defaultDeck.concat(customDeck)
        state.allCards = newDeck
        state.deck.custom = customDeck
      }

      state.status = LoadingStatuses.Succeeded
      state.error = undefined
    })
    builder.addCase(deleteCustomFlashcard.fulfilled, (state, action) => {
      if (state.deck) {
        const cardId = action.meta.arg.cardId

        let customDeck = [...state.deck.custom]
        customDeck = customDeck.filter(card => card.id !== cardId)

        const defaultDeck = state.deck?.flashcards || []
        const newDeck = defaultDeck.concat(customDeck)
        const dropDownList = getObjectivesList(newDeck)

        state.allCards = newDeck
        state.deck.custom = customDeck
        state.dropDown = dropDownList
      }

      state.status = LoadingStatuses.Succeeded
      state.error = undefined
    })
    builder.addCase(getDeck.rejected, (state, action) => {
      state.status = LoadingStatuses.Failed
      state.error = action.error.message
    })
    builder.addCase(toggleMastered.rejected, (state, action) => {
      state.status = LoadingStatuses.Failed
      state.error = action.error.message
    })
    builder.addCase(resetMastered.rejected, (state, action) => {
      state.status = LoadingStatuses.Failed
      state.error = action.error.message
    })
    builder.addCase(updateCustomFlashcard.rejected, (state, action) => {
      state.status = LoadingStatuses.Failed
      state.error = action.error.message
    })
    builder.addCase(deleteCustomFlashcard.rejected, (state, action) => {
      state.status = LoadingStatuses.Failed
      state.error = action.error.message
    })
    builder.addCase(changeDeckState.fulfilled, (state, action) => {
      const shuffled = action.meta.arg.shuffled
      const randomize = action.meta.arg.randomize
      const flipped = action.meta.arg.flipped

      if (state.allCards) {
        let deck = [...state.allCards]

        if (shuffled || randomize) {
          let currentIndex = deck.length,
            randomIndex

          while (currentIndex > 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex)
            currentIndex--
            deck[randomIndex].isFlipped = flipped === 'back'

            if (randomize) {
              deck[randomIndex].isFlipped = Math.random() >= 0.5
            }

            // And swap it with the current element.
            ;[deck[currentIndex], deck[randomIndex]] = [
              deck[randomIndex],
              deck[currentIndex],
            ]
          }
        } else {
          deck = deck.map(element => {
            const tempElement = {...element}
            tempElement.isFlipped = flipped === 'back'

            return tempElement
          })
        }
        state.allCards = deck
      }
    })
  },
})
//#endregion

//#region selectors
export const selectDeckId = ({flashcards}: RootState) => flashcards?.deck?.id
export const selectAllCards = ({flashcards}: RootState) => flashcards.allCards
export const selectCardId = ({flashcards}: RootState) => flashcards.deck
export const selectCustomCards = ({flashcards}: RootState) =>
  flashcards?.deck?.custom
export const selectObjectivesList = ({flashcards}: RootState) =>
  flashcards.dropDown
//#endregion

const getObjectivesList = (deck: Flashcard[]) => {
  const dropDownList: any = {all: deck.length, default: 0, custom: 0}
  const divisions = ['PCM', 'PJM', 'PA', 'PPD', 'PDD', 'CE']

  deck.map((card: any) => {
    if (card?.title) {
      const title = card.title?.split('-')
      const [division, objective] = title
      const divisionCard = divisions.indexOf(division.toUpperCase()) >= 0

      if (divisionCard && title) {
        if (dropDownList[objective]) {
          dropDownList[objective]++
        } else {
          dropDownList[objective] = []
          dropDownList[objective]++
        }
        dropDownList.default++
      } else {
        dropDownList.custom++
      }
    } else {
      // Custom cards with no title
      dropDownList.custom++
    }
  })

  return dropDownList
}
