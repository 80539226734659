import React from 'react'
import SettingsNavbar from '../../components/SettingsNavbar'
import {SettingsContentContainer} from '../../components/SettingsContent/styles'
import {
  CardBox,
  InvoiceItem,
  InvoicesHeader,
  LinkBox,
  StyledLink,
  Title,
} from './styles'
import {Box} from '@mui/material'
import {UserTitle, UsersGroup} from '../GroupManage/styles'
import {formatDate} from '../../utils/formatDate'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import {CardDetail, Invoice} from '../../redux/organizations'
import {LoadingStatuses} from '../../components/types/LoadingStatusses'
import Loading from '../../components/Loading'
import {Link, Link as RouterLink} from 'react-router-dom'

type Props = {
  billingHistory?: Invoice[]
  cardDetails?: CardDetail
  billingStatus: LoadingStatuses
  cardStatus: LoadingStatuses
}

const View = ({
  billingHistory,
  cardDetails,
  billingStatus,
  cardStatus,
}: Props) => {
  let isGrey = true

  const renderHistory = (type: 'paid' | 'pending') => {
    const invoiceList = billingHistory?.filter(
      invoice => invoice.status === type,
    )

    return (
      <Box marginTop={'64px'}>
        <Title>
          {type === 'pending' ? 'Upcoming invoices:' : 'Billing history:'}
        </Title>

        <InvoicesHeader>
          <UserTitle width={'40%'}>Date</UserTitle>
          <UserTitle width={'40%'}>Amount</UserTitle>
          <UserTitle width={'20%'} textAlign={'right'}>
            View
          </UserTitle>
        </InvoicesHeader>
        <Box>
          <UsersGroup>
            {invoiceList?.map((invoice, key) => {
              const color = isGrey ? '#ececec' : '#fff'

              isGrey = !isGrey

              return (
                <InvoiceItem $bgColor={color} key={`history-${key}`}>
                  <Box width={'40%'}>
                    {invoice.date ? formatDate(new Date(invoice.date)) : null}
                  </Box>
                  <Box width={'40%'}>
                    {(invoice.amount / 100).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                    })}
                  </Box>
                  <Box width={'20%'} textAlign={'right'}>
                    <StyledLink
                      component={RouterLink}
                      target={'_blank'}
                      to={`/invoice/${invoice.invoiceId}`}
                    >
                      <VisibilityOutlinedIcon />
                    </StyledLink>
                  </Box>
                </InvoiceItem>
              )
            })}
          </UsersGroup>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <SettingsNavbar />
      {billingStatus === LoadingStatuses.Idle ||
      billingStatus === LoadingStatuses.Loading ||
      cardStatus === LoadingStatuses.Idle ||
      cardStatus === LoadingStatuses.Loading ? (
        <SettingsContentContainer>
          <Loading />
        </SettingsContentContainer>
      ) : (
        <SettingsContentContainer>
          <Box>
            {cardDetails ? (
              <>
                <Title>Group Card Info</Title>
                <CardBox>
                  <div>
                    {cardDetails.brand} ending in {cardDetails.lastFour}
                  </div>
                  <div>Expires {cardDetails.date}</div>
                </CardBox>
              </>
            ) : null}
            <LinkBox>
              <Link to={'/profile/settings/billing'}>
                Update your Credit Card & Billing Address Information &gt;
              </Link>
            </LinkBox>
          </Box>
          {renderHistory('pending')}
          {renderHistory('paid')}
        </SettingsContentContainer>
      )}
    </>
  )
}

export default View
