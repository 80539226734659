import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {RootState} from './rootReducer'
import {LoadingStatuses} from '../components/types/LoadingStatusses'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
export type Exam = {
  id: string
  courseId: string
  title: string
  examType: string
  maxBreakTime: number
  questions: Question[]
  caseStudies: CaseStudy[]
  discreteQuestions: Question[]
  correctQuestions: number
  examTimeRemaining?: number
  breakTimeRemaining?: number
  questionCount?: number
  examDuration?: number
  attempts?: Attempts[]
}

export type AttemptInProgress = {
  attemptId: string
  startedAt: string
  initialTimer: number
  remainingTime: number
  remainingBreak: number
  initialBreak: number
}

export type CaseStudy = {
  examId: string
  questions: Question[]
}

export type Attempts = {
  attemptId: string
  date: Date
  correctAnswers: number
  percentage: number
}

export type Whiteboard = {
  tab: number
  payload: any
}

export type Question = {
  id: string
  examId: string
  examType: string
  displayOrder: number
  title: string
  competency?: string
  reference: string
  stem: string
  body: string
  bodyModified?: string
  questionTypeName: string
  backgroundImage?: string
  layout?: string
  answers: QuestionAnswer[]
  dndAreas?: QuestionDndAreas[]
  isAnswerCorrect?: boolean
  isBlocked?: boolean
  currentAnswer?: any
  forReview?: boolean
  objectives?: QuestionObjectives[]
  caseStudyScenario?: string
  caseStudyAssets?: CaseStudyAssets[]
}

export type CaseStudyAssets = {
  id: string
  order: number
  title: string
  filename: string
}

export type QuestionObjectives = {
  id: string
  description: string
  shortDescription: string
}

export type QuestionAnswer = {
  id: number
  idDisplay: string
  label: string
  image?: string
  labelModified?: string
  explanation?: string
  correctOption: boolean
  displayOrder: number
  answerExplanation: string
  answerImage?: string
  maxUsage?: string
  prefix?: string
  postfix?: string
  answerLogic?: string
}

export type QuestionDndAreas = {
  displayOrder: number
  coordinates: string
  image: string
  rotMin: number
  rotMax: number
  symmetry: number
}

type SliceState = {
  attemptId?: string
  exams: Exam[]
  exam?: Exam
  inProgress?: AttemptInProgress
  examReview?: Exam
  examStatus: LoadingStatuses
  examsStatus: LoadingStatuses
  examReviewStatus: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type StartExamPayload = {
  auth: AuthContextInterface
  examId?: string
  origin: 'quiz' | 'exam'
}
export const startExam = createAsyncThunk<any, StartExamPayload>(
  'exams/start',
  async ({auth, examId}) => {
    const endpoint = `/v1/are/exams/${examId}/start`

    return useApi(auth, endpoint, {
      method: 'POST',
    }).then(res => res.json())
  },
)

type EndExamPayload = {
  auth: AuthContextInterface
  attemptId?: string
}
export const endExam = createAsyncThunk<any, EndExamPayload>(
  'exams/end',
  async ({auth, attemptId}) => {
    const endpoint = `/v1/are/exams/${attemptId}/end`

    return useApi(auth, endpoint, {
      method: 'POST',
    }).then(res => res.json())
  },
)

type StartTimerPayload = {
  auth: AuthContextInterface
  attemptId?: string
}
export const startTimer = createAsyncThunk<any, StartTimerPayload>(
  'exams/timer/start',
  async ({auth, attemptId}) => {
    const endpoint = `/v1/are/exams/${attemptId}/timer/start`

    return useApi(auth, endpoint, {
      method: 'POST',
    }).then(res => res.json())
  },
)

type StartBreakPayload = {
  auth: AuthContextInterface
  attemptId?: string
}
export const startBreak = createAsyncThunk<any, StartBreakPayload>(
  'exams/break/start',
  async ({auth, attemptId}) => {
    const endpoint = `/v1/are/exams/${attemptId}/break/start`

    return useApi(auth, endpoint, {
      method: 'POST',
    }).then(res => res.json())
  },
)

type EndBreakPayload = {
  auth: AuthContextInterface
  attemptId?: string
  breakId?: string
}
export const endBreak = createAsyncThunk<any, EndBreakPayload>(
  'exams/break/end',
  async ({auth, attemptId, breakId}) => {
    const endpoint = `/v1/are/exams/${attemptId}/${breakId}/break/end`

    return useApi(auth, endpoint, {
      method: 'POST',
    }).then(res => res.json())
  },
)

type MarkReviewPayload = {
  auth: AuthContextInterface
  attemptId?: string
  questionId?: string
  isMarked?: boolean
}
export const markReview = createAsyncThunk<any, MarkReviewPayload>(
  'exams/review',
  async ({auth, attemptId, questionId, isMarked}) => {
    const endpoint = `/v1/are/exams/${attemptId}/question/${questionId}/review`

    const body = {
      isMarked,
    }

    return useApi(auth, endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then(res => res.text())
  },
)

type GetExamDetailsPayload = {
  auth: AuthContextInterface
  attemptId?: string
}
export const getExamDetails = createAsyncThunk<any, GetExamDetailsPayload>(
  'exams/details/get',
  async ({auth, attemptId}) => {
    const endpoint = `/v1/are/exams/${attemptId}/details`

    return useApi(auth, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

export const getExamReviewDetails = createAsyncThunk<
  any,
  GetExamDetailsPayload
>('exams/details/review', async ({auth, attemptId}) => {
  const endpoint = `/v1/are/exams/${attemptId}/details`

  return useApi(auth, endpoint, {
    method: 'GET',
  }).then(res => res.json())
})

type SubmitAnswerPayload = {
  auth: AuthContextInterface
  attemptId: string
  questionId: string
  payload: any
}
export const submitAnswer = createAsyncThunk<any, SubmitAnswerPayload>(
  'exams/answer/submit',
  async ({auth, attemptId, questionId, payload}) => {
    const endpoint = `/v1/are/exams/answer/submit`
    const body = {
      attemptId,
      questionId,
      payload,
    }
    return useApi(auth, endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then(res => res.json())
  },
)

export const clearExamState = createAsyncThunk<any>('clerExam/get', () => {
  return ''
})

type GetExamAttemptsPayload = {
  auth: AuthContextInterface
  courseId: string
}
export const getExamAttempts = createAsyncThunk<any, GetExamAttemptsPayload>(
  'exams/attempts/get',
  async ({auth, courseId}) => {
    const endpoint = `/v1/are/exams/course/${courseId}/attempts/details`
    return useApi(auth, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

type GetExamListPayload = {
  courseId: string
}
export const getExamList = createAsyncThunk<any, GetExamListPayload>(
  'guest/exams/list',
  async ({courseId}) => {
    const endpoint = `/v1/guest/are/exams/course/${courseId}/exam/list  `
    return useApi(null, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

type SaveWhiteboardPayload = {
  auth: AuthContextInterface
  attemptId: string
  tab: number
  payload: any
}
export const saveWhiteboard = createAsyncThunk<any, SaveWhiteboardPayload>(
  'exams/whiteboard/save',
  async ({auth, attemptId, tab, payload}) => {
    const endpoint = `/v1/are/exams/whiteboard/save`

    const body = {
      attemptId,
      tabs: [
        {
          tab,
          payload,
        },
      ],
    }

    return useApi(auth, endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then(res => res.text())
  },
)

type DeleteWhiteboardPayload = {
  auth: AuthContextInterface
  attemptId: string
  tab: number
}
export const deleteWhiteboard = createAsyncThunk<any, DeleteWhiteboardPayload>(
  'exams/whiteboard/delete',
  async ({auth, attemptId, tab}) => {
    const endpoint = `/v1/are/exams/${attemptId}/whiteboard/delete/${tab}`

    return useApi(auth, endpoint, {
      method: 'POST',
    }).then(res => res.text())
  },
)

type GetWhiteboardPayload = {
  auth: AuthContextInterface
  attemptId: string
}
export const getWhiteboard = createAsyncThunk<any, GetWhiteboardPayload>(
  'exams/whiteboard/get',
  async ({auth, attemptId}) => {
    const endpoint = `/v1/are/exams/${attemptId}/whiteboard/load`

    return useApi(auth, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)
//#endregion

//#region slice
const initialState: SliceState = {
  attemptId: undefined,
  exams: [],
  exam: undefined,
  inProgress: undefined,
  examReview: undefined,
  examsStatus: LoadingStatuses.Idle,
  examStatus: LoadingStatuses.Idle,
  examReviewStatus: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'exam',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(startExam.fulfilled, (state, action) => {
      state.attemptId = action.payload.attempt

      const storageName =
        action.meta.arg.origin === 'quiz'
          ? 'currentQuizAttemptId'
          : 'currentExamAttemptId'
      localStorage.setItem(storageName, action.payload.attempt)
      localStorage.setItem('currentExamId', action.meta.arg.examId!)
    })
    builder.addCase(startBreak.fulfilled, (state, action) => {
      if (state.exam) {
        localStorage.setItem('examBreakId', action.payload.logId)
      }
    })
    builder.addCase(endBreak.fulfilled, (state, action) => {
      if (state.exam && action.payload.blockedQuestions) {
        const blockedQuestions = action.payload.blockedQuestions
        let questions = [...state.exam.questions]

        questions = questions.map(question => {
          const isBlocked = blockedQuestions.some(
            (row: any) => row.questionId === question.id && row.isBlocked,
          )

          return {...question, isBlocked}
        })

        state.exam.questions = questions
      }
      localStorage.removeItem('examBreakId')
    })
    builder.addCase(clearExamState.fulfilled, state => {
      state.exam = undefined
      state.examStatus = LoadingStatuses.Idle
      localStorage.removeItem('currentQuizAttemptId')
    })
    builder.addCase(getExamDetails.pending, state => {
      state.exam = undefined
      state.examStatus = LoadingStatuses.Loading
    })
    builder.addCase(getExamDetails.fulfilled, (state, action) => {
      state.exam = {...action.payload, correctQuestions: 0}
      state.examStatus = LoadingStatuses.Succeeded

      if (localStorage.getItem('timerStartedAt')) {
        const breakTime =
          Number(localStorage.getItem('initialBreakTime')) -
          Number(localStorage.getItem('remaningBreakTime'))
        const initialTimer = Number(localStorage.getItem('initialTimer'))

        const timerStartedAt = Number(localStorage.getItem('timerStartedAt'))
        const now = new Date().getTime()

        const milisseconds = Math.abs(now - timerStartedAt)
        const seconds = Math.floor(milisseconds / 1000) - breakTime

        localStorage.setItem('timerProgress', String(initialTimer - seconds))
      }

      if (state.exam && state.exam.questions) {
        const questions = [...state.exam.questions]
        const caseStudies: CaseStudy[] = []

        questions.map(question => {
          if (
            (question.questionTypeName === 'hotspot' ||
              question.questionTypeName === 'drag') &&
            question.currentAnswer !== ''
          )
            question.currentAnswer = JSON.parse(question.currentAnswer)

          if (question.examType === 'case_study') {
            const currentIndex = caseStudies.findIndex(
              row => row.examId === question.examId,
            )
            if (currentIndex !== -1) {
              caseStudies[currentIndex].questions.push(question)
            } else {
              caseStudies.push({examId: question.examId, questions: [question]})
            }
          }

          return question
        })

        state.exam.questionCount = questions.length
        state.exam.caseStudies = caseStudies
        state.exam.questions = questions
        state.exam.discreteQuestions = questions.filter(
          question => question.examType !== 'case_study',
        )
      }
    })
    builder.addCase(getExamDetails.rejected, state => {
      state.exam = undefined
      state.examStatus = LoadingStatuses.Failed
    })
    builder.addCase(getExamReviewDetails.pending, state => {
      state.examReview = undefined
      state.examReviewStatus = LoadingStatuses.Loading
    })
    builder.addCase(getExamReviewDetails.fulfilled, (state, action) => {
      state.examReview = {...action.payload, correctQuestions: 0}
      state.examReviewStatus = LoadingStatuses.Succeeded

      if (state.examReview && state.examReview.questions) {
        const questions = [...state.examReview.questions]
        const caseStudies: CaseStudy[] = []

        questions.map(question => {
          if (
            (question.questionTypeName === 'hotspot' ||
              question.questionTypeName === 'drag') &&
            question.currentAnswer !== ''
          ) {
            question.currentAnswer = JSON.parse(
              question.currentAnswer.replaceAll('|', ','),
            )
          }

          if (question.examType === 'case_study') {
            const currentIndex = caseStudies.findIndex(
              row => row.examId === question.examId,
            )
            if (currentIndex !== -1) {
              caseStudies[currentIndex].questions.push(question)
            } else {
              caseStudies.push({examId: question.examId, questions: [question]})
            }
          }

          return question
        })

        state.examReview.caseStudies = caseStudies
        state.examReview.questions = questions
        state.examReview.discreteQuestions = questions.filter(
          question => question.examType !== 'case_study',
        )
      }
    })
    builder.addCase(getExamReviewDetails.rejected, state => {
      state.examReview = undefined
      state.examReviewStatus = LoadingStatuses.Failed
    })
    builder.addCase(startTimer.fulfilled, (state, action) => {
      if (state.exam) {
        state.exam.examTimeRemaining = action.payload.examTimeRemaining
        localStorage.setItem('timerStartedAt', String(new Date().getTime()))
        localStorage.setItem('initialTimer', action.payload.examTimeRemaining)
        localStorage.setItem('timerProgress', action.payload.examTimeRemaining)
        localStorage.setItem('initialBreakTime', '1800')
        localStorage.setItem('remaningBreakTime', '1800')
        localStorage.removeItem('examActivePage')
        localStorage.removeItem('examCurrentQuestion')
        localStorage.removeItem('examSeenQuestions')
        localStorage.removeItem('examReviewState')
        localStorage.removeItem('isExamEnded')
      }
    })
    builder.addCase(endExam.fulfilled, state => {
      if (state.exam) {
        state.exam.examTimeRemaining = 0
        localStorage.setItem('timerProgress', '0')
        localStorage.setItem('isExamEnded', 'true')
      }
    })
    builder.addCase(getExamAttempts.pending, state => {
      state.exams = []
      state.inProgress = undefined
      state.examsStatus = LoadingStatuses.Loading
    })
    builder.addCase(getExamAttempts.fulfilled, (state, action) => {
      state.exams = action.payload.exams
      state.inProgress = action.payload.examInProgress
      state.examsStatus = LoadingStatuses.Succeeded

      const inProgress = action.payload.examInProgress

      if (inProgress) {
        state.attemptId = inProgress.attemptId
        localStorage.setItem('timerProgress', String(inProgress.remainingTime))
        localStorage.setItem('timerStartedAt', inProgress.startedAt)
        localStorage.setItem('initialTimer', inProgress.initialTimer)
        localStorage.setItem('initialBreakTime', inProgress.initialBreak)
        localStorage.setItem('remaningBreakTime', inProgress.remainingBreak)
        localStorage.setItem('currentExamAttemptId', inProgress.attemptId)
      }
    })
    builder.addCase(getExamAttempts.rejected, state => {
      state.exams = []
      state.inProgress = undefined
      state.examsStatus = LoadingStatuses.Failed
    })
    builder.addCase(getExamList.pending, state => {
      state.exams = []
      state.examsStatus = LoadingStatuses.Loading
    })
    builder.addCase(getExamList.fulfilled, (state, action) => {
      state.exams = action.payload.exams
      state.examsStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(getExamList.rejected, state => {
      state.exams = []
      state.examsStatus = LoadingStatuses.Failed
    })
    builder.addCase(submitAnswer.fulfilled, (state, action) => {
      const questionId = action.meta.arg.questionId

      if (state.exam && state.exam.questions) {
        const questions = [...state.exam.questions]
        questions.map(question => {
          if (questionId === question.id) {
            question.isAnswerCorrect = action.payload.isAnswerCorrect
            question.currentAnswer = action.meta.arg.payload
          }

          return question
        })

        const correctAnswers = questions.filter(
          question => question.isAnswerCorrect,
        )

        state.exam.questions = questions

        const caseStudies = [...state.exam.caseStudies]
        caseStudies.map(caseStudy => {
          const questions = caseStudy.questions.map(question => {
            if (questionId === question.id) {
              question.isAnswerCorrect = action.payload.isAnswerCorrect
              question.currentAnswer = action.meta.arg.payload
            }

            return question
          })
          return {
            examId: caseStudy.examId,
            questions,
          }
        })

        const discreteQuestions = [...state.exam.discreteQuestions]
        discreteQuestions.map(question => {
          if (questionId === question.id) {
            question.isAnswerCorrect = action.payload.isAnswerCorrect
            question.currentAnswer = action.meta.arg.payload
          }

          return question
        })

        state.exam.discreteQuestions = discreteQuestions
        state.exam.caseStudies = caseStudies
        state.exam.questions = questions
        state.exam.correctQuestions = correctAnswers.length
      }
    })
    builder.addCase(markReview.pending, (state, action) => {
      const questionId = action.meta.arg.questionId

      if (state.exam && state.exam.questions) {
        const questions = [...state.exam.questions]
        questions.map(question => {
          if (questionId === question.id) {
            question.forReview =
              question.forReview === undefined ? true : !question.forReview
          }

          return question
        })

        state.exam.questions = questions
      }

      if (state.exam && state.exam.discreteQuestions) {
        const discreteQuestions = [...state.exam.discreteQuestions]
        discreteQuestions.map(question => {
          if (questionId === question.id) {
            question.forReview =
              question.forReview === undefined ? true : !question.forReview
          }

          return question
        })

        state.exam.discreteQuestions = discreteQuestions
      }

      if (state.exam && state.exam.caseStudies) {
        const caseStudies = [...state.exam.caseStudies]
        caseStudies.map(caseStudy => {
          const questions = caseStudy.questions.map(question => {
            if (questionId === question.id) {
              question.forReview =
                question.forReview === undefined ? true : !question.forReview
            }

            return question
          })
          return {
            examId: caseStudy.examId,
            questions,
          }
        })

        state.exam.caseStudies = caseStudies
      }
    })
  },
})
//#endregion

//#region selectors
export const selectAttemptId = ({exam}: RootState) => exam.attemptId
export const selectExam = ({exam}: RootState) => exam.exam
export const selectExamStatus = ({exam}: RootState) => exam.examStatus
export const selectExams = ({exam}: RootState) => exam.exams
export const selectInProgress = ({exam}: RootState) => exam.inProgress
export const selectExamsStatus = ({exam}: RootState) => exam.examsStatus
export const selectExamReview = ({exam}: RootState) => exam.examReview
export const selectExamReviewStatus = ({exam}: RootState) =>
  exam.examReviewStatus
//#endregion
