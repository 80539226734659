import React from 'react'
import Warning from '../../images/icons/warning.png'
import {Button} from '../button'

import {
  ButtonBox,
  DialogBox,
  DialogFlexBox,
  DialogHeader,
  DialogImage,
  DialogText,
  BreakDialog,
} from './styles'

export type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (value: boolean) => void
}

const View = ({isOpen, onClose, onSubmit}: Props) => {
  let remainingTime = 0

  if (localStorage.getItem('remaningBreakTime')) {
    const time = parseInt(localStorage.getItem('remaningBreakTime')!) / 60
    remainingTime = Math.round(time)
  }

  return (
    <BreakDialog maxWidth={'md'} open={isOpen} onClose={onClose}>
      <DialogBox>
        <DialogHeader />
        <DialogFlexBox>
          <DialogImage>
            <img src={Warning} alt="Warning" />
          </DialogImage>
          <DialogText>
            <span>
              Your total remaining break time is {remainingTime} minute(s). Do
              you wish to start a break?
            </span>
            <b>
              Reminder: All previously viewed questions (Completed and
              Incomplete) will be locked when you return from break. To take a
              break, click Yes
            </b>
            <span>
              Please note that during the real ARE you will need to notify the
              proctor or test center administrator after clicking Yes.
            </span>
          </DialogText>
        </DialogFlexBox>
        <ButtonBox>
          <Button
            onClick={() => onSubmit(true)}
            children={'YES'}
            color={'black'}
            size={'medium'}
          />
          <Button
            onClick={onClose}
            children={'NO'}
            color={'primary'}
            size={'medium'}
          />
        </ButtonBox>
      </DialogBox>
    </BreakDialog>
  )
}

export default View
