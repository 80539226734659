import React from 'react'
import {ActivationBox, ButtonBox, CheckBoxRow, StyledBox} from './styles'
import BksLogo from '../../images/logo-white.svg'
import {Typography} from '@mui/material'
import {Button} from '../../components/button'
import {Formik, FormikHelpers, Form} from 'formik'
import * as Yup from 'yup'
import TextFieldFormik from '../../components/TextFieldFormik'
import {StyledLogo} from '../../components/Hero/styles'
import {Link} from 'react-router-dom'
import {CheckBox} from '../../components/VideoEnded/styles'

type Props = {
  onSubmit: (values: FormProps) => void
}

export type FormProps = {
  password: string
  terms: boolean
}

const View = ({onSubmit}: Props) => {
  const initialValues: FormProps = {
    password: '',
    terms: false,
  }

  const passwordHintMessage =
    'At least 8 characters, should contain lower case letters (a-z), upper case letters (A-Z), numbers (i.e. 0-9) and a special character.'

  const ValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required(passwordHintMessage)
      .min(8, passwordHintMessage)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d@$!%*?&]/,
        passwordHintMessage,
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password')],
      'Passwords must match',
    ),
    terms: Yup.bool().oneOf([true], 'This field is required.'),
  })

  const handleSubmit = (
    values: FormProps,
    actions: FormikHelpers<FormProps>,
  ) => {
    onSubmit(values)
    actions.setSubmitting(false)
  }

  return (
    <ActivationBox>
      <Link to={'/'}>
        <StyledLogo src={BksLogo} alt="Black Spectacles" />
      </Link>
      <Formik
        onSubmit={(values, actions) => {
          handleSubmit(values, actions)
        }}
        initialValues={initialValues}
        validationSchema={ValidationSchema}
      >
        {formik => (
          <StyledBox>
            <Form>
              <Typography variant="h1" color={'white'} textAlign={'center'}>
                SET YOUR PASSWORD
              </Typography>
              <Typography variant="body1" color={'white'} marginTop={3}>
                <em>Please set a password for your account:</em>
              </Typography>
              <ButtonBox>
                <TextFieldFormik
                  type={'password'}
                  fullWidth
                  name="password"
                  styles={{color: '#fff'}}
                  placeholder="PASSWORD (MIN 8 CHARS)"
                />
              </ButtonBox>
              <ButtonBox>
                <TextFieldFormik
                  type={'password'}
                  fullWidth
                  name="confirmPassword"
                  styles={{color: '#fff'}}
                  placeholder="CONFIRM PASSWORD"
                />
              </ButtonBox>
              <CheckBoxRow
                onClick={() =>
                  formik.setFieldValue('terms', !formik.values.terms)
                }
              >
                <CheckBox $isActive={formik.values.terms}>
                  {formik.values.terms && '✓'}
                </CheckBox>
                <span>
                  I have read and agree to the{' '}
                  <a
                    href="https://www.blackspectacles.com/privacy-policy"
                    target="_blank"
                  >
                    Terms & Privacy
                  </a>{' '}
                  policy.
                </span>
              </CheckBoxRow>
              <ButtonBox>
                <Button
                  children="Activate Account"
                  color="primary"
                  hoverColor="transparent"
                  disabled={formik.isSubmitting || !formik.isValid}
                  type="submit"
                />
              </ButtonBox>
            </Form>
          </StyledBox>
        )}
      </Formik>
    </ActivationBox>
  )
}

export default View
