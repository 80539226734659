import React, {useEffect, useRef, useCallback} from 'react'
import {Exam} from '../../redux/exams'
import TargetImage from '../../images/icons/target.png'
import {Stage, Shape, Bitmap, Touch} from '@createjs/easeljs'
import {ExplanationBox} from '../QuizQuestions/styles'
import renderHTML from '../../utils/renderHTML'

type Props = {
  exam?: Exam
  currentQuestion: number
  isReviewing?: boolean
  onSelect: (value: any) => void
  answer?: any
  currentAnswer?: any
}

const ExamHotspot = ({
  exam,
  currentQuestion,
  isReviewing,
  onSelect,
  answer,
  currentAnswer,
}: Props) => {
  const question = exam?.questions[currentQuestion]
  const canvasRef = useRef(null)

  const hotspot = {
    stages: undefined,
    targets: undefined,
    bgBitmaps: undefined,
    reviewBitmaps: undefined,
    xs: undefined,
    ys: undefined,
  } as any

  const stagemousemove = function (evt: any) {
    //draw horizontal/vertical lines at this position
    if (
      typeof hotspot.xs !== 'undefined' &&
      typeof hotspot.ys !== 'undefined' &&
      typeof hotspot.xs.graphics !== 'undefined' &&
      typeof hotspot.ys.graphics !== 'undefined'
    ) {
      hotspot.xs.graphics
        .clear()
        .beginFill('#ffd280')
        .rect(evt.stageX, 0, 2, evt.currentTarget.canvas.height)
      hotspot.ys.graphics
        .clear()
        .beginFill('#ffd280')
        .rect(0, evt.stageY, evt.currentTarget.canvas.width, 2)
      evt.currentTarget.update()
    }
  }

  const mouseenter = function (evt: any) {
    if (hotspot.xs) {
      evt.currentTarget.addChild(hotspot.xs, hotspot.ys)
      evt.currentTarget.update()
    }
    if (hotspot.stages.canvas.parentNode) {
      hotspot.stages.canvas.parentNode.style.cursor = 'none'
    }
  }

  const mouseleave = function (evt: any) {
    evt.currentTarget.removeChild(hotspot.xs, hotspot.ys)
    evt.currentTarget.update()
    if (hotspot.stages.canvas.parentNode) {
      hotspot.stages.canvas.parentNode.style.cursor = ''
    }
  }

  const stagemousedown = function (evt: any) {
    if (!isReviewing && !question?.isBlocked) {
      if (hotspot.targets) {
        hotspot.targets.x = evt.stageX
        hotspot.targets.y = evt.stageY
        evt.currentTarget.addChild(hotspot.targets)

        onSelect([
          {
            x: hotspot.targets.x,
            y: hotspot.targets.y,
          },
        ])
      }
      adjustZIndexes()
    }
  }

  const drawAnswerEvent = function () {
    drawAnswer()
  }

  const drawAnswer = function () {
    if (isReviewing) {
      const reviewImg = new Image()
      reviewImg.src = question?.answers[0].answerImage!
      reviewImg.addEventListener('load', function () {
        hotspot.reviewBitmaps = new Bitmap(this)

        hotspot.stages.addChild(hotspot.reviewBitmaps)
        adjustZIndexes()
        hotspot.stages.update()
      })
    }
  }

  const adjustZIndexes = function () {
    hotspot.stages.update()
  }

  const loadHotspot = useCallback(() => {
    const canvasItem: any = canvasRef.current
    hotspot.stages = new Stage(canvasRef.current)
    hotspot.stages.enableMouseOver()
    Touch.enable(hotspot.stages)
    hotspot.stages.mouseMoveOutside = false

    const image = new Image()
    image.src = question?.backgroundImage!
    image.addEventListener('load', function (event: any) {
      canvasItem.setAttribute('width', event.target.width)
      canvasItem.setAttribute('height', event.target.height)

      hotspot.bgBitmaps = new Bitmap(this)
      hotspot.stages.addChild(hotspot.bgBitmaps)
      hotspot.stages.setChildIndex(hotspot.bgBitmaps, 0)

      //set up X line
      hotspot.xs = new Shape()
      hotspot.stages.addChild(hotspot.xs)
      hotspot.stages.setChildIndex(hotspot.xs, 1)

      //set up Y line
      hotspot.ys = new Shape()
      hotspot.stages.addChild(hotspot.ys)
      hotspot.stages.setChildIndex(hotspot.ys, 2)

      hotspot.stages.update()
    })

    drawAnswer()

    const targetImg = new Image()
    targetImg.src = TargetImage
    targetImg.addEventListener('load', function () {
      hotspot.targets = new Bitmap(this)
      hotspot.targets.regX = this.width / 2
      hotspot.targets.regY = this.height / 2

      //set hoverable area to be the whole rectangle, not just non-transparent pixels
      const bounds = hotspot.targets.getBounds()
      hotspot.targets.regX = bounds.width / 2
      hotspot.targets.regY = bounds.height / 2

      //define the hitArea and not just the "non-transparent" pixels
      const hit = new Shape()
      hit.graphics
        .beginFill('#000')
        .drawRect(-10, -10, bounds.width + 20, bounds.height + 20)
      hotspot.targets.hitArea = hit

      if (answer || currentAnswer) {
        const finalAnswer = currentAnswer && !answer ? currentAnswer : answer

        hotspot.targets.x = finalAnswer[0].x
        hotspot.targets.y = finalAnswer[0].y

        hotspot.stages.addChild(hotspot.targets)
        adjustZIndexes()
      }
    })

    //animate/draw with the mouse
    hotspot.stages.on('stagemousemove', (e: any) => stagemousemove(e))

    //remove target lines
    hotspot.stages.on('mouseleave', (e: any) => mouseleave(e))

    //add target lines
    hotspot.stages.on('mouseenter', (e: any) => mouseenter(e))

    //move target to the correct position and update xy hidden inputs
    hotspot.stages.on('stagemousedown', (e: any) => stagemousedown(e))

    //draw the answer
    hotspot.stages.on('drawAnswerEvent', drawAnswerEvent)
  }, [exam, currentQuestion])

  useEffect(() => {
    loadHotspot()

    if (currentAnswer) {
      onSelect(currentAnswer)
    }
  }, [loadHotspot])

  return (
    <>
      <canvas
        ref={canvasRef}
        style={{
          border: '1px #eee solid',
          cursor: 'none',
          marginTop: '32px',
          maxWidth: '100%',
        }}
      />
      {isReviewing && (
        <ExplanationBox>
          {renderHTML(question?.answers[0].explanation ?? '')}
        </ExplanationBox>
      )}
    </>
  )
}

export default ExamHotspot
