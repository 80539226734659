import {Close} from '@mui/icons-material'
import {Typography} from '@mui/material'

import {
  CTAButton,
  CloseButton,
  Dialog,
  DialogActions,
  DialogContent,
  IconImage,
} from './styles'

import GiftIcon from '../../images/icons/gift.svg'
import StarBadgeIcon from '../../images/icons/starBadge.svg'

export type ReferralSource = 'sign-up' | 'exam-pass'

type Props = {
  open: boolean
  handleClose: () => void
  source: ReferralSource
}

const View = ({open, handleClose, source}: Props) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <CloseButton onClick={handleClose}>
        <Close />
      </CloseButton>
      <DialogContent>
        <IconImage
          src={source === 'sign-up' ? GiftIcon : StarBadgeIcon}
          width="67px"
          height="68px"
        />
        <Typography
          color="#2D2D2D"
          fontFamily="Freight Disp Pro"
          fontSize="2rem"
          fontWeight={300}
          lineHeight="28px"
        >
          {source === 'sign-up'
            ? 'Invite Friends & Earn $50!'
            : 'Share Your Success & Earn $50!'}
        </Typography>
        <Typography color="#2D2D2D">
          {source === 'sign-up' ? (
            <>
              <b>Welcome!</b> Invite friends, earn a $50 gift card per referral,
              and give them 15% off their subscription. Share your link and
              let's succeed together!
            </>
          ) : (
            <>
              <b>Congrats on passing!</b> Refer friends and earn $50 in a gift
              card for each new member. They'll get 15% off their subscription.
              Let's inspire others!
            </>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CTAButton
          variant="contained"
          onClick={() => {
            window.open('https://www.blackspectacles.com/referral', '_blank')
          }}
        >
          Invite Friends
        </CTAButton>
      </DialogActions>
    </Dialog>
  )
}

export default View
