'use client'
import React from 'react'
import Papa from 'papaparse'
import {StyledUpload} from './styles'

type Props = {
  onChange(data: string[][]): void
}

const CSVSelector = ({onChange}: Props) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      try {
        const file = e.target.files[0]

        Papa.parse<string[]>(file, {
          worker: true,
          complete({data}) {
            onChange(data)
          },
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
  return (
    <StyledUpload
      type="file"
      accept=".csv"
      onChange={handleFileChange}
      id="AddMembers"
    />
  )
}

export default CSVSelector
