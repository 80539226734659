import React, {useState} from 'react'
import {Box, Typography, LinearProgress} from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'

import {
  FlashCardContentContainer,
  FlashCard,
  CardHeader,
  CardContent,
  CardFooter,
  FlashCardProgress,
  Container,
  ProgressBar,
  EmptyBox,
  EditBox,
  StandardTypography,
  CardSide2,
  CardSide1,
} from './styles'
import ReactCardFlip from 'react-card-flip'
import {Flashcard as FlashcardProp} from '../../../../redux/flashcard'
import {
  toggleMastered,
  deleteCustomFlashcard,
  selectDeckId,
  selectCustomCards,
} from '../../../../redux/flashcard'
import {useAppDispatch} from '../../../../redux/configureStore'
import {useSelector} from 'react-redux'
import {useAuth} from '../../../AuthProvider'
import {LoggedOutBox} from '../../../../pages/ExamsList/styles'
import {Link} from 'react-router-dom'
import {Course} from '../../../../redux/courses'
import FlipDeck from '../../../../images/icons/flip.svg'
import {DivisionColors} from '../../../../constants/divisions'
import {toggleErrorDialog} from '../../../../redux/config'
import ConfirmDialog from '../../../ConfirmDialog'
import renderHTML from '../../../../utils/renderHTML'
import {findAll} from 'highlight-words-core'
import {getUpgradePrice} from '../../../../redux/members'

type Props = {
  course?: Course
  deck: FlashcardProp[]
  filterBySelection: string
  toggleForm: (value: boolean) => void
  toggleEditMode: (value: boolean) => void
  updateCardId: (value: string) => void
  flipCard: (cardPosition: number) => void
  hasAccess: boolean
  setFlashCardNumber: (value: number) => void
  currentFlashCardNumber: number
  searchInput: string
}

const View = ({
  course,
  deck,
  filterBySelection,
  toggleForm,
  toggleEditMode,
  updateCardId,
  flipCard,
  hasAccess,
  setFlashCardNumber,
  currentFlashCardNumber,
  searchInput,
}: Props) => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isMastering, setIsMastering] = useState(false)
  const deckId = useSelector(selectDeckId)
  const customCards = useSelector(selectCustomCards)

  const toggleMastery = (e: any) => {
    e.stopPropagation()
    if (!isMastering) {
      handleToggleMastery()
    }
  }

  const handleToggleMastery = async () => {
    const fields = {
      auth,
      status: +!deck[currentFlashCardNumber - 1].mastered as 1 | 0,
      cardId: deck[currentFlashCardNumber - 1].id,
      deckId,
      source: 'website' as 'website' | 'app',
      isFlipped: deck[currentFlashCardNumber - 1]?.isFlipped,
    }
    try {
      setIsMastering(true)
      const {type} = await dispatch(toggleMastered(fields))

      setIsMastering(false)
      if (type === toggleMastered.rejected.type) {
        throw new Error('Unable to toggle mastered')
      }
    } catch (error) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to toggle mastered, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const decrementCardNumber = () => {
    if (currentFlashCardNumber !== 1) {
      setFlashCardNumber(currentFlashCardNumber - 1)
    }
  }

  const incrementCardNumber = () => {
    if (currentFlashCardNumber !== deck.length) {
      setFlashCardNumber(currentFlashCardNumber + 1)
    }
  }

  const handleEdit = () => {
    toggleEditMode(true)
    toggleForm(true)

    const cardId = deck[currentFlashCardNumber - 1].id
    if (cardId) updateCardId(cardId)
  }

  const handleDelete = async () => {
    const cardId = deck[currentFlashCardNumber - 1].id

    if (cardId) {
      try {
        const {type} = await dispatch(deleteCustomFlashcard({auth, cardId}))

        if (type === deleteCustomFlashcard.fulfilled.type) {
          setFlashCardNumber(1)
        } else if (type === deleteCustomFlashcard.rejected.type) {
          throw new Error('Unable to delete flashcard')
        }
      } catch (e) {
        await dispatch(
          toggleErrorDialog({
            opened: true,
            error:
              'We were unable to delete flashcard, please retry. If issues persist contact our support team',
          }),
        )
      }
    }
  }

  const handleUpgradeUrl = async (e:any) => {
    e.preventDefault();
    const resp = await dispatch(getUpgradePrice({auth}))
    console.log(resp.payload)
    window.location.href = String(resp.payload.priceId).length > 0 ? 'https://checkout.blackspectacles.com/' + String(resp.payload.priceId) : '#'
  }

  const colors = DivisionColors.find(item => item.abbr === course?.abbreviation)

  const searchWords = searchInput.split(' ')

  const renderHighliter = (textToHighlight: string) => {
    const chunks = findAll({
      caseSensitive: false,
      searchWords,
      textToHighlight,
    })

    const formattedHTML = chunks
      .map(chunk => {
        const {end, highlight, start} = chunk
        const text = textToHighlight.substring(start, end)
        if (highlight) {
          return `<mark class="searchHighlight">${text}</mark>`
        } else {
          return text
        }
      })
      .join('')

    return renderHTML(formattedHTML)
  }

  return (
    <FlashCardContentContainer>
      <ConfirmDialog
        title="Delete Flashcard"
        message="Are you sure you want to delete this custom card?"
        isOpen={isDialogOpen}
        onConfirm={() => {
          handleDelete()
          setIsDialogOpen(false)
        }}
        onClose={() => setIsDialogOpen(false)}
      />
      <Container className="container">
        {auth.isAuthenticated ? (
          <>
            {deck && deck.length ? (
              <ReactCardFlip
                isFlipped={deck[currentFlashCardNumber - 1]?.isFlipped}
              >
                <FlashCard
                  icon={colors?.icon}
                  onClick={() => flipCard(currentFlashCardNumber - 1)}
                >
                  <CardHeader>
                    <StandardTypography>
                      {deck[currentFlashCardNumber - 1].custom ? (
                        <>{deck[currentFlashCardNumber - 1]?.title}</>
                      ) : (
                        <>
                          {deck[currentFlashCardNumber - 1]?.title
                            ? `Objective ${deck[
                                currentFlashCardNumber - 1
                              ]?.title?.split('-')[1]}`
                            : null}
                        </>
                      )}
                    </StandardTypography>
                    {customCards &&
                    customCards.filter(
                      (customCard: any) =>
                        customCard.id === deck[currentFlashCardNumber - 1]?.id,
                    ).length === 0 ? (
                      <StandardTypography color={'#bfbfbf'}>
                        {deck[currentFlashCardNumber - 1]?.title?.split('-')[3]}
                      </StandardTypography>
                    ) : (
                      <EditBox
                        onClick={e => {
                          e.stopPropagation()
                        }}
                      >
                        <StandardTypography
                          style={{cursor: 'pointer'}}
                          onClick={handleEdit}
                        >
                          Edit
                        </StandardTypography>
                        <StandardTypography
                          style={{cursor: 'pointer'}}
                          onClick={() => setIsDialogOpen(true)}
                        >
                          Delete
                        </StandardTypography>
                      </EditBox>
                    )}
                  </CardHeader>

                  <CardContent>
                    <CardSide1
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      {deck && deck.length
                        ? renderHighliter(
                            deck[currentFlashCardNumber - 1]?.side1,
                          )
                        : 'There are no cards available with your current filters. Try changing the filters to view some cards.'}
                    </CardSide1>
                  </CardContent>

                  <CardFooter>
                    <Box onClick={e => toggleMastery(e)}>
                      <StandardTypography fontWeight={'bold'}>
                        {deck[currentFlashCardNumber - 1]?.mastered ? (
                          <StarIcon />
                        ) : (
                          <StarBorderIcon />
                        )}
                        Mastered
                      </StandardTypography>
                    </Box>
                    <Box>
                      <StandardTypography
                        display={'flex'}
                        alignItems={'center'}
                      >
                        <img src={FlipDeck} height={'16px'} alt="Flip" /> Flip
                        to back
                      </StandardTypography>
                    </Box>
                  </CardFooter>
                </FlashCard>

                <FlashCard
                  icon={colors?.icon}
                  onClick={() => flipCard(currentFlashCardNumber - 1)}
                  style={{backgroundColor: colors?.color}}
                >
                  <CardHeader>
                    <StandardTypography>
                      {deck[currentFlashCardNumber - 1].custom ? (
                        <>{deck[currentFlashCardNumber - 1]?.title}</>
                      ) : (
                        <>
                          {deck[currentFlashCardNumber - 1]?.title
                            ? `Objective ${deck[
                                currentFlashCardNumber - 1
                              ]?.title?.split('-')[1]}`
                            : null}
                        </>
                      )}
                    </StandardTypography>

                    {customCards &&
                    customCards.filter(
                      (customCard: any) =>
                        customCard.id === deck[currentFlashCardNumber - 1]?.id,
                    ).length === 0 ? (
                      <StandardTypography color={'#bfbfbf'}>
                        {deck[currentFlashCardNumber - 1]?.title?.split('-')[3]}
                      </StandardTypography>
                    ) : (
                      <EditBox
                        onClick={e => {
                          e.stopPropagation()
                        }}
                      >
                        <StandardTypography
                          style={{cursor: 'pointer'}}
                          onClick={handleEdit}
                        >
                          Edit
                        </StandardTypography>
                        <StandardTypography
                          style={{cursor: 'pointer'}}
                          onClick={() => setIsDialogOpen(true)}
                        >
                          Delete
                        </StandardTypography>
                      </EditBox>
                    )}
                  </CardHeader>

                  <CardContent>
                    <CardSide2
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      {renderHighliter(
                        deck[currentFlashCardNumber - 1]?.side2.replace(
                          /\n/g,
                          '<br />',
                        ),
                      )}
                    </CardSide2>
                  </CardContent>

                  <CardFooter>
                    <Box
                      onClick={e => {
                        e.stopPropagation()
                        toggleMastery(e)
                      }}
                    >
                      <StandardTypography>
                        {deck[currentFlashCardNumber - 1]?.mastered ? (
                          <StarIcon />
                        ) : (
                          <StarBorderIcon />
                        )}
                        Mastered
                      </StandardTypography>
                    </Box>
                    <Box>
                      <StandardTypography
                        display={'flex'}
                        alignItems={'center'}
                      >
                        <img src={FlipDeck} height={'16px'} alt="Flip" /> Flip
                        to front
                      </StandardTypography>
                    </Box>
                  </CardFooter>
                </FlashCard>
              </ReactCardFlip>
            ) : (
              <>
                {hasAccess ? (
                  <EmptyBox>
                    <StandardTypography>
                      There are no cards available with your current filters.
                      Try changing the filters to view some cards.
                    </StandardTypography>
                  </EmptyBox>
                ) : (
                  <EmptyBox>
                    <LoggedOutBox>
                      <Box>
                        You have reached our paid flashcards for the course{' '}
                        <Link to={`/courses/${course?.id}`}>
                          Flashcards: ARE 5.0 {course?.title}
                        </Link>
                        .
                      </Box>
                      <Box>
                        <a href="#"
                           onClick={handleUpgradeUrl}
                        >
                          Upgrade your membership
                        </a>{' '}
                        to get access to this tutorial.
                      </Box>
                    </LoggedOutBox>
                  </EmptyBox>
                )}
              </>
            )}
          </>
        ) : (
          <EmptyBox>
            <LoggedOutBox>
              <Box>
                You have reached our paid flashcards for the course{' '}
                <Link to={`/courses/${course?.id}`}>
                  Flashcards: ARE 5.0 {course?.title}
                </Link>
                .
              </Box>
              <Box>
                You can either <Link to={'/login'}>log in</Link> to view it or{' '}
                <a href="https://www.blackspectacles.com/pricing">
                  learn more about our memberships
                </a>
                .
              </Box>
            </LoggedOutBox>
          </EmptyBox>
        )}

        <FlashCardProgress>
          <Box
            className={currentFlashCardNumber === 1 ? 'disabled' : ''}
            onClick={decrementCardNumber}
          >
            <Typography className="progress-arrow">
              <ArrowBackIosNewIcon fontSize={'small'} />
              Prev
            </Typography>
          </Box>

          <Box style={{width: '100%', padding: '0 15px'}}>
            <ProgressBar className="progressBar">
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={(currentFlashCardNumber / deck?.length) * 100}
                />
                <StandardTypography>
                  {currentFlashCardNumber}/{deck?.length}
                </StandardTypography>
              </Box>
            </ProgressBar>

            {filterBySelection === 'All' ? (
              <StandardTypography variant="body1" className="mastered-text">
                {deck.filter(card => card.mastered).length} mastered out of{' '}
                {deck?.length}
              </StandardTypography>
            ) : null}
          </Box>
          <Box
            className={currentFlashCardNumber >= deck.length ? 'disabled' : ''}
            onClick={incrementCardNumber}
          >
            <Typography className="progress-arrow">
              Next
              <ArrowForwardIosIcon fontSize={'small'} />
            </Typography>
          </Box>
        </FlashCardProgress>
      </Container>
    </FlashCardContentContainer>
  )
}

export default View
