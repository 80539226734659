import React, {useState} from 'react'
import Popover from '@mui/material/Popover'
import SettingsNavbar from '../../components/SettingsNavbar'
import {SettingsContentContainer} from '../../components/SettingsContent/styles'
import {
  DateButton,
  Header,
  LeftHeader,
  RightHeader,
  SubTitle,
  HeaderTitle,
  NumbersBox,
  NumberItem,
  NumberTitle,
  NumberSubTitle,
  SpacingBox,
  ItemTitle,
} from './styles'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import Chart from './chart'

import ExamIcon from '../../images/icons/exam.svg'
import VideoIcon from '../../images/icons/video.svg'
import FlashCardIcon from '../../images/icons/flashcard.svg'
import {GroupReportingDialog} from '../../components/GroupReportingDialog'
import {OrgReport} from '../../redux/organizations'
import {LoadingStatuses} from '../../components/types/LoadingStatusses'
import Loading from '../../components/Loading'
import {DateFilter} from '.'
import {formatDateReport} from '../../utils/formatDate'
import DateSelector from '../../components/DateRangePicker'
import {InvoiceItem, InvoicesHeader} from '../GroupBilling/styles'
import {UserTitle, UsersGroup} from '../GroupManage/styles'
import {Box} from '@mui/material'
import moment from 'moment'

type Props = {
  report?: OrgReport
  reportStatus: LoadingStatuses
  onUpdateDateFilter: (dateFilter: DateFilter) => void
  dateFilter: DateFilter
}

const View = ({
  report,
  reportStatus,
  onUpdateDateFilter,
  dateFilter,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [dialogType, setDialogType] = useState('')
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

  const handleOpenDatePicker = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseDatePicker = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleOpenDialog = (type: string) => {
    setDialogType(type)
    setIsOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogType('')
    setIsOpen(false)
  }

  const handleChangeDaysRange = (days: number) => {
    const startDate = new Date()
    startDate.setDate(startDate.getDate() - days)
    const endDate = new Date()

    onUpdateDateFilter({startDate, endDate})
  }

  const handleChangeDate = (dateFilter: DateFilter) => {
    onUpdateDateFilter(dateFilter)
    handleCloseDatePicker()
  }

  return (
    <>
      <SettingsNavbar />
      {reportStatus === LoadingStatuses.Idle ||
      reportStatus === LoadingStatuses.Loading ? (
        <SettingsContentContainer>
          <Loading />
        </SettingsContentContainer>
      ) : (
        <SettingsContentContainer>
          <Header>
            <LeftHeader>
              <HeaderTitle variant="h2">Activity</HeaderTitle>
              <SubTitle>
                ({formatDateReport(dateFilter.startDate)} -{' '}
                {formatDateReport(dateFilter.endDate)})
              </SubTitle>
            </LeftHeader>
            <RightHeader>
              <SubTitle>Select a date range</SubTitle>
              <DateButton onClick={() => handleChangeDaysRange(60)}>
                60
              </DateButton>
              <DateButton onClick={() => handleChangeDaysRange(30)}>
                30
              </DateButton>
              <DateButton onClick={() => handleChangeDaysRange(7)}>
                7
              </DateButton>
              <DateButton onClick={handleOpenDatePicker}>
                <CalendarMonthOutlinedIcon />
              </DateButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseDatePicker}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <DateSelector
                  dateFilter={dateFilter}
                  onChangeDateFilter={handleChangeDate}
                />
              </Popover>
            </RightHeader>
          </Header>

          {report?.chart ? (
            <SpacingBox>
              <Chart data={report?.chart} />
            </SpacingBox>
          ) : null}

          {report?.overall ? (
            <SpacingBox>
              <NumbersBox>
                <NumberItem
                  $isLink={true}
                  onClick={() => handleOpenDialog('ALL ACTIVE USERS')}
                >
                  <NumberTitle>Active Users</NumberTitle>
                  <NumberSubTitle>
                    {report.overall.usersData.length}
                  </NumberSubTitle>
                </NumberItem>
                <NumberItem>
                  <NumberTitle>Total Hours</NumberTitle>
                  <NumberSubTitle>
                    {Math.floor(report.overall.time / 3600)}
                  </NumberSubTitle>
                </NumberItem>
              </NumbersBox>
            </SpacingBox>
          ) : null}

          <SpacingBox>
            <ItemTitle>Users Currently Logged In</ItemTitle>
            <InvoicesHeader>
              <UserTitle width={'60%'}>User Name</UserTitle>
              <UserTitle width={'40%'}>Time of Login</UserTitle>
            </InvoicesHeader>
            <Box>
              <UsersGroup>
                {report?.sessions.map((session, key) => {
                  return (
                    <InvoiceItem key={`sessions-${key}`} $bgColor={'#ececec'}>
                      <Box width={'60%'}>{session.name}</Box>
                      <Box width={'40%'}>
                        {moment(session.date).fromNow(true)} ago
                      </Box>
                    </InvoiceItem>
                  )
                })}
              </UsersGroup>
            </Box>
          </SpacingBox>

          {report?.ncarb ? (
            <SpacingBox>
              <ItemTitle>
                <img src={ExamIcon} alt="Passed Exams" /> Passed Exams
              </ItemTitle>
              <NumbersBox paddingTop={'12px'}>
                <NumberItem
                  $isLink={true}
                  onClick={() => handleOpenDialog('EXAM ATTEMPTS/PASSED')}
                >
                  <NumberTitle>Users</NumberTitle>
                  <NumberSubTitle>
                    {report.ncarb.usersData.length}
                  </NumberSubTitle>
                </NumberItem>
                <NumberItem>
                  <NumberTitle>Attempts</NumberTitle>
                  <NumberSubTitle>{report.ncarb.qtdAttemps}</NumberSubTitle>
                </NumberItem>
                <NumberItem>
                  <NumberTitle>Passed</NumberTitle>
                  <NumberSubTitle>{report.ncarb.qtdPassed}</NumberSubTitle>
                </NumberItem>
              </NumbersBox>
            </SpacingBox>
          ) : null}

          {report?.workshops ? (
            <SpacingBox>
              <ItemTitle>
                <img src={ExamIcon} alt="Virtual Workshops" /> Virtual Workshops
              </ItemTitle>
              <NumbersBox paddingTop={'12px'}>
                <NumberItem
                  $isLink={true}
                  onClick={() =>
                    handleOpenDialog('ACTIVE VIRTUAL WORKSHOPS USERS')
                  }
                >
                  <NumberTitle>Active Users</NumberTitle>
                  <NumberSubTitle>
                    {report.workshops.usersData.length}
                  </NumberSubTitle>
                </NumberItem>
                <NumberItem>
                  <NumberTitle>Workshops Attended</NumberTitle>
                  <NumberSubTitle>
                    {report.workshops.qtdWorkshops}
                  </NumberSubTitle>
                </NumberItem>
                <NumberItem>
                  <NumberTitle>Total Hours</NumberTitle>
                  <NumberSubTitle>{report.workshops.time}</NumberSubTitle>
                </NumberItem>
              </NumbersBox>
            </SpacingBox>
          ) : null}

          {report?.videos ? (
            <SpacingBox>
              <ItemTitle>
                <img src={VideoIcon} alt="Video Lectures" /> Video Lectures
              </ItemTitle>
              <NumbersBox paddingTop={'12px'}>
                <NumberItem
                  $isLink={true}
                  onClick={() => handleOpenDialog('ACTIVE VIDEO LECTURE USERS')}
                >
                  <NumberTitle>Active Users</NumberTitle>
                  <NumberSubTitle>
                    {report.videos.usersData.length}
                  </NumberSubTitle>
                </NumberItem>
                <NumberItem
                  $isLink={true}
                  onClick={() => handleOpenDialog('COURSES VIEWED')}
                >
                  <NumberTitle>Courses</NumberTitle>
                  <NumberSubTitle>
                    {report.videos.coursesData.length}
                  </NumberSubTitle>
                </NumberItem>
                <NumberItem>
                  <NumberTitle>Total Hours</NumberTitle>
                  <NumberSubTitle>
                    {Math.floor(report.videos.time / 3600)}
                  </NumberSubTitle>
                </NumberItem>
              </NumbersBox>
            </SpacingBox>
          ) : null}

          {report?.exams ? (
            <SpacingBox>
              <ItemTitle>
                <img src={ExamIcon} alt="Practice Exams" /> Practice Exams
              </ItemTitle>
              <NumbersBox paddingTop={'12px'}>
                <NumberItem
                  $isLink={true}
                  onClick={() => handleOpenDialog('ACTIVE PRACTICE EXAM USERS')}
                >
                  <NumberTitle>Active Users</NumberTitle>
                  <NumberSubTitle>
                    {report.exams.usersData.length}
                  </NumberSubTitle>
                </NumberItem>
                <NumberItem>
                  <NumberTitle>Attempts</NumberTitle>
                  <NumberSubTitle>{report.exams.qtdAttemps}</NumberSubTitle>
                </NumberItem>
                <NumberItem>
                  <NumberTitle>Total Hours</NumberTitle>
                  <NumberSubTitle>
                    {Math.floor(report.exams.time / 3600)}
                  </NumberSubTitle>
                </NumberItem>
              </NumbersBox>
            </SpacingBox>
          ) : null}

          {report?.flashcards ? (
            <SpacingBox>
              <ItemTitle>
                <img src={FlashCardIcon} alt="Digital Flashcards" /> Digital
                Flashcards
              </ItemTitle>
              <NumbersBox paddingTop={'12px'}>
                <NumberItem
                  $isLink={true}
                  onClick={() =>
                    handleOpenDialog('ACTIVE DIGITAL FLASHCARD USERS')
                  }
                >
                  <NumberTitle>Active Users</NumberTitle>
                  <NumberSubTitle>
                    {report.flashcards.usersData.length}
                  </NumberSubTitle>
                </NumberItem>
                <NumberItem>
                  <NumberTitle>Total Hours</NumberTitle>
                  <NumberSubTitle>
                    {Math.floor(report.flashcards.time / 3600)}
                  </NumberSubTitle>
                </NumberItem>
              </NumbersBox>
            </SpacingBox>
          ) : null}

          {report ? (
            <GroupReportingDialog
              isOpen={isOpen}
              type={dialogType}
              report={report}
              onClose={handleCloseDialog}
            />
          ) : null}
        </SettingsContentContainer>
      )}
    </>
  )
}

export default View
