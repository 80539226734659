import {Box, Typography} from '@mui/material'

import {BackgroundImage, CTAButton, ReferralBannerContainer} from './styles'

import ReferralBanner from '../../images/backgrounds/referralBanner.png'

const View = () => {
  return (
    <ReferralBannerContainer
      id="referral-banner"
      position="relative"
      // The height is calculated based on the background's image aspect ratio
      // for better responsiveness. Since it's 1440x416, it needs to be bigger
      // or smaller based on the viewport width.
      height="calc(100vw * (416/1440))"
      minHeight="416px"
      display="flex"
    >
      <BackgroundImage src={ReferralBanner} width="100%" height="100%" />
      <Box width="50%" height="100%" />
      <Box
        width="50%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        zIndex="1"
      >
        <Typography
          color="white"
          fontFamily="Freight Disp Pro"
          fontSize="3rem"
          mb={2}
        >
          Refer Friends, Get $50!
        </Typography>
        <Typography color="white" fontWeight={700} mb={2}>
          For every friend you refer who signs up for a membership:
        </Typography>
        <Typography color="white" ml={1}>
          • We'll give you $50 in your gift card of choice
        </Typography>
        <Typography color="white" ml={1}>
          • We'll give them 15% off their subscription
        </Typography>
        <Typography color="white" mt={2} mb={4}>
          Will send you a personalized discount code to share with your friends!
        </Typography>
        <Box>
          <CTAButton
            variant="contained"
            onClick={() => {
              window.open('https://www.blackspectacles.com/referral')
            }}
          >
            Participate now
          </CTAButton>
        </Box>
      </Box>
    </ReferralBannerContainer>
  )
}

export default View
