import React from 'react'
import View from './view'

type Props = {
  demoModalOpen: boolean
  setDemoModalOpen: (val: boolean) => void
}

const VirtualWorkshopDemoModal = ({demoModalOpen, setDemoModalOpen}: Props) => {
  return (
    <View demoModalOpen={demoModalOpen} setDemoModalOpen={setDemoModalOpen} />
  )
}

export default VirtualWorkshopDemoModal
