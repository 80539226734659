import React, {
  PropsWithChildren,
  ButtonHTMLAttributes,
  useState,
  ReactNode,
  useContext,
} from 'react'
import {Button as MUIButton} from '@mui/material'
import {getStyles, getHoverClass} from './buttonStyles'
import {useWindowDimensions} from '../../hooks/useWindowDimensions'
import theme from '../../styles/theme'
import {SegmentContext} from '../../context'

interface Props extends ButtonHTMLAttributes<object> {
  size?: 'small' | 'medium' | 'large'
  onClick?: React.MouseEventHandler<object>
  variant?: 'text' | 'outlined' | 'contained'
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'default'
    | 'white'
    | 'lightGrey'
    | 'lightBlue'
    | 'whiteGreyBorder'
    | 'whiteBlueBorder'
    | 'red'
    | 'black'
    | 'green'
    | 'grey'
    | 'whiteDisabled'
    | 'ncarbExamBlue'
  hoverColor?:
    | 'default'
    | 'primary'
    | 'whiteRedBorder'
    | 'mediumGrey'
    | 'whiteGreyBorder'
    | 'primaryHover'
    | 'secondaryHover'
    | 'lightBlueHover'
    | 'greenHover'
    | 'lightBlue'
    | 'greyHover'
    | 'ncarbExamBlue'
    | 'ncarbExameBlueHover'
    | 'transparent'
  hoverChildren?: ReactNode
  event?: string
}

export const Button: React.FC<PropsWithChildren<Props>> = ({
  type,
  size,
  style,
  color,
  onClick,
  variant,
  children,
  hoverColor,
  hoverChildren,
  event,
  ...rest
}) => {
  const {width} = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.sm

  const {analytics} = useContext(SegmentContext)

  const [hover, setHover] = useState(false)

  const displayHoverColor = hoverColor ? hoverColor : getHoverClass(color)

  const buttonStyles = getStyles(
    hover ? displayHoverColor : color,
    rest.disabled,
  )

  let styles = {
    fontFamily: 'Public Sans',
    borderRadius: '3px',
    textTransform: 'none',
    minWidth: '120px',
    height: '40px',
    boxSizing: 'border-box',
    fontSize: '14px',
    letterSpacing: '.05em',
    ...buttonStyles,
    ...style,
  }

  if (size === 'medium') {
    styles = {...styles, height: '40px'}
  } else if (size === 'large') {
    styles = {...styles, height: '48px', fontSize: isMobile ? '14px' : '14px'}
  }

  const handleClick = (e: React.MouseEvent<object, MouseEvent>) => {
    if (analytics) {
      analytics.track(event ? event : 'click')
    }

    if (onClick) onClick(e)
  }

  const handleMouseEnter = () => {
    setHover(true)
  }

  const handleMouseleave = () => {
    setHover(false)
  }

  return (
    <MUIButton
      {...rest}
      size={size}
      type={type}
      style={styles}
      variant={variant}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseleave}
    >
      {hover && hoverChildren ? <>{hoverChildren}</> : <>{children}</>}
    </MUIButton>
  )
}
