import {Box} from '@mui/material'
import styled from 'styled-components'
import theme from '../../../styles/theme'

export const Row = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  width: 100% !important;

  && h2 {
    width: 66.667%;
  }

  && button {
    width: 250px !important;
  }

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    text-align: center;

    && h2 {
      padding-bottom: 30px;
    }

    && button {
      width: 100% !important;
      max-width: unset;
    }

    margin-bottom: 10px !important;
  }
`

export const ImageBox = styled(Box)<{$isDisabled?: boolean}>`
  display: flex;
  align-items: center;
  gap: 8px;

  && img {
    width: 24px;
  }

  ${({$isDisabled}) =>
    $isDisabled
      ? `
    && a {
      color: #ccc !important;
    }
  `
      : null}

  ${theme.breakpoints.down('md')} {
    justify-content: center;
  }
`

export const ButtonBox = styled(Box)`
  @media only screen and (max-width: 1150px) {
    && button {
      width: 180px !important;
    }

    && a {
      width: 180px !important;
    }
  }

  ${theme.breakpoints.down('md')} {
    margin-top: 10px !important;
    width: 50% !important;
    align-self: center;

    && button {
      width: 100% !important;
    }

    && a {
      width: 100% !important;
    }
  }

  ${theme.breakpoints.down('sm')} {
    width: 100% !important;
  }
`
