import React from 'react'
import {useParams} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {Box} from '@mui/material'
import ExamQuestions from '../../components/ExamQuestions'

const Exams = () => {
  const {examId} = useParams()

  return (
    <>
      <Helmet>
        <title>{`ARE 5.0 Practice Exam`}</title>
      </Helmet>
      <Box>
        <ExamQuestions examId={examId!} />
      </Box>
    </>
  )
}

export default Exams
