import React, {useState} from 'react'
import View from './view'
import {Attempt, AttemptsBody, completeARE} from '../../redux/reportExamStatus'
import {useAppDispatch} from '../../redux/configureStore'
import {useAuth} from '../AuthProvider'
import {toggleErrorDialog} from '../../redux/config'

type Props = {
  open: boolean
  setOpen: (val: boolean) => void
  attempts?: Attempt[]
}

const ARECompleteDialog = ({open, setOpen, attempts}: Props) => {
  const [isComplete, setIsComplete] = useState(false)
  const auth = useAuth()
  const dispatch = useAppDispatch()

  const handleSubmit = async () => {
    try {
      if (attempts) {
        const body: AttemptsBody[] = []

        attempts.forEach(attempt => {
          const currentIndex = body.findIndex(
            row => row.ncarbExamId === attempt.ncarbExamId,
          )
          if (currentIndex !== -1) {
            if (attempt.passed) {
              body[currentIndex].passes++
            } else {
              body[currentIndex].fails++
            }

            body[currentIndex].totalAttempts++

            body[currentIndex].passRate =
              (body[currentIndex].passes / body[currentIndex].totalAttempts) *
              100
          } else {
            const fails = !attempt.passed ? 1 : 0
            const passes = attempt.passed ? 1 : 0
            const totalAttempts = 1
            const passRate = attempt.passed ? 100 : 0

            body.push({
              ncarbExamId: attempt.ncarbExamId ?? 0,
              fails,
              passes,
              totalAttempts,
              passRate,
            })
          }
        })

        const {type} = await dispatch(completeARE({auth, attempts: body}))
        if (completeARE.rejected.type === type) {
          throw new Error('Error on submit ARE Complete')
        } else {
          setIsComplete(true)
        }
      }
    } catch (error) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to submit, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  return (
    <View
      open={open}
      setOpen={setOpen}
      attempts={attempts}
      handleSubmit={handleSubmit}
      isComplete={isComplete}
    />
  )
}

export default ARECompleteDialog
