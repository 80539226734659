import styled from 'styled-components'
import {Box, Pagination, Stack} from '@mui/material'
import theme from '../../styles/theme'

export const SearchContainer = styled(Box)`
  width: 50%;
  padding: 20px 0 80px;
  min-height: 45vh;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    padding: 20px 0 20px;
  }
`

export const FlexBox = styled(Box)`
  display: flex;
  flex-flow: column;
  height: 100%;
`

export const SearchBox = styled(Box)`
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 12px;

  && a {
    font-size: 18px;
    width: fit-content;

    ${theme.breakpoints.down('md')} {
      font-size: 16px;
    }
  }
`

export const StyledStack = styled(Stack)`
  margin-top: 32px;
`

export const MainPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    width: 40px;
    height: 40px;
    margin: 0;
    color: #ffffff;
    background-color: #1377d4;
    border: 2px solid #1377d4;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    ${theme.breakpoints.down('md')} {
      width: 32px !important;
      height: 32px;
    }
  }

  .MuiPaginationItem-root:hover {
    background-color: #fff;
    color: #000;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: #fff;
    color: #000;
    cursor: default;
  }

  .MuiPaginationItem-page.Mui-selected:hover {
    background-color: #fff;
    color: #000;
  }

  .MuiPaginationItem-ellipsis {
    border: 0 !important;
    background-color: #fff !important;
    color: #000 !important;
    font-size: 20px !important;
  }

  .MuiPagination-ul {
    li {
      margin-right: 6px;
    }

    li:last-child {
      margin: 0;
    }
  }
`
