import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {RootState} from './rootReducer'
import {LoadingStatuses} from '../components/types/LoadingStatusses'
import {Lesson} from './lessons'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
export type Organization = {
  id: string
  title: string
  courses: Course[]
}

export type Course = {
  id: string
  title: string
  description: string
  abbreviation: 'PcM' | 'PjM' | 'PA' | 'PPD' | 'PDD' | 'CE'
  organizationId: string
  lessons: CourseLesson[]
  tags?: CourseTag[]
  videos?: number
  duration?: number
  assets?: Lesson[]
}

export type CourseTag = {
  id: string
  title: string
  category: string
}

export type CourseLesson = {
  id: string
  version: number
  active: boolean
  description: string
  duration: number
}

type SliceState = {
  courses?: Course[]
  coursesStatus: LoadingStatuses
  course?: Course
  courseStatus: LoadingStatuses
  organization?: Organization
  organizationStatus: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
export const getOrganizationCourses = createAsyncThunk<any>(
  'courses/organization/get',
  async () => {
    const organizationId = process.env.REACT_APP_BKS_ORG_ID
    const endpoint = `/v1/guest/are/courses/organization/${organizationId}`

    return useApi(null, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

type GetCoursePayload = {
  courseId?: string
}
export const getCourse = createAsyncThunk<any, GetCoursePayload>(
  'courses/get',
  async ({courseId}) => {
    const endpoint = `/v1/guest/are/courses/${courseId}`

    return useApi(null, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

type GetCoursesByTagPayload = {
  auth: AuthContextInterface
  tag: string
}
export const getCoursesByTag = createAsyncThunk<any, GetCoursesByTagPayload>(
  'courses/tag/get',
  async ({tag, auth}) => {
    const endpoint = `/v1/are/courses/tags/${tag}`

    return useApi(auth, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

type GetGuestCoursesByTagPayload = {
  tag: string
}
export const getGuestCoursesByTag = createAsyncThunk<
  any,
  GetGuestCoursesByTagPayload
>('guest/courses/tag/get', async ({tag}) => {
  const endpoint = `/v1/guest/are/courses/tags/${tag}`

  return useApi(null, endpoint, {
    method: 'GET',
  }).then(res => res.json())
})

type UpdateCourseBookmarkPayload = {
  auth: AuthContextInterface
  courseId: string
  version: number
}
export const updateCourseBookmark = createAsyncThunk<
  any,
  UpdateCourseBookmarkPayload
>('/courses/bookmark/update', async ({courseId, version, auth}) => {
  const endpoint = `/v1/are/courses/${courseId}/version/${version}`

  return useApi(auth, endpoint, {
    method: 'PATCH',
  }).then(res => res.text())
})
//#endregion

//#region slice
const initialState: SliceState = {
  courses: [],
  course: undefined,
  organization: undefined,
  coursesStatus: LoadingStatuses.Idle,
  courseStatus: LoadingStatuses.Idle,
  organizationStatus: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'course',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCoursesByTag.pending, state => {
      state.courses = []
      state.coursesStatus = LoadingStatuses.Loading
    })
    builder.addCase(getCoursesByTag.fulfilled, (state, action) => {
      state.courses = action.payload
      state.coursesStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(getCoursesByTag.rejected, state => {
      state.courses = []
      state.coursesStatus = LoadingStatuses.Failed
    })
    builder.addCase(getGuestCoursesByTag.pending, state => {
      state.courses = []
      state.coursesStatus = LoadingStatuses.Loading
    })
    builder.addCase(getGuestCoursesByTag.fulfilled, (state, action) => {
      state.courses = action.payload
      state.coursesStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(getGuestCoursesByTag.rejected, state => {
      state.courses = []
      state.coursesStatus = LoadingStatuses.Failed
    })
    builder.addCase(getCourse.pending, state => {
      state.course = undefined
      state.courseStatus = LoadingStatuses.Loading
    })
    builder.addCase(getCourse.fulfilled, (state, action) => {
      state.course = action.payload
      state.courseStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(getCourse.rejected, state => {
      state.course = undefined
      state.courseStatus = LoadingStatuses.Failed
    })
    builder.addCase(getOrganizationCourses.pending, state => {
      state.organization = undefined
      state.organizationStatus = LoadingStatuses.Loading
    })
    builder.addCase(getOrganizationCourses.fulfilled, (state, action) => {
      state.organization = action.payload
      state.organizationStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(getOrganizationCourses.rejected, state => {
      state.organization = undefined
      state.organizationStatus = LoadingStatuses.Failed
    })
  },
})
//#endregion

//#region selectors
export const selectCourses = ({course}: RootState) => course.courses
export const selectCourse = ({course}: RootState) => course.course
export const selectOrganization = ({course}: RootState) => course.organization
export const selectCoursesStatus = ({course}: RootState) => course.coursesStatus
export const selectCourseStatus = ({course}: RootState) => course.courseStatus
export const selectOrganizationStatus = ({course}: RootState) =>
  course.organizationStatus
//#endregion
