import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../../hooks/useApi'

export type IndividualAccountDetails = {
  firstName: string
  lastName: string
  email: string
  password: string
}

export type Member = {
  memberId?: string
}

type CreateAccountPayload = {
  individualAccountDetails: IndividualAccountDetails
}

export const createNewAccount = createAsyncThunk<any, CreateAccountPayload>(
  '/createAccount',
  async ({individualAccountDetails}) => {
    const {firstName, lastName, email, password} = individualAccountDetails

    const fields = {
      email: email,
      password: password,
      firstname: firstName,
      lastname: lastName,
      property_id: 1,
    }

    return useApi(
      null,
      `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/guest/are/member`,
      {
        method: 'POST',
        body: JSON.stringify(fields),
      },
    ).then(res => res.json())
  },
)
