import React from 'react'
import View from './view'

type Props = {
  courseAbbreviation: 'PcM' | 'PjM' | 'PA' | 'PPD' | 'PDD' | 'CE'
}

const ExamStatus = ({courseAbbreviation}: Props) => {
  return <View courseAbbreviation={courseAbbreviation} />
}

export default ExamStatus
