import React, {useState, useEffect} from 'react'
import {Box, Typography, Link} from '@mui/material'
import {Button} from '../../button'
import {
  VirtualWorkShopContainer,
  PreRequisiteContainer,
  ButtonContainer,
  UpcomingVirtualWorkshopContainer,
  DateBox,
  DetailsContainer,
} from './styles'
import {colors} from '../../../styles/theme'
import {
  Workshop,
  attendWorkshop,
  getGuestWorkshops,
  getWorkshops,
  rsvpWorkshop,
  selectConflictedWorkshop,
  selectWorkshops,
} from '../../../redux/workshop'
import {useAppDispatch} from '../../../redux/configureStore'
import {useSelector} from 'react-redux'
import {useAuth} from '../../AuthProvider'
import {toggleErrorDialog} from '../../../redux/config'
import {v4 as uuidv4} from 'uuid'
import {useParams} from 'react-router-dom'
import {communityUrls} from '../../../constants/community-urls'
import VirtualWorkshopForm from '../VirtualWorkshopForm'
import VirtualWorkshopDemoModal from '../VirtualWorkshopDemoModal'
import {selectMemberAccess} from '../../../redux/members'
import {getLessons, selectLesson} from '../../../redux/lessons'
import {CourseLesson, getCourse} from '../../../redux/courses'
import convertDuration from '../../../utils/convertDuration'
import formatDuration from 'date-fns/formatDuration'
import moment from 'moment-timezone'
import {google, outlook, yahoo, ics, CalendarEvent} from 'calendar-link'
import CalendarIcon from '../../../images/icons/calendar.svg'
import ConflictedWorkshopDialog from '../../ConflictedWorkshopDialog'
import {formatWorkshopDate} from '../../../utils/formatWorkshopDates'
import {getUpgradePrice} from '../../../redux/members'

type Props = {
  courseAbbreviation: 'PcM' | 'PjM' | 'PA' | 'PPD' | 'PDD' | 'CE'
}

const View = ({courseAbbreviation}: Props) => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const {courseId} = useParams()
  const [open, setOpen] = useState(false)
  const [isConflictDialogOpen, setIsConflictDialogOpen] = useState(false)
  const [demoModalOpen, setDemoModalOpen] = useState(false)

  const workshops = useSelector(selectWorkshops)
  const conflictedWorkshop = useSelector(selectConflictedWorkshop)
  const lesson = useSelector(selectLesson)
  const access = useSelector(selectMemberAccess)

  const hasAccess =
    access && Object.keys(access).length !== 0
      ? Number(access.are?.workshops?.value) === 1
      : false

  let nextWorkshopPrereq: string[] = []

  const nextWorkshop = workshops[0]
  const upcomingWorkshops = workshops.slice(1)

  if (nextWorkshop && auth.isAuthenticated && hasAccess) {
    const prereq = nextWorkshop?.prerequisites
    const idx = prereq?.indexOf('\n2.0')

    if (idx === -1) {
      nextWorkshopPrereq = [...prereq.split('\n')]
    } else {
      const version2Prereq = prereq?.slice(idx)
      const prereq2 = version2Prereq.split('\n2.0').slice(1)
      nextWorkshopPrereq = [...prereq2]
    }
  }

  const fetchWorkshops = async () => {
    if (courseId) {
      // get GMT time
      const startTime = new Date(new Date().toUTCString()).getTime() / 1000
      // add 1 month to check for next 30 days of workshops
      const endTime = startTime + 2592000

      try {
        if (auth.isAuthenticated && hasAccess) {
          const {type, payload} = await dispatch(
            getWorkshops({
              auth,
              courseId,
              startTime,
              endTime,
            }),
          )

          if (type === getWorkshops.rejected.type) {
            throw new Error('Unable to get workshops')
          } else {
            checkRSVPStatus(payload.workshops[0]?.id)
          }
        } else {
          const {type} = await dispatch(
            getGuestWorkshops({
              courseId,
              startTime,
              endTime,
            }),
          )

          if (type === getGuestWorkshops.rejected.type) {
            throw new Error('Unable to get workshops')
          }
        }
      } catch (e) {
        await dispatch(
          toggleErrorDialog({
            opened: true,
            error:
              'We were unable to get upcoming virtual workshops data, please retry reloading the page. If issue persists please contact our support team',
          }),
        )
      }
    }
  }

  const formatPrereq = (prereq?: string) => {
    if (!prereq) return

    let title
    const preqReqParts = prereq.split('- ')

    if (preqReqParts.length === 3) {
      title = preqReqParts[0] + preqReqParts[1]
    } else {
      title = preqReqParts[0]
    }

    return title
  }

  const fetchLessons = async () => {
    try {
      if (courseId) {
        const {type, payload} = await dispatch(getCourse({courseId}))
        if (type === getCourse.fulfilled.type) {
          const courseLessons = payload.lessons.filter(
            (item: CourseLesson) => item.active,
          )

          courseLessons.sort((a: any, b: any) => b.version - a.version)
          const lessonId = courseLessons[0].id

          const lessonData = await dispatch(getLessons({auth, lessonId}))

          if (lessonData.type === getLessons.rejected.type) {
            throw new Error('Unable to get lessons')
          }
        } else if (type === getCourse.rejected.type) {
          throw new Error('Unable to get course')
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const getNextWorkshopPrerequisitesApproximateDuration = (
    preReq: string[],
  ): string => {
    if (lesson) {
      const videoAssets = lesson.assets.filter(
        asset => asset.assetTypeAbbr === 'video',
      )

      const approximateDuration = preReq.reduce((acc, cur) => {
        const prereqId = cur?.split('video/')[1]?.trim()

        const asset = videoAssets.find(lesson => lesson.id === prereqId)

        if (asset) {
          return acc + convertDuration(asset.videoDuration!)
        }

        return acc
      }, 250) // "Getting Started with Virtual Workshops" duration

      return `approx. ${formatDuration(
        {
          hours: Math.floor(approximateDuration / 3600),
          minutes: Math.floor((approximateDuration % 3600) / 60),
          seconds: Math.floor(approximateDuration % 60),
        },
        {delimiter: '; '},
      )}`
    }

    return ''
  }

  // count attendance and then open workshop zoom link
  const launchWorkshop = async () => {
    if (nextWorkshop) {
      try {
        const {type} = await dispatch(
          attendWorkshop({auth, workshopId: nextWorkshop?.id}),
        )

        if (type === attendWorkshop.rejected.type) {
          throw new Error('Unable to count attendance status')
        }

        window.open(nextWorkshop?.link, '_blank')
      } catch (e) {
        await dispatch(
          toggleErrorDialog({
            opened: true,
            error:
              'We were unable to update your attendance status, please retry. If issue persists please contact our support team',
          }),
        )
      }
    }
  }

  const handleRSVP = async () => {
    if (nextWorkshop) {
      try {
        const action = nextWorkshop.rsvp === true ? 'cancel' : 'reserve'

        const {type, payload} = await dispatch(
          rsvpWorkshop({auth, workshopId: nextWorkshop?.id, action}),
        )

        if (type === rsvpWorkshop.rejected.type) {
          throw new Error('Unable to update RSVP status')
        } else {
          if (payload.conflict && payload.conflict.id) {
            setIsConflictDialogOpen(true)
          }
        }
      } catch (e) {
        await dispatch(
          toggleErrorDialog({
            opened: true,
            error:
              'We were unable to update your RSVP status, please retry. If issue persists please contact our support team',
          }),
        )
      }
    }
  }

  const redirectToCommunity = () => {
    const url = communityUrls[courseAbbreviation]
    window.open(url, '_blank')
  }

  const checkRSVPStatus = async (workshopId: string) => {
    try {
      if (workshopId) {
        const {type} = await dispatch(
          rsvpWorkshop({auth, workshopId, action: 'status'}),
        )

        if (type === rsvpWorkshop.rejected.type) {
          throw new Error('Unable to fetch RSVP status')
        }
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to fetch your RSVP status, please retry. If issue persists please contact our support team',
        }),
      )
    }
  }

  const getEventData = (workshop?: Workshop) => {
    if (!workshop) return {} as CalendarEvent

    const startDate = new Date(workshop?.date)
    const endDate = new Date(workshop?.date)
    endDate.setHours(endDate.getHours() + 2)

    const centralStartDate = moment(startDate)
      .tz('America/Chicago')
      .format('YYYY-MM-DD HH:mm:ss ZZ')
    const centralEndDate = moment(endDate)
      .tz('America/Chicago')
      .format('YYYY-MM-DD HH:mm:ss ZZ')

    const response: CalendarEvent = {
      title: workshop?.title,
      description: workshop?.description,
      start: centralStartDate,
      end: centralEndDate,
      location: window.location.href,
    }

    return response
  }

  const formatPrereqURL = (url: string) => {
    if (url) {
      const preqReqParts = url.split('- ')
      if (preqReqParts.length === 3) {
        return preqReqParts[2]
      }

      return preqReqParts[1]
    }
  }

  useEffect(() => {
    fetchWorkshops()

    if (auth.isAuthenticated && hasAccess) {
      fetchLessons()
    }
  }, [])

  const handleDownload = (workshop?: Workshop) => {
    const response = decodeURIComponent(
      ics(getEventData(workshop)).replace(
        /^data:text\/calendar;charset=utf8,/,
        '',
      ),
    )
    const blob = new Blob([response], {type: 'text/calendar;charset=utf-8'})
    const url = window.URL.createObjectURL(blob)
    return url
  }

  const handleDemoClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault()
    setDemoModalOpen(true)
  }

  const handleUpgradeUrl = async (e:any) => {
    e.preventDefault();
    const resp = await dispatch(getUpgradePrice({auth}))
    console.log(resp.payload)
    window.location.href = String(resp.payload.priceId).length > 0 ? 'https://checkout.blackspectacles.com/' + String(resp.payload.priceId) : '#'
  }

  return (
    <VirtualWorkShopContainer>
      <Typography variant={'h3'}>Virtual Workshops</Typography>

      <Typography variant={'body1'} marginTop={'16px'}>
        Next Virtual Workshop:
      </Typography>

      {auth.isAuthenticated && hasAccess ? null : (
        <>
          <Typography variant={'body1'}>
            <Link href="#"
                  onClick={handleUpgradeUrl}
            >
              Upgrade your subscription to an Expert plan
            </Link>{' '}
            to get access to this material.
          </Typography>
        </>
      )}

      <>
        <PreRequisiteContainer>
          <DetailsContainer>
            {nextWorkshop ? (
              <>
                <Typography
                  variant={'body2'}
                  sx={{fontWeight: 700, color: '#2D2D2D'}}
                >
                  {nextWorkshop?.title}
                </Typography>

                <Typography variant={'body2'}>
                  {nextWorkshop.rsvp ? (
                    <>
                      ✔ you RSVPed
                      <br />
                    </>
                  ) : null}
                  {formatWorkshopDate(nextWorkshop?.date)}
                </Typography>

                {auth.isAuthenticated && hasAccess ? (
                  <>
                    <Typography variant={'body2'}>
                      Add to Calendar:{' '}
                      <em>
                        <Link
                          href={`${handleDownload(nextWorkshop)}`}
                          target="_blank"
                        >
                          Apple
                        </Link>{' '}
                        |{' '}
                        <Link
                          href={`${google(
                            getEventData(nextWorkshop),
                          )}&ctz=America/Chicago`}
                          target="blank"
                        >
                          Google Calendar
                        </Link>{' '}
                        |{' '}
                        <Link
                          href={outlook(getEventData(nextWorkshop))}
                          target="blank"
                        >
                          Outlook Live
                        </Link>{' '}
                        |{' '}
                        <Link
                          href={yahoo(getEventData(nextWorkshop))}
                          target="blank"
                        >
                          Yahoo
                        </Link>
                      </em>
                    </Typography>

                    <Typography variant={'body2'} color={'#999'}>
                      {nextWorkshop?.description}
                    </Typography>

                    <Typography variant={'body2'}>
                      Prerequisites:
                      {nextWorkshopPrereq.length && lesson && (
                        <Typography
                          component="span"
                          variant="body2"
                          style={{color: '#999', letterSpacing: 0}}
                        >
                          {' '}
                          (
                          {getNextWorkshopPrerequisitesApproximateDuration(
                            nextWorkshopPrereq,
                          )}
                          )
                        </Typography>
                      )}
                    </Typography>

                    <Typography
                      variant={'body2'}
                      marginTop={'8px'}
                      key={uuidv4()}
                    >
                      <Link
                        href={'#'}
                        color={colors.blue1}
                        sx={{textDecoration: 'none'}}
                        onClick={handleDemoClick}
                      >
                        - Getting Started
                      </Link>
                    </Typography>

                    {nextWorkshopPrereq.map(prereq => (
                      <Typography
                        variant={'body2'}
                        marginTop={'8px'}
                        key={uuidv4()}
                      >
                        <Link
                          href={`${formatPrereqURL(prereq)}`}
                          color={colors.blue1}
                          sx={{textDecoration: 'none'}}
                        >
                          - {formatPrereq(prereq)}
                        </Link>
                      </Typography>
                    ))}
                  </>
                ) : (
                  <Typography variant={'body2'} color={'#999'}>
                    <em>{nextWorkshop?.description}</em>
                  </Typography>
                )}
              </>
            ) : null}
          </DetailsContainer>

          {/* Buttons */}
          <ButtonContainer>
            <Button
              disabled={!auth.isAuthenticated || !hasAccess || !nextWorkshop}
              color={!nextWorkshop?.rsvp ? 'secondary' : 'grey'}
              children={!nextWorkshop?.rsvp ? 'RSVP' : 'UN-RSVP'}
              onClick={handleRSVP}
            />
            {nextWorkshop?.rsvp ? (
              <Button
                color={'primary'}
                disabled={!nextWorkshop.isLaunchEnabled}
                children={'Launch Workshop'}
                onClick={launchWorkshop}
              />
            ) : null}
            <Button
              color={'primary'}
              disabled={!auth.isAuthenticated || !hasAccess || !nextWorkshop}
              children={'Submit Question'}
              onClick={() => setOpen(true)}
            />
            <Button
              color={'primary'}
              children={`${courseAbbreviation} Community`}
              onClick={redirectToCommunity}
            />
          </ButtonContainer>
        </PreRequisiteContainer>
      </>

      {upcomingWorkshops ? (
        <>
          <UpcomingVirtualWorkshopContainer>
            <Typography variant={'h3'} style={{marginBottom: '16px'}}>
              Upcoming Virtual Workshops
            </Typography>
            {upcomingWorkshops.map(upcomingWorkshop => (
              <Box key={uuidv4()}>
                <Typography
                  variant={'body2'}
                  style={{fontWeight: 700, color: '#2d2d2d'}}
                >
                  {upcomingWorkshop.title}
                </Typography>
                <DateBox>
                  <Typography variant={'body2'}>
                    {formatWorkshopDate(upcomingWorkshop.date)}
                  </Typography>
                  {auth.isAuthenticated && hasAccess && (
                    <Link
                      href={`${handleDownload(upcomingWorkshop)}`}
                      target="_blank"
                    >
                      <img src={CalendarIcon} alt="Add to Calendar" />
                    </Link>
                  )}
                </DateBox>
              </Box>
            ))}
          </UpcomingVirtualWorkshopContainer>

          <ConflictedWorkshopDialog
            open={isConflictDialogOpen}
            setOpen={setIsConflictDialogOpen}
            workshop={nextWorkshop}
            conflictedWorkshop={conflictedWorkshop}
          />

          <VirtualWorkshopForm open={open} setOpen={setOpen} />

          <VirtualWorkshopDemoModal
            demoModalOpen={demoModalOpen}
            setDemoModalOpen={setDemoModalOpen}
          />
        </>
      ) : null}
    </VirtualWorkShopContainer>
  )
}

export default View
