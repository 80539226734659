import {AuthContextInterface} from '../components/AuthProvider'

export const useApi = async (
  auth: AuthContextInterface | null,
  url: string,
  options: any = {},
  origin?: string,
) => {
  const checkStatus = async (res: Response) => {
    if (res.ok) {
      return res
    } else {
      const {error, errors} = await res.json()

      if (errors && errors.length > 0) {
        throw new Error(errors[0])
      } else if (error && error.message) {
        throw new Error(error.message)
      } else {
        throw new Error(res.statusText)
      }
    }
  }

  options.headers = options.headers || {}
  if (options.headers && options.headers['Content-Type'] === 'Automatic') {
    delete options.headers['Content-Type']
  } else {
    options.headers['Content-Type'] = 'application/json'
  }

  if (origin !== 'network') {
    if (
      process.env.REACT_APP_X_MEMBER_ID &&
      process.env.REACT_APP_X_MEMBER_ID !== 'blank'
    ) {
      options.headers['X-Member-Id'] = process.env.REACT_APP_X_MEMBER_ID
    }

    if (
      process.env.REACT_APP_MEMBER_ACCESS_HEADER &&
      process.env.REACT_APP_MEMBER_ACCESS_HEADER !== 'blank'
    ) {
      options.headers['X-Member-Access'] =
        process.env.REACT_APP_MEMBER_ACCESS_HEADER
    }
  }

  let baseUrl = url

  if (baseUrl.indexOf('https') === -1) {
    if (origin === 'network') {
      //GO SERVICES ENDPOINT
      baseUrl = `${process.env.REACT_APP_CHECKOUT_API_URL}${url}`
    } else {
      //NODE SERVICES ENDPOINT BY DEFAULT
      baseUrl = `${process.env.REACT_APP_BKS_PLATFORM_SERVICES_URL}${url}`
    }
  }

  if (auth) {
    const accessToken = auth.getAuthToken()

    return fetch(baseUrl, {
      ...options,
      headers: {
        // Add the Authorization header to the existing headers
        Authorization: `Bearer ${accessToken}`,
        ...options.headers,
      },
    }).then(checkStatus)
  } else {
    return fetch(baseUrl, {
      ...options,
      headers: {
        ...options.headers,
      },
    }).then(checkStatus)
  }
}
