import React from 'react'
import View from './view'

type Props = {
  open: boolean
  setOpen: (value: boolean) => void
}

const SubscriptionModal = ({open, setOpen}: Props) => {
  return <View open={open} setOpen={setOpen} />
}

export default SubscriptionModal
