import React, {useEffect} from 'react'
import View from './view'
import {useIntercom} from 'react-use-intercom'

type Props = {
  children: React.ReactNode
  showHero?: boolean
  hideNavbar?: boolean
  hideFooter?: boolean
  hideIntercom?: boolean
  showCompleteText?: boolean
}

const Wrapper = ({
  children,
  showHero,
  hideNavbar,
  hideFooter,
  hideIntercom,
  showCompleteText,
}: Props) => {
  const {boot: bootIntercom} = useIntercom()

  useEffect(() => {
    try {
      if (!hideIntercom) bootIntercom()
    } catch (error) {
      console.error(error)
    }
  }, [bootIntercom])

  return (
    <View
      children={children}
      showHero={showHero}
      hideNavbar={hideNavbar}
      hideFooter={hideFooter}
      showCompleteText={showCompleteText}
    />
  )
}

export default Wrapper
