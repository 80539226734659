import React, {useState} from 'react'
import {VideoGroup} from '../../redux/videos'
import {Course, selectCourseStatus} from '../../redux/courses'
import {useSelector} from 'react-redux'
import {MobileBox, RightBox, VideosBox} from '../Videos/styles'
import VideoSideBar from '../../components/VideoSideBar'
import {LoadingStatuses} from '../../components/types/LoadingStatusses'
import Loading from '../../components/Loading'
import CloseIcon from '@mui/icons-material/Close'
import {QuizHeader} from './styles'
import {Helmet} from 'react-helmet'
import QuizQuestions from '../../components/QuizQuestions'

type Props = {
  course: Course
  examId: string
  videoGroup?: VideoGroup[]
  mobileOpen: boolean
  onChangeMobileMenu: (value: boolean) => void
}

const View = ({
  course,
  examId,
  videoGroup,
  mobileOpen,
  onChangeMobileMenu,
}: Props) => {
  const courseStatus = useSelector(selectCourseStatus)

  const filteredGroup = videoGroup
    ?.map((group, index) => {
      const found = group.lessons.some(lesson => lesson.id === examId)

      return found ? {group, index} : null
    })
    .filter(item => item !== null)[0]

  const currentGroup = filteredGroup?.group

  const nextGroup =
    videoGroup && videoGroup[filteredGroup?.index! + 1]
      ? videoGroup[filteredGroup?.index! + 1]
      : undefined
  const previousGroup =
    videoGroup && videoGroup[filteredGroup?.index! - 1]
      ? videoGroup[filteredGroup?.index! - 1]
      : undefined

  const currentIndex = currentGroup?.lessons.findIndex(
    lesson => lesson.id === examId,
  )

  const currentQuiz = currentGroup?.lessons[currentIndex!]

  const getNextUrl = () => {
    const nextLesson = currentGroup?.lessons[currentIndex! + 1]
    if (nextLesson) {
      return `/courses/${currentGroup?.courseId}/${
        nextLesson.assetTypeAbbr
      }/${nextLesson?.id}${nextLesson?.isFree ? '/free' : ''}`
    } else if (nextGroup) {
      return `/courses/${currentGroup?.courseId}/${nextGroup?.lessons[0]
        .assetTypeAbbr}/${nextGroup?.lessons[0].id}${
        nextGroup?.lessons[0]?.isFree ? '/free' : ''
      }`
    } else {
      return '/'
    }
  }

  const isSectionQuiz = currentQuiz?.shortDescription.includes('Section')

  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [attemptStarted, setAttemptStarted] = useState(false)

  return (
    <>
      <Helmet>
        {currentQuiz && (
          <title>{`${currentQuiz.shortDescription} | Black Spectacles`}</title>
        )}
      </Helmet>
      <VideosBox>
        {!isSectionQuiz && currentQuiz && (
          <VideoSideBar
            course={course}
            id={examId}
            mobileOpen={mobileOpen}
            currentGroup={currentGroup}
            previousGroup={previousGroup}
            nextGroup={nextGroup}
            currentIndex={currentIndex}
          />
        )}
        <RightBox>
          {courseStatus === LoadingStatuses.Loading ||
          courseStatus === LoadingStatuses.Idle ? (
            <Loading />
          ) : (
            <>
              <MobileBox onClick={() => onChangeMobileMenu(!mobileOpen)}>
                View Chapter
                {mobileOpen && (
                  <CloseIcon
                    className="x-button"
                    style={{
                      height: 32,
                      width: 32,
                      color: `#fff`,
                    }}
                  />
                )}
              </MobileBox>

              <QuizHeader>
                {currentQuiz && currentQuiz.questions && (
                  <>
                    {currentQuiz.shortDescription}
                    {attemptStarted &&
                    currentQuestion < currentQuiz.questions ? (
                      <span>
                        Question {currentQuestion + 1} of{' '}
                        {currentQuiz.questions}
                      </span>
                    ) : null}
                  </>
                )}
              </QuizHeader>

              <QuizQuestions
                lesson={currentQuiz}
                currentQuestion={currentQuestion}
                setCurrentQuestion={setCurrentQuestion}
                setAttemptStarted={setAttemptStarted}
                getNextUrl={getNextUrl}
              />
            </>
          )}
        </RightBox>
      </VideosBox>
    </>
  )
}

export default View
