import {Box, Typography} from '@mui/material'
import styled from 'styled-components'
import theme, {colors} from '../../../../styles/theme'

export const FlashCardContentContainer = styled(Box)`
  padding: 40px;
  background: #fafafa;
  width: 75%;

  ${theme.breakpoints.down('md')} {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }
`
export const FlashCard = styled(Box)<{icon?: string}>`
  background-image: url(${({icon}) => (icon ? icon : '')});
  background-position: 50% 10px;
  background-repeat: no-repeat;
  background-size: 48px 48px;
  background-color: #fff;
  height: 480px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  ${theme.breakpoints.down('md')} {
    max-height: 60vh;
    padding: 10px;
  }
`

export const EmptyBox = styled(Box)`
  background: #fff;
  height: 480px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
`

export const CardHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
`

export const CardContent = styled(Box)`
  display: flex;
  justify-content: center;
`

export const CardSide1 = styled(Typography)`
  padding: 50px !important;
  font-size: 22px !important;
  cursor: default !important;

  ${theme.breakpoints.down('md')} {
    padding: 10px !important;
    font-size: 20px !important;
    text-align: center;
  }
`

export const CardSide2 = styled(Typography)`
  padding: 50px !important;
  font-size: 18px !important;
  cursor: default !important;

  ${theme.breakpoints.down('md')} {
    padding: 10px !important;
    font-size: 14px !important;
  }
`

export const CardFooter = styled(Box)`
  display: flex;
  justify-content: space-between;

  && p {
    display: flex;
    gap: 6px;
  }
`

export const FlashCardProgress = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin: auto;

  && p {
    display: flex;
    justify-content: center;
  }

  && p.progress-arrow {
    color: ${colors.blue1};
    cursor: pointer;

    font-family: Public Sans !important;
    font-size: 16px !important;
    display: flex;
    align-items: center;

    &:hover {
      color: black;
    }
  }

  && .disabled p.progress-arrow {
    opacity: 0.5;
    cursor: not-allowed;
  }

  && p.mastered-text {
    color: #5f727c;
    font-size: 14px;
  }
`
export const Container = styled(Box)`
  width: 100%;
  max-width: 800px;
  margin: auto;
`

export const ProgressBar = styled(Box)`
  min-width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;

  && .MuiLinearProgress-root {
    min-width: 80%;
    height: 12px;
    border-radius: 6px;
    margin-right: 10px;
    background-color: #f5f5f5;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

    span {
      background-color: ${colors.blue1};
    }
  }

  && p {
    color: ${colors.blue1};
  }

  @media (max-width: 1100px) {
    min-width: 100%;

    && .MuiLinearProgress-root {
      min-width: 80%;
    }
  }
`

export const EditBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 5px;
  cursor: default;

  && p {
    color: #000;
  }
`

export const StandardTypography = styled(Typography)`
  font-family: Public Sans !important;
  font-size: 14px !important;
`
