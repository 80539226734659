import React from 'react'
import {Typography, Box} from '@mui/material'
import {useSelector} from 'react-redux'
import {
  AttempDetails,
  AttemptsBox,
  AttemptsTitle,
  ContinueExamBox,
  DetailsBox,
  ExamItem,
  ExamsBox,
  ExamsFlexBox,
  InProgressTitle,
  LoggedOutBox,
  Title,
} from './styles'
import {Course} from '../../redux/courses'
import {NCARBAPPROVEDIMAGEABBR} from '../../constants/divisions'
import {LeftBox, RightBox, StyledTitle} from '../../styles/global'
import FollowUs from '../../components/FollowUs'
import {Button} from '../../components/button'
import {Link} from 'react-router-dom'
import {AttemptInProgress, Exam} from '../../redux/exams'
import {useAuth} from '../../components/AuthProvider'
import LockIcon from '@mui/icons-material/Lock'
import Countdown from 'react-countdown'
import hoursMinutes from '../../utils/hoursMinutes'
import {selectMemberAccess, getUpgradePrice} from '../../redux/members'
import {useAppDispatch} from '../../redux/configureStore'

type Props = {
  course?: Course
  exams?: Exam[]
  inProgress?: AttemptInProgress
}

const View = ({course, exams, inProgress}: Props) => {
  const auth = useAuth()
  const dispatch = useAppDispatch()

  const access = useSelector(selectMemberAccess)

  const hasAccess =
    access && Object.keys(access).length !== 0
      ? Number(access.are?.exams?.value) === 1
      : false

  const goToExam = (examId: string) => {
    window.open(`/practice-exams/questions/${examId}`)
  }

  const orderedExams = exams
    ? [...exams].sort((a, b) => a.title.localeCompare(b.title))
    : []

  const currentExamId = inProgress?.attemptId

  const handleUpgradeUrl = async (e:any) => {
    e.preventDefault();
    const resp = await dispatch(getUpgradePrice({auth}))
    console.log(resp.payload)
    window.location.href = String(resp.payload.priceId).length > 0 ? 'https://checkout.blackspectacles.com/' + String(resp.payload.priceId) : '#'
  }
  
  const renderer = ({total, hours, minutes, seconds, completed}: any) => {
    if (completed) {
      localStorage.clear()
    } else {
      localStorage.setItem('timerProgress', String(total / 1000))
      return (
        <Link to={`/practice-exams/questions/${currentExamId}`} target="_blank">
          Continue exam in progress ({String(hours).padStart(2, '0')}:
          {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}{' '}
          remaining)
        </Link>
      )
    }
  }

  let questionCount = 0
  let examDuration = 0

  if (exams && exams[0]) {
    questionCount = exams[0].questionCount ?? 0
    examDuration = exams[0].examDuration ?? 0
  }

  return (
    <ExamsBox>
      <LeftBox>
        <Box>
          <StyledTitle variant={'h2'}>
            ARE 5.0 {course?.title} PRACTICE EXAM
          </StyledTitle>
          {!auth.isAuthenticated ||
            (!hasAccess && (
              <LoggedOutBox>
                <Box>
                  You have reached one of our{' '}
                  <a
                    href="https://learn.blackspectacles.com/blog/post/black-spectacles-becomes-ncarbs-first-ever-approved-test-prep-provider"
                    target="_blank"
                  >
                    NCARB-approved
                  </a>{' '}
                  Premium Practice Exams.
                </Box>
                <Box>
                  {!auth.isAuthenticated ? (
                    <>
                      <Link to={'/login'}>
                        <LockIcon /> Log in
                      </Link>{' '}
                      to access this material.
                    </>
                  ) : (
                    <>
                      <Link to={'#'} onClick={handleUpgradeUrl}>
                        <LockIcon /> Upgrade your membership
                      </Link>{' '}
                      to access this material.
                    </>
                  )}
                </Box>
              </LoggedOutBox>
            ))}
          <Box margin={'1em 0'}>
            <Typography variant={'body1'}>
              Below are the available forms for this division. Within each form
              you’ll find two case studies and questions distinct to that form.
              Each form contains a different set of unique questions so you can
              truly test your knowledge of the material - not your memory of the
              question. Every time you take a form we’ll shuffle that form’s
              questions and save your scores for you to review. If you want to
              spice it up a bit then select “randomized” and the system will
              automatically pull from all the questions and case studies we
              have.
            </Typography>
          </Box>
          <img
            width="146"
            src={NCARBAPPROVEDIMAGEABBR[course?.abbreviation!]}
          />
        </Box>
        <DetailsBox>
          <b>Exam duration:</b> {hoursMinutes(examDuration * 60)}
          <br />
          <b>Number of questions:</b> {questionCount} questions
        </DetailsBox>

        {currentExamId && (
          <ContinueExamBox>
            <InProgressTitle>In-progress exam:</InProgressTitle>
            <Countdown
              date={
                Date.now() +
                (localStorage.getItem('timerProgress')
                  ? parseInt(localStorage.getItem('timerProgress')!) * 1000
                  : 0)
              }
              renderer={renderer}
            />
          </ContinueExamBox>
        )}

        <ExamsFlexBox>
          {orderedExams?.map((exam, key) => {
            const title = exam.title.split('-')

            const isExamRunning = currentExamId === exam.id

            return (
              <React.Fragment key={`exam-${key}`}>
                <ExamItem>
                  <Title variant="h3">{title[1].trim()}</Title>
                  <Button
                    children={
                      !currentExamId
                        ? 'Start Exam'
                        : isExamRunning
                        ? 'Continue Exam'
                        : 'Exam in Progress'
                    }
                    color={
                      auth.isAuthenticated &&
                      hasAccess &&
                      (!currentExamId || isExamRunning)
                        ? 'secondary'
                        : 'whiteDisabled'
                    }
                    onClick={() =>
                      auth.isAuthenticated &&
                      hasAccess &&
                      (!currentExamId || isExamRunning)
                        ? goToExam(exam.id)
                        : {}
                    }
                    style={{height: '40px'}}
                  />
                  <AttemptsBox>
                    <AttemptsTitle>Completed Exams:</AttemptsTitle>
                    <AttempDetails>
                      {exam.attempts && exam.attempts?.length > 0 ? (
                        <ul>
                          {exam.attempts.map(attempt => {
                            const dateObject = new Date(attempt.date)
                            const formattedDate = dateObject.toLocaleDateString(
                              'en-US',
                              {year: 'numeric', month: 'long', day: 'numeric'},
                            )

                            return (
                              <li key={attempt.attemptId}>
                                <Link
                                  to={`/practice-exams/review/${attempt.attemptId}`}
                                  target="_blank"
                                >
                                  {formattedDate} - Score:{' '}
                                  {attempt.correctAnswers}/{exam.questionCount}{' '}
                                  ({Math.floor(attempt.percentage)}%)
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      ) : (
                        <>
                          Practice exams will be reviewable after they are
                          completed.
                        </>
                      )}
                    </AttempDetails>
                  </AttemptsBox>
                </ExamItem>
              </React.Fragment>
            )
          })}
        </ExamsFlexBox>
        <Typography variant="body1" align="center">
          <em>
            Completed exams will be reviewable for 60 days with an active
            subscription.
          </em>
        </Typography>
      </LeftBox>

      <RightBox>
        <FollowUs />
      </RightBox>
    </ExamsBox>
  )
}

export default View
