import React, {useState} from 'react'
import {
  Typography,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
  SideMenuContainer,
  SideMenuSection,
  SectionOption,
  Container,
  SectionOptionBold,
  FlexBox,
  HelpIcon,
  BootstrapTooltip,
} from './styles'
import TextField from '../../../TextField'
import ShuffleDeck from '../../../../images/icons/shuffleDeck.svg'
import RandomizeDeck from '../../../../images/icons/randomizeDeck.svg'
import FlipDeck from '../../../../images/icons/flipDeck.svg'
import ResetMastered from '../../../../images/icons/resetMastered.svg'
import CreateFlashCard from '../../../../images/icons/createFlashcard.svg'
import DivisionIcon from '../../../../images/icons/division.svg'
import {useSelector} from 'react-redux'
import {selectObjectivesList} from '../../../../redux/flashcard'
import {v4 as uuidv4} from 'uuid'
import {useWindowDimensions} from '../../../../hooks/useWindowDimensions'
import theme from '../../../../styles/theme'
import {Icon} from '../../../SideNav/styles'
import {capitalizeFirstChar} from '../../../../utils/capitalizeFirstLetter'
import {CardNumber} from '../../../../pages/FlashCards/view'

type Props = {
  setFlashCardFormToggle: (value: boolean) => void
  dropDownSelection: string
  setDropDownSelection: (value: string) => void
  filterBySelection: string
  setFilterBySelection: (value: string) => void
  setSearchInput: (value: string) => void
  cardNumbers: CardNumber
  searchInput: string
  areCardsFlipped: boolean
  hasAccess: boolean
  setFlashCardNumber: (value: number) => void
  updateMenuAction: (value: string) => void
}

const View = ({
  setFlashCardFormToggle,
  dropDownSelection,
  setDropDownSelection,
  filterBySelection,
  setFilterBySelection,
  setSearchInput,
  cardNumbers,
  searchInput,
  areCardsFlipped,
  hasAccess,
  setFlashCardNumber,
  updateMenuAction,
}: Props) => {
  const [open, setOpen] = useState(false)
  const dropDownOptions = useSelector(selectObjectivesList)

  const handleChange = (event: SelectChangeEvent) => {
    setFlashCardNumber(1)
    setDropDownSelection(event.target.value)
  }

  const {width} = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  const toggleMobileSideNav = () => {
    setOpen(!open)
  }

  return (
    <>
      <Box
        sx={{
          display: {sm: 'flex', md: 'none'},
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '70px',
          background: '#221f1f',
          marginBottom: 0,
          cursor: 'pointer',
          color: '#fff',
          textTransform: 'uppercase',
          padding: '0 20px',
          width: '100%',
          boxSizing: 'border-box',
        }}
        onClick={toggleMobileSideNav}
      >
        <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
          <Icon src={DivisionIcon} className="division-icon" />

          <Typography
            variant="body1"
            color={'#fff'}
            style={{fontFamily: 'Public Sans'}}
          >
            View Options
          </Typography>
          {isMobile && open ? (
            <Box style={{marginLeft: 'auto'}}>
              <CloseIcon />
            </Box>
          ) : null}
        </Box>
      </Box>
      <SideMenuContainer style={{display: isMobile && !open ? 'none' : 'flex'}}>
        <SideMenuSection background="#666">
          <Typography
            variant={'body1'}
            style={{
              color: '#ccc',
              fontFamily: 'Public Sans',
              fontSize: '14px',
            }}
            className="label"
          >
            Show Cards:
          </Typography>

          <FormControl
            sx={{
              m: 1,
              minWidth: 120,
              margin: 0,
              height: 44,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Select
              value={dropDownSelection}
              onChange={handleChange}
              displayEmpty
              inputProps={{'aria-label': 'Without label'}}
              style={{
                fontSize: '14px',
                fontStyle: 'italic',
                textTransform: 'capitalize',
              }}
            >
              {dropDownOptions
                ? Object.keys(dropDownOptions)
                    .sort((a, b) => {
                      const aIsNumber = !isNaN(Number(a))
                      const bIsNumber = !isNaN(Number(b))

                      if (aIsNumber && bIsNumber) {
                        return Number(a) - Number(b)
                      } else if (!aIsNumber && !bIsNumber) {
                        return a.localeCompare(b)
                      } else {
                        return aIsNumber ? 1 : -1
                      }
                    })
                    .map((objectKey: string) => {
                      const objectiveStart = ![
                        'all',
                        'custom',
                        'default',
                      ].includes(objectKey)
                        ? 'Objective '
                        : ''

                      return (
                        <MenuItem value={objectKey} key={uuidv4()}>
                          {`${objectiveStart} ${capitalizeFirstChar(
                            objectKey,
                          )} (${dropDownOptions[objectKey as any]})`}
                        </MenuItem>
                      )
                    })
                : null}
            </Select>
          </FormControl>
        </SideMenuSection>

        <SideMenuSection>
          <Typography
            variant={'body1'}
            className="label"
            style={{fontFamily: 'Public Sans', fontSize: '14px'}}
          >
            Filter By:
          </Typography>
          <form style={{marginLeft: '-5px'}}>
            <FlexBox>
              <SectionOptionBold>
                <input
                  type="radio"
                  id="all-cards-filter"
                  name="radio-filter"
                  checked={filterBySelection === 'All'}
                  onClick={() => {
                    setFilterBySelection('All')
                    setFlashCardNumber(1)
                  }}
                />
                <label
                  htmlFor="all-cards-filter"
                  style={{fontFamily: 'Public Sans'}}
                >
                  All Cards <em>({cardNumbers.all}</em>)
                </label>
              </SectionOptionBold>

              <SectionOptionBold>
                <input
                  type="radio"
                  id="mastered-filter"
                  name="radio-filter"
                  checked={filterBySelection === 'Mastered'}
                  onClick={() => {
                    setFilterBySelection('Mastered')
                    setFlashCardNumber(1)
                  }}
                />
                <label
                  htmlFor="mastered-filter"
                  style={{fontFamily: 'Public Sans'}}
                >
                  Mastered <em>({cardNumbers.mastered})</em>
                </label>
              </SectionOptionBold>

              <SectionOptionBold>
                <input
                  type="radio"
                  id="unmastered-filter"
                  name="radio-filter"
                  checked={filterBySelection === 'Unmastered'}
                  onClick={() => {
                    setFilterBySelection('Unmastered')
                    setFlashCardNumber(1)
                  }}
                />
                <label
                  htmlFor="unmastered-filter"
                  style={{fontFamily: 'Public Sans'}}
                >
                  Unmastered <em>({cardNumbers.unmastered})</em>
                </label>
              </SectionOptionBold>
            </FlexBox>
          </form>
        </SideMenuSection>

        <SideMenuSection>
          <BootstrapTooltip
            title="Filter by specific term(s)"
            placement="top-start"
          >
            <Typography
              variant={'body1'}
              className="label"
              style={{
                fontFamily: 'Public Sans',
                fontSize: '14px',
                position: 'relative',
                width: 'fit-content',
              }}
            >
              Search: <HelpIcon>?</HelpIcon>
            </Typography>
          </BootstrapTooltip>
          <TextField
            name={'searchInput'}
            variant={'outlined'}
            fullWidth={true}
            placeholder={'Ex: Information'}
            type={'text'}
            borderColor={'none'}
            className={'searchInput'}
            value={searchInput}
            onChange={e => {
              setSearchInput(e.target.value)
              setFlashCardNumber(1)
            }}
          ></TextField>
        </SideMenuSection>

        <SideMenuSection border={0}>
          {hasAccess && (
            <>
              <Typography
                variant={'body1'}
                className="label"
                style={{fontFamily: 'Public Sans', fontSize: '14px'}}
              >
                Actions
              </Typography>

              <Container
                onClick={() => {
                  updateMenuAction('shuffle')
                  setFlashCardNumber(1)
                }}
              >
                <img src={ShuffleDeck} />
                <BootstrapTooltip
                  title="Standard deck shuffle"
                  placement="top-start"
                >
                  <SectionOption variant={'body1'}>
                    Shuffle Deck <HelpIcon>?</HelpIcon>
                  </SectionOption>
                </BootstrapTooltip>
              </Container>

              <Container
                onClick={() => {
                  updateMenuAction('randomize')
                }}
              >
                <img src={RandomizeDeck} />
                <BootstrapTooltip
                  title="Shuffle + flip random cards"
                  placement="top-start"
                >
                  <SectionOption variant={'body1'}>
                    Randomize <HelpIcon>?</HelpIcon>
                  </SectionOption>
                </BootstrapTooltip>
              </Container>

              <Container onClick={() => updateMenuAction('flip')}>
                <img src={FlipDeck} />
                <SectionOption variant={'body1'}>
                  Flip Deck To {areCardsFlipped ? 'Front' : 'Back '}
                </SectionOption>
              </Container>

              <Container onClick={() => updateMenuAction('reset')}>
                <img src={ResetMastered} />
                <SectionOption variant={'body1'}>
                  Reset All Mastered
                </SectionOption>
              </Container>

              <Container>
                <img src={CreateFlashCard} />
                <SectionOption
                  variant={'body1'}
                  onClick={() => setFlashCardFormToggle(true)}
                >
                  Create Custom Card
                </SectionOption>
              </Container>
            </>
          )}
        </SideMenuSection>
      </SideMenuContainer>
    </>
  )
}

export default View
