import React, {useState} from 'react'
import ApiList from '../../components/postman/ApiList'
import ResponseData from '../../components/postman/ResponseData'
import {Box} from '@mui/material'

const View = () => {
  const [data, updateData] = useState('')

  return (
    <>
      <Box sx={{display: 'flex', width: '100%'}}>
        <ApiList updateData={updateData} />
        <ResponseData data={data} />
      </Box>
    </>
  )
}

export default View
