import React, {useState, useEffect, useRef, useCallback} from 'react'
import {Exam} from '../../redux/exams'
import {Stage, Shape, Bitmap, Ticker, Touch} from '@createjs/easeljs'
import {Tween} from '@createjs/tweenjs'
import './styles.css'
import {ExplanationBox} from '../QuizQuestions/styles'
import renderHTML from '../../utils/renderHTML'

type Props = {
  exam?: Exam
  currentQuestion: number
  isReviewing?: boolean
  onSelect: (value: any) => void
  answer?: any
  currentAnswer?: any
}

type SelectedValue = {
  images?: string[]
  choices?: [
    {
      rot: number
      x: number
      y: number
    },
  ][]
}

const ExamDrag = ({
  exam,
  currentQuestion,
  isReviewing,
  onSelect,
  answer,
  currentAnswer,
}: Props) => {
  const question = exam?.questions[currentQuestion]
  const canvasRef = useRef(null)

  const [selectedValues, setSelectedValues] = useState<SelectedValue>(
    currentAnswer
      ? JSON.parse(JSON.stringify({...currentAnswer}))
      : {
          choices: [],
          images: [],
        },
  )

  const selectDrag = (value: any) => {
    const stateAnswers = {...selectedValues}

    if (!stateAnswers?.images?.includes(value.image)) {
      stateAnswers.choices?.push([value.choices])
      stateAnswers.images?.push(value.image)
    } else {
      const currentIndex = stateAnswers.images.findIndex(
        (image: string) => image === value.image,
      )

      stateAnswers.choices![currentIndex] = [value.choices]
      stateAnswers.images[currentIndex] = value.image
    }

    setSelectedValues(stateAnswers)
    onSelect(stateAnswers)
  }

  const drag = {
    stages: undefined,
    dragging: undefined,
    bgs: undefined,
    choices: undefined,
    choiceBoundaries: undefined,
    target: undefined,
    canvases: undefined,
    numLoaded: undefined,
    bgLoaded: undefined,
    choiceImages: undefined,
  } as any

  const dragPadding = 10
  const dragLineWidth = 2
  let dragTarget: any = null

  const resetFormDisplay = function () {
    if (document.getElementById('showRotateForm')) {
      document.getElementById('showRotateForm')!.style.display = 'block'
    }

    if (document.getElementById('rotateForm')) {
      document.getElementById('rotateForm')!.style.display = 'none'
    }

    if (document.getElementById('rightClick')) {
      document.getElementById('rightClick')!.style.display = 'none'
    }
  }

  const click = function (evt: any) {
    if (!isReviewing && !question?.isBlocked) {
      if (evt.nativeEvent.button == 2 && evt.target.x > drag.choiceBoundaries) {
        dragTarget = evt.target
        const item = document.querySelector('canvas')
        const offset = item?.getBoundingClientRect()
        let left = evt.stageX
        let top = offset?.top! + window.scrollY + evt.stageY

        //scale the vars by the ratio of displayed width vs HTML width attribute

        const scale =
          parseInt(item?.getAttribute('height')!) / item?.offsetHeight!
        left /= scale
        top /= scale

        left += 80
        top -= 130

        const rightClick = document.getElementById('rightClick')
        const rotate = document.getElementById('rotate') as HTMLInputElement

        if (rightClick) {
          rightClick.style.left = left + 'px'
          rightClick.style.top = top + 'px'
          rightClick.style.display = 'block'
        }
        rotate.value = dragTarget.rotation
      }
    }
  }

  const pressmove = function (evt: any) {
    if (!isReviewing && !question?.isBlocked) {
      // register object starting point and mousedrag (stage) starting point
      if (
        !drag.dragging ||
        !drag.dragging.startCoords ||
        !drag.dragging.stageCoords
      ) {
        drag.dragging = evt.currentTarget
        drag.dragging.startCoords = {x: drag.dragging.x, y: drag.dragging.y}
        drag.dragging.stageCoords = {x: evt.stageX, y: evt.stageY}
      }

      // calculate mouse offset after move, relative to starting point, subtract this movement from object coords (move)
      drag.dragging.stageMove = {
        x: drag.dragging.stageCoords.x - evt.stageX,
        y: drag.dragging.stageCoords.y - evt.stageY,
      }
      drag.dragging.objectMove = {
        x: drag.dragging.startCoords.x - drag.dragging.stageMove.x,
        y: drag.dragging.startCoords.y - drag.dragging.stageMove.y,
      }

      // apply movement to object
      evt.currentTarget.x = drag.dragging.objectMove.x
      evt.currentTarget.y = drag.dragging.objectMove.y

      //if all images in this object are on the right side of the choiceBoundary, hide the label
      let allOnRightSide = true
      if (drag && drag.choices) {
        for (let i = 0; i < drag.choices.length; i++) {
          if (drag.choices[i].image == evt.target.image.src) {
            //show/hide the label
            if (drag.choices[i].label) {
              for (let j = 0; j < drag.choices[i].objects.length; j++) {
                if (drag.choices[i].objects[j].x < drag.choiceBoundaries) {
                  allOnRightSide = false
                  break
                }
              }

              if (allOnRightSide) {
                drag.choices[i].label.alpha = 0
              } else {
                drag.choices[i].label.alpha = 1
              }
            }

            break
          }
        }
      }

      drag.stages.setChildIndex(evt.target, drag.stages.numChildren - 1)
      drag.stages.update() //update stage without passing through ticker for higher FPS
    }
  }

  //mouse clicked up - reset back to initial position or update the value for this input
  const pressup = function (evt: any) {
    if (!isReviewing && !question?.isBlocked) {
      //if it's on the left side, animate it back to its initial position
      if (evt.target.x < drag.choiceBoundaries) {
        if (drag && drag.choices) {
          for (let i = 0; i < drag.choices.length; i++) {
            if (drag.choices[i].image == evt.target.image.src) {
              //animate the object back to the initial positions
              Tween.get(evt.target)
                .to(
                  {
                    x: drag.choices[i].initialX,
                    y: drag.choices[i].initialY,
                    rotation: 0,
                  },
                  300,
                )
                .call(function () {
                  drag.stages.setChildIndex(
                    evt.target,
                    drag.stages.numChildren - 1,
                  )
                })

              break
            }
          }
        }
      } else {
        drag.stages.setChildIndex(evt.target, drag.stages.numChildren - 1)
      }

      setInputValue(evt.target.image.src)

      drag.stages.update()
    }
  }

  //set the JSON value of this hidden input
  const setInputValue = function (inputImage: any) {
    //set the JSON value of this hidden input
    if (drag && drag.choices) {
      for (let i = 0; i < drag.choices.length; i++) {
        if (drag.choices[i].image == inputImage) {
          let choices = {}
          let image = ''
          for (let j = 0; j < drag.choices[i].objects.length; j++) {
            image = drag.choices[i].image
            if (drag.choices[i].objects[j].x < drag.choiceBoundaries) {
              choices = {x: 0, y: 0, rot: 0} //might as well set it to 0 if it's still on the left side
            } else {
              choices = {
                //normalize the coordinates to the original image (i.e. undo the canvas padding, lineWidths, and choiceBoundary amounts)
                x:
                  drag.choices[i].objects[j].x -
                  drag.choiceBoundaries +
                  dragPadding,
                y: drag.choices[i].objects[j].y - dragPadding,
                rot: drag.choices[i].objects[j].rotation,
              }
            }
          }
          selectDrag({choices, image})
          break
        }
      }
    }
  }

  //mouse clicked down, reset the drag and z-index for this item
  const mousedown = function (evt: any) {
    if (!isReviewing && !question?.isBlocked) {
      drag.dragging = false //reset the mouse offset for each new click/drag
      drag.stages.setChildIndex(evt.target, 3)
      drag.stages.update()
    }
  }

  //set the canvas pointer style
  const mouseover = function () {
    if (!isReviewing && !question?.isBlocked && drag.stages.canvas.parentNode) {
      drag.stages.canvas.parentNode.style.cursor = 'pointer'
    }
  }

  //set the canvas pointer style
  const mouseout = function () {
    if (!isReviewing && !question?.isBlocked && drag.stages.canvas.parentNode) {
      drag.stages.canvas.parentNode.style.cursor = ''
    }
  }

  //choice image has finished loading
  const choiceImageLoad = function (event: any) {
    if (drag.numLoaded == undefined) {
      drag.numLoaded = 0
    }
    drag.numLoaded++

    let imageIndex = -1
    for (let j = 0; j < drag.choiceImages.length; j++) {
      if (drag.choices && drag.choices[j]) continue

      const imageFilename = event.target.src.split('/')
      const choiceFilename = drag.choiceImages[j].image.split('/')

      if (
        imageFilename[imageFilename.length - 1] ==
        choiceFilename[choiceFilename.length - 1]
      ) {
        imageIndex = j
        break
      }
    }

    if (drag.choices == undefined) {
      drag.choices = []
    }
    drag.choices[imageIndex] = {
      image: '',
      label: null,
      objects: [], //uses
      initialX: 0,
      initialY: 0,
      width: 0,
      height: 0,
    }

    const object = new Bitmap(event.target.src)
    const bounds = object.getBounds()

    object.regX = bounds.width / 2
    object.regY = bounds.height / 2

    //define the hitArea and not just the "non-transparent" pixels
    const hit = new Shape()
    hit.graphics.beginFill('#000').drawRect(0, 0, bounds.width, bounds.height)
    object.hitArea = hit

    //make the choices draggable
    object.on('pressmove', pressmove)

    //animate image back to starting position if dropped on the left side
    object.on('pressup', pressup)

    //on mouse press, set the reg positions to the mouse position
    object.on('mousedown', mousedown)

    //right click menu
    object.on('click', click)

    //adjust the cursor
    object.on('mouseover', mouseover)
    object.on('mouseout', mouseout)

    drag.stages.addChild(object)

    if (drag && drag.choices[imageIndex]) {
      drag.choices[imageIndex].objects.push(object)
      drag.choices[imageIndex].image = event.target.src
    }

    drag.stages.update()

    drawInitialView()
  }

  //background image has finished loading
  const backgroundImageLoad = function (event: any) {
    drag.bgLoaded = true

    drag.canvases.setAttribute('width', event.target.width)
    drag.canvases.setAttribute('height', event.target.height)

    drag.bgs = new Bitmap(question?.backgroundImage)
    drag.stages.addChild(drag.bgs)
    drag.stages.setChildIndex(drag.bgs, 0)

    drag.stages.update()

    drawInitialView()
  }

  //helper functions to redraw the canvas correctly

  //set up the initial view with the choices on the left and bg image on the right after everything has loaded
  const drawInitialView = function () {
    //check if all images have been loaded
    if (drag.numLoaded == drag.choiceImages.length && drag.bgLoaded === true) {
      //draw each item on the left
      if (question?.layout == 'horizontal') {
        //left to right

        let tallestChoice = 0
        let choicesWidth = 0

        if (drag && drag.choices) {
          for (let i = 0; i < drag.choices.length; i++) {
            //each image in this choice is the same (height), so just grab the first one
            const bounds = drag.choices[i].objects[0].getBounds()
            tallestChoice =
              bounds.height > tallestChoice ? bounds.height : tallestChoice
          }
        }

        let choiceOffsetX = 0
        const choiceOffsetY =
          parseInt(drag.canvases.getAttribute('height')) / 2 - dragPadding * 2

        if (drag && drag.choices) {
          for (let i = 0; i < drag.choices.length; i++) {
            //draw each choice[i].objects in the initial position
            let choiceWidth = 0
            for (let j = 0; j < drag.choices[i].objects.length; j++) {
              const imageWidth = drag.choices[i].objects[0].getBounds().width
              const imageHeight = drag.choices[i].objects[j].getBounds().height
              let textWidth = 0

              if (drag.choices[i].label) {
                const textBounds = drag.choices[i].label.getBounds()
                textWidth = textBounds.width
              }
              choiceWidth = Math.max(imageWidth, textWidth)

              drag.choices[i].objects[j].x =
                choiceOffsetX + choiceWidth / 2 + dragPadding
              drag.choices[i].objects[j].y = choiceOffsetY
              drag.stages.setChildIndex(drag.choices[i].objects[j], 1) //reset z-index of each object to 1

              //keep track of the initial locations of these to animate back to
              drag.choices[i].initialX = drag.choices[i].objects[j].x
              drag.choices[i].initialY = drag.choices[i].objects[j].y

              drag.choices[i].height = imageHeight
              drag.choices[i].width = choiceWidth

              //set the x and y position of the label
              if (drag.choices[i].label) {
                drag.choices[i].label.x =
                  choiceOffsetX + choiceWidth / 2 + dragPadding
                drag.choices[i].label.y =
                  choiceOffsetY + tallestChoice / 2 + dragPadding * 3
              }
            }

            choiceOffsetX += choiceWidth + dragPadding
            choicesWidth += choiceWidth + dragPadding
          }
        }

        drag.choiceBoundaries = dragPadding + choicesWidth + dragPadding * 6 //set the right side of the choice boundary

        //make the canvas choicesWidth wider
        drag.bgs.x = choicesWidth + dragPadding * 6
        drag.bgs.y = dragPadding

        //resize the canvas to fit everything
        const bgBounds = drag.bgs.getBounds()
        drag.canvases.setAttribute(
          'width',
          bgBounds.width + choicesWidth + dragPadding * 6,
        )
        drag.canvases.setAttribute('height', bgBounds.height + dragPadding * 2)

        //draw boundaries for choices and background image
        drawBoundaries(bgBounds, choicesWidth + dragPadding * 3)
      } else {
        //top to bottom (default)
        let widestChoice = 0

        if (drag && drag.choices) {
          for (let i = 0; i < drag.choices.length; i++) {
            //each image in this choice is the same (width), so just grab the first one
            const bounds = drag.choices[i].objects[0].getBounds()
            widestChoice =
              bounds.width > widestChoice ? bounds.width : widestChoice
          }
        }

        //resize the canvas to fit everything
        const bgBounds = drag.bgs.getBounds()
        drag.canvases.setAttribute(
          'width',
          bgBounds.width + widestChoice + dragPadding * 4,
        )
        drag.canvases.setAttribute('height', bgBounds.height + dragPadding * 2)

        let choiceOffsetY = dragPadding
        const choiceOffsetX = widestChoice / 2 + dragPadding

        if (drag && drag.choices) {
          for (let i = 0; i < drag.choices.length; i++) {
            let height = 0
            //draw each choice[i].objects in the initial position
            for (let j = 0; j < drag.choices[i].objects.length; j++) {
              let choiceHeight = 0
              const imageHeight = drag.choices[i].objects[0].getBounds().height
              const imageWidth = drag.choices[i].objects[j].getBounds().width
              let textHeight = 0

              if (drag.choices[i].label) {
                const textBounds = drag.choices[i].label.getBounds()
                textHeight = textBounds.width
              }
              choiceHeight = Math.max(imageHeight, textHeight)

              height = drag.choices[i].objects[j].getBounds().height

              drag.choices[i].objects[j].x = choiceOffsetX
              drag.choices[i].objects[j].y =
                choiceOffsetY + height / 2 + dragPadding

              //keep track of the initial locations of these to animate back to
              drag.choices[i].initialX = drag.choices[i].objects[j].x
              drag.choices[i].initialY = drag.choices[i].objects[j].y

              drag.choices[i].width = imageWidth
              drag.choices[i].height = choiceHeight

              //set the x and y position of the label
              if (drag.choices[i].label) {
                drag.choices[i].label.x = choiceOffsetX
                drag.choices[i].label.y =
                  drag.choices[i].objects[j].y + imageHeight + dragPadding
                height += imageHeight //draw the next image below this one
              }
            }

            choiceOffsetY += height + dragPadding
          }
        }

        drag.choiceBoundaries = dragPadding + widestChoice + dragPadding * 3 //set the right side of the choice boundary

        //make the canvas widestChoice wider
        drag.bgs.x = widestChoice + dragPadding * 3
        drag.bgs.y = dragPadding

        //draw boundaries for choices and background image
        drawBoundaries(bgBounds, widestChoice)
      }

      //stage is drawn and everything is sized correctly,
      // so now we just need to adjust any values to the correct starting position (i.e. the user has already answered this)
      const selectedAnswer = currentAnswer && !answer ? currentAnswer : answer
      const finalAnswer = {
        choices: [] as string[],
        images: [] as string[],
      }

      if (selectedAnswer) {
        for (let i = 0; i < selectedAnswer.choices.length; i++) {
          const element = selectedAnswer.choices[i] as string
          const imageElement = selectedAnswer.images[i] as string
          if (element && imageElement) {
            finalAnswer.choices.push(element)
            finalAnswer.images.push(imageElement)
          }
        }
      }

      finalAnswer?.choices?.forEach((choice: any, key: number) => {
        const order = question?.dndAreas?.find((item, imgkey) => {
          const imageName = `${item.image}?index=${item.displayOrder}`
          const selectedImageName = finalAnswer.images[key].includes('?index')
            ? finalAnswer.images[key]
            : `${finalAnswer.images[key]}?index=${imgkey + 1}`

          return imageName === selectedImageName
        })

        const imagePosition = order?.displayOrder ?? 0

        const updatedChoice =
          typeof choice === 'string' ? JSON.parse(choice) : choice

        if (drag && drag.choices) {
          if (updatedChoice[0].x != 0 && updatedChoice[0].y != 0) {
            drag.choices[imagePosition - 1].objects[0].x =
              updatedChoice[0].x + drag.choiceBoundaries - dragPadding
            drag.choices[imagePosition - 1].objects[0].y =
              updatedChoice[0].y + dragPadding
            drag.choices[imagePosition - 1].objects[0].rotation =
              updatedChoice[0].rot
          }
        }
      })

      drag.stages.update()
    }
  }

  //draw boundary lines for the dragChoices and bg
  const drawBoundaries = function (bgBounds: any, choiceWidth: any) {
    const canvasHeight = parseInt(drag.canvases.getAttribute('height'))
    const canvasWidth = parseInt(drag.canvases.getAttribute('width'))

    const lines = new Shape()
    const lineColor = '#bbb'

    //choice box
    lines.graphics
      .beginFill(lineColor)
      .drawRect(0, 0, choiceWidth + dragPadding * 2, dragLineWidth) //top
    lines.graphics
      .beginFill(lineColor)
      .drawRect(
        0,
        canvasHeight - dragLineWidth,
        choiceWidth + dragPadding * 2,
        dragLineWidth,
      ) //bottom
    lines.graphics
      .beginFill(lineColor)
      .drawRect(0, 0, dragLineWidth, canvasHeight) //left
    lines.graphics
      .beginFill(lineColor)
      .drawRect(choiceWidth + dragPadding * 2, 0, dragLineWidth, canvasHeight) //right

    //background box
    lines.graphics
      .beginFill(lineColor)
      .drawRect(
        choiceWidth + dragPadding * 3,
        0,
        bgBounds.width + dragPadding,
        dragLineWidth,
      ) //top
    lines.graphics
      .beginFill(lineColor)
      .drawRect(
        choiceWidth + dragPadding * 3,
        canvasHeight - dragLineWidth,
        bgBounds.width + dragPadding,
        dragLineWidth,
      ) //bottom
    lines.graphics
      .beginFill(lineColor)
      .drawRect(choiceWidth + dragPadding * 3, 0, dragLineWidth, canvasHeight) //left
    lines.graphics
      .beginFill(lineColor)
      .drawRect(canvasWidth - dragLineWidth, 0, dragLineWidth, canvasHeight) //right

    drag.stages.addChild(lines)
    drag.stages.setChildIndex(lines, 1)
  }

  const loadDrag = useCallback(() => {
    const canvasItem: any = canvasRef.current
    drag.canvases = canvasItem
    drag.stages = new Stage(canvasItem)
    drag.stages.enableMouseOver()
    Touch.enable(drag.stages) //allow mobile touch events

    //set up animation
    Ticker.framerate = 60
    Ticker.addEventListener('tick', drag.stages)

    drag.stages.mouseMoveOutside = false

    //load the choice images
    const usableAreas = question?.dndAreas?.map(area => {
      const newImageName = `${area.image}?index=${area.displayOrder}`
      return {...area, image: newImageName}
    })

    drag.choiceImages = usableAreas

    for (let i = 0; i < drag.choiceImages.length; i++) {
      const img = new Image()
      img.src = drag.choiceImages[i].image
      img.addEventListener('load', event => choiceImageLoad(event))
    }

    //load bg image and add it to the canvas
    const image = new Image()
    image.src = question?.backgroundImage!
    image.addEventListener('load', event => backgroundImageLoad(event))

    //rotate event listeners

    document.addEventListener('contextmenu', function (e: any) {
      if (e.target.closest('canvas') || e.target.closest('#rightClick')) {
        e.preventDefault()
      }
    })

    const canvasElement = document.querySelector('canvas')
    const showRotateForm = document.getElementById('showRotateForm')
    const rotateForm = document.getElementById('rotateForm') as HTMLFormElement
    const rotate = document.getElementById('rotate') as HTMLInputElement
    const cancel = document.getElementById('cancel')
    const apply = document.getElementById('apply')

    canvasElement?.addEventListener('click', function () {
      if (showRotateForm && showRotateForm.style.display !== 'none') {
        resetFormDisplay()
      }
    })

    //right click "rotate" form interactions
    rotateForm?.addEventListener('submit', function (e) {
      e.preventDefault()
    })

    showRotateForm?.addEventListener('click', function (e) {
      e.preventDefault()
      if (showRotateForm) {
        showRotateForm.style.display = 'none'
      }
      if (rotateForm) {
        rotateForm.style.display = 'block'
      }
      return false
    })

    cancel?.addEventListener('click', function (e) {
      e.preventDefault()
      resetFormDisplay()
      return false
    })

    apply?.addEventListener('click', function () {
      let rotVal = parseFloat(rotate.value) % 360
      if (isNaN(rotVal)) {
        rotVal = 0
      }

      dragTarget.rotation = rotVal

      drag.stages.update()
      setInputValue(dragTarget.image.src)

      dragTarget = null
      resetFormDisplay()

      return false
    })
  }, [exam, currentQuestion])

  useEffect(() => {
    loadDrag()

    if (currentAnswer) {
      onSelect(currentAnswer)
    }
  }, [loadDrag])
  return (
    <>
      <canvas ref={canvasRef} style={{maxWidth: '100%'}} />

      <div id="rightClick">
        <a href="#" id="showRotateForm">
          Rotate
        </a>
        <form id="rotateForm" data-hs-cf-bound="true">
          <div id="rotateInput">
            <label>Angle (degrees):</label>
            <input type="text" min="-180" max="180" name="rotate" id="rotate" />
          </div>
          <div id="rotateButtons">
            <a href="#" id="apply">
              Apply
            </a>
            <a href="#" id="cancel">
              Cancel
            </a>
          </div>
        </form>
      </div>

      {isReviewing && question?.answers[0].explanation && (
        <ExplanationBox>
          {renderHTML(question?.answers[0].explanation)}
        </ExplanationBox>
      )}
    </>
  )
}

export default ExamDrag
