import React, {useEffect, useCallback} from 'react'
import View from './view'
import {useAppDispatch} from '../../redux/configureStore'
import {useAuth} from '../../components/AuthProvider'
import {
  getBillingHistory,
  getCardDetails,
  selectBillingHistory,
  selectBillingHistoryStatus,
  selectCardDetails,
  selectCardDetailsStatus,
} from '../../redux/organizations'
import {toggleErrorDialog} from '../../redux/config'
import {useSelector} from 'react-redux'
import {Helmet} from 'react-helmet'

const GroupBilling = () => {
  const dispatch = useAppDispatch()
  const auth = useAuth()

  const billingHistory = useSelector(selectBillingHistory)
  const billingStatus = useSelector(selectBillingHistoryStatus)
  const cardDetails = useSelector(selectCardDetails)
  const cardStatus = useSelector(selectCardDetailsStatus)

  const loadBillingHistory = useCallback(async () => {
    try {
      const {type} = await dispatch(getBillingHistory({auth}))
      if (type === getBillingHistory.rejected.type) {
        throw new Error('Unable to get billing history')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to get the billing history, please retry. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch])

  const loadCardDetails = useCallback(async () => {
    await dispatch(getCardDetails({auth}))
  }, [dispatch])

  useEffect(() => {
    loadBillingHistory()
    loadCardDetails()
  }, [loadBillingHistory, loadCardDetails])

  return (
    <>
      <Helmet>
        <title>Group Billing | Black Spectacles</title>
      </Helmet>
      <View
        billingHistory={billingHistory}
        billingStatus={billingStatus}
        cardDetails={cardDetails}
        cardStatus={cardStatus}
      />
    </>
  )
}

export default GroupBilling
