import React, {useState} from 'react'
import {
  AppBar,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Divider,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CloseIcon from '@mui/icons-material/Close'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import SearchBar from '../searchBar'
import {Link as RouterLink} from 'react-router-dom'
import {
  NavButton,
  SearchBoxContainer,
  NavbarContainer,
  MobileNavMenu,
  StyledLink,
  SettingsItem,
  SettingsLink,
  StyledIcon,
  FlexBox,
  LeftBox,
  LinkButton,
} from './styles'
import theme, {colors} from '../../styles/theme'
import {ReactComponent as BlackSpectaclesLogo} from '../../images/black-spectacles.svg'
import {useWindowDimensions} from '../../hooks/useWindowDimensions'
import {LinkBox} from '../../styles/global'
import {useAuth} from '../AuthProvider'

const View = () => {
  const leftNav = [
    {
      name: 'individual pricing',
      url: 'https://www.blackspectacles.com/pricing',
    },
    {name: 'help', url: 'https://help.blackspectacles.com/en/'},
  ]

  const settings = [
    {name: 'manage subscriptions', url: '/profile/settings/manage'},
    {name: 'payment method', url: '/profile/settings/billing'},
    {name: 'billing history', url: '/profile/settings/history'},
    {name: 'update profile', url: '/profile/settings/update'},
    {name: 'change password', url: '/profile/settings/change-password'},
    {name: 'report exam results', url: '/profile/settings/are-progress'},
    {name: 'log out'},
  ]

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [searchBoxOpen, setSearchBoxOpen] = useState<any>(null)

  const auth = useAuth()
  const {width} = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.sm

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
    // close search box when opening menu on mobile
    setSearchBoxOpen(false)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
    setAnchorElUser(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElNav(null)
    setAnchorElUser(null)
  }

  const toggleSearchBox = () => {
    setSearchBoxOpen(!searchBoxOpen)
  }

  return (
    <AppBar position="sticky" color="default" sx={{boxShadow: 'none'}}>
      <SearchBoxContainer $isSearchBoxOpen={searchBoxOpen}>
        <SearchBar toggleSearchBox={toggleSearchBox} />
      </SearchBoxContainer>
      <NavbarContainer $isSearchBoxOpen={searchBoxOpen}>
        {/*----------- Mobile Version -----------*/}
        <Box
          sx={{
            flexGrow: 1,
            height: 60,
            background: `${colors.white}`,
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'none',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box sx={{width: '95px', padding: '15px', display: 'flex'}}>
            <BlackSpectaclesLogo />
          </Box>

          <Box sx={{display: 'flex', height: '60px'}}>
            {!auth.isAuthenticated && (
              <LinkButton component={RouterLink} to={'/login'}>
                Login
              </LinkButton>
            )}
            <Button
              sx={{
                backgroundColor: `${colors.blue1}`,
                borderRadius: 0,
                width: 60,
                '&:hover': {
                  backgroundColor: `${colors.blue1}`,
                },
              }}
              onClick={toggleSearchBox}
            >
              <SearchOutlinedIcon style={{color: '#000'}} />
            </Button>

            {auth.isAuthenticated && (
              <IconButton
                size="large"
                aria-label="navbar menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{
                  width: 60,
                  backgroundColor: `${colors.blue2}`,
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: `${colors.blue2}`,
                  },
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Box>

          <MobileNavMenu
            className="dropdown-menu"
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav) || (isMobile && !!anchorElUser)}
          >
            <CloseIcon
              className="x-button"
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                height: 38,
                width: 38,
                color: `${colors.white}`,
                zIndex: 10,
                cursor: 'pointer',
              }}
              onClick={handleCloseNavMenu}
            />
            <MenuItem style={{marginTop: 30}} onClick={handleCloseUserMenu}>
              <LinkBox component={RouterLink} to={'/'}>
                <Typography>Dashboard</Typography>
              </LinkBox>
            </MenuItem>

            {settings.map(page => {
              const {name} = page
              return (
                <MenuItem key={name} onClick={handleCloseNavMenu}>
                  {name === 'log out' ? (
                    <LinkBox
                      component={RouterLink}
                      onClick={() => auth.logout()}
                    >
                      <Typography>{name} </Typography>
                    </LinkBox>
                  ) : (
                    <LinkBox component={RouterLink} to={page.url}>
                      <Typography>{name} </Typography>
                    </LinkBox>
                  )}
                </MenuItem>
              )
            })}

            <div
              style={{
                marginTop: 30,
                height: 40,
                width: '100%',
                backgroundColor: 'rgb(241,244,249) ',
              }}
            ></div>

            <Box>
              <MenuItem style={{justifyContent: 'end', marginTop: 8}}>
                <StyledLink
                  href="https://www.blackspectacles.com/pricing"
                  target="_blank"
                >
                  <Typography variant={'body1'}>Individual Pricing</Typography>
                </StyledLink>
              </MenuItem>
              <Divider
                variant={'middle'}
                sx={{bgcolor: 'rgba(255,255,255,0.25)'}}
              />
              <MenuItem style={{justifyContent: 'end'}}>
                <StyledLink
                  href="https://help.blackspectacles.com/en/"
                  target="_blank"
                >
                  <Typography variant={'body1'}>Help</Typography>
                </StyledLink>
              </MenuItem>
            </Box>
          </MobileNavMenu>
        </Box>
        {/*----------- Mobile Version -----------*/}

        {/*------------ Desktop Version ------------*/}
        <FlexBox>
          <LeftBox>
            <Box width={'100px'} height={'36px'} position={'relative'}>
              <StyledIcon component={RouterLink} to="/" />
            </Box>
            {leftNav.map(({name, url}, key) => (
              <StyledLink
                key={`left-nav-${key}`}
                href={url}
                target="_blank"
                style={{marginRight: key === 0 ? '32px' : 0}}
              >
                {name}
              </StyledLink>
            ))}
          </LeftBox>

          <Box>
            <NavButton background={'blue2'} onClick={toggleSearchBox}>
              Search
            </NavButton>
            {auth.isAuthenticated && (
              <>
                <LinkBox to={'/'} component={RouterLink}>
                  <NavButton background={'blue1'}>Dashboard</NavButton>
                </LinkBox>
                <NavButton onClick={handleOpenUserMenu} sx={{}}>
                  Settings
                  {anchorElUser ? (
                    <KeyboardArrowUpIcon
                      sx={{paddingLeft: '22px'}}
                    ></KeyboardArrowUpIcon>
                  ) : (
                    <KeyboardArrowDownIcon
                      sx={{paddingLeft: '22px'}}
                    ></KeyboardArrowDownIcon>
                  )}
                </NavButton>
              </>
            )}

            {/*------------ Drop down menu ------------*/}
            <Menu
              sx={{
                mt: '0',
                '& .MuiMenu-paper': {
                  left: 'unset !important',
                  right: 0,
                  backgroundColor: `#2d2d2d`,
                  borderRadius: 0,
                  padding: '30px',
                },
                /*------------ Drop down menu item ------------*/
                '& ul': {
                  padding: 0,
                },
                '& li': {
                  padding: '0',
                  '&:hover': {
                    '& p': {
                      backgroundColor: `${colors.white}`,
                      color: `#2d2d2d`,
                    },
                  },
                  '& p': {
                    width: '100%',
                    maxWidth: '280px',
                    fontSize: 14,
                    padding: '10px 12px',
                    marginBottom: '2px',
                    textTransform: 'uppercase',
                    textAlign: 'left',
                    letterSpacing: 0,
                  },
                },
                /*------------ Drop down menu item ------------*/
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              keepMounted
              open={
                (Boolean(anchorElUser) && !isMobile) ||
                (!isMobile && !!anchorElNav)
              }
              onClose={handleCloseUserMenu}
            >
              {settings.map(setting => {
                const {name, url} = setting
                return (
                  <MenuItem key={name} onClick={handleCloseUserMenu}>
                    {name === 'log out' ? (
                      <SettingsItem onClick={() => auth.logout()}>
                        {name}
                      </SettingsItem>
                    ) : (
                      <SettingsLink component={RouterLink} to={url}>
                        <SettingsItem>{name}</SettingsItem>
                      </SettingsLink>
                    )}
                  </MenuItem>
                )
              })}
            </Menu>
            {/*------------ Drop down menu ------------*/}
          </Box>
        </FlexBox>
        {/*------------ Desktop Version ------------*/}
        {/* </Toolbar> */}
      </NavbarContainer>
    </AppBar>
  )
}

export default View
