import {Box} from '@mui/material'
import styled from 'styled-components'

export const PaymentMethodContainer = styled(Box)`
  max-width: 880px;
  height: 1160px;

  @media (max-width: 1154px) {
    height: 1800px;
  }
`

export const PaymentMethodIframeContainer = styled('iframe')`
  width: 100%;
  height: 100%;
  border: 0;
`
