import {useSelector} from 'react-redux'
import {Link as RouterLink} from 'react-router-dom'
import {DashboardContentContainer} from '../DashboardContent/styles'
import {Title, TopicsBox, TopicsItem} from './styles'
import {selectOrganization} from '../../redux/courses'
import {PrimaryLinkButton} from '../../styles/global'
import {normalizeTitle} from '../../utils/normalizeTitle'
import {Helmet} from 'react-helmet'

const View = () => {
  const organization = useSelector(selectOrganization)

  const courses = organization?.courses.filter(
    course => course.tags?.some(tag => tag.category === 'SOFTWARE_COURSE'),
  )

  const topics: string[] = []

  courses?.forEach(course => {
    if (course.tags) {
      course.tags.forEach(tag => {
        if (!topics.includes(tag.title)) {
          topics.push(tag.title)
        }
      })
    }
  })

  topics.sort()
  return (
    <>
      <Helmet>
        <title>{`Software Learning Dashboard | Black Spectacles`}</title>
      </Helmet>
      <DashboardContentContainer>
        <TopicsBox>
          {topics.map((topic, key) => {
            return (
              <TopicsItem key={`topic-${key}`}>
                <Title>{topic}</Title>
                <PrimaryLinkButton
                  component={RouterLink}
                  to={`/topics/${normalizeTitle(topic)}`}
                >
                  View
                </PrimaryLinkButton>
              </TopicsItem>
            )
          })}
        </TopicsBox>
      </DashboardContentContainer>
    </>
  )
}

export default View
