import React from 'react'
import {Box, Typography, LinearProgress} from '@mui/material'
import {Link as RouterLink} from 'react-router-dom'
import {
  SectionProgress,
  SectionProgressContainer,
} from '../../SectionContainer/styles'
import {DashboardExams} from '../../../redux/dashboard'
import {Course} from '../../../redux/courses'
import {PrimaryLinkButton} from '../../../styles/global'
import {ButtonBox} from '../StudyMaterials/styles'

type Props = {
  course?: Course
  quizData?: DashboardExams
}

const View = ({course, quizData}: Props) => {
  const percentagePlaceholderValue = quizData?.averagePercentage ?? 0

  return (
    <>
      <Box>
        <Typography variant={'h3'}>Quizzes</Typography>

        <SectionProgressContainer marginTop={'20px'}>
          <Typography variant={'body1'}>Average Score</Typography>
          <SectionProgress>
            <Box sx={{width: '100%'}}>
              <LinearProgress
                variant="determinate"
                value={percentagePlaceholderValue}
              />
            </Box>

            <Typography variant={'body2'}>
              {percentagePlaceholderValue}%
            </Typography>
          </SectionProgress>
        </SectionProgressContainer>
      </Box>

      <ButtonBox>
        <PrimaryLinkButton component={RouterLink} to={`/quizzes/${course?.id}`}>
          View Quizzes
        </PrimaryLinkButton>
      </ButtonBox>
    </>
  )
}

export default View
