import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../../hooks/useApi'

//#region types
export type BillingAddress = {
  id: number
  address: string
  city: string
  country: string
  postal_code: string
  state: string
  primary: boolean
}
//#endregion

//#region api
type BillingAddressPayload = {
  jwt: string
  id?: string
}

export const getBillingAddress = createAsyncThunk<any, BillingAddressPayload>(
  '/getBillingAddress',
  async ({jwt}) => {
    return useApi(
      null,
      `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/network/member/billing_address`,
      {
        method: 'GET',
        headers: {Authorization: `Bearer ${jwt}`},
      },
    ).then(res => res.json())
  },
)
//#endregion

type BillingAddressUpdatePayload = {
  jwt: string
  pmID: string
  address_1: string
  city: string
  state: string
  postal_code: string
  country: string
  ccUpdated: boolean
}

export const updateBillingAddress = createAsyncThunk<
  any,
  BillingAddressUpdatePayload
>('/updateBillingInfo', async ({jwt}) => {
  const fields = {}

  return useApi(
    null,
    `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/guest/member/billing_address`,
    {
      method: 'POST',
      body: JSON.stringify(fields),
      headers: {Authorization: `Bearer ${jwt}`},
    },
  ).then(res => ({status: res.ok}))
})
