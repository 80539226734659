import React from 'react'
import {
  ButtonBox,
  StyledDialog,
  DialogBox,
  DialogSubtitle,
  DialogText,
  DialogTitle,
} from './styles'
import {Button} from '../button'
import CloseIcon from '@mui/icons-material/Close'
import {Workshop} from '../../redux/workshop'
import {formatWorkshopDate} from '../../utils/formatWorkshopDates'

type Props = {
  open: boolean
  setOpen: (value: boolean) => void
  workshop?: Workshop
  conflictedWorkshop?: Workshop
  handleSubmit: () => void
  isSubmitting: boolean
}

const View = ({
  open,
  setOpen,
  workshop,
  conflictedWorkshop,
  handleSubmit,
  isSubmitting,
}: Props) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <StyledDialog maxWidth={'md'} onClose={handleClose} open={open}>
      <DialogBox>
        <DialogTitle>
          Schedule conflict
          <CloseIcon
            onClick={handleClose}
            className="x-button"
            style={{
              height: 32,
              width: 32,
              cursor: 'pointer',
            }}
          />
        </DialogTitle>
        <DialogSubtitle>
          You already RSVPed to another Virtual Workshop that's happening at the
          same time:
        </DialogSubtitle>
        <DialogText>
          <b>- {conflictedWorkshop?.title}</b>
          <span>{formatWorkshopDate(workshop?.date)}</span>
        </DialogText>
        <DialogText paddingTop={'24px'}>
          Do you want to replace the above RSVP with this RSVP?
        </DialogText>
        <DialogText>
          <b>- {workshop?.title}</b>
          <span>{formatWorkshopDate(workshop?.date)}</span>
        </DialogText>
        <ButtonBox>
          <Button
            onClick={handleClose}
            children={'Cancel'}
            color={'grey'}
            size={'medium'}
          />

          <Button
            onClick={handleSubmit}
            disabled={isSubmitting}
            children={'Replace'}
            color={'secondary'}
            size={'medium'}
          />
        </ButtonBox>
      </DialogBox>
    </StyledDialog>
  )
}

export default View
