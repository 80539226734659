import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../../hooks/useApi'

export type CartProrateDetails = {
  items: Item[]
  sub_total: number
  tax: number
  tax_rate: number
  tax_rate_id?: string
  discount: number
  proration: number
  credit: number
  total: number
}

export type Item = {
  id: string
  coupon?: string
  coupon_type: string
  original_price: number
  discount: number
  proration: number
}

export type AddressDetails = {
  address1?: string
  address2?: string
  city?: string
  state?: string
  postal_code?: string
  country?: string
}

type UpdatePricingPayload = {
  jwt: string
  items: Item[]
  coupon?: string | undefined
  address?: AddressDetails
}

export const networkReprice = createAsyncThunk<any, UpdatePricingPayload>(
  '/prorate',
  async ({jwt, items, coupon, address}) => {
    const fields = {
      items,
      coupon: coupon ? coupon.toUpperCase() : '',
      address,
      no_cache: true,
    }

    return useApi(
      null,
      `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/network/cart/reprice`,
      {
        method: 'POST',
        headers: {Authorization: `Bearer ${jwt}`},
        body: JSON.stringify(fields),
      },
    ).then(res => res.json())
  },
)
