import React, {useEffect} from 'react'
import {Exam} from '../../redux/exams'
import {ExplanationBox} from '../QuizQuestions/styles'
import renderHTML from '../../utils/renderHTML'
import {CheckallRow} from '../ExamCheckall/styles'
import {Radio} from './styles'

type Props = {
  exam?: Exam
  currentQuestion: number
  isReviewing?: boolean
  onSelect: (value: any) => void
  answer?: any
  currentAnswer?: any
}

const ExamMultiple = ({
  exam,
  currentQuestion,
  isReviewing,
  onSelect,
  answer,
  currentAnswer,
}: Props) => {
  const question = exam?.questions[currentQuestion]

  useEffect(() => {
    if (currentAnswer) {
      onSelect(Number(currentAnswer))
    }
  }, [question])

  return (
    <>
      {question?.answers.map(item => {
        return (
          <React.Fragment key={item.displayOrder}>
            <CheckallRow
              $isReviewing={isReviewing}
              $isCorrectAnswer={item.correctOption}
              $isActive={Number(answer) === item.displayOrder}
            >
              <Radio
                $isActive={Number(answer) === item.displayOrder}
                onClick={() =>
                  !isReviewing &&
                  !question.isBlocked &&
                  onSelect(item.displayOrder)
                }
              >
                {Number(answer) === item.displayOrder && '✓'}
              </Radio>
              {item.label ? (
                <span>{item.label}</span>
              ) : (
                <img src={item.image} alt="Option" />
              )}
            </CheckallRow>
            {isReviewing && (
              <ExplanationBox>
                {renderHTML(item.answerExplanation)}
              </ExplanationBox>
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default ExamMultiple
