import styled from 'styled-components'
import {Dialog, Typography, Box} from '@mui/material'
import theme from '../../styles/theme'

export const BreakDialog = styled(Dialog)`
  min-height: 100vh;
  border-radius: 0;
`

export const DialogBox = styled(Box)`
  padding: 24px;
  width: 508px;
  box-sizing: border-box;
  overflow-x: hidden;

  ${theme.breakpoints.down('md')} {
    width: auto;
  }
`

export const DialogHeader = styled(Box)`
  margin-top: 26px;
  border-bottom: 1px #808080 solid;
`

export const DialogFlexBox = styled(Box)`
  display: flex;
  gap: 12px;
`

export const DialogImage = styled(Box)`
  padding-top: 10px;
`

export const DialogText = styled(Typography)`
  padding-top: 10px;
  font-size: 16px;
  color: #2d2d2d;
  display: flex;
  flex-flow: column;
  gap: 10px;

  ${theme.breakpoints.down('xs')} {
    font-size: 14px;
  }
`

export const ButtonBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 24px;
  justify-content: space-between;
  border-top: 1px #808080 solid;
  padding-top: 10px;

  ${theme.breakpoints.down('md')} {
    flex-flow: column;
    gap: 8px;
    && button {
      width: 100% !important;
    }
  }
`
