import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../hooks/useApi'
import {AuthContextInterface} from '../../components/AuthProvider'

type SSOPayload = {
  auth: AuthContextInterface
  payload: string
  sig: string
}

export const handleSSO = createAsyncThunk<any, SSOPayload>(
  '/sso',
  async ({auth, payload, sig}) => {
    const endpoint = `/v1/are/discourse/sso`
    const fields = {
      payload,
      sig,
    }

    return useApi(auth, endpoint, {
      method: 'POST',
      body: JSON.stringify(fields),
    }).then(res => res.json())
  },
)
