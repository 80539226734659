import React from 'react'
import View from './view'
import {DashboardExams} from '../../../redux/dashboard'
import {Course} from '../../../redux/courses'

type Props = {
  course: Course
  examsData?: DashboardExams
}

const PracticeExams = ({course, examsData}: Props) => {
  return <View course={course} examsData={examsData} />
}

export default PracticeExams
