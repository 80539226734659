import React from 'react'
import View from './view'
import {Helmet} from 'react-helmet'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {selectOrganization} from '../../redux/courses'
import {normalizeTitle} from '../../utils/normalizeTitle'

const Topics = () => {
  const {topic} = useParams()

  const organization = useSelector(selectOrganization)

  const courses = organization?.courses.filter(
    course => course.tags?.some(tag => normalizeTitle(tag.title) === topic),
  )

  const reverseCourses = courses?.reverse()

  return (
    <>
      {topic && (
        <Helmet>
          <title>{`Tutorials & Training | Black Spectacles`}</title>
        </Helmet>
      )}
      <View courses={reverseCourses} />
    </>
  )
}

export default Topics
