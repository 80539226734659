import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
export type Password = {
  password: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  passwordUpdated: boolean
  status: LoadingStatuses
  error?: string | null | undefined
}

//#endregion

//#region api
type updatePasswordPayload = {
  auth: AuthContextInterface
  password: string
}

export const updatePassword = createAsyncThunk<any, updatePasswordPayload>(
  '/updatePassword',
  async ({auth, password}) => {
    const endpoint = `/v1/network/member/password`
    const fields = {password}

    return useApi(
      auth,
      endpoint,
      {
        method: 'PUT',
        body: JSON.stringify(fields),
      },
      'network',
    ).then(res => res.status === 200)
  },
)
//#endregion

//#region slice
const initialState: SliceState = {
  passwordUpdated: false,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'password',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(updatePassword.fulfilled, state => {
        state.passwordUpdated = true
        state.status = LoadingStatuses.Succeeded
        state.error = undefined
      })
      .addCase(updatePassword.pending, state => {
        state.passwordUpdated = false
        state.status = LoadingStatuses.Loading
        state.error = undefined
      })
      .addCase(updatePassword.rejected, state => {
        state.passwordUpdated = false
        state.status = LoadingStatuses.Failed
        state.error = undefined
      })
  },
})
//#endregion
