import React from 'react'
import View from './view'
import {CardNumber} from '../../../../pages/FlashCards/view'

type Props = {
  setFlashCardFormToggle: (value: boolean) => void
  dropDownSelection: string
  setDropDownSelection: (value: string) => void
  filterBySelection: string
  setFilterBySelection: (value: string) => void
  setSearchInput: (value: string) => void
  cardNumbers: CardNumber
  searchInput: string
  areCardsFlipped: boolean
  hasAccess: boolean
  setFlashCardNumber: (value: number) => void
  updateMenuAction: (value: string) => void
}

const SideMenu = ({
  setFlashCardFormToggle,
  dropDownSelection,
  setDropDownSelection,
  filterBySelection,
  setFilterBySelection,
  setSearchInput,
  cardNumbers,
  searchInput,
  areCardsFlipped,
  hasAccess,
  setFlashCardNumber,
  updateMenuAction,
}: Props) => {
  return (
    <View
      setFlashCardFormToggle={setFlashCardFormToggle}
      dropDownSelection={dropDownSelection}
      setDropDownSelection={setDropDownSelection}
      filterBySelection={filterBySelection}
      setFilterBySelection={setFilterBySelection}
      setSearchInput={setSearchInput}
      cardNumbers={cardNumbers}
      searchInput={searchInput}
      areCardsFlipped={areCardsFlipped}
      hasAccess={hasAccess}
      setFlashCardNumber={setFlashCardNumber}
      updateMenuAction={updateMenuAction}
    />
  )
}

export default SideMenu
