import React, {useState} from 'react'
import {Box, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
  SideNavContainer,
  SideNavWrapper,
  Icon,
  StyledCheckIcon,
  StyledTitle,
  ReferralProgramButton,
} from './styles'
import theme from '../../styles/theme'
import {useWindowDimensions} from '../../hooks/useWindowDimensions'
import {NCARBAPPROVEDIMAGEABBR} from '../../constants/divisions'
import DivisionIcon from '../../images/icons/division.svg'
import {Link as RouterLink, useLocation, useParams} from 'react-router-dom'
import {BlueOutlineLinkButton, LinkButton} from '../../styles/global'
import {useSelector} from 'react-redux'
import {selectOrganization} from '../../redux/courses'
import {useAuth} from '../AuthProvider'
import {selectMember, selectMemberAccess} from '../../redux/members'
import {GroupedResults, selectExamReports} from '../../redux/reportExamStatus'

const View = () => {
  const auth = useAuth()
  const {courseId} = useParams()
  // temporary selected division value
  // default value should be based on last visited division if any or current url
  const [isSuperAdmin] = useState(false)
  const [open, setOpen] = useState(false)
  const {pathname} = useLocation()

  const {width} = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md
  const isSoftware = pathname.includes('software/topics')

  const toggleMobileSideNav = () => {
    setOpen(!open)
  }

  const organization = useSelector(selectOrganization)
  const member = useSelector(selectMember)
  const access = useSelector(selectMemberAccess)

  const courses = organization?.courses.filter(course => {
    return ['PcM', 'PjM', 'PA', 'PPD', 'PDD', 'CE'].includes(
      course.abbreviation,
    )
  })
  const examReports = useSelector(selectExamReports)

  const currentCourse = organization?.courses.filter(course => {
    return course.id === courseId
  })[0]

  const softwareSubscription =
    access && Object.keys(access).length !== 0
      ? Number(access.software?.videos?.value) === 1
      : false

  return (
    <SideNavWrapper className="nav-wrapper">
      <Box
        sx={{
          display: {xs: 'flex', md: 'none'},
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '70px',
          background: '#221f1f',
          marginBottom: open ? '30px' : 0,
          cursor: 'pointer',
          color: '#fff',
          textTransform: 'uppercase',
          padding: '0 20px',
        }}
        onClick={toggleMobileSideNav}
      >
        <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
          <Icon src={DivisionIcon} className="division-icon" />

          <Typography variant="body1" color={'#fff'}>
            View Divisions
          </Typography>
        </Box>

        {isMobile && open ? (
          <>
            <CloseIcon />
          </>
        ) : null}
      </Box>
      <SideNavContainer style={{display: isMobile && !open ? 'none' : 'flex'}}>
        {isSuperAdmin ? (
          <>
            <Box sx={{marginBottom: '30px'}}>
              <StyledTitle variant={'h2'} className="align-text-center">
                QA Process
              </StyledTitle>
              <LinkButton component={RouterLink} to={'/qa-process'}>
                Quality Assurance
              </LinkButton>
            </Box>
          </>
        ) : null}

        {member && member.isGroupAdmin && (
          <Box sx={{marginBottom: '30px'}}>
            <StyledTitle variant={'h2'} className="align-text-center">
              Group
            </StyledTitle>

            <LinkButton component={RouterLink} to={'/group/manage'}>
              Manage Group
            </LinkButton>
            <LinkButton component={RouterLink} to={'/group/reporting'}>
              Group Reporting
            </LinkButton>
            <LinkButton component={RouterLink} to={'/group/billing'}>
              Group Billing
            </LinkButton>
          </Box>
        )}

        <Box sx={{marginBottom: '30px'}}>
          <StyledTitle variant={'h2'} className="align-text-center">
            ARE 5.0
          </StyledTitle>

          {courses?.map((course, index) => {
            return (
              <Box key={course.abbreviation + ' ' + index} position="relative">
                {examReports?.[course.title as keyof GroupedResults]?.passed ? (
                  <StyledCheckIcon fontSize="medium" />
                ) : null}
                <Box>
                  <LinkButton
                    $isActive={courseId === course.id}
                    component={RouterLink}
                    to={`/profile/dashboard/${course.id}`}
                  >
                    {course.title}
                  </LinkButton>
                </Box>
              </Box>
            )
          })}
        </Box>

        {auth.isAuthenticated && (
          <Box>
            <BlueOutlineLinkButton
              style={{justifyContent: 'center'}}
              component={RouterLink}
              to={`/profile/settings/are_progress`}
            >
              Report Exam Status
            </BlueOutlineLinkButton>
          </Box>
        )}

        <Box sx={{margin: '30px 0', display: 'flex', justifyContent: 'center'}}>
          <img
            width="146"
            src={NCARBAPPROVEDIMAGEABBR[currentCourse?.abbreviation!]}
          />
        </Box>

        <Box
          sx={{marginBottom: '30px', display: 'flex', flexDirection: 'column'}}
        >
          <StyledTitle variant={'h2'} className="align-text-center">
            Referral Program
          </StyledTitle>
          <ReferralProgramButton
            id="referral-program-button"
            variant="contained"
            onClick={() => {
              window.open('https://www.blackspectacles.com/referral')
            }}
          >
            Participate now
          </ReferralProgramButton>
        </Box>

        {softwareSubscription ? (
          <Box sx={{marginBottom: '30px'}}>
            <StyledTitle variant={'h2'} className="align-text-center">
              Software
            </StyledTitle>
            <LinkButton
              component={RouterLink}
              to={'/profile/dashboard/software/topics'}
              $isActive={isSoftware}
            >
              Software Topics
            </LinkButton>
          </Box>
        ) : null}
      </SideNavContainer>
    </SideNavWrapper>
  )
}

export default View
