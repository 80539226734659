import React, {useEffect, useCallback} from 'react'
import PDFView from './view'
import {useParams} from 'react-router-dom'
import {useAppDispatch} from '../../redux/configureStore'
import {useSelector} from 'react-redux'
import {toggleErrorDialog} from '../../redux/config'
import {LoadingStatuses} from '../../components/types/LoadingStatusses'
import Loading from '../../components/Loading'
import {useAuth} from '../../components/AuthProvider'
import {
  getInvoiceDetails,
  selectInvoiceDetails,
  selectInvoiceDetailsStatus,
} from '../../redux/organizations'

import {BlobProvider} from '@react-pdf/renderer'

const InvoiceDetails = () => {
  const {invoiceId} = useParams()
  const auth = useAuth()
  const dispatch = useAppDispatch()

  const invoice = useSelector(selectInvoiceDetails)
  const invoiceStatus = useSelector(selectInvoiceDetailsStatus)

  const loadInvoice = useCallback(async () => {
    try {
      if (invoiceId) {
        const {type} = await dispatch(getInvoiceDetails({auth, invoiceId}))
        if (type === getInvoiceDetails.rejected.type) {
          throw new Error('Unable to get invoice')
        }
      } else {
        throw new Error('Unable to get invoice')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load document, please retry reloading the page. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch, invoiceId])

  useEffect(() => {
    loadInvoice()
  }, [loadInvoice])

  if (
    invoiceStatus === LoadingStatuses.Loading ||
    invoiceStatus === LoadingStatuses.Idle
  ) {
    return <Loading />
  }

  if (invoice) {
    return (
      <BlobProvider document={<PDFView invoice={invoice} />}>
        {({url}) => {
          return (
            <object
              style={{width: '100%', height: '100%'}}
              data={url!}
              type="application/pdf"
            />
          )
        }}
      </BlobProvider>
    )
  }
}

export default InvoiceDetails
