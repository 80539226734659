import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
type SliceState = {
  error: string | null | undefined
}
//#endregion

//#region api
type ClaimGuaranteePayload = {
  auth: AuthContextInterface
  lessonPlanId: string
  examDate: string
  registrationId: string
  scoreReport: any
}

export const claimGuarantee = createAsyncThunk<any, ClaimGuaranteePayload>(
  '/guarantee/claim',
  async ({auth, lessonPlanId, examDate, registrationId, scoreReport}) => {
    const endpoint = `/v1/are/guarantee`

    const formData = new FormData()

    formData.append('examDate', examDate)
    formData.append('division', lessonPlanId)
    formData.append('registrationId', registrationId)
    formData.append('scoreReport', scoreReport)

    return useApi(auth, endpoint, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'Automatic',
      },
    }).then(res => res.status === 200)
  },
)
//#endregion

//#region slice
const initialState: SliceState = {
  error: undefined,
}

export default createSlice({
  name: 'guarantee',
  initialState,
  reducers: {},
})
//#endregion

//#region selectors
//#endregion
