const dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const formatTime = (centralFormatTime: string, start: boolean) => {
  const dateValues = centralFormatTime.split(' ')
  const time = dateValues[1]
  const values = time.split(':')

  if (start) return values[0] + ':' + values[1] + ' ' + dateValues[2]

  return Number(values[0]) + 2 + ':' + values[1] + ' ' + dateValues[2]
}

export const formatWorkshopDate = (date?: string) => {
  if (!date) return ''

  const options = {timeZone: 'America/Chicago', timeZoneName: 'short'}
  const centralFormat = new Date(date).toLocaleString(
    'en-US',
    options as Intl.DateTimeFormatOptions,
  )

  const displayStartTime = formatTime(centralFormat, true)
  const displayEndTime = formatTime(centralFormat, false)
  const dateObject = new Date(date)

  const dayOfWeek = dayNames[dateObject.getDay()]
  const month = monthNames[dateObject.getMonth()]
  const year = dateObject.getFullYear()
  const day = dateObject.getDate()

  return `${dayOfWeek}, ${month} ${day}, ${year} @ ${displayStartTime} - ${displayEndTime} Central`
}
