import {Box, Button} from '@mui/material'
import styled from 'styled-components'

import theme from '../../styles/theme'

// Attach ReferralBanner to the Footer on smaller screens and remove it on
// too smaller screens
export const ReferralBannerContainer = styled(Box)`
  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-bottom: -30px;
  }

  @media (max-width: 895px) {
    display: none !important;
  }
`

export const BackgroundImage = styled.img`
  position: absolute;
  object-fit: cover;
`

export const CTAButton = styled(Button)`
  && {
    background-color: #1377d4;
    border-radius: 2px;
    font-family: unset;
    font-weight: 700;
    padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
    text-transform: unset;

    &:hover {
      background-color: #1377d4;
    }
  }
`
