import {Box, Typography, Button as MUIButton} from '@mui/material'
import styled from 'styled-components'
import theme, {colors} from '../../styles/theme'
import {Button} from '../button'
import CheckIcon from '@mui/icons-material/Check'

export const SideNavContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  margin: 0 -15px;

  && div {
    width: 100%;
    min-width: 160px;
    max-width: 280px;
  }

  && button:not(#referral-program-button) {
    width: 100%;
    text-align: left;
    background-color: ${colors.gray};
    color: ${colors.black1} !important;
    margin-bottom: 2px;
    display: flex;
    justify-content: start;
  }

  && button#report-exam-results-btn {
    display: flex;
    justify-content: center;
    background-color: ${colors.white};
    color: ${colors.blue1} !important;
  }

  && button#report-exam-results-btn:hover {
    color: ${colors.white} !important;
  }

  && button:hover {
    background-color: ${colors.blue1} !important;
    color: ${colors.white} !important;
  }

  && button.selected {
    color: ${colors.white} !important;
  }

  && div > h1 {
    margin-bottom: 15px;
    padding-left: 0.25em;
  }

  ${theme.breakpoints.down('md')} {
    width: 100%;
    align-items: center;

    && button#report-exam-results-btn {
      background-color: #151515 !important;
      color: ${colors.blue1} !important;
    }

    && button#report-exam-results-btn:hover {
      background-color: ${colors.blue1} !important;
      color: ${colors.white} !important;
    }
  }
`

export const SideNavWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 160px;
  max-width: 280px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    background: #151515;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 100;
    margin: 0 -25px;
    padding-left: 25px;
    max-width: unset;

    .align-text-center {
      text-align: center;
    }
  }
`

export const Icon = styled('img')`
  height: 25px;
  margin-right: 15px;
`

export const BlueOutlineButton = styled(Button)`
  && span {
    border: 2px solid ${colors.blue1} !important;
  }
`

export const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -40px;
  width: 32px !important;
  height: 32px !important;
  color: #1377d4;
`

export const StyledTitle = styled(Typography)`
  margin-bottom: 10px !important;
  font-size: 32px !important;
  color: #2d2d2d !important;

  ${theme.breakpoints.down('md')} {
    color: #ffffff !important;
  }
`

export const ReferralProgramButton = styled(MUIButton)`
  && {
    background-color: #1377d4;
    border-radius: 2px;
    font-family: unset;
    font-weight: 700;
    padding: 10.25px ${theme.spacing(3)};
    text-transform: unset;

    // cleanup unwanted default styles
    color: white;

    &:hover {
      background-color: #1377d4;
    }
  }
`
