import React from 'react'

import {Analytics, AnalyticsBrowser} from '@segment/analytics-next'

import {SegmentContext} from './Context'

interface Props {
  children: React.ReactNode
}

export const SegmentProvider: React.FC<Props> = ({children}) => {
  const [analytics, setAnalytics] = React.useState<Analytics | undefined>(
    undefined,
  )

  React.useEffect(() => {
    const loadAnalytics = async () => {
      if (
        process.env.REACT_APP_SEGMENT_APP_ID &&
        process.env.REACT_APP_ENV_NAME === 'production'
      ) {
        const [response] = await AnalyticsBrowser.load({
          writeKey: process.env.REACT_APP_SEGMENT_APP_ID,
        })
        setAnalytics(response)
      }
    }
    loadAnalytics()
  }, [])

  return (
    <SegmentContext.Provider value={{analytics}}>
      {children}
    </SegmentContext.Provider>
  )
}
