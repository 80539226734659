import React from 'react'
import {Box} from '@mui/material'
import {
  ButtonBox,
  ConfirmDialog,
  DialogBox,
  DialogText,
  DialogTitle,
} from './styles'
import {Button} from '../button'

export type Props = {
  isOpen: boolean
  title: string
  message: string
  onClose: () => void
  onConfirm: () => void
}

const View = ({isOpen, onClose, onConfirm, title, message}: Props) => {
  return (
    <ConfirmDialog maxWidth={'md'} onClose={onClose} open={isOpen}>
      <DialogTitle>{title}</DialogTitle>

      <DialogBox>
        <Box>
          <DialogText>{message}</DialogText>
        </Box>
        <ButtonBox>
          <Button
            onClick={onClose}
            children={'Cancel'}
            color={'secondary'}
            size={'medium'}
          />
          <Button
            onClick={onConfirm}
            children={'Confirm'}
            color={'primary'}
            size={'medium'}
          />
        </ButtonBox>
      </DialogBox>
    </ConfirmDialog>
  )
}

export default View
