import React, {useState, useEffect, useRef} from 'react'
import {
  Box,
  Typography,
  Modal,
  TextField,
  FormControl,
  RadioGroup,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import {Button} from '../button'
import {
  FormContainer,
  CloseContainer,
  Row,
  FormTitle,
  DateField,
  ResourcesField,
  ButtonRow,
  SubmittedAttemptsSection,
  AttemptRow,
  ResourcesOptionsContainer,
  ResourcesList,
  RadioBox,
  ButtonBox,
} from './styles'
import {DIVISION, DivisionNameType} from '../../constants/divisions'
import {v4 as uuidv4} from 'uuid'
import {
  Formik,
  FormikHelpers,
  Form,
  Field,
  FieldProps,
  FormikProps,
} from 'formik'
import {
  selectExamReports,
  submitExamDate,
  getReportedExamResults,
  submitExamResults,
  deleteExamDate,
  Attempt,
} from '../../redux/reportExamStatus'
import {useAppDispatch} from '../../redux/configureStore'
import {useSelector} from 'react-redux'
import {useAuth} from '../AuthProvider'
import {toggleErrorDialog} from '../../redux/config'
import {StyledTitle} from '../../styles/global'
import {useSearchParams} from 'react-router-dom'

type Props = {
  open: boolean
  setOpen: (val: boolean) => void
  courseId?: string
  division: string
}

type Division =
  | 'Practice Management'
  | 'Project Management'
  | 'Programming & Analysis'
  | 'Project Planning & Design'
  | 'Project Development & Documentation'
  | 'Construction & Evaluation'

type FormValues = {
  examDate: string
  passed: 'true' | 'false' | null
  surveyResources: string[]
  surveyHoursStudied: string
}

type FormikRefType = React.RefObject<FormikProps<any>>

const resourceOptions = [
  {label: 'Black Spectacles Videos'},
  {label: 'Black Spectacles Practice Exams'},
  {label: 'Black Spectacles Flashcards'},
  {label: 'Black Spectacles Virtual Workshops'},
  {label: "Competitor's Videos"},
  {label: "Competitor's Practice Exams"},
  {label: "Competitor's Flashcards"},
  {label: "Competitor's Virtual Workshops"},
]

const View = ({open, setOpen, courseId, division}: Props) => {
  const [, setSearchParams] = useSearchParams()
  const [previousAttemptsList, setPreviousAttemptsList] = useState<Attempt[]>(
    [],
  )
  const auth = useAuth()
  const divisionName = DIVISION[division as keyof DivisionNameType]
  const dispatch = useAppDispatch()
  const examReports = useSelector(selectExamReports)
  const [isEditing, setIsEditing] = useState(false)
  // for loading in scheduled exam date into form
  const [scheduledDate, setScheduledDate] = useState('')
  const [currentEntryId, setCurrentEntryId] = useState('')
  const initialValues: FormValues = {
    examDate: '',
    passed: null,
    surveyResources: [],
    surveyHoursStudied: '',
  }
  // const currentEntry = useSelector(selectCurrentEntry)
  const formikRef: FormikRefType = useRef(null)

  const handleClose = () => {
    setOpen(false)
    setScheduledDate('')
  }

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    try {
      if (courseId) {
        // Get current course ID
        const fields = {auth, courseId, examDate: scheduledDate, pass: null}
        const resources = values.surveyResources.join(', ')

        // Submitting New Attempt
        if (!currentEntryId) {
          // Create NCARB Exam Entry
          const createEntryResponse = await dispatch(submitExamDate(fields))

          if (createEntryResponse.type === submitExamDate.rejected.type) {
            throw new Error('Unable to create entry')
          }

          if (createEntryResponse.payload[0].entryId) {
            const submitExamResultFields = {
              auth,
              entryId: createEntryResponse.payload[0].entryId,
              ...values,
              passed: values.passed === 'true',
              examDate: scheduledDate,
              surveyResources: resources,
            }

            // Update NCARB Exam Entry
            const {type} = await dispatch(
              submitExamResults(submitExamResultFields),
            )

            actions.setSubmitting(false)

            if (type === submitExamResults.rejected.type) {
              throw new Error('Unable to update entry')
            }
          }
        } else {
          // Updating Existing Attempt
          const submitExamResultFields = {
            auth,
            entryId: currentEntryId,
            ...values,
            passed: values.passed === 'true',
            examDate: scheduledDate,
            surveyResources: resources,
          }

          // Update NCARB Exam Entry
          const {type} = await dispatch(
            submitExamResults(submitExamResultFields),
          )

          if (type === submitExamResults.rejected.type) {
            throw new Error('Unable to update entry')
          }
        }

        // update report exam section UI
        await dispatch(getReportedExamResults({auth}))

        // clear out entry id after submissions
        setCurrentEntryId('')
        setOpen(false)

        // open referral dialog
        if (values.passed === 'true') {
          setSearchParams({showReferralDialog: 'true', source: 'exam-pass'})
        }
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the upload this exam attempt, please retry. If issue persists please contact our support team',
        }),
      )
    }
  }

  const handleEdit = (attemptId: string) => {
    if (formikRef.current) {
      formikRef.current.resetForm()
    }

    document.getElementById('exam-modal')?.scrollTo(0, 0)

    // get current attempts date
    if (examReports) {
      examReports[divisionName as Division].attempts.map(attempt => {
        if (attempt.attemptId === attemptId) {
          setCurrentEntryId(attemptId)
          const formattedDate = attempt.examDate.split('T')[0]
          setScheduledDate(formattedDate)
          setIsEditing(true)
        }
      })
      // for testing
    } else if (attemptId.length === 1) {
      previousAttemptsList.map(attempt => {
        if (attempt.attemptId == attemptId) {
          setCurrentEntryId(attemptId)

          const reformatedDate = reorderDateFormat(attempt.examDate)
          setScheduledDate(reformatedDate)
          setIsEditing(true)
        }
      })
    }
  }

  const reorderDateFormat = (date: string) => {
    const dateValues = date.split('-')
    return dateValues[2] + '-' + dateValues[0] + '-' + dateValues[1]
  }

  const handleDelete = async (attemptId: string) => {
    try {
      const {type} = await dispatch(deleteExamDate({auth, entry_id: attemptId}))
      if (type === deleteExamDate.rejected.type) {
        throw new Error('Unable to delete exam attempt')
      }
      // update previous attempts list UI when deleting attempts
      const list = [...previousAttemptsList]
      let attemptIdx

      list.map((attempt, idx) => {
        if (attempt.attemptId === attemptId) attemptIdx = idx
      })

      if (attemptIdx || attemptIdx === 0) list.splice(attemptIdx, 1)
      setPreviousAttemptsList(list)

      // update Report Exam Results page
      await dispatch(getReportedExamResults({auth}))
    } catch (e) {
      toggleErrorDialog({
        opened: true,
        error:
          'We were unable to delete this exam attempt, please retry. If issue persists please contact our support team',
      })
    }
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScheduledDate(e.target.value)
  }

  const showAttemptStatus = (passed: boolean | number | null) => {
    switch (passed) {
      case true:
      case 1:
        return 'Pass'
      case null:
        return 'Scheduled'
      default:
        return 'Fail'
    }
  }

  // remove leadings 0s and switch '-' with '/'
  const formatDate = (date: string) => {
    return date
      ?.split('-')
      .map(val => parseInt(val))
      .join('/')
  }

  const getStatusColor = (status: boolean | null) => {
    switch (status) {
      case false:
        return '#f44336'
      case true:
        return '#1377D4'
      default:
        return '#000'
    }
  }
  const getCurrentDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  useEffect(() => {
    if (examReports) {
      Object.keys(examReports).map(key => {
        const exam = examReports[key as Division]
        if (
          exam.courseAbbreviation.toLowerCase() === division &&
          exam.scheduled
        ) {
          setIsEditing(true)
          setScheduledDate(exam.scheduled.split('T')[0])
        }
      })

      // update previous attempts list
      setPreviousAttemptsList(
        examReports[divisionName as Division]?.attempts.slice().reverse(),
      )
    }
  }, [examReports])

  useEffect(() => {
    if (previousAttemptsList?.length === 0) {
      setIsEditing(false)
    }
  }, [previousAttemptsList])

  return (
    <Box sx={open ? {overflow: 'hidden'} : {overflow: 'auto'}}>
      <Modal open={open} onClose={handleClose}>
        <FormContainer id={'exam-modal'}>
          <CloseContainer>
            <CloseIcon
              onClick={handleClose}
              style={{fontSize: '35px', cursor: 'pointer'}}
            />
          </CloseContainer>

          <FormTitle>
            <StyledTitle variant={'h2'}>
              Updating exam progress for ARE 5.0: {divisionName}
            </StyledTitle>
          </FormTitle>

          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions)
            }}
          >
            {formik => (
              <Form>
                <Row>
                  <DateField>
                    <Typography variant={'h3'} style={{marginBottom: '24px'}}>
                      {isEditing ? 'Edit Attempt' : 'Submit New Attempt'}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: '#2d2d2d',
                        marginTop: '16px',
                        marginBottom: '8px',
                      }}
                    >
                      Exam Date*
                    </Typography>
                    <Field name="examDate">
                      {(props: FieldProps<string>) => (
                        <TextField
                          {...props.field}
                          id="outlined-basic"
                          variant="outlined"
                          type="date"
                          inputProps={{
                            max: getCurrentDate(),
                          }}
                          sx={{
                            '&& div': {
                              width: '100% !important',
                              maxWidth: '150px',
                              height: '48px',
                              marginRight: '8px',
                              marginBottom: '15px',
                              border: '2px #96e0fc solid',
                            },
                          }}
                          value={scheduledDate}
                          onChange={handleDateChange}
                        />
                      )}
                    </Field>
                  </DateField>
                </Row>
                <Row>
                  <FormControl>
                    <Typography
                      variant="body1"
                      style={{
                        color: '#2d2d2d',
                        marginTop: '16px',
                        marginBottom: '8px',
                      }}
                    >
                      Exam Status*
                    </Typography>
                    <RadioGroup
                      row
                      aria-labelledby="passed"
                      name="row-radio-buttons-group"
                      className="radioRow"
                    >
                      <RadioBox>
                        <Field type="radio" name="passed" value="true" />
                        <Typography variant="body2">Pass</Typography>
                      </RadioBox>
                      <RadioBox>
                        <Field type="radio" name="passed" value="false" />
                        <Typography variant="body2">Fail</Typography>
                      </RadioBox>
                    </RadioGroup>
                  </FormControl>
                </Row>
                <Row>
                  <FormControl>
                    <Typography
                      variant="body1"
                      style={{
                        color: '#2d2d2d',
                        marginTop: '16px',
                        marginBottom: '8px',
                      }}
                    >
                      How long did you study?
                    </Typography>

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      className="radioRow"
                    >
                      <Field
                        type="radio"
                        value="Not much (<15hours)"
                        name="surveyHoursStudied"
                        className="radioButton"
                      />
                      <Typography variant="body2">
                        {'Not much (<15hours)'}
                      </Typography>
                    </RadioGroup>

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      className="radioRow"
                    >
                      <Field
                        type="radio"
                        value="Average (15-40 hours)"
                        name="surveyHoursStudied"
                        className="radioButton"
                      />
                      <Typography variant="body2">
                        {'Average (15-40 hours)'}
                      </Typography>
                    </RadioGroup>

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      className="radioRow"
                    >
                      <Field
                        type="radio"
                        value="A lot (>40 hours)"
                        name="surveyHoursStudied"
                        className="radioButton"
                      />
                      <Typography variant="body2">
                        {'A lot (>40 hours)'}
                      </Typography>
                    </RadioGroup>
                  </FormControl>
                </Row>
                <Row>
                  <ResourcesField>
                    <Typography variant="body1" style={{color: '#2d2d2d'}}>
                      Which resources helped you study?
                    </Typography>

                    <ResourcesOptionsContainer>
                      {resourceOptions.map(({label}) => (
                        <ResourcesList key={uuidv4()}>
                          <Field
                            value={label}
                            type="checkbox"
                            name="surveyResources"
                            checked={formik.values.surveyResources.includes(
                              label,
                            )}
                          />
                          <Typography variant="body2">{label}</Typography>
                        </ResourcesList>
                      ))}
                    </ResourcesOptionsContainer>
                  </ResourcesField>
                </Row>
                <ButtonRow>
                  <Button
                    type="submit"
                    color={'primary'}
                    children={'Submit Results'}
                    disabled={
                      formik.isSubmitting ||
                      !scheduledDate ||
                      formik.values.passed === null
                    }
                  />
                </ButtonRow>
              </Form>
            )}
          </Formik>

          <SubmittedAttemptsSection>
            <Typography variant={'h2'}>Submitted Attempts</Typography>

            {previousAttemptsList && previousAttemptsList.length
              ? previousAttemptsList.map(({passed, examDate, attemptId}) => (
                  <AttemptRow key={uuidv4()}>
                    <Box>
                      <FiberManualRecordIcon
                        sx={{
                          fontSize: '10px ',
                          color: getStatusColor(passed),
                        }}
                      />{' '}
                      {showAttemptStatus(passed)}
                      {' ('}
                      {formatDate(examDate)}
                      {')'}
                    </Box>

                    <ButtonBox>
                      {showAttemptStatus(passed) === 'Scheduled' ? (
                        <Button
                          color="primary"
                          onClick={() => handleEdit(attemptId)}
                          children={'Edit'}
                        />
                      ) : null}
                      <Button
                        color="grey"
                        onClick={() => handleDelete(attemptId)}
                        children={'Delete'}
                      />
                    </ButtonBox>
                  </AttemptRow>
                ))
              : null}
          </SubmittedAttemptsSection>
        </FormContainer>
      </Modal>
    </Box>
  )
}

export default View
