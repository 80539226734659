import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import store from './redux/configureStore'
import {ThemeProvider} from '@mui/material/styles'
import theme from './styles/theme'
import {BrowserRouter as Router} from 'react-router-dom'
import {IntercomProvider} from 'react-use-intercom'

import './index.css'

import App from './App'
import reportWebVitals from './reportWebVitals'
import {AuthProvider} from './components/AuthProvider'
import {datadogRum} from '@datadog/browser-rum'
import {SegmentProvider} from './context'

if (
  process.env.REACT_APP_DATADOG_UI_APPLICATION_ID &&
  process.env.REACT_APP_DATADOG_UI_CLIENT_TOKEN
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_UI_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_UI_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'bks-ui',
    env: process.env.REACT_APP_ENV_NAME,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID || 'rm29b11q'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <AuthProvider>
      <IntercomProvider appId={intercomAppId}>
        <ThemeProvider theme={theme}>
          <Router>
            <SegmentProvider>
              <App />
            </SegmentProvider>
          </Router>
        </ThemeProvider>
      </IntercomProvider>
    </AuthProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
