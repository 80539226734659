import React from 'react'
import View from './view'

type Props = {
  open: boolean
  setOpen: (val: boolean) => void
}

const VirtualWorkshopForm = ({open, setOpen}: Props) => {
  return <View open={open} setOpen={setOpen} />
}

export default VirtualWorkshopForm
