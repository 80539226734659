import React, {useEffect, useCallback} from 'react'

import './styles.css'
import 'jquery'

import {useAppDispatch} from '../../redux/configureStore'
import {
  Whiteboard as WhiteboardData,
  deleteWhiteboard,
  getWhiteboard,
  saveWhiteboard,
} from '../../redux/exams'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../components/AuthProvider'

const Whiteboard = () => {
  const auth = useAuth()
  const params = useParams()
  const dispatch = useAppDispatch()
  const selectedWhiteboardTab = '0'

  const attemptId = params.attemptId

  interface WhiteboardDataEntry {
    TS: any
    Data: any
  }

  class WhiteboardDataEntry {
    constructor(data: any, ts?: any) {
      this.TS = typeof ts === 'undefined' ? Date.now() : ts
      this.Data = data
    }
  }

  interface WhiteboardHistoryData {
    LastModified: any
    UndoStack: any
    RedoStack: any
    BlankData: any
    maxSize: any
  }

  class WhiteboardHistoryData {
    constructor(initData?: any) {
      this.LastModified = Date.now()
      this.UndoStack = []
      this.RedoStack = []
      this.BlankData = '{"objects":[],"background":""}'
      this.maxSize = 6
      if (typeof initData === 'undefined' || initData == '')
        this.UndoStack.push(new WhiteboardDataEntry(this.BlankData))
      else this.UndoStack.push(new WhiteboardDataEntry(initData))
    }
    Update(newData: any) {
      if (
        this.UndoStack.length > 0 &&
        this.UndoStack[this.UndoStack.length - 1].Data == newData
      )
        return false
      if (newData == '')
        this.UndoStack.push(new WhiteboardDataEntry(this.BlankData))
      else this.UndoStack.push(new WhiteboardDataEntry(newData))
      if (this.UndoStack.length > this.maxSize) {
        this.UndoStack.splice(0, 1)
      }
      if (this.RedoStack.length > 0) this.RedoStack = []
      return true
    }
    Undo() {
      if (this.UndoStack.length == 1) return ''

      if (this.UndoStack.length > 1) {
        this.RedoStack.push(this.UndoStack.pop())
        return this.UndoStack[this.UndoStack.length - 1].Data
      } else return ''
    }
    Redo() {
      if (this.RedoStack.length > 0) {
        this.UndoStack.push(this.RedoStack.pop())
        return this.UndoStack[this.UndoStack.length - 1].Data
      } else return ''
    }
  }

  let mydrawer: any = null
  let selectedTab = 0

  if (typeof selectedWhiteboardTab !== 'undefined')
    selectedTab = parseInt(selectedWhiteboardTab, 10)

  const cleanWhiteboardSavedData = (jData: any) => {
    if (jData) {
      for (let i = 0; i < jData.objects.length; i++) {
        //remove Eraser shape
        if (
          jData.objects[i].type == 'PCircle' &&
          jData.objects[i].shapeName == 'Eraser'
        ) {
          jData.objects.splice(i, 1)
          i--
        }
        if (
          typeof jData.objects[i].strokeDashArray !== 'undefined' &&
          jData.objects[i].strokeDashArray == null
        ) {
          delete jData.objects[i].strokeDashArray
        }
      }
    }
    return jData
  }

  let lstWhiteboardData: any = []
  const lstHistoryData: any = []

  const saveData = async (canvasData: any) => {
    const whiteboardData = lstWhiteboardData[selectedTab]
    const strData = JSON.stringify(canvasData)

    if (strData != whiteboardData.Data) {
      whiteboardData.Data = strData

      await dispatch(
        saveWhiteboard({
          auth,
          attemptId: attemptId!,
          tab: selectedTab,
          payload: strData,
        }),
      )
    }
  }

  const deleteData = async () => {
    await dispatch(
      deleteWhiteboard({
        auth,
        attemptId: attemptId!,
        tab: selectedTab,
      }),
    )
  }

  const renderTabs = () => {
    $('#whiteboard_tabs').empty()
    if (lstWhiteboardData.length == 0) {
      $('#whiteboard_tabs').append(
        '<a href="#" class="selected">Page&nbsp;1</a>',
      )
    } else {
      for (let j = 0; j < lstWhiteboardData.length; j++) {
        $('#whiteboard_tabs').append(
          '<a href="#" class="' +
            (j == selectedTab ? 'selected' : '') +
            '" indextab="' +
            j +
            '">Page&nbsp;' +
            (j + 1) +
            '</a>',
        )
      }
    }

    $('#whiteboard_tabs a').click(function () {
      saveCurrentTab()
      selectedTab = $(this).attr('indextab')
        ? parseInt($(this).attr('indextab')!, 10)
        : 0
      selectTab(selectedTab)
      return false
    })
  }

  const clearDrawer = () => {
    let isActive = true
    if (mydrawer.api.drawer.mode != mydrawer.api.drawer.MODE_ACTIVE)
      isActive = false
    if (!isActive) {
      mydrawer._startEditing()
    }
    mydrawer.fCanvas.clear()
  }

  const saveCurrentTab = () => {
    let isActive = true
    if (mydrawer.api.drawer.mode != mydrawer.api.drawer.MODE_ACTIVE)
      isActive = false
    if (!isActive) {
      mydrawer._startEditing()
    }
    mydrawer.api.saveCanvas()
  }

  const deleteTab = (idx: any) => {
    if (lstWhiteboardData.length == 1) {
      clearDrawer()
      lstWhiteboardData[0].Data = ''
      saveCurrentTab()
    } else {
      if (idx == selectedTab) {
        deleteData()
        lstWhiteboardData.splice(idx, 1)
        lstHistoryData.splice(idx, 1)
        if (selectedTab > lstWhiteboardData.length - 1) {
          selectedTab = lstWhiteboardData.length - 1
        }
      }
      renderTabs()
      selectTab(selectedTab)
    }
    setTimeout(() => {
      if (mydrawer.api.drawer.mode != mydrawer.api.drawer.MODE_ACTIVE) {
        mydrawer.api.startEditing()
        rebindClearBtn()
      }
    }, 300)

    const btnDeleteCanvas = document.querySelectorAll('.btn-delete-canvas')

    btnDeleteCanvas.forEach(btn => {
      btn.classList.remove('active')
    })
  }

  const addNewTab = () => {
    saveCurrentTab()

    let lastSeq = 0
    if (lstWhiteboardData.length > 0) {
      lastSeq = lstWhiteboardData[lstWhiteboardData.length - 1].Seq + 1
    }

    lstWhiteboardData.push({WhiteboardId: 0, Seq: lastSeq, Data: ''})
    lstHistoryData.push(new WhiteboardHistoryData())
    selectedTab = lstWhiteboardData.length - 1

    clearDrawer()
    renderTabs()
    rebindClearBtn()
  }

  const selectTab = (idx: any) => {
    selectedTab = idx

    if (lstWhiteboardData.length > selectedTab) {
      clearDrawer()
      if (lstWhiteboardData[selectedTab].Data != '')
        mydrawer.api.loadCanvasFromData(
          JSON.parse(lstWhiteboardData[selectedTab].Data),
        )

      const whiteboardTabsLinks =
        document.querySelectorAll('#whiteboard_tabs a')

      whiteboardTabsLinks.forEach(link => {
        link.classList.remove('selected')
      })

      const specificTabLink = document.querySelector(
        '#whiteboard_tabs a[indextab="' + idx + '"]',
      )
      if (specificTabLink) {
        specificTabLink.classList.add('selected')
      }

      rebindClearBtn()

      if (lstHistoryData[selectedTab].UndoStack.length > 1)
        mydrawer._pluginsInstances['Undo'].enable()
      else mydrawer._pluginsInstances['Undo'].disable()

      if (lstHistoryData[selectedTab].RedoStack.length > 0)
        mydrawer._pluginsInstances['Redo'].enable()
      else mydrawer._pluginsInstances['Redo'].disable()

      if (mydrawer.activeDrawingTool) {
        const curtoolname = mydrawer.activeDrawingTool.name

        mydrawer.trigger(
          mydrawer.EVENT_DO_DEACTIVATE_TOOL,
          mydrawer._pluginsInstances[curtoolname],
        )

        mydrawer.trigger(
          mydrawer.EVENT_DO_ACTIVATE_TOOL,
          mydrawer._pluginsInstances[curtoolname],
        )
      }
    }
  }

  const rebindClearBtn = () => {
    const deleteCanvasLinks = document.querySelectorAll(
      'li.btn-delete-canvas a',
    )

    deleteCanvasLinks.forEach(link => {
      link.removeEventListener('click', showDelConfirm)
      link.addEventListener('click', showDelConfirm)
    })
  }

  const showDelConfirm = (event: any) => {
    event.preventDefault()
    const delConfirm = document.getElementById('delConfirm')
    if (delConfirm) {
      delConfirm.style.height = window.innerHeight + 'px'
      delConfirm.style.display = 'block'
    }
  }

  const onWindowResize = () => {
    const canvasWrapper = document.getElementById('whiteboard_canvaswrapper')
    if (canvasWrapper) {
      canvasWrapper.style.height = window.innerHeight - 120 + 'px'
    }
  }

  document.addEventListener('keyup', function (e: any) {
    if (
      document.documentElement.scrollLeft > 0 ||
      document.documentElement.scrollTop > 0
    ) {
      setTimeout(function () {
        document.documentElement.scrollLeft = 0
        document.documentElement.scrollTop = 0
        let textRight = window
          .getComputedStyle(e.target)
          .getPropertyValue('right')
        let textBottom = window
          .getComputedStyle(e.target)
          .getPropertyValue('bottom')
        textRight = textRight.replace('px', '')
        textBottom = textBottom.replace('px', '')
        const wrapper = document.getElementById('whiteboard_canvaswrapper')
        if (wrapper) {
          wrapper.scrollLeft += -parseInt(textRight, 10) + 20
          wrapper.scrollTop += -parseInt(textBottom, 10) + 40
        }
      }, 0)
    }
  })

  const canvasSize = {width: 1876, height: 834}

  const loadWhiteboard = useCallback(async () => {
    ;(window as any).DrawerJs.util.setDrawerFolderUrl('/drawerjs/')
    const drawerPlugins = [
      'Pencil',
      'Eraser',
      'Text',
      'Line',
      'ArrowOneSide',
      'ArrowTwoSide',
      'Triangle',
      'Rectangle',
      'Circle',
      'Selection',
      'Undo',
      'Redo',
      'Color',
      'ShapeBorder',
      'BrushSize',
      'OpacityOption',
      'StrokeWidth',
      'ShapeContextMenu',
      'CloseButton',
      'OvercanvasPopup',
      'OpenPopupButton',
      'ToggleVisibilityButton',
      'TextFontFamily',
      'TextFontSize',
      'TextFontWeight',
      'TextFontStyle',
      'TextDecoration',
    ]

    const {type, payload} = await dispatch(
      getWhiteboard({auth, attemptId: attemptId!}),
    )

    if (type === getWhiteboard.fulfilled.type) {
      mydrawer = new (window as any).DrawerJs.Drawer(
        null,
        {
          plugins: drawerPlugins,
          corePlugins: [
            'Zoom', // use null here if you want to disable Zoom completely
          ],
          pluginsConfig: {
            Image: {
              scaleDownLargeImage: true,
              maxImageSizeKb: 10240, //1MB
              cropIsActive: true,
            },
            BackgroundImage: {
              scaleDownLargeImage: true,
              maxImageSizeKb: 10240, //1MB
              imagePosition: 'center', // one of  'center', 'stretch', 'top-left', 'top-right', 'bottom-left', 'bottom-right'
              acceptedMIMETypes: ['image/jpeg', 'image/png', 'image/gif'],
              dynamicRepositionImage: true,
              dynamicRepositionImageThrottle: 100,
              cropIsActive: false,
            },
            Text: {
              editIconMode: false,
              editIconSize: 'large',
              defaultFont: 'Arial',
              defaultValues: {
                fontSize: 22,
                lineHeight: 1,
                textFontWeight: 'bold',
              },
              predefined: {
                fontSize: [
                  8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 40, 72,
                ],
                lineHeight: [1, 2, 3, 4, 6],
              },
            },
            Zoom: {
              enabled: true,
              showZoomTooltip: true,
              useWheelEvents: true,
              zoomStep: 0.05,
              defaultZoom: 1,
              maxZoom: 32,
              minZoom: 1,
              smoothnessOfWheel: 20,
              //Moving:
              enableMove: true,
              enableWhenNoActiveTool: true,
              enableButton: true,
            },
            Eraser: {
              brushSize: 50,
            },
            ShapeBorder: {
              color: '#000000',
              defaultBorder: 'Solid bold',
              filledColor: 'transparent',
            },
            Undo: {
              onClick: () => {
                const wData = lstHistoryData[selectedTab].Undo(
                  mydrawer.api.getCanvasAsJSON(),
                )
                if (wData != '') {
                  mydrawer.api.loadCanvasFromData(JSON.parse(wData))
                  if (mydrawer.activeDrawingTool) {
                    const curtoolname = mydrawer.activeDrawingTool.name
                    mydrawer.trigger(
                      mydrawer.EVENT_DO_DEACTIVATE_TOOL,
                      mydrawer._pluginsInstances[curtoolname],
                    )
                    mydrawer.trigger(
                      mydrawer.EVENT_DO_ACTIVATE_TOOL,
                      mydrawer._pluginsInstances[curtoolname],
                    )
                  }
                }
                if (lstHistoryData[selectedTab].UndoStack.length <= 1)
                  mydrawer._pluginsInstances['Undo'].disable()

                if (lstHistoryData[selectedTab].RedoStack.length > 0)
                  mydrawer._pluginsInstances['Redo'].enable()
              },
              onChanged: function (canvasData: any) {
                const jData = JSON.parse(canvasData)
                if (
                  lstHistoryData[selectedTab].Update(
                    JSON.stringify(cleanWhiteboardSavedData(jData)),
                  )
                ) {
                  mydrawer._pluginsInstances['Undo'].enable()
                  mydrawer._pluginsInstances['Redo'].disable()
                }
              },
            },
            Redo: {
              onClick: function () {
                const wData = lstHistoryData[selectedTab].Redo(
                  mydrawer.api.getCanvasAsJSON(),
                )

                if (wData != '') {
                  mydrawer.api.loadCanvasFromData(JSON.parse(wData))
                  if (mydrawer.activeDrawingTool) {
                    const curtoolname = mydrawer.activeDrawingTool.name
                    mydrawer.trigger(
                      mydrawer.EVENT_DO_DEACTIVATE_TOOL,
                      mydrawer._pluginsInstances[curtoolname],
                    )
                    mydrawer.trigger(
                      mydrawer.EVENT_DO_ACTIVATE_TOOL,
                      mydrawer._pluginsInstances[curtoolname],
                    )
                  }
                }

                if (lstHistoryData[selectedTab].UndoStack.length > 1)
                  mydrawer._pluginsInstances['Undo'].enable()

                if (lstHistoryData[selectedTab].RedoStack.length == 0)
                  mydrawer._pluginsInstances['Redo'].disable()
              },
            },
          },
          toolbars: {
            drawingTools: {
              position: 'custom',
              positionType: 'inside',
              customAnchorSelector: '#drawingTools',
              compactType: 'scrollable',
              hidden: false,
              toggleVisibilityButton: false,
              fullscreenMode: {
                position: 'top',
                hidden: false,
                toggleVisibilityButton: false,
              },
            },
            toolOptions: {
              position: 'custom',
              positionType: 'outside',
              compactType: 'scrollable',
              hidden: false,
              toggleVisibilityButton: false,
              fullscreenMode: {
                position: 'bottom',
                compactType: 'popup',
                hidden: false,
                toggleVisibilityButton: false,
              },
              customAnchorSelector: '#toolOptions',
            },
            settings: {
              position: 'custom',
              positionType: 'inside',
              compactType: 'scrollable',
              hidden: false,
              toggleVisibilityButton: false,
              fullscreenMode: {
                position: 'right',
                hidden: false,
                toggleVisibilityButton: false,
              },
              customAnchorSelector: '#drawerSettings',
            },
          },
          defaultImageUrl: '',
          defaultActivePlugin: {name: 'Pencil', mode: 'lastUsed'},
          debug: false,
          activeColor: '#000000',
          transparentBackground: false,
          align: 'floating', //one of 'left', 'right', 'center', 'inline', 'floating'
          lineAngleTooltip: {enabled: true, color: 'blue', fontSize: 15},
          exitOnOutsideClick: false,
          contentConfig: {
            saveAfterInactiveSec: 1,
            saveInHtml: false,
            saveCanvasData: function (canvasId: any, canvasData: any) {
              saveData(cleanWhiteboardSavedData(JSON.parse(canvasData)))
            },
          },
          wrapperId: 'whiteboard_canvaswrapper',
        },
        canvasSize.width,
        canvasSize.height,
      )

      const canvasEditor = document.getElementById('canvas-editor')
      const htmlFromMyDrawer = mydrawer.getHtml()
      if (canvasEditor) {
        canvasEditor.insertAdjacentHTML('beforeend', htmlFromMyDrawer)
      }

      mydrawer.onInsert()

      mydrawer.api.startEditing()

      if (payload.tabs.length > 0) {
        const hfData = payload.tabs.map((tab: WhiteboardData) => {
          return {Data: tab.payload}
        })

        lstWhiteboardData = hfData

        if (selectedTab > lstWhiteboardData.length - 1) selectedTab = 0

        mydrawer.api.loadCanvasFromData(
          JSON.parse(lstWhiteboardData[selectedTab].Data),
        )
      } else {
        lstWhiteboardData.push({WhiteboardId: 0, Seq: 0, Data: '{}'})
      }

      for (let i = 0; i < lstWhiteboardData.length; i++) {
        lstHistoryData.push(
          new WhiteboardHistoryData(JSON.parse(lstWhiteboardData[i].Data)),
        )
      }

      if (mydrawer.activeDrawingTool)
        mydrawer.activeDrawingTool.highlightButton()

      renderTabs()

      $('#whiteboard_add').click(function () {
        addNewTab()
        return false
      })

      $(window).resize(function () {
        onWindowResize()
      })
      //Prevent Right Click
      $('#whiteboard_wrap').bind('contextmenu', function (e) {
        e.preventDefault()
      })

      onWindowResize()

      rebindClearBtn()

      $('.btnDeleteNo').click(function () {
        $('#delConfirm').hide()
      })
      $('.btnDeleteYes').click(function () {
        deleteTab(selectedTab)
        $('#delConfirm').hide()
      })

      mydrawer.on(
        mydrawer.EVENT_ZOOM_CHANGE,
        (zoomProps: any, zoomData: any) => {
          const zoomAsText = 'Zoom: ' + Math.round(zoomData.zoom * 100) + '%'

          const zoomLabel = document.getElementById('zoomLabel')
          if (zoomLabel) {
            zoomLabel.textContent = zoomAsText
          }
        },
      )

      if (mydrawer._pluginsInstances['Undo'])
        mydrawer._pluginsInstances['Undo'].disable()
      if (mydrawer._pluginsInstances['Redo'])
        mydrawer._pluginsInstances['Redo'].disable()
    }
  }, [])

  useEffect(() => {
    loadWhiteboard()
  }, [loadWhiteboard])

  return (
    <div id="whiteboard-box">
      <div id="whiteboard_wrap">
        <div id="whiteboard_tabs"></div>
        <div id="whiteboard_add">+</div>
      </div>
      <div id="drawingTools" style={{height: '38px'}}></div>
      <div
        id="drawerSettings"
        style={{
          position: 'absolute',
          right: '0px',
          top: '24px',
          width: '130px',
          height: '38px',
        }}
      ></div>
      <div
        id="toolOptions"
        style={{
          position: 'relative',
          left: '0px',
          bottom: '0px',
          width: '100%',
          minHeight: '38px',
        }}
      ></div>
      <div
        id="zoomLabel"
        style={{
          position: 'absolute',
          right: '140px',
          top: '30px',
          height: '30px',
          fontWeight: 'bold',
        }}
      >
        Zoom: 100%
      </div>
      <div
        id="whiteboard_canvaswrapper"
        style={{
          position: 'relative',
          left: '0px',
          top: '3px',
          overflow: 'auto',
          width: '100%',
          height: '476px',
        }}
      >
        <div
          id="canvas-editor"
          style={{position: 'absolute', left: '0px', top: '0px'}}
        ></div>
      </div>
      <div
        id="delConfirm"
        style={{
          display: 'none',
          backgroundColor: '#f2f2f2',
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          zIndex: '3000',
        }}
      >
        <div
          style={{
            position: 'absolute',
            margin: 'auto',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: '250px',
            height: '150px',
            backgroundColor: '#ffffff',
            border: '1px solid #888888',
            boxShadow: '5px 5px #888888',
          }}
        >
          <div
            style={{
              fontWeight: 'bold',
              padding: '5px',
              borderBottom: '1px solid #888888',
            }}
          >
            Please Confirm
          </div>
          <div style={{padding: '10px'}}>Are you sure want to delete it?</div>
          <div style={{textAlign: 'center', marginTop: '10px'}}>
            <input
              className="btnDeleteYes"
              type="button"
              name="Yes"
              value="Yes"
            />
            <input className="btnDeleteNo" type="button" name="No" value="No" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Whiteboard
