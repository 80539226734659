import React, {useState, useEffect} from 'react'
import {Box, Typography, Link} from '@mui/material'
import {Button} from '../../../button'
import {OptionsBox} from './styles'
import {v4 as uuidv4} from 'uuid'
import {useAppDispatch} from '../../../../redux/configureStore'
import {useAuth} from '../../../../components/AuthProvider'
import {
  Subscription,
  getActiveSubscriptions,
  selectSubscriptions,
} from '../../../../redux/subscription'
import {toggleErrorDialog} from '../../../../redux/config'
import SubscriptionModal from '../../SubscriptionModal'
import {useSelector} from 'react-redux'
import HubSpotForm from '../../../HubSpotForm'
import {StyledTitle} from '../../../../styles/global'

const View = () => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const activeSubscriptions = useSelector(selectSubscriptions)
  const [cancelFormOpen, setCancelFormOpen] = useState(false)
  const [subscriptionId, setSubscriptionId] = useState('')

  const fetchSubscriptions = async () => {
    try {
      // get active subscriptions
      const {type} = await dispatch(getActiveSubscriptions({auth}))

      if (type === getActiveSubscriptions.rejected.type) {
        throw new Error('Unable to fetch subscriptions')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to fetch your active subscriptions, please try reloading the page. If the issue persists contact our support team.',
        }),
      )
    }
  }

  const handleCancelSubscription = (subscriptionId: string) => {
    setSubscriptionId(subscriptionId)
    setCancelFormOpen(true)
  }

  const generateSubscriptionMessage = (subscription: Subscription) => {
    if (subscription.cancel_at_period_end) {
      return `which will end at `
    } else {
      return `which will renew at `
    }
  }

  const displayDate = (subscription: Subscription) => {
    if (subscription.cancel_at_period_end) {
      return ` ${subscription.cancel_at}. `
    } else {
      return `${subscription.current_period_end}`
    }
  }

  const displayStatus = (subscription: Subscription) => {
    if (subscription.cancel_at_period_end) {
      return `a canceled`
    } else {
      return `an active`
    }
  }

  useEffect(() => {
    fetchSubscriptions()
  }, [])

  return (
    <>
      <Box>
        <StyledTitle variant={'h2'} style={{marginBottom: '32px'}}>
          Your Subscriptions
        </StyledTitle>
        <Box>
          {activeSubscriptions.length > 0 ? (
            <>
              {activeSubscriptions.map(subscription => (
                <Box key={uuidv4()}>
                  <Typography variant={'body1'}>
                    You have {displayStatus(subscription)}
                    <b> {subscription.name} </b>{' '}
                    {generateSubscriptionMessage(subscription)}
                    <b>{displayDate(subscription)}</b>
                  </Typography>
                  {subscription.cancel_at_period_end ? (
                    <Typography>
                      If you would like to reactive your subscription please
                      contact customer support.
                    </Typography>
                  ) : null}

                  <OptionsBox>
                    {subscription.name.includes('ARE 5.0') ? (
                      <Button
                        color={'primary'}
                        children={'Change Subscription'}
                        onClick={() => setOpen(true)}
                      />
                    ) : null}

                    {!subscription?.cancel_at_period_end ? (
                      <Button
                        color={'primary'}
                        children={'Cancel Subscription'}
                        onClick={() =>
                          handleCancelSubscription(subscription.id)
                        }
                      />
                    ) : null}
                  </OptionsBox>
                </Box>
              ))}
              {cancelFormOpen ? (
                <HubSpotForm
                  open={cancelFormOpen}
                  handleOpen={setCancelFormOpen}
                  subscriptionId={subscriptionId}
                />
              ) : null}
            </>
          ) : (
            <Typography variant={'body1'}>
              You do not have any active or canceled subscriptions.{' '}
              <Link href={`${process.env.REACT_APP_PRICING_PAGE}`}>
                Sign up for a new one
              </Link>{' '}
              to get started!
            </Typography>
          )}
        </Box>
        <SubscriptionModal open={open} setOpen={setOpen} />
      </Box>
    </>
  )
}

export default View
