import React, {useEffect} from 'react'
import View from './view'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAppDispatch} from '../../redux/configureStore'
import {handleSSO} from '../../redux/discourse/sso'
import {useAuth} from '../../components/AuthProvider'

const Community = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const auth = useAuth()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const sso = searchParams.get('sso')
  const sig = searchParams.get('sig')

  const handleRedirect = async () => {
    try {
      if (sso && sig) {
        const {type, payload} = await dispatch(
          handleSSO({auth, payload: sso, sig}),
        )

        // redirect to BKS login and redirect back to this page after login
        if (type === handleSSO.rejected.type) {
          navigate('/login', {
            state: {from: location.pathname + location.search},
          })
        } else {
          // redirect to community site
          window.location.href = payload.redirection
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (sso && sig) {
      handleRedirect()
    }
  }, [])

  return (
    <>
      <View />
    </>
  )
}

export default Community
