import React, {useState, useEffect} from 'react'
import {Box, Alert, LinearProgress, Typography} from '@mui/material'
import {Lesson} from '../../redux/lessons'
import {Link as RouterLink} from 'react-router-dom'
import {
  ButtonsBox,
  ExamBox,
  FixedBottom,
  FlexBox,
  PreExamBox,
  QuestionId,
  Title,
  LinkButton,
  LinearProgressBox,
} from './styles'
import {Button} from '../button'
import {Exam, selectExamStatus} from '../../redux/exams'
import {useSelector} from 'react-redux'
import renderHTML from '../../utils/renderHTML'
import ExamHotspot from '../ExamHotspot'
import Loading from '../Loading'
import ExamDrag from '../ExamDrag'
import ExamCheckall from '../ExamCheckall'
import ExamMultiple from '../ExamMultiple'
import ExamBlank from '../ExamBlank'
import {useAuth} from '../AuthProvider'
import {Link} from 'react-router-dom'
import {LoadingStatuses} from '../types/LoadingStatusses'
import {useAppDispatch} from '../../redux/configureStore'
import {selectMemberAccess, getUpgradePrice} from '../../redux/members'
import LockIcon from '@mui/icons-material/Lock'

type Props = {
  lesson?: Lesson
  onStartExam: () => void
  onEndExam: () => void
  exam?: Exam
  currentQuestion: number
  onChangeCurrentQuestion: (value: number) => void
  answers: any
  onChangeAnswers: (value: any) => void
  isSubmitting: boolean
  onSubmit: (questionId: string) => void
  onChangeIsReviewing: () => void
  isReviewing: boolean
  getNextUrl: () => string
}

const View = ({
  lesson,
  onStartExam,
  onEndExam,
  exam,
  currentQuestion,
  onChangeCurrentQuestion,
  answers,
  onChangeAnswers,
  isSubmitting,
  onSubmit,
  onChangeIsReviewing,
  isReviewing,
  getNextUrl,
}: Props) => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const question = exam?.questions[currentQuestion]

  const [shouldStick, setShouldStick] = useState(false)

  const access = useSelector(selectMemberAccess)

  const hasAccess =
    access && Object.keys(access).length !== 0
      ? Number(access.are?.videos?.value) === 1
      : false

  const examStatus = useSelector(selectExamStatus)

  const questionTitle = question?.title.split('-')
  const questionId = questionTitle && questionTitle[3] ? questionTitle[3] : ''

  const handleSubmit = () => {
    if (question?.id) {
      onSubmit(question?.id)

      if (exam && currentQuestion === exam?.questions.length - 1) {
        onEndExam()
      }

      onChangeCurrentQuestion(currentQuestion + 1)
    }

    if (exam?.questions && currentQuestion === exam?.questions.length - 1) {
      localStorage.clear()
    }
  }

  const selectAnswer = (value: any) => {
    const stateAnswers = [...answers]
    stateAnswers[currentQuestion] = value
    onChangeAnswers(stateAnswers)
  }

  const progressBar = exam?.questions ? 100 / exam?.questions.length : 0

  const handleScroll = () => {
    const footer = document.getElementById('referral-banner')
    const menu = document.getElementById('quiz-fixed-menu')
    if (!footer || !menu) return

    const footerTop = footer.offsetTop
    const scrollTop = window.scrollY || document.documentElement.scrollTop

    if (scrollTop + window.innerHeight - 40 >= footerTop) {
      setShouldStick(false)
    } else if (
      !shouldStick &&
      scrollTop + window.innerHeight - 40 < footerTop
    ) {
      setShouldStick(true)
    }
  }

  const handleUpgradeUrl = async (e:any) => {
    e.preventDefault();
    const resp = await dispatch(getUpgradePrice({auth}))
    console.log(resp.payload)
    window.location.href = String(resp.payload.priceId).length > 0 ? 'https://checkout.blackspectacles.com/' + String(resp.payload.priceId) : '#'
  }
  
  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [shouldStick, exam, currentQuestion])

  const renderQuestion = (type: string) => {
    if (type === 'checkall') {
      return (
        <ExamCheckall
          exam={exam}
          currentQuestion={currentQuestion}
          currentAnswer={question?.currentAnswer}
          answer={answers[currentQuestion]}
          isReviewing={isReviewing}
          onSelect={selectAnswer}
        />
      )
    } else if (type === 'hotspot') {
      return (
        <ExamHotspot
          exam={exam}
          currentQuestion={currentQuestion}
          currentAnswer={question?.currentAnswer}
          answer={answers[currentQuestion]}
          isReviewing={isReviewing}
          onSelect={selectAnswer}
        />
      )
    } else if (type === 'drag') {
      return (
        <ExamDrag
          exam={exam}
          currentQuestion={currentQuestion}
          currentAnswer={question?.currentAnswer}
          answer={answers[currentQuestion]}
          isReviewing={isReviewing}
          onSelect={selectAnswer}
        />
      )
    } else if (type === 'multiple') {
      return (
        <ExamMultiple
          exam={exam}
          currentQuestion={currentQuestion}
          currentAnswer={question?.currentAnswer}
          answer={answers[currentQuestion]}
          isReviewing={isReviewing}
          onSelect={selectAnswer}
        />
      )
    } else if (type === 'blank') {
      return (
        <ExamBlank
          exam={exam}
          currentQuestion={currentQuestion}
          currentAnswer={question?.currentAnswer}
          answer={answers[currentQuestion]}
          isReviewing={isReviewing}
          onSelect={selectAnswer}
        />
      )
    }
  }

  if (isSubmitting)
    return (
      <ExamBox>
        <Loading />
      </ExamBox>
    )

  if (examStatus === LoadingStatuses.Loading)
    return (
      <ExamBox>
        <Loading />
      </ExamBox>
    )

  return (
    <>
      {auth.isAuthenticated && hasAccess ? (
        <>
          {!exam ? (
            <PreExamBox>
              Test your understanding of the content you just watched by taking
              this {lesson?.questions} question quiz.
              <Button
                onClick={onStartExam}
                color="secondary"
                children="START QUIZ"
                style={{width: '190px'}}
              />
            </PreExamBox>
          ) : (
            <>
              {currentQuestion >= exam.questions.length ? (
                <PreExamBox>
                  <Title variant="h2">
                    You got {exam.correctQuestions} out of{' '}
                    {exam.questions.length} correct -{' '}
                    {Math.floor(
                      (exam.correctQuestions / exam.questions.length) * 100,
                    )}
                    %
                  </Title>
                  Quizzing yourself as part of your study plan is an excellent
                  way to test your knowledge and help you retain information.
                  Don’t feel you retained as much information on{' '}
                  {questionTitle ? questionTitle[0] : ''} as you’d like? Try
                  rewatching the previous videos and retake this quiz — the act
                  of recalling the information will help it stick!
                  <ButtonsBox>
                    <Button
                      onClick={onChangeIsReviewing}
                      color="primary"
                      children="Review Answers"
                      style={{width: '190px'}}
                    />
                    <LinkButton
                      component={RouterLink}
                      to={`${getNextUrl()}`}
                      style={{width: '220px'}}
                    >
                      {getNextUrl() === '/' ? <>Dashboard</> : <>Next Video</>}
                    </LinkButton>
                  </ButtonsBox>
                </PreExamBox>
              ) : (
                <FlexBox>
                  <ExamBox>
                    {isReviewing && (
                      <>
                        {question?.isAnswerCorrect ? (
                          <Alert severity="success">Correct!</Alert>
                        ) : (
                          <Alert severity="error">Incorrect!</Alert>
                        )}
                      </>
                    )}
                    {question?.questionTypeName !== 'drag' &&
                      question?.questionTypeName !== 'hotspot' &&
                      question?.backgroundImage && (
                        <img
                          src={question?.backgroundImage}
                          alt="Question"
                          style={{maxWidth: '100%'}}
                        />
                      )}

                    {renderHTML(question?.stem ?? '')}
                    {renderHTML(question?.body ?? '')}

                    {renderQuestion(question?.questionTypeName!)}

                    {isReviewing && question?.reference && (
                      <Typography variant="body1" fontSize={'14px'}>
                        Reference: <em>{question?.reference}</em>
                      </Typography>
                    )}

                    <QuestionId>{questionId}</QuestionId>
                  </ExamBox>

                  <FixedBottom id="quiz-fixed-menu" $isSticked={shouldStick}>
                    <Button
                      children={'PREVIOUS'}
                      color="secondary"
                      disabled={currentQuestion === 0}
                      onClick={() =>
                        onChangeCurrentQuestion(currentQuestion - 1)
                      }
                    />
                    <LinearProgressBox>
                      <LinearProgress
                        variant="determinate"
                        value={progressBar * (currentQuestion + 1)}
                        style={{width: '100%', height: '100%'}}
                      />
                      <Box
                        style={{
                          height: '100%',
                          width: '100%',
                          pointerEvents: 'none',
                          position: 'absolute',
                          top: 0,
                          transform: `translateX(calc(${
                            progressBar * (currentQuestion + 1)
                          }% - 100% - 24px))`,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {currentQuestion + 1}
                      </Box>
                    </LinearProgressBox>
                    <Button
                      children={
                        currentQuestion === exam.questions.length - 1 &&
                        !isReviewing
                          ? 'SUBMIT'
                          : 'NEXT'
                      }
                      onClick={() =>
                        isReviewing
                          ? onChangeCurrentQuestion(currentQuestion + 1)
                          : handleSubmit()
                      }
                      color="secondary"
                    />
                  </FixedBottom>
                </FlexBox>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <PreExamBox>
            <Box>
              You have reached our paid quizzes.
              <br />
              <br />
              {!auth.isAuthenticated ? (
                <>
                  You can either <Link to={'/login'}>log in</Link> to view it or{' '}
                  <a
                    href="https://www.blackspectacles.com/pricing"
                    target="_blank"
                  >
                    learn more about our memberships
                  </a>
                  .
                </>
              ) : (
                <>
                  <span>
                    <Link to={'#'} onClick={handleUpgradeUrl}>
                      <LockIcon /> Upgrade your membership
                    </Link>{' '}
                    to get access this material.
                  </span>
                </>
              )}
            </Box>
          </PreExamBox>
        </>
      )}
    </>
  )
}

export default View
