const convertDuration = (duration: string) => {
  let seconds = 0

  const timeComp = duration.split(' ')
  timeComp.forEach((el: string) => {
    el = el.replace(', ', '')
    if (el.includes('hrs')) {
      const val: number = parseInt(el.replace('hrs', ''))
      seconds += val * 3600
    } else if (el.includes('hr')) {
      const val: number = parseInt(el.replace('hr', ''))
      seconds += val * 3600
    } else if (el.includes('h')) {
      const val: number = parseInt(el.replace('h', ''))
      seconds += val * 3600
    } else if (el.includes('m')) {
      const val: number = parseInt(el.replace('m', ''))
      seconds += val * 60
    } else if (el.includes('s')) {
      const val: number = parseInt(el.replace('s', ''))
      seconds += val
    }
  })

  return seconds
}

export default convertDuration
