import styled from 'styled-components'
import {Box} from '@mui/material'
import Bg from '../../images/backgrounds/about.jpg'
import theme from '../../styles/theme'

export const ActivationBox = styled(Box)`
  width: 100%;
  min-height: 60vh;
  background: url(${Bg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  box-sizing: border-box;

  ${theme.breakpoints.down('md')} {
    min-height: 70vh;
    padding: 0 20px;
  }
`

export const StyledBox = styled(Box)`
  width: 50%;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ButtonBox = styled(Box)`
  width: 100%;
  text-align: center;
  margin-top: 16px;

  && button {
    width: 50%;

    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  }
`

export const CheckBoxRow = styled(Box)`
  width: 100%;
  text-align: center;
  margin-top: 16px;
  display: flex;
  align-items: center;
  color: #fff;
  font-family:
    Freight Disp Pro,
    sans-serif;
  font-style: italic;
  cursor: pointer;

  && span {
    font-size: 18px;
  }
`

export const StyledLinkBox = styled.span`
  && svg {
    font-size: 16px;
    font-style: italic !important;
    vertical-align: text-top;
  }
`
