import React, {useCallback} from 'react'
import View from './view'
import {VideoGroup, getVideo} from '../../redux/videos'
import {useAppDispatch} from '../../redux/configureStore'
import {useAuth} from '../AuthProvider'
import {useSelector} from 'react-redux'
import {selectCourse} from '../../redux/courses'

type Props = {
  currentGroup: VideoGroup
  nextGroup?: VideoGroup
  currentIndex: number
  onChangeVideoEnded: (value: boolean) => void
  videoId: string
}

const VideoEnded = ({
  currentGroup,
  nextGroup,
  currentIndex,
  onChangeVideoEnded,
  videoId,
}: Props) => {
  const auth = useAuth()
  const dispatch = useAppDispatch()

  const course = useSelector(selectCourse)

  const software = course?.tags?.find(
    item => item.category === 'SOFTWARE_COURSE',
  )

  const isSoftware = software ? true : false

  const loadVideo = useCallback(async () => {
    try {
      await dispatch(getVideo({auth, videoId, isSoftware}))
    } catch (e) {
      console.error(e)
    }
  }, [dispatch, videoId])

  return (
    <View
      currentGroup={currentGroup}
      nextGroup={nextGroup}
      currentIndex={currentIndex}
      onChangeVideoEnded={onChangeVideoEnded}
      loadVideo={loadVideo}
    />
  )
}

export default VideoEnded
