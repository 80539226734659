import React, {useRef, useEffect, useContext} from 'react'
import {
  RightBox,
  VideosBox,
  VideoContent,
  VideoTitle,
  Tabs,
  CourseContent,
  TabContent,
  ContentBox,
  SocialBox,
  MobileBox,
  PlayerBox,
  LoggedOutBox,
  VideoTitleBox,
  VideoTime,
} from './styles'
import {
  Video as VideoModel,
  VideoGroup,
  selectVideoStatus,
} from '../../redux/videos'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {Course, selectCourseStatus} from '../../redux/courses'
import ReactPlayerLoader from '@brightcove/react-player-loader'
import {Button} from '../../components/button'
import renderHTML from '../../utils/renderHTML'
import {LectureSlides} from '../../constants/lectureSlides'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import {FacebookIcon, LinkedinIcon, TwitterIcon} from '../../styles/global'
import CloseIcon from '@mui/icons-material/Close'
import {LoadingStatuses} from '../../components/types/LoadingStatusses'
import Loading from '../../components/Loading'
import convertDuration from '../../utils/convertDuration'
import VideoEnded from '../../components/VideoEnded'
import VideoSideBar from '../../components/VideoSideBar'
import Cookies from 'universal-cookie'
import {useAuth} from '../../components/AuthProvider'
import {LessonPlan} from '../../redux/lessons'
import hoursMinutes from '../../utils/hoursMinutes'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import {SegmentContext} from '../../context'
import { getUpgradePrice } from '../../redux/members'
import {useAppDispatch} from '../../redux/configureStore'
import LockIcon from '@mui/icons-material/Lock'

type Props = {
  videoId: string
  video?: VideoModel
  course?: Course
  onChangeTab: (value: string) => void
  currentTab: string
  mobileOpen: boolean
  videoEnded: boolean
  onChangeMobileMenu: (value: boolean) => void
  onUpdate: (value: number) => void
  onChangeVideoEnded: (value: boolean) => void
  lesson?: LessonPlan
  hasAccess?: boolean
  status?: string
  nextGroup?: VideoGroup
  currentGroup?: VideoGroup
  previousGroup?: VideoGroup
  currentIndex?: number
}

const View = ({
  videoId,
  video,
  course,
  currentTab,
  onChangeTab,
  mobileOpen,
  onChangeMobileMenu,
  onUpdate,
  videoEnded,
  onChangeVideoEnded,
  lesson,
  hasAccess,
  status,
  nextGroup,
  currentGroup,
  previousGroup,
  currentIndex,
}: Props) => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const cookies = new Cookies()
  const {analytics} = useContext(SegmentContext)

  const courseStatus = useSelector(selectCourseStatus)
  const videoStatus = useSelector(selectVideoStatus)

  const software = course?.tags?.find(
    item => item.category === 'SOFTWARE_COURSE',
  )

  const isSoftware = software ? true : false

  const playerRef: any = useRef()

  let nextEvent = 10

  const onSuccess = (player: any) => {
    const myPlayer = player.ref
    const videoDuration = convertDuration(video?.duration ?? '0')

    //EVENT THROWN WHEN THE VIDEO ENDS
    myPlayer.on('ended', function () {
      const lines = document.querySelectorAll('#transcript .line')
      lines.forEach(function (line) {
        line.classList.remove('transcriptActive')
      })

      onChangeVideoEnded(true)
    })

    // EVENT THROWN WHEN THE VIDEO STARTS
    myPlayer.on('loadstart', function () {
      myPlayer.currentTime(0)

      myPlayer.playbackRate(
        Number(localStorage.getItem('bksPlaybackRate')) || 1,
      )
    })

    //EVENT THROWN EVERY SECOND WHILE THE VIDEO IS PLAYING
    myPlayer.on('timeupdate', function () {
      const time = myPlayer.currentTime()
      const link = document.querySelector(
        '#transcript .line[data-time="' + Math.round(time) + '"]',
      )
      if (link) {
        const lines = document.querySelectorAll('#transcript .line')
        lines.forEach(function (line) {
          line.classList.remove('transcriptActive')
        })

        link.classList.add('transcriptActive')
      }

      const percent = Math.round((time / videoDuration) * 100)
      if (percent % 10 == 0) {
        if (percent >= nextEvent) {
          updateProgress(percent)
        }
      }

      const playbackRate = myPlayer.playbackRate()
      if (playbackRate !== localStorage.getItem('bksPlaybackRate')) {
        localStorage.setItem('bksPlaybackRate', playbackRate)
      }
    })

    let initialSetupDone = false

    const setCaptions = (enabled: any) => {
      const tracks = myPlayer.textTracks()
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].kind === 'captions' || tracks[i].kind === 'subtitles') {
          tracks[i].mode = enabled ? 'showing' : 'hidden'
        } else {
          tracks[i].mode = 'disabled'
        }
      }
    }

    myPlayer.on('loadedmetadata', () => {
      const captionsEnabled = localStorage.getItem('captionsEnabled') === 'true'
      setCaptions(captionsEnabled)
      initialSetupDone = true
    })

    myPlayer.textTracks().addEventListener('change', () => {
      if (initialSetupDone) {
        const tracks = myPlayer.textTracks()
        let captionsEnabled = false
        for (let i = 0; i < tracks.length; i++) {
          if (tracks[i].kind === 'captions' || tracks[i].kind === 'subtitles') {
            if (tracks[i].mode === 'showing') {
              captionsEnabled = true
              break
            }
          }
        }
        localStorage.setItem('captionsEnabled', String(captionsEnabled))
      }
    })
  }

  useEffect(() => {
    const transcriptLines = document.querySelectorAll('#transcript .line')

    transcriptLines.forEach(line => {
      line.addEventListener('click', () => {
        transcriptLines.forEach(line => {
          line.classList.remove('transcriptActive')
        })

        line.classList.add('transcriptActive')

        playerRef.current?.player.currentTime(
          Number(line.getAttribute('data-time')),
        )
      })
    })
  }, [video, currentTab])

  const updateProgress = (percent: number) => {
    nextEvent = percent + 10
    onUpdate(percent)
  }

  const trackClick = () => {
    if (analytics) {
      analytics.track('file_download')
    }
  }

  const handleUpgradeUrl = async (e:any) => {
    e.preventDefault();
    const resp = await dispatch(getUpgradePrice({auth}))
    console.log(resp.payload)
    window.location.href = String(resp.payload.priceId).length > 0 ? 'https://checkout.blackspectacles.com/' + String(resp.payload.priceId) : '#'
  }

  return (
    <VideosBox>
      <VideoSideBar
        id={videoId}
        course={course!}
        mobileOpen={mobileOpen}
        currentGroup={currentGroup}
        previousGroup={previousGroup}
        nextGroup={nextGroup}
        currentIndex={currentIndex}
      />
      {(auth.isAuthenticated && hasAccess) || status === 'free' ? (
        <RightBox>
          {(courseStatus === LoadingStatuses.Loading ||
            courseStatus === LoadingStatuses.Idle ||
            videoStatus === LoadingStatuses.Idle ||
            videoStatus === LoadingStatuses.Loading) &&
          !videoEnded ? (
            <Loading />
          ) : (
            <>
              <MobileBox onClick={() => onChangeMobileMenu(!mobileOpen)}>
                View Chapter
                {mobileOpen && (
                  <CloseIcon
                    className="x-button"
                    style={{
                      height: 32,
                      width: 32,
                      color: `#fff`,
                    }}
                  />
                )}
              </MobileBox>

              <PlayerBox>
                {videoEnded ? (
                  <VideoEnded
                    videoId={videoId}
                    currentGroup={currentGroup!}
                    nextGroup={nextGroup}
                    currentIndex={currentIndex!}
                    onChangeVideoEnded={onChangeVideoEnded}
                  />
                ) : (
                  <ReactPlayerLoader
                    ref={playerRef}
                    id={'player'}
                    accountId="5454530067001"
                    playerId={process.env.REACT_APP_BRIGHTCOVE_ID}
                    videoId={video?.brightcoveId}
                    onSuccess={onSuccess}
                    options={{
                      responsive: true,
                      autoplay: cookies.get('bks-autoplay')
                        ? cookies.get('bks-autoplay')
                        : false,
                      muted: false,
                      aspectRatio: '4:3',
                      playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
                    }}
                  />
                )}
              </PlayerBox>
              <VideoContent>
                <VideoTitleBox>
                  <VideoTitle variant="h1">{video?.title}</VideoTitle>
                  <VideoTime>
                    <AccessTimeIcon
                      style={{color: '#90A4AE'}}
                      fontSize="medium"
                    />{' '}
                    {video?.duration}
                  </VideoTime>
                </VideoTitleBox>

                <Tabs>
                  <Button
                    children={'ABOUT COURSE'}
                    onClick={() => onChangeTab('about')}
                    color={
                      currentTab === 'about' ? 'primary' : 'whiteBlueBorder'
                    }
                    hoverColor={'primary'}
                  />

                  <Button
                    children={'VIEW TRANSCRIPT'}
                    onClick={() => onChangeTab('transcript')}
                    color={
                      currentTab === 'transcript'
                        ? 'primary'
                        : 'whiteBlueBorder'
                    }
                    hoverColor={'primary'}
                  />

                  <Button
                    children={'LECTURE SLIDES'}
                    onClick={() => onChangeTab('download')}
                    color={
                      currentTab === 'download' ? 'primary' : 'whiteBlueBorder'
                    }
                    hoverColor={'primary'}
                  />

                  <Button
                    children={'SHARE'}
                    onClick={() => onChangeTab('share')}
                    color={
                      currentTab === 'share' ? 'primary' : 'whiteBlueBorder'
                    }
                    hoverColor={'primary'}
                  />
                </Tabs>

                <ContentBox>
                  <CourseContent>
                    From the course:
                    <br />
                    <Link
                      to={
                        course?.abbreviation
                          ? `/courses/${currentGroup?.courseId}`
                          : '/practical-applications'
                      }
                    >
                      {course?.abbreviation ? (
                        <>{currentGroup?.title}</>
                      ) : (
                        <>
                          {currentGroup?.title
                            .replace('ARE 5.0', '')
                            .replace('Exam Prep', '')}
                        </>
                      )}
                    </Link>
                    {lesson && (
                      <>
                        <br />
                        <br />
                        Duration: {hoursMinutes(lesson.duration, true)}
                        <br />
                        <br />
                        Author: {lesson?.author}
                      </>
                    )}
                  </CourseContent>
                  <TabContent>
                    {currentTab === 'about' && (
                      <>{renderHTML(lesson?.description!)}</>
                    )}

                    {currentTab === 'transcript' && (
                      <div id="transcript">
                        {renderHTML(video?.transcriptFormatted!)}
                      </div>
                    )}

                    {currentTab === 'download' && (
                      <>
                        <Link
                          to={LectureSlides[course?.abbreviation!]}
                          target="_blank"
                          onClick={trackClick}
                        >
                          Download lecture slides
                        </Link>
                      </>
                    )}

                    {currentTab === 'share' && (
                      <SocialBox>
                        <FacebookShareButton url={window.location.href}>
                          <FacebookIcon />
                        </FacebookShareButton>

                        <TwitterShareButton
                          url={`${video?.title} ${window.location.href} via @blackspectacles`}
                        >
                          <TwitterIcon />
                        </TwitterShareButton>

                        <LinkedinShareButton url={window.location.href}>
                          <LinkedinIcon />
                        </LinkedinShareButton>
                      </SocialBox>
                    )}
                  </TabContent>
                </ContentBox>
              </VideoContent>
            </>
          )}
        </RightBox>
      ) : (
        <RightBox>
          <MobileBox onClick={() => onChangeMobileMenu(!mobileOpen)}>
            View Chapter
            {mobileOpen && (
              <CloseIcon
                className="x-button"
                style={{
                  height: 32,
                  width: 32,
                  color: `#fff`,
                }}
              />
            )}
          </MobileBox>
          <LoggedOutBox>
            <span>
              You have reached one of our paid video tutorials for the course:
            </span>
            <Link to={`/courses/${course?.id}`}>
              {course?.abbreviation ? (
                <>ARE 5.0 {course?.title} Exam Prep</>
              ) : (
                <>{course?.title}</>
              )}
            </Link>
            {!hasAccess && auth.isAuthenticated ? (
              <span>
                <a href="#"
                   onClick={handleUpgradeUrl}
                >
                  <LockIcon /> Upgrade your membership
                </a>{' '}
                to get access to this material.
              </span>
            ) : (
              <span>
                You can either <Link to={'/login'}>log in</Link> to view it or{' '}
                <a
                  href="https://www.blackspectacles.com/pricing"
                  target="_blank"
                >
                  learn more about our memberships
                </a>
                .
              </span>
            )}
          </LoggedOutBox>
        </RightBox>
      )}
    </VideosBox>
  )
}

export default View
