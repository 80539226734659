import React from 'react'
import styled from 'styled-components'
import {TextField, TextFieldProps} from '@mui/material'
import theme, {colors} from '../../styles/theme'
import {useWindowDimensions} from '../../hooks/useWindowDimensions'

const Field = styled(TextField)<Props>`
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  && {
    .MuiInputLabel-filled {
      transform: translate(12px, 14px) scale(1);
    }

    .MuiInputLabel-shrink {
      transform: translate(12px, 5px) scale(0.7);
    }

    .MuiFormHelperText-contained {
      margin-right: 14px;
      margin-left: 0px !important;
    }

    .MuiFormHelperText-root.Mui-error {
      color: #f44c49;
      font-size: 14px;
      margin-bottom: -5px;
      font-weight: 400;
    }

    .MuiFilledInput-root.Mui-error:after {
      border-bottom-color: #f44c49;
    }

    input {
      padding: 12.5px 0 12.5px 13px;]
    }

    fieldset {
        border: 2px solid ${props =>
          props.borderColor ? props.borderColor : '#1377d4'};
    }

    .Mui-focused > fieldset {
        border-color: ${props =>
          props.borderColor ? props.borderColor : colors.blue1} !important;
    }

    legend {
        display: none;
    }
  }
`

export type Props = TextFieldProps & {
  errorMessage?: string | null
  inputTestId?: string
  presetValue?: string
  borderColor?: string
  styles?: any
}

const View = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  inputTestId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  presetValue,
  errorMessage,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  borderColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  InputLabelProps,
  styles,
  ...props
}: Props) => {
  const {width} = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  return (
    <Field
      {...props}
      error={!!errorMessage}
      style={!errorMessage ? {marginBottom: '0'} : {}}
      helperText={errorMessage || ''}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
        style: {
          width: '100%',
          color: '#2d2d2d',
          fontSize: isMobile ? '14px' : '16px',
          marginTop: isMobile ? '4px' : '',
          transform: 'translate(0px, -26px)',
          lineHeight: '22px',
        },
      }}
      InputProps={{
        style: {
          ...styles,
          ...{
            fontSize: isMobile ? '14px' : '16px',
            height: '48px',
            backgroundColor: errorMessage
              ? 'rgba(244, 76, 73, 0.08)'
              : 'transparent',
          },
        },
        ...props.InputProps,
      }}
    />
  )
}

export default View
