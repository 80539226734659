import styled from 'styled-components'
import {Box, Typography} from '@mui/material'
import theme from '../../styles/theme'

export const ExamsBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 80px;
`

export const DetailsBox = styled(Box)`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px #eee solid;
`

export const ExamsFlexBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 64px;
  row-gap: 80px;
  column-gap: 20px;
  margin-bottom: 80px;
`

export const ExamItem = styled(Box)`
  flex: 40%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 32px;
  box-sizing: border-box;

  && button {
    width: 200px;
  }
  &:after {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 80%;
    background: #eee;
  }
`

export const Title = styled(Typography)`
  font-size: 24px !important;
`

export const AttemptsBox = styled(Box)`
  ${theme.breakpoints.down('md')} {
    align-self: flex-start;
  }
`

export const AttemptsTitle = styled(Typography)`
  color: #6b6b6b !important;
`

export const AttempDetails = styled(Box)`
  && ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

  && li {
    margin-top: 8px;
  }
`

export const LoggedOutBox = styled(Box)`
  display: flex;
  gap: 4px;
  font-family: Public Sans;
  flex-flow: column;
  padding: 40px 0;
  align-items: center;
  gap: 32px;

  && svg {
    font-size: 16px;
    font-style: italic !important;
    vertical-align: text-top;
  }
`

export const ContinueExamBox = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 40px;
`

export const InProgressTitle = styled(Typography)`
  font-family: Public Sans !important;
  text-transform: none !important;
  font-style: italic !important;
  color: #b0bec5 !important;
  letter-spacing: 0 !important;
  font-size: 18px !important;
`
