import React from 'react'
import {ActivationBox, ButtonBox, StyledBox} from './styles'
import BksLogo from '../../images/logo-white.svg'
import {Alert, Typography} from '@mui/material'
import {Button} from '../../components/button'
import {Formik, FormikHelpers, Form} from 'formik'
import * as Yup from 'yup'
import TextFieldFormik from '../../components/TextFieldFormik'
import {StyledLogo} from '../../components/Hero/styles'
import {Link} from 'react-router-dom'
import {SuccessDialog} from '../../components/SuccessDialog'

type Props = {
  onSubmit: (values: FormProps) => void
  isSuccessDialogOpen: boolean
  errorMessage: string
  handleChangeIsSuccessDialogOpen: (value: boolean) => void
}

export type FormProps = {
  email: string
}

const View = ({
  onSubmit,
  isSuccessDialogOpen,
  errorMessage,
  handleChangeIsSuccessDialogOpen,
}: Props) => {
  const initialValues: FormProps = {
    email: '',
  }

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('You must add a valid email address'),
  })

  const handleSubmit = (
    values: FormProps,
    actions: FormikHelpers<FormProps>,
  ) => {
    onSubmit(values)
    actions.setSubmitting(false)
  }

  return (
    <ActivationBox>
      <Link to={'/'}>
        <StyledLogo src={BksLogo} alt="Black Spectacles" />
      </Link>
      <Formik
        onSubmit={(values, actions) => {
          handleSubmit(values, actions)
        }}
        initialValues={initialValues}
        validationSchema={ValidationSchema}
      >
        {formik => (
          <StyledBox>
            <Form>
              <Typography variant="h1" color={'white'} textAlign={'center'}>
                ACCOUNT ACTIVATION
              </Typography>
              <Typography variant="body1" color={'white'} marginTop={3}>
                <em>
                  Enter your email address and we will send you instructions for
                  setting your password.
                </em>
              </Typography>
              <ButtonBox>
                <TextFieldFormik
                  type={'email'}
                  fullWidth
                  name="email"
                  styles={{color: '#fff'}}
                  placeholder="EMAIL ADDRESS *"
                />
              </ButtonBox>
              {errorMessage ? (
                <Alert
                  style={{
                    marginTop: '24px',
                    color: '#f44c49',
                    borderColor: '#f44c49',
                  }}
                  severity="error"
                  variant="outlined"
                >
                  {errorMessage}
                </Alert>
              ) : null}
              <ButtonBox>
                <Button
                  children="Activate Account"
                  color="secondary"
                  hoverColor="transparent"
                  disabled={formik.isSubmitting || !formik.isValid}
                  type="submit"
                />
              </ButtonBox>
            </Form>
          </StyledBox>
        )}
      </Formik>
      <SuccessDialog
        onClose={() => handleChangeIsSuccessDialogOpen(false)}
        title="Account Activation"
        message="Your email has been sent successfully!"
        isOpen={isSuccessDialogOpen}
      />
    </ActivationBox>
  )
}

export default View
