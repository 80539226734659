import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../../hooks/useApi'

//#region types
export type CouponDetails = {
  code: string
  valid: boolean
}

//#endregion

//#region api
type GetCouponPayload = {
  couponCode: string
  id?: string
}

export const checkCoupon = createAsyncThunk<any, GetCouponPayload>(
  '/checkCoupon',
  async ({couponCode, id}) => {
    const fields = {products: [id]}

    return useApi(
      null,
      `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/guest/payment/coupon/${couponCode}/valid`,
      {
        method: 'POST',
        body: JSON.stringify(fields),
      },
    ).then(res => ({
      couponCode,
      status: res.ok,
    }))
  },
)
//#endregion
