import React from 'react'
import SettingsNavbar from '../../components/SettingsNavbar'
import SettingsContent from '../../components/SettingsContent'

const View = () => {
  return (
    <>
      <SettingsNavbar />
      <SettingsContent />
    </>
  )
}

export default View
