import React, {useEffect, useCallback, useState, ChangeEvent} from 'react'
import View from './view'
import {useAppDispatch} from '../../redux/configureStore'
import {useSelector} from 'react-redux'
import {toggleErrorDialog} from '../../redux/config'
import {Helmet} from 'react-helmet'
import {useAuth} from '../../components/AuthProvider'
import {getSearchResults, selectSearchResults} from '../../redux/search'
import {useQuery} from '../../hooks/useQuery'

const SearchResults = () => {
  const [page, setPage] = useState(0)

  const query = useQuery()
  const auth = useAuth()
  const dispatch = useAppDispatch()

  const results = useSelector(selectSearchResults)

  const keywords = query.get('search')

  const loadSearchResults = useCallback(async () => {
    try {
      if (keywords) {
        const {type} = await dispatch(getSearchResults({auth, page, keywords}))
        if (type === getSearchResults.rejected.type) {
          throw new Error('Unable to get search results')
        }
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the search results, please retry reloading the page. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch, page, keywords])

  const handleChangePage = (_event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  useEffect(() => {
    loadSearchResults()
  }, [loadSearchResults])

  return (
    <>
      {results && (
        <>
          <Helmet>
            <title>{`Search Results | Black Spectacles`}</title>
          </Helmet>
        </>
      )}
      <View
        results={results}
        onChangePage={handleChangePage}
        keywords={keywords}
      />
    </>
  )
}

export default SearchResults
