import React from 'react'
import View from './view'
import {DashboardFlashcards} from '../../../redux/dashboard'
import {Course} from '../../../redux/courses'

type Props = {
  course: Course
  flashcardData?: DashboardFlashcards
}

const FlashCards = ({course, flashcardData}: Props) => {
  return <View course={course} flashcardData={flashcardData} />
}

export default FlashCards
