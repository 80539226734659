import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {RootState} from './rootReducer'

//#region TYPES
export enum LoadingStatuses {
  Idle,
  Failed,
  Loading,
  Succeeded,
}

export type Config = {
  isErrorDialogOpen?: boolean
  errorMessage?: string
  title?: string
  ctaType?: string
  login?: boolean
}

type SliceState = {
  isErrorDialogOpen: boolean
  errorMessage: string
  title?: string
  ctaType?: string
  login?: boolean
}
//#endregion

//#region API

export const toggleErrorDialog = createAsyncThunk<
  {
    opened: boolean
    error: string
    title?: string
    ctaType?: string
    login?: boolean
  },
  {
    opened: boolean
    error: string
    title?: string
    ctaType?: string
    login?: boolean
  }
>(
  'config/toggleErrorDialog',
  async ({opened, error, title, ctaType, login}) => {
    return Promise.resolve({opened, error, title, ctaType, login})
  },
)
//#endregion

//#region SLICE
const initialState: SliceState = {
  isErrorDialogOpen: false,
  errorMessage: '',
  title: undefined,
  ctaType: '',
  login: false,
}

export default createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(toggleErrorDialog.fulfilled, (state, {payload}) => {
      state.isErrorDialogOpen = payload.opened
      state.errorMessage = payload.error
      state.title = payload.title
      state.ctaType = payload.ctaType
      state.login = payload.login
    })
  },
})
//#endregion

//#region selectors
export const selectConfigState = ({config}: RootState) => config
//#endregion
