import React from 'react'
import {
  ButtonBox,
  Caption,
  CloseButton,
  CloseButtonMobile,
  ErrorBox,
  FormBox,
  LeftBox,
  LoginBox,
  LoginContainer,
  RightBox,
  SubTitle,
  Title,
} from './styles'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import {Button} from '../../components/button'
import {Link} from 'react-router-dom'

type Props = {
  onChangeUsername: (value: string) => void
  onChangePassword: (value: string) => void
  onLogin: () => void
  error?: string
  isSubmitting: boolean
}

const View = ({
  onChangeUsername,
  onChangePassword,
  onLogin,
  error,
  isSubmitting,
}: Props) => {
  const handleLogin = (e: any) => {
    onLogin()
    e.preventDefault()
  }

  return (
    <LoginContainer>
      <form onSubmit={handleLogin}>
        <LoginBox>
          <LeftBox>
            <CloseButtonMobile>
              <a href="https://www.blackspectacles.com/en-us/">
                <CloseIcon fontSize="large" />
              </a>
            </CloseButtonMobile>
            <Caption>Turbocharge your way to a kickass career</Caption>
          </LeftBox>
          <RightBox>
            <CloseButton>
              <a href="https://www.blackspectacles.com/en-us/">
                <CloseIcon fontSize="large" />
              </a>
            </CloseButton>
            <Title variant={'h1'}>Log in</Title>
            <SubTitle>
              Not a member?{' '}
              <a href={process.env.REACT_APP_PRICING_PAGE}>Sign up!</a>
            </SubTitle>
            <FormBox>
              <TextField
                onChange={e => onChangeUsername(e.target.value)}
                label="Username"
                variant="standard"
              />
              <TextField
                onChange={e => onChangePassword(e.target.value)}
                label="Password"
                variant="standard"
                type="password"
              />
            </FormBox>
            <ErrorBox>{error ?? ''}</ErrorBox>
            <ButtonBox>
              <Button
                children={'Log in'}
                color={'primary'}
                disabled={isSubmitting}
                type="submit"
              />
            </ButtonBox>
            <SubTitle style={{marginTop: 'auto'}}>
              <Link to="/forgot-password">Forgot your password?</Link>
            </SubTitle>
          </RightBox>
        </LoginBox>
      </form>
    </LoginContainer>
  )
}

export default View
