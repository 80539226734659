import React, {useState, useEffect, useCallback} from 'react'
import View from './view'
import {useParams} from 'react-router-dom'
import {useAppDispatch} from '../../redux/configureStore'
import {CourseLesson, getCourse, selectCourse} from '../../redux/courses'
import {getGuestLessons, getLessons} from '../../redux/lessons'
import {getVideoGroup, selectVideoGroup} from '../../redux/videos'
import {useSelector} from 'react-redux'
import {toggleErrorDialog} from '../../redux/config'
import {clearExamState} from '../../redux/exams'
import {useAuth} from '../../components/AuthProvider'

const Quizzes = () => {
  const {courseId, examId} = useParams()
  const auth = useAuth()
  const dispatch = useAppDispatch()

  const [mobileOpen, setMobileOpen] = useState(false)

  const videoGroup = useSelector(selectVideoGroup)
  const course = useSelector(selectCourse)

  const handleChangeMobileMenu = (value: boolean) => {
    setMobileOpen(value)
  }

  const clearQuiz = async () => {
    await dispatch(clearExamState())
  }

  const loadCourse = useCallback(async () => {
    try {
      const {type, payload} = await dispatch(getCourse({courseId}))
      if (type === getCourse.fulfilled.type) {
        const courseLessons = payload.lessons.filter(
          (item: CourseLesson) => item.active,
        )

        courseLessons.sort((a: any, b: any) => b.version - a.version)
        const lessonId = courseLessons[0].id
        let lessons
        if (auth.isAuthenticated) {
          lessons = await dispatch(getLessons({auth, lessonId}))
        } else {
          lessons = await dispatch(getGuestLessons({lessonId}))
        }
        if (
          lessons.type === getLessons.fulfilled.type ||
          lessons.type === getGuestLessons.fulfilled.type
        ) {
          await dispatch(
            getVideoGroup({
              lessons: lessons.payload.assets,
              courseId,
              title: payload.title,
            }),
          )
        } else if (
          lessons.type === getLessons.rejected.type ||
          lessons.type === getGuestLessons.rejected.type
        ) {
          throw new Error('Unable to get quiz')
        }
      } else if (type === getCourse.rejected.type) {
        throw new Error('Unable to get quiz')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the quiz, please retry reloading the page. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch, courseId])

  useEffect(() => {
    loadCourse()

    return () => {
      clearQuiz()
    }
  }, [loadCourse, examId])

  return (
    <View
      course={course!}
      examId={examId!}
      mobileOpen={mobileOpen}
      videoGroup={videoGroup}
      onChangeMobileMenu={handleChangeMobileMenu}
    />
  )
}

export default Quizzes
