import PCM from '../images/ncarb/pcm-horizontal.svg'
import PJM from '../images/ncarb/pjm-horizontal.svg'
import PA from '../images/ncarb/pa-horizontal.svg'
import PPD from '../images/ncarb/ppd-horizontal.svg'
import PDD from '../images/ncarb/pdd-horizontal.svg'
import CE from '../images/ncarb/ce-horizontal.svg'

import PCMIcon from '../images/icons/icon-pcm-color.svg'
import PJMIcon from '../images/icons/icon-pjm-color.svg'
import PAIcon from '../images/icons/icon-pa-color.svg'
import PPDIcon from '../images/icons/icon-ppd-color.svg'
import PDDIcon from '../images/icons/icon-pdd-color.svg'
import CEIcon from '../images/icons/icon-ce-color.svg'

export type VerticalObjectType = {
  PcM: string
  PjM: string
  PA: string
  PPD: string
  PDD: string
  CE: string
}

export const NCARBAPPROVEDIMAGEABBR: VerticalObjectType = {
  PcM: PCM,
  PjM: PJM,
  PA: PA,
  PPD: PPD,
  PDD: PDD,
  CE: CE,
}

export type DivisionNameType = {
  pcm: string
  pjm: string
  pa: string
  ppd: string
  pdd: string
  ce: string
}

export const DIVISION: DivisionNameType = {
  pcm: 'Practice Management',
  pjm: 'Project Management',
  pa: 'Programming & Analysis',
  ppd: 'Project Planning & Design',
  pdd: 'Project Development & Documentation',
  ce: 'Construction & Evaluation',
}

export const DivisionColors = [
  {
    abbr: 'PcM',
    icon: PCMIcon,
    color: 'rgba(52,197,238,.2)',
  },
  {
    abbr: 'PjM',
    icon: PJMIcon,
    color: 'rgb(197,106,196,.2)',
  },
  {
    abbr: 'PA',
    icon: PAIcon,
    color: 'rgba(126,122,223,.25)',
  },
  {
    abbr: 'PPD',
    icon: PPDIcon,
    color: 'rgba(171,210,100,.25)',
  },
  {
    abbr: 'PDD',
    icon: PDDIcon,
    color: 'rgba(242,105,119,.25)',
  },
  {
    abbr: 'CE',
    icon: CEIcon,
    color: 'rgb(255,147,99,.2)',
  },
]
