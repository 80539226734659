import React from 'react'
import {Box, Alert, Typography} from '@mui/material'
import {
  ColumnBox,
  ColumnItem,
  ExamBox,
  ExamHeader,
  FixedBottom,
  LeftBox,
  LeftButtonBox,
  LogoBox,
  PreExamBox,
  RightBox,
  StyledLogo,
  TitleBox,
  Toolbar,
  UpperBox,
} from '../ExamQuestions/styles'
import {Button} from '../button'
import {Exam} from '../../redux/exams'
import renderHTML from '../../utils/renderHTML'
import ExamHotspot from '../ExamHotspot'
import ExamDrag from '../ExamDrag'
import BksLogo from '../../images/black-spectacles.svg'
import BksWhite from '../../images/icons/bks-white-icon.svg'
import ExamCheckall from '../ExamCheckall'
import ExamMultiple from '../ExamMultiple'
import ExamBlank from '../ExamBlank'
import Score from './Score'
import Summary from '../ExamQuestions/Summary'
import {useWindowDimensions} from '../../hooks/useWindowDimensions'
import theme from '../../styles/theme'
import {useSelector} from 'react-redux'
import {selectMember} from '../../redux/members'
import CaseStudyTabs from '../ExamQuestions/CaseStudyTabs'

type Props = {
  exam?: Exam
  currentQuestion: number
  onChangeCurrentQuestion: (
    value: number,
    orientation?: string,
    newState?: string,
  ) => void
  onUpdateReviewState: (value: string) => void
  onUpdateActivePage: (value: string) => void
  activePage: string
}

const View = ({
  exam,
  currentQuestion,
  onChangeCurrentQuestion,
  onUpdateReviewState,
  onUpdateActivePage,
  activePage,
}: Props) => {
  const {width} = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  const member = useSelector(selectMember)

  const question = exam?.questions[currentQuestion]

  const questionTitle = question?.title.split('-')
  const questionId = questionTitle && questionTitle[3] ? questionTitle[3] : ''

  const goToReview = (state: string) => {
    if (state === 'incorrect') {
      const filteredAnswers = exam?.questions.filter(
        item => !item.isAnswerCorrect,
      )

      if (filteredAnswers?.length === 0) {
        alert('There are no incorrect questions to review.')
        return
      }
    } else if (state === 'correct') {
      const filteredAnswers = exam?.questions.filter(
        item => item.isAnswerCorrect,
      )

      if (filteredAnswers?.length === 0) {
        alert('There are no correct questions to review.')
        return
      }
    }

    onUpdateReviewState(state)
    onChangeCurrentQuestion(0, 'first', state)
    onUpdateActivePage('question')
  }

  const goToSummary = () => {
    const questionId = question?.id
    if (questionId) {
      onUpdateActivePage('summary')
    }
  }

  const goToQuestion = (value: number) => {
    onUpdateReviewState('all')
    onUpdateActivePage('question')
    onChangeCurrentQuestion(value, 'current', 'all')
  }

  const handlePaginate = (value: number, orientation: string) => {
    if (activePage === 'score') {
      onUpdateActivePage('question')
    } else {
      onChangeCurrentQuestion(value, orientation)
    }
  }

  const renderQuestion = (type: string) => {
    if (type === 'checkall') {
      return (
        <ExamCheckall
          exam={exam}
          currentQuestion={currentQuestion}
          answer={question?.currentAnswer}
          currentAnswer={question?.currentAnswer}
          isReviewing={true}
          onSelect={() => {}}
        />
      )
    } else if (type === 'hotspot') {
      return (
        <ExamHotspot
          exam={exam}
          currentQuestion={currentQuestion}
          answer={question?.currentAnswer}
          currentAnswer={question?.currentAnswer}
          isReviewing={true}
          onSelect={() => {}}
        />
      )
    } else if (type === 'drag') {
      return (
        <ExamDrag
          exam={exam}
          currentQuestion={currentQuestion}
          answer={question?.currentAnswer}
          currentAnswer={question?.currentAnswer}
          isReviewing={true}
          onSelect={() => {}}
        />
      )
    } else if (type === 'multiple') {
      return (
        <ExamMultiple
          exam={exam}
          currentQuestion={currentQuestion}
          answer={question?.currentAnswer}
          currentAnswer={question?.currentAnswer}
          isReviewing={true}
          onSelect={() => {}}
        />
      )
    } else if (type === 'blank') {
      return (
        <ExamBlank
          exam={exam}
          currentQuestion={currentQuestion}
          answer={question?.currentAnswer}
          currentAnswer={question?.currentAnswer}
          isReviewing={true}
          onSelect={() => {}}
        />
      )
    }
  }

  const courseTitle = localStorage.getItem('currentCourseTitle')

  return (
    <>
      <ExamHeader>
        <LogoBox>
          <img src={BksWhite} />
        </LogoBox>
        <TitleBox>
          <UpperBox>
            <LeftBox>
              <h1>ARE 5.0 {courseTitle} Practice Exam</h1>
              <span>{`${member?.firstName} ${member?.lastName}`}</span>
            </LeftBox>
            <RightBox>
              {exam && (
                <>
                  <ColumnBox>
                    {activePage !== 'summary' && activePage !== 'score' && (
                      <>
                        <ColumnItem>Question</ColumnItem>
                        <ColumnItem>
                          {currentQuestion + 1} of {exam?.questions.length}
                        </ColumnItem>
                      </>
                    )}
                  </ColumnBox>
                </>
              )}
            </RightBox>
          </UpperBox>
          <Toolbar />
        </TitleBox>
      </ExamHeader>
      {activePage === 'summary' ? (
        <Summary
          exam={exam}
          goToQuestion={goToQuestion}
          goToReview={goToReview}
          toggleDialog={() => {}}
          onUpdateActivePage={onUpdateActivePage}
          isExamEnded={true}
          seenQuestions={[]}
          selectForReview={() => {}}
        />
      ) : (
        <>
          {activePage === 'expired' ? (
            <PreExamBox textAlign={'center'}>
              Time has run out!
              <br />
              <br />
              <br />
              You can begin reviewing your exam below or visit the summary page
              at any time.
            </PreExamBox>
          ) : (
            <>
              {activePage === 'score' ? (
                <Score exam={exam!} />
              ) : (
                <Box position={'relative'} marginTop={'20px'}>
                  <ExamBox>
                    <Box>
                      {question?.isAnswerCorrect ? (
                        <Alert severity="success">Correct!</Alert>
                      ) : (
                        <Alert severity="error">Incorrect!</Alert>
                      )}
                    </Box>

                    {question?.examType === 'case_study' && (
                      <Box marginTop={'10px'}>
                        <CaseStudyTabs
                          caseStudies={exam?.caseStudies}
                          question={question}
                        />
                      </Box>
                    )}

                    {question?.questionTypeName !== 'drag' &&
                      question?.questionTypeName !== 'hotspot' &&
                      question?.backgroundImage && (
                        <img src={question?.backgroundImage} alt="Question" />
                      )}
                    {renderHTML(question?.stem ?? '')}
                    {renderHTML(question?.body ?? '')}

                    {renderQuestion(question?.questionTypeName!)}

                    {question?.reference && (
                      <Typography variant="body1" fontSize={'14px'}>
                        Reference: <em>{question?.reference}</em>
                      </Typography>
                    )}
                  </ExamBox>
                </Box>
              )}
            </>
          )}
          <FixedBottom style={{paddingLeft: isMobile ? '8px' : '20px'}}>
            {activePage === 'question' && (
              <Button
                children={'PREVIOUS'}
                color="grey"
                disabled={currentQuestion === 0}
                onClick={() => handlePaginate(currentQuestion, 'previous')}
              />
            )}
            <Button
              children={'NEXT'}
              onClick={() =>
                activePage === 'expired'
                  ? onUpdateActivePage('score')
                  : handlePaginate(currentQuestion, 'next')
              }
              color="ncarbExamBlue"
            />
            <LeftButtonBox>
              <Button
                children={'EXAM SUMMARY'}
                onClick={goToSummary}
                color="black"
              />
              {activePage !== 'score' && <span>{questionId}</span>}
            </LeftButtonBox>
            <StyledLogo src={BksLogo} alt="Black Spectacles" />
          </FixedBottom>
        </>
      )}
    </>
  )
}

export default View
