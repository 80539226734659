import image0 from '../images/backgrounds/practical-applications/0.jpg'
import image1 from '../images/backgrounds/practical-applications/1.jpg'
import image2 from '../images/backgrounds/practical-applications/2.jpg'
import image3 from '../images/backgrounds/practical-applications/3.jpg'
import image4 from '../images/backgrounds/practical-applications/4.jpg'
import image5 from '../images/backgrounds/practical-applications/5.jpg'
import image6 from '../images/backgrounds/practical-applications/6.jpg'
import image7 from '../images/backgrounds/practical-applications/7.jpg'
import image8 from '../images/backgrounds/practical-applications/8.jpg'
import image9 from '../images/backgrounds/practical-applications/9.jpg'
import image10 from '../images/backgrounds/practical-applications/10.jpg'
import image11 from '../images/backgrounds/practical-applications/11.jpg'
import image12 from '../images/backgrounds/practical-applications/12.jpg'
import image13 from '../images/backgrounds/practical-applications/13.jpg'
import image14 from '../images/backgrounds/practical-applications/14.jpg'
import image15 from '../images/backgrounds/practical-applications/15.jpg'
import image16 from '../images/backgrounds/practical-applications/16.jpg'
import image17 from '../images/backgrounds/practical-applications/17.jpg'
import image18 from '../images/backgrounds/practical-applications/18.jpg'
import image19 from '../images/backgrounds/practical-applications/19.jpg'
import image20 from '../images/backgrounds/practical-applications/20.jpg'
import image21 from '../images/backgrounds/practical-applications/21.jpg'
import image22 from '../images/backgrounds/practical-applications/22.jpg'
import image23 from '../images/backgrounds/practical-applications/23.jpg'
import image24 from '../images/backgrounds/practical-applications/24.jpg'

export const practicalApplicationImages = [
  image0,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
]
