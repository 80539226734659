import React, {useEffect, useContext} from 'react'
import {Helmet} from 'react-helmet'
import {Box, Modal, Fade, Backdrop, Typography} from '@mui/material'
import {FormContainer, CloseContainer, FormTitle} from './styles'
import {
  cancelSubscription,
  getActiveSubscriptions,
} from '../../redux/subscription'
import {notifySlackChannel} from '../../redux/notification'
import CloseIcon from '@mui/icons-material/Close'
import {useAppDispatch} from '../../redux/configureStore'
import {toggleErrorDialog} from '../../redux/config'
import {SegmentContext} from '../../context'
import {useAuth} from '../../components/AuthProvider'
import {selectMember} from '../../redux/members'
import {useSelector} from 'react-redux'
declare global {
  interface Window {
    hbspt: any
  }
}

type Props = {
  open: boolean
  handleOpen: (val: boolean) => void
  subscriptionId: string
}

const HubSpotForm = ({open, handleOpen, subscriptionId}: Props) => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const {analytics} = useContext(SegmentContext)
  const member = useSelector(selectMember)

  const loadHubspotform = () => {
    const script = document.createElement('script')
    script.src = '//js.hsforms.net/forms/embed/v2.js'
    script.setAttribute('charset', 'utf-8')
    script.async = true
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '8822583',
          formId: '6155f8cc-3a67-4095-9fe7-60fa41ac1f89',
          target: '#hubspotForm',
          onFormSubmit: (form: any) => {
            const formElement = form.get(0) as HTMLFormElement
            const formData = new FormData(formElement)
            const formValues: {[key: string]: string} = {}

            formData.forEach((value, key) => {
              formValues[key] = value.toString()
            })

            handleFormSubmit(formValues)
          },
        })
      }
    }
    document.body.appendChild(script)
  }

  const handleFormSubmit = async (formValues: any) => {
    try {
      const {type} = await dispatch(cancelSubscription({auth, subscriptionId}))

      if (type === cancelSubscription.rejected.type) {
        throw new Error('Unable to cancel subscriptions')
      }

      if (analytics) {
        analytics.track('cancel_subscription')
      }

      const email = formValues.email || member?.email
      const selectedReason = formValues.cancellation_reason
      const sharedReason = formValues.share_reason
      const message = `Member (${email}) canceled subscription because ${sharedReason}. Selected Reason: ${selectedReason}`

      await dispatch(
        notifySlackChannel({auth, channel: 'customer-feedback', message}),
      )

      //refresh subscription display
      fetchSubscriptions()
      handleOpen(false)
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to cancel your subscription, please try reloading the page. If the issue persists contact our support team.',
        }),
      )
    }
  }

  const fetchSubscriptions = async () => {
    try {
      const {type} = await dispatch(getActiveSubscriptions({auth}))

      if (type === getActiveSubscriptions.rejected.type) {
        throw new Error('Unable to fetch subscriptions')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to fetch your active subscriptions, please try reloading the page. If the issue persists contact our support team.',
        }),
      )
    }
  }

  const handleClose = () => {
    handleOpen(false)
  }

  //   const loadEmailField = () => {
  //     if (document.readyState === 'loading') {
  //       document.addEventListener('DOMContentLoaded', setEmailField)
  //     } else {
  //       setEmailField()
  //     }
  //   }

  //   const setEmailField = () => {
  //     const ele = document.querySelector('input[name="email"]')
  //   }

  useEffect(() => {
    loadHubspotform()
    // loadEmailField()
  }, [])

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="//js.hsforms.net/forms/embed/v2.js"
        ></script>
      </Helmet>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <FormContainer>
            <CloseContainer>
              <CloseIcon onClick={handleClose} style={{fontSize: '35px'}} />
            </CloseContainer>

            <FormTitle>
              <Typography variant={'h2'}>Cancellation Form </Typography>
            </FormTitle>
            <Box id="hubspotForm"></Box>
          </FormContainer>
        </Fade>
      </Modal>
    </>
  )
}

export default HubSpotForm
