import React, {useState} from 'react'
import {Content, Header, HeaderItem, ReferencesBox} from './styles'
import PDFViewer from '../PDFViewer'

const View = () => {
  const [currentTab, setCurrentTab] = useState('Acoustics')

  const documents: any = {
    Acoustics:
      'https://bksps-live-public.s3.us-east-2.amazonaws.com/references/Acoustics_Formulas_v2.pdf',
    Electrical:
      'https://bksps-live-public.s3.us-east-2.amazonaws.com/references/Electrical_Formulas_v2.pdf',
    HVAC: 'https://bksps-live-public.s3.us-east-2.amazonaws.com/references/HVAC_References_v2.pdf',
    Plumbing:
      'https://bksps-live-public.s3.us-east-2.amazonaws.com/references/Plumbing_References_v2.pdf',
    Structural:
      'https://bksps-live-public.s3.us-east-2.amazonaws.com/references/Structural_References_ALL_v2.pdf',
  }

  return (
    <ReferencesBox>
      <Header>
        <HeaderItem
          onClick={() => setCurrentTab('Acoustics')}
          $isActive={currentTab === 'Acoustics'}
        >
          Acoustics
        </HeaderItem>
        <HeaderItem
          onClick={() => setCurrentTab('Electrical')}
          $isActive={currentTab === 'Electrical'}
        >
          Electrical
        </HeaderItem>
        <HeaderItem
          onClick={() => setCurrentTab('HVAC')}
          $isActive={currentTab === 'HVAC'}
        >
          HVAC
        </HeaderItem>
        <HeaderItem
          onClick={() => setCurrentTab('Plumbing')}
          $isActive={currentTab === 'Plumbing'}
        >
          Plumbing
        </HeaderItem>
        <HeaderItem
          onClick={() => setCurrentTab('Structural')}
          $isActive={currentTab === 'Structural'}
        >
          Structural
        </HeaderItem>
      </Header>
      <Content>
        <PDFViewer file={documents[currentTab]} height="600px" />
      </Content>
    </ReferencesBox>
  )
}

export default View
