import {Box} from '@mui/material'
import styled from 'styled-components'
import theme from '../../styles/theme'

export const SettingsContentContainer = styled(Box)`
  width: 66.667%;
  margin: 0 0 30px;

  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }
`
export const Title = styled(Box)`
  margin-top: 10px;
  margin-bottom: 30px;

  h1 {
    color: #37474f;
    letter-spacing: 0;
  }
`
