import styled from 'styled-components'
import {Box, Typography} from '@mui/material'
import theme from '../../styles/theme'
import BGLogin from '../../images/backgrounds/bg-login.jpg'
import BksLogo from '../../images/logo-white.svg'
import {getRandomLoginBackground} from '../../utils/getRandomBackground'

export const LoginContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #000 url(${BGLogin}) no-repeat 50% 50%;
  background-size: cover;
  height: 100vh;
`

export const LoginBox = styled(Box)`
  display: flex;
  width: 90vw;
  max-width: 48em;
  height: 550px;
  box-sizing: border-box;

  ${theme.breakpoints.down('sm')} {
    height: auto;
    flex-flow: column;
    max-width: 100%;
    width: 100%;
    min-height: initial;
    padding: 20px;
  }
`

export const LeftBox = styled(Box)`
  display: flex;
  flex-flow: column;
  width: 50%;
  background: url(${getRandomLoginBackground()}) no-repeat;
  background-color: #333;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  background-size: cover;
  padding: 2em 2em 3em;
  box-sizing: border-box;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 180px;
    height: 85px;
    position: absolute;
    z-index: 2;
    top: 2em;
    left: 2em;
    background: url(${BksLogo}) no-repeat 50% 50%;
    background-size: contain;

    ${theme.breakpoints.down('sm')} {
      width: 140px;
      height: 66px;
      top: 1.5em;
      left: 2em;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0) 200px,
      rgba(0, 0, 0, 0) calc(100% - 12em),
      rgba(0, 0, 0, 0.75) 100%
    );
  }

  ${theme.breakpoints.down('sm')} {
    width: 100%;
    height: 216px;
    padding: 0;
  }
`

export const Caption = styled(Box)`
  color: #fff;
  margin-top: auto;
  z-index: 10;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 2.5em;
  letter-spacing: -0.02em;
  width: 100%;

  ${theme.breakpoints.down('sm')} {
    box-sizing: border-box;
    font-size: 1.75em;
    padding: 0 30px 25px;
  }
`

export const RightBox = styled(Box)`
  display: flex;
  flex-flow: column;
  background-color: #fff;
  width: 50%;
  box-sizing: border-box;
  padding: 2em 2em 3em;

  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }
`

export const CloseButton = styled(Box)`
  width: 100%;
  text-align: right;

  && a {
    color: #000 !important;
  }

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`

export const CloseButtonMobile = styled(Box)`
  display: none;
  position: absolute;
  right: 1.5em;
  top: 1.5em;
  z-index: 10;

  && a {
    color: #fff !important;
  }

  ${theme.breakpoints.down('sm')} {
    display: block;
  }
`

export const Title = styled(Typography)`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
  font-size: 2.25em !important;
  letter-spacing: -0.02em !important;
  color: #111 !important;
  margin-bottom: 0.375em !important;
  text-transform: none !important;
  margin-top: 0.5em !important;

  ${theme.breakpoints.down('sm')} {
    font-size: 1.5em !important;
  }
`

export const SubTitle = styled(Box)`
  font-family: Public Sans;
  font-size: 1.125em;
  margin: 0 0 1.5em;
  color: #666;
  line-height: 1.333;

  && a {
    &:hover {
      color: #1bceff !important;
    }
  }
`

export const FormBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 20px;

  && .MuiFormLabel-root {
    color: #ccd0db !important;
  }

  && .MuiInputBase-root {
    &:before {
      border-bottom: 2px #1377d4 solid !important;
    }

    &:after {
      border-bottom: 2px #1377d4 solid !important;
    }
  }

  && input {
    padding: 4px 0 9px !important;
  }
`

export const ButtonBox = styled(Box)`
  margin-bottom: 48px;
`

export const ErrorBox = styled(Box)`
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #f44336;
`
