import React, {useEffect, useCallback} from 'react'
import View from './view'
import {useAppDispatch} from '../../redux/configureStore'
import {useSelector} from 'react-redux'
import {
  getCoursesByTag,
  getGuestCoursesByTag,
  selectCourses,
  selectCoursesStatus,
} from '../../redux/courses'
import Loading from '../../components/Loading'
import {LoadingStatuses} from '../../components/types/LoadingStatusses'
import {Helmet} from 'react-helmet'
import {clearVideoState} from '../../redux/videos'
import {toggleErrorDialog} from '../../redux/config'
import {useAuth} from '../../components/AuthProvider'

const PracticalApplications = () => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const courses = useSelector(selectCourses)
  const coursesStatus = useSelector(selectCoursesStatus)

  const loadCourses = useCallback(async () => {
    await dispatch(clearVideoState())
    try {
      if (auth.isAuthenticated) {
        const {type} = await dispatch(
          getCoursesByTag({auth, tag: 'PRACTICAL_APPLICATION_COURSE'}),
        )
        if (type === getCoursesByTag.rejected.type) {
          throw new Error('Unable to get course')
        }
      } else {
        const {type} = await dispatch(
          getGuestCoursesByTag({tag: 'PRACTICAL_APPLICATION_COURSE'}),
        )
        if (type === getGuestCoursesByTag.rejected.type) {
          throw new Error('Unable to get course')
        }
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the course, please retry. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch])

  useEffect(() => {
    loadCourses()
  }, [loadCourses])

  if (
    coursesStatus === LoadingStatuses.Loading ||
    coursesStatus === LoadingStatuses.Idle
  ) {
    return <Loading />
  }

  return (
    <>
      {courses && courses.length > 0 && (
        <Helmet>
          <title>{`Practical Applications | Black Spectacles`}</title>
        </Helmet>
      )}
      <View courses={courses!} />
    </>
  )
}

export default PracticalApplications
