import React from 'react'
import View from './view'

type Props = {
  data?: string
}

const ResponseData = ({data}: Props) => {
  return <View data={data} />
}

export default ResponseData
