import React from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import PassImage from '../../images/guarantee.svg'
import {GuaranteeBox, StyledImage} from './styles'
import {Button} from '../../components/button'
import {Course} from '../../redux/courses'
import {useSelector} from 'react-redux'
import {selectMemberAccess} from '../../redux/members'

type Props = {
  courses?: Course[]
  onChangeDivision: (value: string) => void
}

const View = ({courses, onChangeDivision}: Props) => {
  const [failedDivision, setFailedDivision] = React.useState('')

  const access = useSelector(selectMemberAccess)

  const hasAccess =
    access && Object.keys(access).length !== 0
      ? Number(access.are?.guarantee_claim?.value) === 1
      : false

  const handleChange = (event: SelectChangeEvent) => {
    setFailedDivision(event.target.value)
  }

  const getExpert = () => {
    window.open('https://www.blackspectacles.com/pricing#are5')
  }

  return (
    <GuaranteeBox id="content">
      <Box textAlign={'center'}>
        <StyledImage src={PassImage} alt="Pass the ARE Guarantee" />
      </Box>
      <Typography variant="body1" textAlign={'center'} marginTop={6}>
        Pass your division or we'll pay for your retake. Our members{' '}
        <a href="https://learn.blackspectacles.com/pass-rate" target="_blank">
          pass their ARE Exams at higher rates
        </a>{' '}
        than the NCARB national averages for all six divisions! We're confident
        that if you use our Expert membership to the fullest, you can pass too.
        If you don't, we're putting our money where our mouth is. We'll call
        NCARB and give you a seat credit to use toward your retake.
      </Typography>
      {!hasAccess && (
        <Box textAlign={'center'} marginTop={2}>
          <Button children={'GET EXPERT'} color="primary" onClick={getExpert} />
        </Box>
      )}
      <Typography variant="h2" textAlign={'center'} marginTop={3}>
        QUALIFICATIONS TO PASS THE GUARANTEE
      </Typography>
      <Typography variant="body1" marginTop={4} fontSize={'18px'}>
        <em>
          You do the work, and you get rewarded. To meet our guarantee
          standards, adhere to the following requirements
        </em>
      </Typography>
      <Typography variant="body2" marginTop={4}>
        <strong>ARE GUARANTEE REQUIREMENTS</strong>
      </Typography>
      <ul>
        <li>You must be an Expert subscriber with an individual membership*</li>
        <li>
          Report all pass/fails in your Black Spectacles Dashboard along with
          scheduled exam dates
        </li>
        <li>
          Make your claim within 60 days of the exam date of the failed division
        </li>
        <li>
          Submit NCARB AREⓇ Score Report for proof of fail within 30-day claim
          period
        </li>
        <li>
          Apply the NCARB seat credit to the retake of the division in which
          you've met all requirements
        </li>
      </ul>
      <Typography variant="body1" textAlign={'center'} marginTop={6}>
        *For users registered under a firm membership, speak with your Account
        Manager, or{' '}
        <a
          href="https://www.blackspectacles.com/group-guarantee"
          target="_blank"
        >
          learn more
        </a>{' '}
        about our Firm Expert Guarantee.
      </Typography>
      <FormControl fullWidth style={{marginTop: '32px'}}>
        <InputLabel id="exam-failed">Exam failed:</InputLabel>
        <Select
          labelId="exam-failed"
          id="exam-failed"
          value={failedDivision}
          label="Exam failed"
          onChange={handleChange}
        >
          <MenuItem
            value={'PCM'}
            disabled={
              !courses?.some(item => item.abbreviation.toUpperCase() === 'PCM')
            }
          >
            <em>PCM</em>
          </MenuItem>
          <MenuItem
            value={'PJM'}
            disabled={
              !courses?.some(item => item.abbreviation.toUpperCase() === 'PJM')
            }
          >
            <em>PJM</em>
          </MenuItem>
          <MenuItem
            value={'PA'}
            disabled={
              !courses?.some(item => item.abbreviation.toUpperCase() === 'PA')
            }
          >
            <em>PA</em>
          </MenuItem>
          <MenuItem
            value={'PPD'}
            disabled={
              !courses?.some(item => item.abbreviation.toUpperCase() === 'PPD')
            }
          >
            <em>PPD</em>
          </MenuItem>
          <MenuItem
            value={'PDD'}
            disabled={
              !courses?.some(item => item.abbreviation.toUpperCase() === 'PDD')
            }
          >
            <em>PDD</em>
          </MenuItem>
          <MenuItem
            value={'CE'}
            disabled={
              !courses?.some(item => item.abbreviation.toUpperCase() === 'CE')
            }
          >
            <em>CE</em>
          </MenuItem>
        </Select>
      </FormControl>
      <Box textAlign={'center'} marginTop={2}>
        <Button
          children={'CLAIM A GUARANTEE'}
          color="black"
          onClick={() => onChangeDivision(failedDivision)}
          style={{width: '70%'}}
          disabled={failedDivision === '' || !hasAccess}
        />
      </Box>
      {!hasAccess && (
        <Typography variant="body1" textAlign={'center'} marginTop={6}>
          You don’t qualify to submit a claim. Next time, make sure you have
          (and use) an Expert membership and upload the record of your failed
          division attempt. Then you can ceremoniously burn it.
        </Typography>
      )}
    </GuaranteeBox>
  )
}

export default View
