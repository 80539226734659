import styled from 'styled-components'
import theme from '../../styles/theme'
import {Box} from '@mui/material'

export const QuizHeader = styled(Box)`
  background-color: #222;
  height: 72px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;

  ${theme.breakpoints.down('md')} {
    margin-top: 60px;
  }
`
