import React, {useState} from 'react'
import {Box} from '@mui/material'
import {FormContainer, RowContainer} from '../updateProfile/styles'
import TextField from '../../../TextField'
import {Button} from '../../../button'
import * as Yup from 'yup'
import {useAppDispatch} from '../../../../redux/configureStore'
import {updatePassword} from '../../../../redux/password'
import {useAuth} from '../../../../components/AuthProvider'
import {toggleErrorDialog} from '../../../../redux/config'
import {SuccessDialog} from '../../../SuccessDialog'
import {StyledTitle} from '../../../../styles/global'

type PasswordProps = {
  currentPassword: string
  newPassword: string
  verifyNewPassword: string
}

const View = () => {
  const dispatch = useAppDispatch()
  const auth = useAuth()

  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false)
  const [formValues, setFormValues] = useState<PasswordProps>({
    currentPassword: '',
    newPassword: '',
    verifyNewPassword: '',
  })
  const [formErrors, setFormErrors] = useState({
    currentPassword: '',
    newPassword: '',
    verifyNewPassword: '',
  })
  const [touched, setTouched] = useState<any>({
    currentPassword: false,
    newPassword: false,
    verifyNewPassword: false,
  })

  const [isDisabled, setIsDisabled] = useState(true)

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('Required')
      .min(8, 'Password must be at least 8 characters'),
    newPassword: Yup.string()
      .required('Required')
      .min(8, 'Password must be at least 8 characters')
      .test(
        'newPasswordValidation',
        'New password cannot be the same as current password',
        () => formValues.newPassword !== formValues.currentPassword,
      )
      .test(
        'passwordValidation',
        'Passwords do not match',
        () => formValues.newPassword === formValues.verifyNewPassword,
      ),
    verifyNewPassword: Yup.string()
      .required('Required')
      .min(8, 'Password must be at least 8 characters')
      .test(
        'newPasswordValidation',
        'New password cannot be the same as current password',
        () => formValues.newPassword !== formValues.currentPassword,
      )
      .test(
        'passwordValidation',
        'Passwords do not match',
        () => formValues.newPassword === formValues.verifyNewPassword,
      ),
  })

  const onFieldChange = (fieldName: string, value: string) => {
    setFormValues({...formValues, [fieldName]: value})
  }

  const handleSetTouched = (field: string) => {
    setTouched({
      ...touched,
      [field]: true,
    })
  }

  const validateFields = async () => {
    await validationSchema
      .validate(formValues, {abortEarly: false})
      .then(() => {
        setFormErrors({
          currentPassword: '',
          newPassword: '',
          verifyNewPassword: '',
        })

        setIsDisabled(false)
      })
      .catch(err => {
        const errors = err.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: touched[error.path] ? error.message : '',
          }
        }, {})

        setFormErrors(errors)
        setIsDisabled(true)
      })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    setIsDisabled(true)

    setTouched({
      currentPassword: true,
      newPassword: true,
      verifyNewPassword: true,
    })

    // validate form
    const isFormValid = await validationSchema.isValid(formValues, {
      abortEarly: false, // Prevent aborting validation after first error
    })

    if (!isFormValid) return

    try {
      // validate current password
      // TODO: integrate when API endpoint is created

      // submit form to update password
      const {type} = await dispatch(
        updatePassword({auth, password: formValues.newPassword}),
      )

      setIsDisabled(false)
      if (type === updatePassword.rejected.type) {
        throw new Error('Unable to update password')
      } else if (type === updatePassword.fulfilled.type) {
        setIsSuccessDialogOpen(true)
        setFormValues({
          currentPassword: '',
          newPassword: '',
          verifyNewPassword: '',
        })
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to update your password, please retry. If issues persist contact our support team.',
        }),
      )
    }
  }

  return (
    <Box>
      <StyledTitle variant={'h2'} style={{marginBottom: '32px'}}>
        Change Password
      </StyledTitle>

      <FormContainer
        sx={{
          width: {sm: '100%', md: '50%'},
          paddingTop: '15px',
          '&& div': {paddingBottom: '5px'},
        }}
      >
        <form onSubmit={e => handleSubmit(e)}>
          <RowContainer>
            <TextField
              name={'currentPassword'}
              variant={'outlined'}
              fullWidth={true}
              placeholder={'Current Password'}
              type={'password'}
              onChange={e => onFieldChange('currentPassword', e.target.value)}
              onBlur={() => validateFields()}
              errorMessage={formErrors?.currentPassword}
              onFocus={() => {
                handleSetTouched('currentPassword')
              }}
            />
          </RowContainer>

          <RowContainer>
            <TextField
              name={'newPassword'}
              variant={'outlined'}
              fullWidth={true}
              placeholder={'New Password (Min 8 Chars)'}
              type={'password'}
              onChange={e => onFieldChange('newPassword', e.target.value)}
              onBlur={() => validateFields()}
              errorMessage={formErrors?.newPassword}
              onFocus={() => {
                handleSetTouched('newPassword')
              }}
            />
          </RowContainer>

          <RowContainer>
            <TextField
              name={'verifyNewPassword'}
              variant={'outlined'}
              fullWidth={true}
              placeholder={'Verify New Password'}
              type={'password'}
              onChange={e => onFieldChange('verifyNewPassword', e.target.value)}
              onBlur={() => validateFields()}
              errorMessage={formErrors?.verifyNewPassword}
              onFocus={() => {
                handleSetTouched('verifyNewPassword')
              }}
            />
          </RowContainer>

          <RowContainer style={{justifyContent: 'end'}}>
            <Button
              color={'primary'}
              children={'Change Password'}
              type={'submit'}
              disabled={isDisabled}
            />
          </RowContainer>
        </form>
      </FormContainer>

      {isSuccessDialogOpen && (
        <SuccessDialog
          isOpen={isSuccessDialogOpen}
          onClose={() => setIsSuccessDialogOpen(false)}
          title="Change Password"
          message="Your password has been changed successfully."
        />
      )}
    </Box>
  )
}

export default View
