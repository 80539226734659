import React, {useState} from 'react'
import SettingsNavbar from '../../components/SettingsNavbar'
import {SettingsContentContainer} from '../../components/SettingsContent/styles'
import {
  AddMemberHeader,
  AddMemberHeaderItem,
  AddMemberItem,
  AddMemberSubtitle,
  ButtonsBox,
  ImportBox,
  ImportTitle,
  StyledInput,
  SubTitle,
  Title,
  UserItem,
  UserTitle,
  UsersBox,
  UsersGroup,
  UsersHeader,
} from './styles'
import {Box} from '@mui/material'
import {Button} from '../../components/button'
import {
  MemberList,
  Organization,
  SubmitMemberList,
  importMembersList,
} from '../../redux/organizations'
import {LoadingStatuses} from '../../components/types/LoadingStatusses'
import Loading from '../../components/Loading'
import CSVSelector from '../../components/CSVSelector'
import {Field, FieldArray, Form, Formik, FormikHelpers} from 'formik'
import {useAppDispatch} from '../../redux/configureStore'
import {useAuth} from '../../components/AuthProvider'
import {toggleErrorDialog} from '../../redux/config'

type Props = {
  members?: MemberList[]
  status: LoadingStatuses
  organization?: Organization
}

type NewUsers = {
  firstName?: string
  lastName?: string
  email?: string
}

type FormValues = {
  addUsers: string
  newUsers: NewUsers[]
}

const View = ({members, status, organization}: Props) => {
  const dispatch = useAppDispatch()
  const auth = useAuth()

  const [usersToRemove, setUsersToRemove] = useState<string[]>([])
  // eslint-disable-next-line
  const [csvData, setCsvData] = useState<string[][]>([])

  const handleSetCsvData = (data: string[][], values: FormValues) => {
    setCsvData(data)

    for (let index = 0; index < values.newUsers.length; index++) {
      values.newUsers[index].firstName = data[index] ? data[index][0] : ''
      values.newUsers[index].lastName = data[index] ? data[index][1] : ''
      values.newUsers[index].email = data[index] ? data[index][2] : ''
    }
  }

  const handleClearInputs = (values: FormValues) => {
    setCsvData([])
    setUsersToRemove([])

    for (let index = 0; index < values.newUsers.length; index++) {
      values.newUsers[index].firstName = ''
      values.newUsers[index].lastName = ''
      values.newUsers[index].email = ''
      $(`#user-${index}`).prop('checked', false)
    }

    $('#AddMembers').val('')
  }

  const handleAddUsersToRemove = (user: string, id: string) => {
    let users = [...usersToRemove]
    const checkbox = $(`#${id}`)
    if (users.includes(user)) {
      users = users.filter(e => e !== user)
      checkbox.prop('checked', false)
    } else {
      users.push(user)
      checkbox.prop('checked', true)
    }
    setUsersToRemove(users)
  }

  const admins = members?.filter(member => member.isAdmin)
  const users = members?.filter(member => !member.isAdmin)

  let isGreyAdmins = true
  let isGreyUsers = true

  const groupLimit = organization?.groupSize ?? 10
  const currentLimit = groupLimit - (members?.length ?? 0)

  const initialValues: FormValues = {
    addUsers: '1',
    newUsers: [],
  }

  for (let i = 0; i < currentLimit; i++) {
    initialValues.newUsers.push({firstName: '', lastName: '', email: ''})
  }

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    try {
      const list: SubmitMemberList[] = []

      if (usersToRemove.length > 0) {
        usersToRemove.forEach(user => {
          const removedUser = members?.find(member => member.email === user)
          if (removedUser) {
            list.push({
              firstName: removedUser.firstName,
              lastName: removedUser.lastName,
              email: removedUser.email,
              admin: removedUser.isAdmin,
              remove: true,
            })
          }
        })
      }

      values.newUsers.forEach(user => {
        if (
          user.firstName &&
          user.lastName &&
          user.email &&
          !members?.some(member => member.email === user.email)
        ) {
          const data = {
            ...user,
            admin: false,
            remove: false,
          }

          list.push(data)
        }
      })

      if (values.addUsers === '2') {
        members?.forEach(member => {
          if (
            !member.isAdmin &&
            !values.newUsers.some(user => user.email === member.email)
          ) {
            list.push({
              firstName: member.firstName,
              lastName: member.lastName,
              email: member.email,
              admin: false,
              remove: true,
            })
          }
        })
      }

      const {type} = await dispatch(importMembersList({auth, list}))
      if (type === importMembersList.fulfilled.type) {
        handleClearInputs(values)
        actions.setSubmitting(false)
        window.scrollTo(0, 0)
      } else if (type === importMembersList.rejected.type) {
        actions.setSubmitting(false)
        throw new Error('Unable to import members list')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to import the members list, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  return (
    <>
      <SettingsNavbar />
      {status === LoadingStatuses.Idle || status === LoadingStatuses.Loading ? (
        <SettingsContentContainer>
          <Loading />
        </SettingsContentContainer>
      ) : (
        <SettingsContentContainer>
          <Title variant="h2">Active Members</Title>
          <UsersBox>
            <UsersHeader>
              <UserTitle>Current User</UserTitle>
              <UserTitle>Remove?</UserTitle>
            </UsersHeader>
            <Box>
              <SubTitle>Group admins:</SubTitle>
              <UsersGroup>
                {admins?.map((admin, key) => {
                  const color = isGreyAdmins ? '#ececec' : '#fff'

                  isGreyAdmins = !isGreyAdmins

                  return (
                    <UserItem $bgColor={color} key={`admin-${key}`}>
                      <Box>
                        {`${admin.firstName} ${admin.lastName} (${admin.email})`}
                      </Box>
                    </UserItem>
                  )
                })}
              </UsersGroup>
              <SubTitle>Members:</SubTitle>
              <UsersGroup>
                {users?.map((user, key) => {
                  let color = isGreyUsers ? '#ececec' : '#fff'
                  if (usersToRemove.includes(user.email)) {
                    color = '#fbb'
                  }

                  isGreyUsers = !isGreyUsers

                  return (
                    <UserItem
                      $bgColor={color}
                      key={`user-${key}`}
                      onClick={() =>
                        handleAddUsersToRemove(user.email, `user-${key}`)
                      }
                    >
                      <Box>
                        {`${user.firstName} ${user.lastName} (${user.email})`}
                      </Box>
                      <input type="checkbox" id={`user-${key}`} />
                    </UserItem>
                  )
                })}
              </UsersGroup>
            </Box>
          </UsersBox>

          <Title variant="h2">Add Members to Group</Title>
          {currentLimit <= 3 && (
            <AddMemberSubtitle>
              You are approaching your maximum group size. Please contact your
              account manager to increase your group size.
            </AddMemberSubtitle>
          )}
          <Formik
            onSubmit={(values, actions) => {
              handleSubmit(values, actions)
            }}
            initialValues={initialValues}
          >
            {formik => (
              <Form>
                <AddMemberHeader>
                  <AddMemberHeaderItem>First Name</AddMemberHeaderItem>
                  <AddMemberHeaderItem>Last Name</AddMemberHeaderItem>
                  <AddMemberHeaderItem>Email</AddMemberHeaderItem>
                </AddMemberHeader>
                <Box>
                  <FieldArray name="newUsers">
                    {() => (
                      <>
                        {formik.values.newUsers?.map((_newUser, index) => (
                          <AddMemberItem key={`newUser-${index}`}>
                            <StyledInput
                              name={`newUsers.${index}.firstName`}
                              placeholder="FIRST NAME*"
                            />
                            <StyledInput
                              name={`newUsers.${index}.lastName`}
                              placeholder="LAST NAME*"
                            />
                            <StyledInput
                              name={`newUsers.${index}.email`}
                              placeholder="EMAIL*"
                            />
                          </AddMemberItem>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </Box>
                <ImportBox>
                  <ImportTitle>Import from CSV</ImportTitle>
                  <CSVSelector
                    onChange={data => handleSetCsvData(data, formik.values)}
                  />
                  <Box marginTop={'20px'}>
                    <ImportTitle>
                      <Field
                        type="radio"
                        name="addUsers"
                        value={'1'}
                        selected
                        id="new-users"
                      />{' '}
                      <label htmlFor="new-users">Only ADD new users</label>
                    </ImportTitle>
                    <ImportTitle>
                      <Field
                        type="radio"
                        name="addUsers"
                        value={'2'}
                        id="full-import"
                      />{' '}
                      <label htmlFor="full-import">
                        Full import (ADD and remove)
                      </label>
                    </ImportTitle>
                  </Box>
                </ImportBox>
                <ButtonsBox>
                  <Button
                    color="secondary"
                    children={'Clear inputs'}
                    onClick={() => handleClearInputs(formik.values)}
                  />
                  <Button
                    color="primary"
                    children={'Update members'}
                    type="submit"
                    disabled={formik.isSubmitting}
                  />
                </ButtonsBox>
              </Form>
            )}
          </Formik>
        </SettingsContentContainer>
      )}
    </>
  )
}

export default View
