import React, {useEffect} from 'react'

import 'jquery'

const View = () => {
  const loadCalculator = () => {
    // @ts-ignore
    $('#calculatorWin10').calculator({
      showFormula: true,
      layout: [
        'MCMRM+M-MS',
        'DRPI_%CECABS',
        'SNAS1XSQSR_/',
        'CSAC_7_8_9_*',
        'TNAT_4_5_6_-',
        'XYLG_1_2_3_+',
        'EXLN+-_0_._=',
      ],
      calculatorClass: 'win2021',
      historySelector: '.calculator-history',
      memoryDisplaySelector: '.calculator-displaymemory',
      canCopyHistory: true,
    })
    $('<div class="calculator-rightpnl"></div>')
      .append(
        '<div class="calculator-infoctl"><span class="calculator-blankspacing">&nbsp;</span><span class="selected">History</span><span>Memory</span></div>',
      )
      .append('<div class="calculator-history"></div>')
      .append(
        '<div class="calculator-displaymemory" style="display: none;"></div>',
      )
      .append('<div class="calculator-deletehistory"></div>')
      .appendTo($('#calculatorWin10'))
    $('.calculator-infoctl span').click(function () {
      if ($(this).index() == 0) return
      $('.calculator-infoctl span').removeClass('selected')
      $(this).addClass('selected')
      if ($(this).index() == 1) {
        $('.calculator-displaymemory').hide()
        $('.calculator-history').show()
      } else {
        $('.calculator-displaymemory').show()
        $('.calculator-history').hide()
      }
    })
    $('#calculatorWin10 .calculator-deletehistory').click(function () {
      if ($('#calculatorWin10 .calculator-displaymemory').is(':visible'))
        $('#calculatorWin10 .calculator-mem-clear').click()
      else if ($('#calculatorWin10 .calculator-history').is(':visible')) {
        // @ts-ignore
        $('#calculatorWin10').calculator('clearHistory')
      }
    })
  }

  useEffect(() => {
    loadCalculator()
  }, [loadCalculator])

  return (
    <>
      <div id="calculatorWin10"></div>
    </>
  )
}

export default View
