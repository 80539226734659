import {Box, CircularProgress} from '@mui/material'
import styled from 'styled-components'
import theme from '../../styles/theme'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

export const PlayIconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: #1377d4;
  }
`

export const StyledCircularProgress = styled(CircularProgress)`
  width: 150px !important;
  height: 150px !important;
  color: #1377d4 !important;

  ${theme.breakpoints.down('sm')} {
    width: 80px !important;
    height: 80px !important;
  }
`

export const StyledIcon = styled(PlayArrowIcon)`
  position: absolute;
  width: 90px !important;
  height: 90px !important;

  ${theme.breakpoints.down('sm')} {
    width: 40px !important;
    height: 40px !important;
  }
`
