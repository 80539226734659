import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import Cookies from 'universal-cookie'
import {RootState} from './rootReducer'

const cookies = new Cookies()

const today = new Date()
const nextWeek = new Date()
nextWeek.setDate(today.getDate() + 7)

type SliceState = {
  error?: string
}
//#endregion

//#region api
type LoginPayload = {
  username: string
  password: string
  error?: boolean
}

export const login = createAsyncThunk<any, LoginPayload>(
  'login',
  async ({username, password}) => {
    const endpoint = `/v1/guest/are/log_in`

    const body = {
      email: username,
      password,
    }

    return useApi(
      null,
      endpoint,
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      'network',
    ).then(res => res.json())
  },
)

type ForgotPasswordPayload = {
  email: string
}

export const forgotPassword = createAsyncThunk<any, ForgotPasswordPayload>(
  'password/forgot',
  async ({email}) => {
    const endpoint = `/v1/guest/are/member/password/reset`

    const body = {
      email,
    }

    return useApi(null, endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then(res => res.json())
  },
)

type ValidateResetCodePayload = {
  resetCode: string
}

export const validateResetCode = createAsyncThunk<
  any,
  ValidateResetCodePayload
>('password/code/validate', async ({resetCode}) => {
  const endpoint = `/v1/guest/are/member/password/validate/${resetCode}`

  return useApi(null, endpoint, {
    method: 'GET',
  }).then(res => res.json())
})

type UpdatePasswordPayload = {
  password: string
  resetCode: string
}

export const updatePassword = createAsyncThunk<any, UpdatePasswordPayload>(
  'password/update',
  async ({resetCode, password}) => {
    const endpoint = `/v1/guest/network/member/password`

    const body = {
      resetCode,
      password,
    }

    return useApi(
      null,
      endpoint,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      },
      'network',
    ).then(res => res.text())
  },
)

export const validateToken = createAsyncThunk<any>(
  'token/validate',
  async () => {
    const endpoint = `/jwt/validate`

    const body = {
      jwt: cookies.get(process.env.REACT_APP_TOKEN_NAME || 'local_bks_token'),
    }

    return useApi(
      null,
      endpoint,
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      'network',
    ).then(res => res.text())
  },
)
//#endregion

//#region slice
const initialState: SliceState = {
  error: undefined,
}

export default createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      state.error = ''
      cookies.remove(process.env.REACT_APP_TOKEN_NAME || 'local_bks_token', {
        path: '/',
        domain:
          process.env.REACT_APP_TOKEN_NAME === 'local_bks_token'
            ? 'localhost'
            : 'blackspectacles.com',
      })
    })
    builder.addCase(login.rejected, (state, action) => {
      state.error = action.error.message
      if (state.error === 'organization cap limit reached') {
        window.location.href = '/cap-limit'
      }
    })
    builder.addCase(login.fulfilled, (state, action) => {
      cookies.set(
        process.env.REACT_APP_TOKEN_NAME || 'local_bks_token',
        action.payload.auth_token,
        {
          path: '/',
          domain:
            process.env.REACT_APP_TOKEN_NAME === 'local_bks_token'
              ? 'localhost'
              : 'blackspectacles.com',
          expires: nextWeek,
        },
      )
    })
    builder.addCase(validateToken.rejected, () => {
      cookies.remove(process.env.REACT_APP_TOKEN_NAME || 'local_bks_token', {
        path: '/',
        domain:
          process.env.REACT_APP_TOKEN_NAME === 'local_bks_token'
            ? 'localhost'
            : '.blackspectacles.com	',
      })
    })
  },
})
//#endregion

//#region selectors
export const selectError = ({auth}: RootState) => auth.error
//#endregion
