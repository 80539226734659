import React, {useState, createContext, useContext} from 'react'
import Cookies from 'universal-cookie'

export interface AuthContextInterface {
  isAuthenticated: boolean
  getAuthToken(): string
  handleSetIsAuthenticated(value: boolean): void
  logout(): void
}

const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface,
)

export const AuthProvider = ({children}: any) => {
  const cookies = new Cookies()

  const [isAuthenticated, setIsAuthenticated] = useState(
    process.env.REACT_APP_X_MEMBER_ID &&
      process.env.REACT_APP_X_MEMBER_ID !== 'blank'
      ? true
      : false,
  )

  const handleSetIsAuthenticated = (value: boolean) => {
    if (
      !process.env.REACT_APP_X_MEMBER_ID ||
      process.env.REACT_APP_X_MEMBER_ID === 'blank'
    ) {
      setIsAuthenticated(value)
    }
  }

  const getAuthToken = () => {
    return cookies.get(process.env.REACT_APP_TOKEN_NAME || 'local_bks_token')
  }

  const logout = () => {
    setIsAuthenticated(false)

    cookies.remove(process.env.REACT_APP_TOKEN_NAME || 'local_bks_token', {
      path: '/',
      domain:
        process.env.REACT_APP_TOKEN_NAME === 'local_bks_token'
          ? 'localhost'
          : 'blackspectacles.com',
    })

    //SEGMENT COOKIES
    cookies.remove('ajs_anonymous_id', {path: '/'})
    cookies.remove('ajs_user_id', {path: '/'})

    window.location.href = 'https://blackspectacles.com/'
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        getAuthToken,
        handleSetIsAuthenticated,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)

  return context
}
