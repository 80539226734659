import React, {useState} from 'react'
import View from './view'
import {Lesson} from '../../redux/lessons'
import {useAppDispatch} from '../../redux/configureStore'
import {
  endExam,
  getExamDetails,
  selectAttemptId,
  selectExam,
  startExam,
  submitAnswer,
} from '../../redux/exams'
import {toggleErrorDialog} from '../../redux/config'
import {useSelector} from 'react-redux'
import {useAuth} from '../AuthProvider'

type Props = {
  lesson?: Lesson
  currentQuestion: number
  setCurrentQuestion: React.Dispatch<React.SetStateAction<number>>
  setAttemptStarted: React.Dispatch<React.SetStateAction<boolean>>
  getNextUrl: () => string
}

const QuizQuestions = ({
  lesson,
  currentQuestion,
  setCurrentQuestion,
  setAttemptStarted,
  getNextUrl,
}: Props) => {
  const auth = useAuth()

  const [answers, setAnswers] = useState<any[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isReviewing, setIsReviewing] = useState(false)

  const dispatch = useAppDispatch()
  const exam = useSelector(selectExam)
  const attemptId = useSelector(selectAttemptId)

  const loadExam = async () => {
    try {
      const currentAttemptId = localStorage.getItem('currentQuizAttemptId')
      if (currentAttemptId) {
        const {type, error}: any = await dispatch(
          getExamDetails({auth, attemptId: currentAttemptId}),
        )
        if (type === getExamDetails.rejected.type) {
          const authError = error.message.includes('authorization failed')
          throw new Error(
            authError ? 'Auth token missing' : 'Unable to load exam',
          )
        }
        setAttemptStarted(true)
      }
    } catch (error) {
      const authError = String(error).includes('Auth token missing')
      await dispatch(
        toggleErrorDialog({
          opened: true,
          login: authError,
          error:
            'We were unable to start the quiz, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const handleStartExam = async () => {
    if (localStorage.getItem('currentQuizAttemptId')) {
      loadExam()
    } else {
      try {
        const {type, payload, error}: any = await dispatch(
          startExam({auth, examId: lesson?.id, origin: 'quiz'}),
        )
        if (type === startExam.fulfilled.type) {
          await dispatch(getExamDetails({auth, attemptId: payload.attempt}))
          setAttemptStarted(true)
        } else if (type === startExam.rejected.type) {
          const authError = error.message.includes('authorization failed')
          throw new Error(
            authError ? 'Auth token missing' : 'Unable to start exam',
          )
        }
      } catch (error) {
        const authError = String(error).includes('Auth token missing')
        await dispatch(
          toggleErrorDialog({
            opened: true,
            login: authError,
            error:
              'We were unable to start the quiz, please retry. If issues persist contact our support team',
          }),
        )
      }
    }
  }

  const currentAttemptId = localStorage.getItem('currentQuizAttemptId')
    ? localStorage.getItem('currentQuizAttemptId')
    : attemptId

  const handleSubmit = async (questionId: string) => {
    setIsSubmitting(true)
    try {
      if (currentAttemptId) {
        const {type, error}: any = await dispatch(
          submitAnswer({
            auth,
            attemptId: currentAttemptId,
            questionId,
            payload: answers[currentQuestion] ? answers[currentQuestion] : '',
          }),
        )
        if (type === submitAnswer.fulfilled.type) {
          setIsSubmitting(false)
        } else if (type === submitAnswer.rejected.type) {
          setIsSubmitting(false)
          setCurrentQuestion(currentQuestion)
          const authError = error.message.includes('authorization failed')
          throw new Error(
            authError ? 'Auth token missing' : 'Unable to submit the answer',
          )
        }
      }
    } catch (error) {
      setIsSubmitting(false)
      const authError = String(error).includes('Auth token missing')
      await dispatch(
        toggleErrorDialog({
          opened: true,
          login: authError,
          error:
            'We were unable to submit your answer, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const handleEndExam = async () => {
    setIsSubmitting(true)
    try {
      const {type, error}: any = await dispatch(
        endExam({auth, attemptId: currentAttemptId!}),
      )
      if (type === endExam.fulfilled.type) {
        localStorage.clear()
      } else if (type === endExam.rejected.type) {
        const authError = error.message.includes('authorization failed')
        throw new Error(authError ? 'Auth token missing' : 'Unable to end quiz')
      }
    } catch (error) {
      const authError = String(error).includes('Auth token missing')
      await dispatch(
        toggleErrorDialog({
          opened: true,
          login: authError,
          error:
            'We were unable to end the quiz, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const handleChangeCurrentQuestion = (value: number) => {
    setCurrentQuestion(value)
    if (exam?.questions && value >= exam?.questions.length) {
      localStorage.removeItem('currentQuizAttemptId')
    }
  }

  const handleChangeAnswers = (value: any) => {
    setAnswers(value)
  }

  const handleChangeIsReviewing = () => {
    setIsSubmitting(true)
    setCurrentQuestion(0)
    setIsReviewing(true)
    setTimeout(() => setIsSubmitting(false), 500)
  }

  return (
    <View
      lesson={lesson}
      onStartExam={handleStartExam}
      onEndExam={handleEndExam}
      exam={exam}
      currentQuestion={currentQuestion}
      onChangeCurrentQuestion={handleChangeCurrentQuestion}
      answers={answers}
      onChangeAnswers={handleChangeAnswers}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
      onChangeIsReviewing={handleChangeIsReviewing}
      isReviewing={isReviewing}
      getNextUrl={getNextUrl}
    />
  )
}

export default QuizQuestions
