import {createTheme} from '@mui/material/styles'

// TODO: Update colors when design guidelines are ready
export const colors = {
  black1: '#707070',
  black2: '#111',
  // slate: '#717171',
  // silver: '#F7F7F7',
  white: '#FFFFFF',
  blue1: '#1377D4',
  blue2: '#56CEFC',
  alert: '#F44C49',
  gray: '#ECEFF1',
  // gray: '#D8D8D8',
  pcm: '#34C5EE',
  pjm: '#C56AC4',
  pa: '#7E7ADF',
  ppd: '#ABD264',
  pdd: '#F26977',
  ce: '#FF9363',
  border: '#EDEDED',
}

const theme = createTheme({
  palette: {
    primary: {
      main: colors.black1,
    },
    secondary: {
      main: colors.blue1,
    },
  },
  typography: {
    h1: {
      fontFamily: 'Freight Disp Pro,sans-serif',
      fontSize: '32px',
      color: '#1377D4',
      letterSpacing: '0.5px',
      textTransform: 'none',
    },
    h2: {
      fontFamily: 'Freight Disp Pro,sans-serif',
      fontSize: 30,
      color: '#1377D4',
      letterSpacing: '0.5px',
      textTransform: 'none',
    },
    h3: {
      fontFamily: 'Public Sans',
      fontSize: '24px',
      color: '#37374F',
      letterSpacing: '0.5px',
      textTransform: 'none',
    },
    h4: {
      // fontFamily: 'Freight Disp Pro, sans-serif',
      // fontSize: 16,
      // color: '',
    },
    body1: {
      fontFamily: 'Public Sans',
      fontSize: '16px',
      color: colors.black1,
      letterSpacing: '0.5px',
    },
    body2: {
      fontFamily: 'Public Sans',
      fontSize: '14px',
      color: colors.black1,
      letterSpacing: '0.5px',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
})

Object.assign(theme, {
  ...theme.typography,
  ...theme.palette,
  overrides: {
    MUIRichTextEditor: {
      placeHolder: {
        paddingLeft: 10,
      },
    },
  },
})

export default theme
