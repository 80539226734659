export const settingsNav = [
  {name: 'Dashboard', url: '/profile/dashboard', slug: 'dashboard'},
  {
    name: 'Manage Subscription',
    url: '/profile/settings/manage',
    slug: 'manage',
  },
  {name: 'Payment Method', url: '/profile/settings/billing', slug: 'billing'},
  {name: 'Billing History', url: '/profile/settings/history', slug: 'history'},
  {name: 'Update Profile', url: '/profile/settings/update', slug: 'update'},
  {
    name: 'Change Password',
    url: '/profile/settings/change-password',
    slug: 'change-password',
  },
  {
    name: 'Report Exam Results',
    url: '/profile/settings/are-progress',
    slug: 'are-progress',
  },
]

export const admingNavSettings = [
  {name: 'Manage Group', url: '/group/manage'},
  {name: 'Group Reporting', url: '/group/reporting'},
  {name: 'Group Billing', url: '/group/billing'},
]
