import {Box, Typography} from '@mui/material'
import styled from 'styled-components'
import theme, {colors} from '../../styles/theme'
import About from '../../images/backgrounds/about.jpg'
import SoftwareBG from '../../images/backgrounds/bg-software.jpg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const HeroContainer = styled(Box)<{
  $isDashboard: boolean
  $isSoftware: boolean
  $heroVariant: string
}>`
  ${({$heroVariant}) =>
    $heroVariant === 'default'
      ? `
    height: 270px;
    justify-content: flex-start;
    background-image: url(${About});

    ${theme.breakpoints.down('md')} {
      clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 100%);
      height: 140px;
    }
  `
      : null}

  ${({$heroVariant}) =>
    $heroVariant === 'small'
      ? `
    height: 330px;
    justify-content: center;
    background-image: url(${SoftwareBG});
    
    ${theme.breakpoints.down('md')} {
      clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 100%);
      height: 200px;
    }
  `
      : null}
  
  ${({$heroVariant}) =>
    $heroVariant === 'large'
      ? `
    height: 418px;
    justify-content: center;
    background-image: url(${About});
    
    ${theme.breakpoints.down('md')} {
      clip-path: polygon(0 0, 100% 0%, 100% 89%, 0 100%);
      height: 310px;
    }
  `
      : null}
  
  ${({$heroVariant}) =>
    $heroVariant === 'dashboard'
      ? `
    height: 418px;
    justify-content: center;
    background-image: url(${About});

    ${theme.breakpoints.down('md')} {
      clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 100%);
      display: none;
    }

  `
      : null}

  width: 100%;
  display: flex;
  align-items: center;
  background-position: 50% 0;
  background-size: cover;
  clip-path: polygon(0 0, 100% 0%, 100% 65%, 0 100%);
  position: relative;
  flex-flow: column;
  gap: 30px;

  h1 {
    color: ${colors.white};
  }

  ${theme.breakpoints.down('md')} {
    h1 {
      font-size: 24px !important;
    }
  }
`

export const StyledLogo = styled.img`
  width: 214px;
  cursor: pointer;
  position: absolute;
  top: 70px;
  left: 50px;

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`

export const FloatingButton = styled.div<{$heroVariant: string}>`
  width: 80px;
  height: 80px;
  color: #fff;
  background-color: #1377d4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  bottom: ${({$heroVariant}) =>
    $heroVariant === 'small'
      ? '15px'
      : $heroVariant === 'large'
      ? '35px'
      : '5px'};

  ${theme.breakpoints.down('md')} {
    bottom: ${({$heroVariant}) =>
      $heroVariant === 'small' ? '-20px' : '-25px'};
  }
`

export const StyledIcon = styled(ExpandMoreIcon)`
  width: 60px !important;
  height: auto !important;
`

export const LoggedOutText = styled(Typography)`
  color: #fff !important;
  font-style: italic;
  display: flex;
  gap: 4px;

  && a {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  && svg {
    font-size: 16px;
    font-style: italic !important;
  }
`

export const StyledTitle = styled(Typography)`
  font-size: 48px !important;
  text-transform: none !important;
  color: #fff !important;
`
