import React from 'react'
import {Box, Divider, Typography} from '@mui/material'

type Props = {
  data?: string
}

const View = ({data}: Props) => {
  return (
    <Box sx={{width: '50vw', maxWidth: '50vw'}}>
      <div>
        <h1>API Response:</h1>
        <Divider />

        <Box
          sx={{
            width: '100%',
            maxWidth: '50vw',
            height: '800px',
            border: '1px solid gray',
            wordWrap: 'break-word',
          }}
        >
          <Typography variant="body1">{data}</Typography>
        </Box>
      </div>
    </Box>
  )
}

export default View
