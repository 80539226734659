export const getStyles = (type?: string, disabled?: boolean) => {
  if (disabled) return disabledStyle
  if (type === 'white') return white
  else if (type === 'whiteGreyBorder') return whiteGreyBorder
  else if (type === 'whiteBlueBorder') return whiteBlueBorder
  else if (type === 'lightGrey') return lightGrey
  else if (type === 'mediumGrey') return mediumGrey
  else if (type === 'secondary') return secondary
  else if (type === 'secondaryHover') return secondaryHover
  else if (type === 'primary') return primary
  else if (type === 'primaryHover') return primaryHover
  else if (type === 'lightBlue') return lightBlue
  else if (type === 'lightBlueHover') return lightBlueHover
  else if (type === 'red') return red
  else if (type === 'redHover') return redHover
  else if (type === 'black') return black
  else if (type === 'green') return green
  else if (type === 'greenHover') return greenHover
  else if (type === 'grey') return grey
  else if (type === 'greyHover') return greyHover
  else if (type === 'blackHover') return blackHover
  else if (type === 'whiteDisabled') return whiteDisabled
  else if (type === 'ncarbExamBlue') return ncarbExamBlue
  else if (type === 'ncarbExamBlueHover') return ncarbExamBlueHover
  else if (type === 'transparent') return transparent

  return {border: '1px solid black'}
}

export const getHoverClass = (type?: string) => {
  if (type === 'white') return 'whiteGreyBorder'
  else if (type === 'whiteGreyBorder') return 'white'
  else if (type === 'lightGrey') return 'mediumGrey'
  else if (type === 'secondary') return 'secondaryHover'
  else if (type === 'primary') return 'primaryHover'
  else if (type === 'lightBlue') return 'lightBlueHover'
  else if (type === 'red') return 'redHover'
  else if (type === 'black') return 'blackHover'
  else if (type === 'green') return 'greenHover'
  else if (type === 'grey') return 'greyHover'
  else if (type === 'whiteDisabled') return 'whiteDisabled'
  else if (type === 'ncarbExamBlue') return 'ncarbExamBlueHover'
}

const red: any = {
  background: '#F44C49',
  color: '#fff',
  fontWeight: 500,
}

const redHover: any = {
  background: '#E8403D',
  color: '#fff',
  fontWeight: 500,
}

const white: any = {
  background: 'white',
  border: '1px solid #2D2D2D',
  fontWeight: 500,
}

const whiteGreyBorder: any = {
  background: 'white',
  border: '1px solid #D8D8D8',
  color: '#979797',
  fontWeight: 500,
}

const whiteBlueBorder: any = {
  background: 'white',
  border: '2px solid #1377D4',
  color: '#1377D4',
  fontWeight: 500,
}

const grey: any = {
  color: '#000',
  backgroundColor: '#ccc',
  fontWeight: 500,
}

const greyHover: any = {
  color: '#000',
  backgroundColor: '#bbb',
  fontWeight: 500,
}

const lightGrey: any = {
  color: '#07bafe',
  backgroundColor: '#f6f6f6',
  fontWeight: 500,
}

const mediumGrey: any = {
  color: '#000',
  backgroundColor: '#f6f6f6',
  fontWeight: 500,
}

const primary: any = {
  color: '#1377D4',
  border: '2px solid #1377D4',
  fontWeight: 500,
}

const primaryHover: any = {
  color: '#FFFFFF',
  backgroundColor: '#1377D4',
  border: '2px solid #1377D4',
  fontWeight: 500,
}

const secondary: any = {
  color: '#FFFFFF',
  backgroundColor: '#1377D4',
  border: '2px solid #1377D4',
  fontWeight: 500,
}

const secondaryHover: any = {
  color: '#1377D4',
  backgroundColor: '#fff',
  border: '2px solid #1377D4',
  fontWeight: 500,
}

const lightBlue: any = {
  color: '#FFFFFF',
  backgroundColor: '#07bafe',
  border: '2px solid #07bafe',
  fontWeight: 500,
}

const lightBlueHover: any = {
  color: '#2D2D2D',
  backgroundColor: '#DDE3F8',
  fontWeight: 500,
}

const black: any = {
  color: '#FFFFFF',
  backgroundColor: '#2d2d2d',
  fontWeight: 500,
}

const blackHover: any = {
  color: '#FFFFFF',
  backgroundColor: '#000000',
  fontWeight: 500,
}

const green: any = {
  color: '#FFFFFF',
  backgroundColor: '#18940A',
  fontWeight: 500,
}

const greenHover: any = {
  color: '#FFFFFF',
  backgroundColor: '#126808',
  fontWeight: 500,
}

const whiteDisabled: any = {
  color: '#07bafe',
  backgroundColor: '#fff',
  border: '2px #07bafe solid',
  cursor: 'wait',
  fontWeight: 500,
  boxSizing: 'border-box',
}

const ncarbExamBlue: any = {
  color: '#FFFFFF',
  backgroundColor: '#07bafe',
  border: '2px solid #07bafe',
  fontWeight: 500,
}

const ncarbExamBlueHover: any = {
  color: '#000',
  backgroundColor: '#fff',
  border: '2px solid #07bafe',
  fontWeight: 500,
}

const disabledStyle: any = {
  color: 'FFFFFF',
  backgroundColor: '#CCCCCC',
  opacity: '.5',
  fontWeight: 500,
}

const transparent: any = {
  background: 'transparent',
  border: '2px solid #1377D4',
  color: 'FFFFFF',
  fontWeight: 500,
}
