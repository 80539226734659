import React, {useState, useRef, useEffect} from 'react'
import {Box, Button, OutlinedInput} from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import theme, {colors} from '../../styles/theme'
import {useWindowDimensions} from '../../hooks/useWindowDimensions'
import {useNavigate} from 'react-router-dom'

type Props = {
  toggleSearchBox: () => void
}

const View = ({toggleSearchBox}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState('')
  const {width} = useWindowDimensions()
  const navigate = useNavigate()
  const isMobile = width <= theme.breakpoints.values.sm

  const handleSearch = (event: any) => {
    event.preventDefault()
    // Search API endpoint
    if (inputValue) {
      toggleSearchBox()
      navigate(`/search/results?search=${inputValue}`, {
        replace: true,
      })
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(inputValue.length, inputValue.length)
    }
  }, [inputValue])

  return (
    <Box
      sx={{width: '100%', maxWidth: '100%', display: 'flex', borderRadius: 0}}
    >
      <form style={{width: '100%', height: 76}} onSubmit={handleSearch}>
        <OutlinedInput
          fullWidth
          label=""
          id="fullWidth"
          placeholder={'Search Black Spectacles'}
          dir={isMobile ? 'ltr' : 'rtl'}
          notched
          inputRef={inputRef}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          autoComplete="off"
          sx={{
            borderRadius: 0,
            height: '100%',
            backgroundColor: `${colors.black1}`,
            color: `${colors.white}`,
            '& input': {
              fontSize: '28px',
              unicodeBidi: 'plaintext',
              textAlign: isMobile ? 'lefet' : 'right',
            },
          }}
        />
        <input type="submit" style={{display: 'none'}} />
      </form>

      <Button
        sx={{
          height: 76,
          width: 76,
          backgroundColor: `${colors.black2}`,
          borderRadius: '0',
          '&:hover': {backgroundColor: `${colors.black2}`},
          display: {xs: 'none', sm: 'none', md: 'block'},
        }}
        onClick={handleSearch}
      >
        <SearchOutlinedIcon
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: `${colors.black2}`,
            color: `${colors.white}`,
            '&:hover': {backgroundColor: `${colors.black2}`},
            display: {xs: 'none', md: 'block'},
          }}
        />
      </Button>
    </Box>
  )
}

export default View
