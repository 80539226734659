import React, {useEffect, useCallback, useState, useContext} from 'react'
import {DefaultRoutes} from './routes'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {selectConfigState, toggleErrorDialog} from './redux/config'
import {useAppDispatch} from './redux/configureStore'
import {ErrorDialog} from './components/ErrorDialog'
import Loading from './components/Loading'
import {useAuth} from './components/AuthProvider'
import {validateToken} from './redux/auth'
import {getMember, selectMember} from './redux/members'
import {getOrganizationCourses, selectOrganizationStatus} from './redux/courses'
import {LoadingStatuses} from './components/types/LoadingStatusses'
import {SegmentContext} from './context'
import {pusher, PusherChannels} from './pusher'
import {setupWootric} from './wootric'
import {getUpgradePrice } from './redux/members'

function App() {
  const [isLoading, setIsLoading] = useState(true)

  const auth = useAuth()
  const {pathname} = useLocation()
  const dispatch = useAppDispatch()

  const {analytics} = useContext(SegmentContext)

  const configState = useSelector(selectConfigState)
  const organizationStatus = useSelector(selectOrganizationStatus)
  const member = useSelector(selectMember)

  const isWhiteboard = pathname.includes('/whiteboard')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (member && member.groupAccounts && member.groupAccounts.length > 0) {
      setupWootric(member.email, member.createDate.toString())

      pusher.subscribe(
        PusherChannels.presenceOrgChannel(
          member?.groupAccounts
            ? member?.groupAccounts[0].organizationId ?? 0
            : 0,
        ),
      )
    }
  }, [member])

  useEffect(() => {}, [configState.isErrorDialogOpen])

  const validate = useCallback(async () => {
    if (
      process.env.REACT_APP_X_MEMBER_ID &&
      process.env.REACT_APP_X_MEMBER_ID !== 'blank'
    ) {
      await dispatch(getMember({auth}))
    } else if (auth.getAuthToken()) {
      const {type} = await dispatch(validateToken())
      if (type === validateToken.fulfilled.type) {
        auth.handleSetIsAuthenticated(true)

        const memberDispatch = await dispatch(getMember({auth}))
        if (memberDispatch.type === getMember.rejected.type) {
          auth.logout()
        }
      } else if (type === validateToken.rejected.type) {
        auth.logout()
      }
    }
    setIsLoading(false)
  }, [dispatch])

  const loadOrganization = useCallback(async () => {
    await dispatch(getOrganizationCourses())
  }, [dispatch])

  const loadUpgradePricePoint = useCallback(async () => {
    await dispatch(getUpgradePrice({auth}))
  }, [dispatch])

  useEffect(() => {
    if (!isWhiteboard) {
      validate()
      loadOrganization()
    }
  }, [validate, loadOrganization])

  useEffect(() => {
    if (analytics && member) {
      analytics.identify(member.id, {
        email: member.email,
      })
    }
  }, [member])

  if (
    (isLoading ||
      organizationStatus === LoadingStatuses.Idle ||
      organizationStatus === LoadingStatuses.Loading) &&
    !isWhiteboard
  )
    return <Loading />

  return (
    <>
      <ErrorDialog
        isOpen={configState.isErrorDialogOpen}
        errorMessage={configState.errorMessage}
        title={configState.title}
        ctaType={configState.ctaType}
        login={configState.login}
        onClose={async () =>
          await dispatch(toggleErrorDialog({opened: false, error: ''}))
        }
      />
      <DefaultRoutes />
    </>
  )
}

export default App
