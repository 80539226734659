import React, {useEffect} from 'react'
import Loading from '../../components/Loading'

const View = () => {
  return (
    <>
      <Loading />
    </>
  )
}

export default View
