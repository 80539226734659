import React, {useState} from 'react'
import {Box} from '@mui/material'
import {Course} from '../../redux/courses'
import {
  FlexBox,
  LessonBody,
  LessonHeader,
  LessonsBox,
} from '../QuizzesList/styles'
import {Link} from 'react-router-dom'
import {useAuth} from '../../components/AuthProvider'
import {ExpandedBox} from './styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import renderHTML from '../../utils/renderHTML'
import hoursMinutes from '../../utils/hoursMinutes'

type Props = {
  courses?: Course[]
}

const View = ({courses}: Props) => {
  const auth = useAuth()

  return (
    <Box id="content" style={{width: '100%'}}>
      <LessonsBox>
        <LessonHeader>
          <Box width={'70%'}>Course Name</Box>
          <Box width={'20%'}>Duration</Box>
          <Box></Box>
        </LessonHeader>
        {courses?.map((course, key) => {
          const [isExpanded, setIsExpanded] = useState(false)

          const courseLessons = course.lessons.sort(
            (a, b) => b.version - a.version,
          )
          const latestLesson = courseLessons[0]

          return (
            <React.Fragment key={key}>
              <LessonBody $isAuthenticated={auth.isAuthenticated}>
                <FlexBox width={'70%'}>
                  <Link to={`/courses/${course.id}`}>{course.title}</Link>
                </FlexBox>
                <FlexBox width={'70%'}>
                  <Link to={`/courses/${course.id}`}>
                    {latestLesson?.duration
                      ? hoursMinutes(latestLesson.duration, true)
                      : ''}
                  </Link>
                </FlexBox>
                <FlexBox
                  marginLeft={'auto'}
                  style={{color: '#c1c1c1', cursor: 'pointer'}}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </FlexBox>
              </LessonBody>
              {isExpanded ? (
                <ExpandedBox>
                  {renderHTML(latestLesson?.description)}
                </ExpandedBox>
              ) : null}
            </React.Fragment>
          )
        })}
      </LessonsBox>
    </Box>
  )
}

export default View
