import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {RootState} from './rootReducer'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
export type Payment = {
  date: number
  description: string
  invoice: string
  amount: number
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  payments: Payment[]
  status: LoadingStatuses
  error?: string | null | undefined
}
//#endregion

//#region api
type billingHistoryPayload = {
  auth: AuthContextInterface
}

export const getBillingHistory = createAsyncThunk<any, billingHistoryPayload>(
  '/getBillingHistory',
  async ({auth}) => {
    const endpoint = `/v1/network/billing/history`

    return useApi(
      auth,
      endpoint,
      {
        method: 'GET',
      },
      'network',
    ).then(res => res.json())
  },
)

//#endregion

//#region slice
const initialState: SliceState = {
  payments: [],
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'billingHistory',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getBillingHistory.fulfilled, (state, action) => {
        state.payments = action.payload.payments
        state.status = LoadingStatuses.Succeeded
        state.error = undefined
      })
      .addCase(getBillingHistory.pending, state => {
        state.payments = []
        state.status = LoadingStatuses.Loading
        state.error = undefined
      })
      .addCase(getBillingHistory.rejected, state => {
        state.payments = []
        state.status = LoadingStatuses.Failed
        state.error = undefined
      })
  },
})
//#endregion

//#region selectors
export const selectPayments = ({billingHistory}: RootState) =>
  billingHistory.payments
//#endregion
