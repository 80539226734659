import React from 'react'
import {Box, Typography, LinearProgress} from '@mui/material'
import {
  SectionProgress,
  SectionProgressContainer,
  SectionTextLink,
} from '../../SectionContainer/styles'
import ExamIcon from '../../../images/icons/exam.svg'
import {Link} from 'react-router-dom'
import {DashboardExams} from '../../../redux/dashboard'
import {Course} from '../../../redux/courses'
import {PrimaryLinkButton} from '../../../styles/global'
import {ButtonBox} from '../StudyMaterials/styles'

type Props = {
  course?: Course
  examsData?: DashboardExams
}

const View = ({examsData, course}: Props) => {
  const percentagePlaceholderValue = examsData?.averagePercentage ?? 0
  const attempts = examsData?.attempts.filter(attempt => {
    if (attempt.completedAt) {
      const diffInMs = Date.now() - new Date(attempt.completedAt).getTime()
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24)
      return diffInDays <= 60
    }

    return false
  })

  return (
    <>
      <Box>
        <Typography variant={'h3'}>Practice Exams</Typography>

        <SectionProgressContainer marginTop={'20px'}>
          <Typography variant={'body1'}>Best Score</Typography>
          <SectionProgress>
            <Box sx={{width: '100%'}}>
              <LinearProgress
                variant="determinate"
                value={percentagePlaceholderValue}
              />
            </Box>

            <Typography variant={'body2'}>
              {Math.floor(percentagePlaceholderValue)}%
            </Typography>
          </SectionProgress>
        </SectionProgressContainer>

        <Box marginTop={'16px'}>
          {attempts && attempts?.length > 0 && (
            <Box marginBottom={'10px'}>
              {attempts?.map(attempt => {
                const dateObject = new Date(attempt.completedAt!)
                const formattedDate = dateObject.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })

                return (
                  <Typography variant={'body2'} key={attempt.attemptId}>
                    <Link to={`/practice-exams/review/${attempt.attemptId}`}>
                      {formattedDate}
                    </Link>{' '}
                    - {attempt.correct}/{attempt.correct + attempt.incorrect}
                  </Typography>
                )
              })}
            </Box>
          )}

          <SectionTextLink
            to={`/practice-exams/${course?.id}`}
            style={{display: 'flex', alignItems: 'center'}}
          >
            <img src={ExamIcon} className="ignore" />
            <span className="ignore">View all attempts</span>
          </SectionTextLink>
        </Box>
      </Box>

      <ButtonBox>
        <PrimaryLinkButton
          component={Link}
          to={`/practice-exams/${course?.id}`}
        >
          Practice
        </PrimaryLinkButton>
      </ButtonBox>
    </>
  )
}

export default View
