import React, {useState, useCallback, useEffect} from 'react'
import View from './view'
import {Helmet} from 'react-helmet'
import {useParams} from 'react-router-dom'
import {useAppDispatch} from '../../redux/configureStore'
import {CourseLesson, getCourse, selectCourse} from '../../redux/courses'
import {getGuestLessons, getLessons, selectLesson} from '../../redux/lessons'
import {
  clearVideoState,
  getGuestVideo,
  getVideo,
  getVideoGroup,
  selectVideo,
  selectVideoGroup,
  trackProgress,
} from '../../redux/videos'
import {useSelector} from 'react-redux'
import {toggleErrorDialog} from '../../redux/config'
import {useAuth} from '../../components/AuthProvider'
import Cookies from 'universal-cookie'
import {selectMemberAccess} from '../../redux/members'

const Videos = () => {
  const {courseId, videoId, status} = useParams()
  const auth = useAuth()
  const dispatch = useAppDispatch()

  const [currentTab, setCurrentTab] = useState('about')
  const [mobileOpen, setMobileOpen] = useState(false)
  const [videoEnded, setVideoEnded] = useState(false)

  const course = useSelector(selectCourse)
  const video = useSelector(selectVideo)
  const videoGroup = useSelector(selectVideoGroup)
  const lesson = useSelector(selectLesson)
  const access = useSelector(selectMemberAccess)

  const filteredGroup = videoGroup
    ?.map((group, index) => {
      const found = group.lessons.some(lesson => lesson.id === videoId)

      return found ? {group, index} : null
    })
    .filter(item => item !== null)[0]

  const currentGroup = filteredGroup?.group

  const nextGroup =
    videoGroup && videoGroup[filteredGroup?.index! + 1]
      ? videoGroup[filteredGroup?.index! + 1]
      : undefined
  const previousGroup =
    videoGroup && videoGroup[filteredGroup?.index! - 1]
      ? videoGroup[filteredGroup?.index! - 1]
      : undefined

  const currentIndex = currentGroup?.lessons.findIndex(
    lesson => lesson.id === videoId,
  )

  const assetIsPracticalApplication =
    currentGroup && currentIndex
      ? currentGroup.lessons[currentIndex].isPracticalApplication
      : false

  const practicalApplication = course?.tags?.find(
    item => item.category === 'PRACTICAL_APPLICATION_COURSE',
  )
  const isPracticalApplication = practicalApplication ? true : false

  const software = course?.tags?.find(
    item => item.category === 'SOFTWARE_COURSE',
  )

  const isSoftware = software ? true : false

  let hasAccess = false
  if (isSoftware) {
    hasAccess =
      access && Object.keys(access).length !== 0
        ? Number(access.software?.videos?.value) === 1
        : false
  } else if (isPracticalApplication || assetIsPracticalApplication) {
    hasAccess =
      access && Object.keys(access).length !== 0
        ? Number(access.are?.practical_apps?.value) === 1
        : false
  } else {
    hasAccess =
      access && Object.keys(access).length !== 0
        ? Number(access.are?.videos?.value) === 1
        : false
  }

  const clearVideo = async () => {
    await dispatch(clearVideoState())
  }

  const handleChangeTab = (value: string) => {
    setCurrentTab(value)
  }

  const handleChangeMobileMenu = (value: boolean) => {
    setMobileOpen(value)
  }

  const handleChangeVideoEnded = (value: boolean) => {
    clearVideo()
    setVideoEnded(value)
  }

  const handleUpdate = async (percent: number) => {
    const courseLessons = course?.lessons.filter(
      (item: CourseLesson) => item.active,
    )

    courseLessons?.sort((a: any, b: any) => b.version - a.version)

    const lessonPlanId =
      courseLessons && courseLessons[0] ? courseLessons[0].id : undefined

    await dispatch(trackProgress({auth, videoId, ts: percent, lessonPlanId}))
  }

  const loadCourse = useCallback(async () => {
    handleChangeVideoEnded(false)
    try {
      const {type, payload} = await dispatch(getCourse({courseId}))
      if (type === getCourse.fulfilled.type) {
        const courseLessons = payload.lessons.filter(
          (item: CourseLesson) => item.active,
        )
        courseLessons.sort((a: any, b: any) => b.version - a.version)
        const lessonId = courseLessons[0].id
        let lessons
        if (auth.isAuthenticated) {
          lessons = await dispatch(getLessons({auth, lessonId}))
        } else {
          lessons = await dispatch(getGuestLessons({lessonId}))
        }
        if (
          lessons.type === getLessons.fulfilled.type ||
          lessons.type === getGuestLessons.fulfilled.type
        ) {
          await dispatch(
            getVideoGroup({
              lessons: lessons.payload.assets,
              courseId,
              title: payload.title,
            }),
          )
        } else if (
          lessons.type === getLessons.rejected.type ||
          lessons.type === getGuestLessons.rejected.type
        ) {
          throw new Error('Unable to get video')
        }
      } else if (type === getCourse.rejected.type) {
        throw new Error('Unable to get video')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the video, please retry reloading the page. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch, courseId])

  const loadVideo = useCallback(async () => {
    try {
      setMobileOpen(false)
      if (auth.isAuthenticated && hasAccess) {
        const {type} = await dispatch(getVideo({auth, videoId, isSoftware}))
        if (type === getVideo.rejected.type) {
          throw new Error('Unable to get video')
        }
      } else if (status === 'free') {
        const {type} = await dispatch(getGuestVideo({videoId}))
        if (type === getGuestVideo.rejected.type) {
          throw new Error('Unable to get video')
        }
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the video, please retry reloading the page. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch, videoId])

  const cookies = new Cookies()

  useEffect(() => {
    loadCourse()
    loadVideo()

    if (cookies.get('bks-autoplay') === undefined) {
      cookies.set('bks-autoplay', true, {
        path: '/',
      })
    }

    return () => {
      clearVideo()
    }
  }, [loadCourse, loadVideo])

  return (
    <>
      {video && (
        <Helmet>
          <title>{`${video?.title} | Black Spectacles`}</title>
        </Helmet>
      )}
      <View
        videoId={videoId!}
        video={video}
        course={course}
        currentTab={currentTab}
        onChangeTab={handleChangeTab}
        mobileOpen={mobileOpen}
        onChangeMobileMenu={handleChangeMobileMenu}
        onUpdate={handleUpdate}
        onChangeVideoEnded={handleChangeVideoEnded}
        videoEnded={videoEnded}
        lesson={lesson}
        hasAccess={hasAccess}
        status={status}
        nextGroup={nextGroup}
        currentGroup={currentGroup}
        previousGroup={previousGroup}
        currentIndex={currentIndex}
      />
    </>
  )
}

export default Videos
