import React, {useEffect} from 'react'
import {Exam} from '../../redux/exams'
import {CheckBox} from '../VideoEnded/styles'
import {ExplanationBox} from '../QuizQuestions/styles'
import {CheckallRow} from './styles'
import renderHTML from '../../utils/renderHTML'

type Props = {
  exam?: Exam
  currentQuestion: number
  isReviewing?: boolean
  onSelect: (value: any) => void
  answer?: any
  currentAnswer?: any
}

const ExamCheckall = ({
  exam,
  currentQuestion,
  isReviewing,
  onSelect,
  answer,
  currentAnswer,
}: Props) => {
  const question = exam?.questions[currentQuestion]

  const selectCheckallAnswer = (value: number) => {
    let tempAnswers: any[] | undefined = []

    const currentAnswers = answer?.split('|')

    if (!currentAnswers?.includes(String(value)) || !currentAnswers) {
      tempAnswers = question?.answers.map(answer => {
        return currentAnswers?.includes(String(answer.displayOrder)) ||
          answer.displayOrder === value
          ? answer.displayOrder
          : null
      })
    } else {
      tempAnswers = question?.answers.map(answer => {
        return currentAnswers?.includes(String(answer.displayOrder)) &&
          answer.displayOrder !== value
          ? answer.displayOrder
          : null
      })
    }

    tempAnswers = tempAnswers?.filter(item => item !== null)

    onSelect(tempAnswers?.join('|'))
  }

  useEffect(() => {
    if (currentAnswer) {
      onSelect(currentAnswer)
    }
  }, [question])

  return (
    <>
      {question?.answers.map(item => {
        const arrAnswer = answer?.includes('|')
          ? answer?.split('|')
          : answer?.split(',')

        return (
          <React.Fragment key={item.idDisplay}>
            <CheckallRow
              $isReviewing={isReviewing}
              $isCorrectAnswer={item.correctOption}
              $isActive={arrAnswer?.includes(String(item.displayOrder))}
            >
              <CheckBox
                $isActive={arrAnswer?.includes(String(item.displayOrder))}
                onClick={() =>
                  !isReviewing &&
                  !question.isBlocked &&
                  selectCheckallAnswer(item.displayOrder)
                }
              >
                {arrAnswer?.includes(String(item.displayOrder)) && '✓'}
              </CheckBox>
              {item.label ? (
                <span>{item.label}</span>
              ) : (
                <img src={item.image} alt="Option" />
              )}
            </CheckallRow>
            {isReviewing && (
              <ExplanationBox>
                {renderHTML(item.answerExplanation)}
              </ExplanationBox>
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default ExamCheckall
