import React from 'react'
import View from './view'

type Props = {
  open: boolean
  closeForm: () => void
  editMode: boolean
  cardId?: string
  updateCardId: (value: string) => void
}

const CustomCard = ({
  open,
  closeForm,
  updateCardId,
  editMode,
  cardId,
}: Props) => {
  return (
    <View
      open={open}
      closeForm={closeForm}
      editMode={editMode}
      cardId={cardId}
      updateCardId={updateCardId}
    />
  )
}

export default CustomCard
