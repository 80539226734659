import React, {useState} from 'react'
import {DateFilter} from '../../pages/GroupReporting'
import {DateRangePicker} from 'react-date-range'
import {FlexBox} from './styles'
import {Button} from '../button'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

type Props = {
  dateFilter: DateFilter
  onChangeDateFilter: (dateFilter: DateFilter) => void
}

const DateSelector = ({dateFilter, onChangeDateFilter}: Props) => {
  const [state, setState] = useState([
    {
      ...dateFilter,
      key: 'selection',
    },
  ])

  const handleChangeDate = () => {
    if (state[0]) {
      const dates = {
        startDate: state[0].startDate,
        endDate: state[0].endDate,
      }

      onChangeDateFilter(dates)
    }
  }

  return (
    <FlexBox>
      <DateRangePicker
        onChange={(item: any) => setState([item.selection])}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
        rangeColors={['#1377D4']}
        showDateDisplay={false}
        inputRanges={[]}
        staticRanges={[]}
      />

      <Button color="primary" children="apply" onClick={handleChangeDate} />
    </FlexBox>
  )
}

export default DateSelector
