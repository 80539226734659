import React, {useState} from 'react'
import {Alert, Box, TextField} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {login as dispatchLogin, selectError} from '../../redux/auth'

import CloseIcon from '@mui/icons-material/Close'

import {Button} from '../button'

import {
  ButtonBox,
  DialogBox,
  DialogText,
  DialogTitle,
  ErrorDialog,
  FormBox,
} from './styles'
import {useAppDispatch} from '../../redux/configureStore'
import {useSelector} from 'react-redux'

export type Props = {
  isOpen: boolean
  errorMessage: string
  title?: string
  ctaType?: string
  login?: boolean
  onClose: () => void
}

const View = ({
  isOpen,
  title,
  ctaType,
  errorMessage,
  login,
  onClose,
}: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const error = useSelector(selectError)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleClick = () => {
    if (ctaType === 'login') {
      navigate(`/login`, {
        replace: true,
      })
    }

    onClose()
  }

  const handleLogin = async () => {
    setIsSubmitting(true)
    const {type} = await dispatch(dispatchLogin({username, password}))
    if (type === dispatchLogin.fulfilled.type) {
      window.location.reload()
      setIsSubmitting(false)
    }
  }

  const renderCta = () => {
    if (ctaType === 'login') return 'Log in to update us with your ARE progress'

    return 'Close'
  }

  return (
    <ErrorDialog maxWidth={'md'} open={isOpen} onClose={onClose}>
      <DialogTitle>
        {title ? title : 'Error'}

        <CloseIcon
          onClick={onClose}
          className="x-button"
          style={{
            height: 32,
            width: 32,
            cursor: 'pointer',
          }}
        />
      </DialogTitle>
      <DialogBox>
        <Box>
          <DialogText>
            {login
              ? 'It looks like your session has expired. Please log in again to continue using the application. Thank you!'
              : errorMessage}
          </DialogText>
          {login && (
            <form onSubmit={handleLogin}>
              <FormBox>
                <TextField
                  onChange={e => setUsername(e.target.value)}
                  label="Username"
                />
                <TextField
                  onChange={e => setPassword(e.target.value)}
                  label="Password"
                  type="password"
                />
              </FormBox>
              {error ? (
                <Alert severity="error" style={{marginTop: '8px'}}>
                  {error}
                </Alert>
              ) : null}
            </form>
          )}
        </Box>
        <ButtonBox $isFullWidth={ctaType === 'login'}>
          {login && (
            <Button
              onClick={handleLogin}
              children={`Login`}
              color={'secondary'}
              size={'medium'}
              disabled={isSubmitting}
            />
          )}
          <Button
            onClick={handleClick}
            children={`${renderCta()}`}
            color={'primary'}
            size={'medium'}
          />
        </ButtonBox>
      </DialogBox>
    </ErrorDialog>
  )
}

export default View
