import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../../hooks/useApi'

//#region types
export type Product = {
  id: string
  name: string
  active: boolean
  amount: number
  type: string
  duration?: number
  site: string
  display_name: string
  audience: string
  product_details: string
}

export type CartProducts = {
  products?: Product[]
}

//#endregion

const compare = (a: any, b: any) => {
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
}

//#region api
type GetProductsPayload = {
  productIds: string[]
}
export const getProductsInfo = createAsyncThunk<any, GetProductsPayload>(
  '/getProducts',
  async ({productIds}) => {
    const fields = {
      products: productIds,
    }

    return useApi(
      null,
      `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/guest/payment/products`,
      {
        method: 'POST',
        body: JSON.stringify(fields),
      },
    ).then(async res => {
      const results = await res.json()
      const products = results?.products

      // sort products order in store to adjust display order
      if (products.length > 1) {
        products.sort((a: any, b: any) => compare(a, b))
      }

      return results
    })
  },
)
//#endregion
