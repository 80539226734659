import React from 'react'
import {Box, Typography} from '@mui/material'
import {useSelector} from 'react-redux'
import {Button} from '../../button'
import {ButtonBox, ImageBox, Row} from './styles'
import {studyGuideURLs} from '../../../constants/studyGuides'
import {Course} from '../../../redux/courses'
import {useAuth} from '../../AuthProvider'
import StudyGuide from '../../../images/icons/study_guide.svg'
import {selectMemberAccess} from '../../../redux/members'
import {Link} from 'react-router-dom'

type Props = {
  course: Course
}

const View = ({course}: Props) => {
  const auth = useAuth()

  const access = useSelector(selectMemberAccess)

  const goToStudyGuide = () => {
    const url = studyGuideURLs[course.abbreviation]
    window.open(url)
  }

  const hasAccess =
    access && Object.keys(access).length !== 0
      ? Number(access.are?.study_guides?.value) === 1
      : false

  return (
    <Box className="ignore" sx={{width: '100% !important'}}>
      <Row>
        <Typography variant={'h3'}>Study Guide</Typography>
        {auth.isAuthenticated && hasAccess && (
          <ButtonBox>
            <Button
              color={'primary'}
              children={'Get Started'}
              onClick={goToStudyGuide}
              event="file_download"
            />
          </ButtonBox>
        )}
      </Row>
      {!hasAccess && auth.isAuthenticated && (
        <Row>
          <ImageBox $isDisabled={true}>
            <img src={StudyGuide} className="ignore" />
            <Link to="/profile/settings/manage">Get Started</Link>
          </ImageBox>
        </Row>
      )}
      {!auth.isAuthenticated && (
        <Row>
          <ImageBox $isDisabled={true}>
            <img src={StudyGuide} className="ignore" />
            <a href="https://learn.blackspectacles.com/pricing">Get Started</a>
          </ImageBox>
        </Row>
      )}
    </Box>
  )
}

export default View
