import React from 'react'
import {Typography} from '@mui/material'
import {
  FloatingButton,
  HeroContainer,
  LoggedOutText,
  StyledIcon,
  StyledLogo,
  StyledTitle,
} from './styles'
import {Link, useLocation, useParams} from 'react-router-dom'
import BksLogo from '../../images/logo-white.svg'
import {useAuth} from '../AuthProvider'
import LockIcon from '@mui/icons-material/Lock'
import {useSelector} from 'react-redux'
import {selectMember} from '../../redux/members'
import {useQuery} from '../../hooks/useQuery'
import {selectSearchResults} from '../../redux/search'

const View = () => {
  const auth = useAuth()
  const {topic} = useParams()
  const query = useQuery()

  const member = useSelector(selectMember)
  const searchResults = useSelector(selectSearchResults)

  const location = useLocation()

  const isDashboard = location.pathname.includes('/dashboard')
  const isGroupAdmin = location.pathname.includes('/group')
  const isSettingsPage = !isDashboard
    ? location.pathname.includes('/profile')
    : false
  const isSoftware = location.pathname.includes('/topics/')
  const isSearch = location.pathname.includes('/search/results')
  const isGuarantee = location.pathname.includes('/guarantee')

  let title = ''
  let heroVariant = 'default'

  if (isDashboard) {
    title = 'My Dashboard'
    heroVariant = 'dashboard'
  } else if (!auth.isAuthenticated && !isGuarantee) {
    if (isDashboard) {
      title = 'Preview Dashboard'
    } else {
      title = 'Welcome Back'
    }

    heroVariant = 'dashboard'
  } else if (isGroupAdmin) {
    title = 'Group Membership'
    heroVariant = 'dashboard'
  } else if (isSettingsPage && member) {
    title = `Welcome back, ${member.firstName}!`
    heroVariant = 'dashboard'
  } else if (isSoftware && topic) {
    title = `${topic.replaceAll('-', ' ')} Tutorials`
    heroVariant = 'small'
  } else if (isSearch) {
    const keywords = query.get('search')
    const total = searchResults?.total || 0
    title = `${total} Results for '${keywords}'`
    heroVariant = 'small'
  } else if (isGuarantee) {
    title = 'ARE Expert Guarantee'
    heroVariant = 'large'
  }

  return (
    <div style={{position: 'relative'}}>
      <HeroContainer
        $isDashboard={isDashboard || isGroupAdmin || isSettingsPage}
        $isSoftware={isSoftware || isSearch}
        $heroVariant={heroVariant}
      >
        {isDashboard ||
        isGroupAdmin ||
        isSettingsPage ||
        isSoftware ||
        isGuarantee ||
        isSearch ? (
          <>
            <StyledTitle variant="h1">{title}</StyledTitle>
            {isGuarantee && (
              <Typography variant="body1" style={{color: '#fff'}}>
                <em>Do the work. Get rewarded.</em>
              </Typography>
            )}
            {!auth.isAuthenticated && !isGuarantee ? (
              <LoggedOutText variant={'body1'}>
                <Link to={'/login'}>
                  <LockIcon /> Log in
                </Link>{' '}
                or <a href="https://www.blackspectacles.com/pricing">sign up</a>{' '}
                to view your personalized dashboard
              </LoggedOutText>
            ) : null}
            <Link to={'/'}>
              <StyledLogo src={BksLogo} alt="Black Spectacles" />
            </Link>
          </>
        ) : (
          <Link to={'/'}>
            <StyledLogo src={BksLogo} alt="Black Spectacles" />
          </Link>
        )}
      </HeroContainer>
      {!isDashboard && !isGroupAdmin && !isSettingsPage && (
        <FloatingButton
          $heroVariant={heroVariant}
          onClick={() =>
            document
              .getElementById('content')
              ?.scrollIntoView({behavior: 'smooth'})
          }
        >
          <StyledIcon />
        </FloatingButton>
      )}
    </div>
  )
}

export default View
