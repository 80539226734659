import React, {useEffect} from 'react'
import {Exam} from '../../redux/exams'
import {ExplanationBox} from '../QuizQuestions/styles'
import renderHTML from '../../utils/renderHTML'
import {InputBox, StyledInput} from './styles'

type Props = {
  exam?: Exam
  currentQuestion: number
  isReviewing?: boolean
  onSelect: (value: any) => void
  answer?: any
  currentAnswer?: any
}

const ExamBlank = ({
  exam,
  currentQuestion,
  isReviewing,
  onSelect,
  answer,
  currentAnswer,
}: Props) => {
  const question = exam?.questions[currentQuestion]

  useEffect(() => {
    if (currentAnswer) {
      onSelect(currentAnswer)
    }
  }, [])

  return (
    <>
      <InputBox>
        <span>{question?.answers[0].prefix}</span>
        <StyledInput value={answer} onChange={e => onSelect(e.target.value)} />
        <span>{question?.answers[0].postfix}</span>
      </InputBox>
      {isReviewing && (
        <ExplanationBox>
          {renderHTML(question?.answers[0].explanation ?? '')}
        </ExplanationBox>
      )}
    </>
  )
}

export default ExamBlank
