import {Box} from '@mui/material'
import styled from 'styled-components'
import theme from '../../styles/theme'

export const FlashCardPageContainer = styled(Box)`
  display: flex;

  ${theme.breakpoints.down('md')} {
    display: block;
  }
`
