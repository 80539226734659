export const invoiceItems = [
  {
    item: 'are5_videos',
    title: 'ARE 5.0 Video Tutorials',
    description:
      'Access to all ARE 5.0 Prep Lectures and the Practical Application video series for duration of license',
  },
  {
    item: 'are5_exams',
    title: 'ARE 5.0 Practice Exams',
    description:
      'Access to all ARE 5.0 Prep Practice Exams for duration of license',
  },
  {
    item: 'are5_flashcards',
    title: 'ARE 5.0 Flashcards',
    description:
      'Access to our full library of ARE 5.0 Digital Flashcards for duration of license',
  },
  {
    item: 'are5_pro',
    title: 'ARE 5.0 Pro',
    description:
      'Access to all ARE 5.0 Prep Lectures (incl. Practical Applications), Practice Exams, and Digital Flashcards for duration of license',
  },
  {
    item: 'are5_expert',
    title: 'ARE 5.0 Content',
    description:
      'Access to all ARE 5.0 Online Video Lectures, Practice Exams, Digital Flashcards & Practical Applications. Weekly Live Virtual Workshops, led by licensed architects',
  },
  {
    item: 'software_standard',
    title: 'Software Learning Standard',
    description:
      'Access to all software learning video tutorials for duration of license',
  },
  {
    item: 'software_premium',
    title: 'Software Learning Premium',
    description:
      'Access to all software learning video tutorials and exercise files for duration of license',
  },
]
