import React from 'react'
import {
  ButtonBox,
  Caption,
  CloseButton,
  CloseButtonMobile,
  ErrorBox,
  FormBox,
  LeftBox,
  LoginBox,
  LoginContainer,
  RightBox,
  SubTitle,
  Title,
} from './styles'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import {Button} from '../../components/button'

type Props = {
  onChangeEmail: (value: string) => void
  onSubmit: () => void
  error?: string
  isSubmitting: boolean
  isComplete: boolean
}

const View = ({
  onChangeEmail,
  onSubmit,
  error,
  isSubmitting,
  isComplete,
}: Props) => {
  const handleLogin = (e: any) => {
    onSubmit()
    e.preventDefault()
  }

  return (
    <LoginContainer>
      <form onSubmit={handleLogin}>
        <LoginBox>
          <LeftBox>
            <CloseButtonMobile>
              <a href="https://www.blackspectacles.com/en-us/">
                <CloseIcon fontSize="large" />
              </a>
            </CloseButtonMobile>
            <Caption>Turbocharge your way to a kickass career</Caption>
          </LeftBox>
          <RightBox>
            <CloseButton>
              <a href="https://www.blackspectacles.com/en-us/">
                <CloseIcon fontSize="large" />
              </a>
            </CloseButton>
            <Title variant={'h1'}>Forgot password?</Title>
            <SubTitle>
              {isComplete ? (
                <>
                  Check your inbox - we just sent you an email with reset
                  instructions.
                </>
              ) : (
                <>
                  Enter the email address associated with your membership, and
                  we'll send you instructions for resetting your password.
                </>
              )}
            </SubTitle>
            {!isComplete && (
              <>
                <FormBox>
                  <TextField
                    onChange={e => onChangeEmail(e.target.value)}
                    label="Email Address"
                    type="email"
                    variant="standard"
                  />
                </FormBox>
                <span
                  style={{
                    fontSize: '14px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  * Please note that if you have a Black Spectacles and a
                  Spectacular account your password will be reset for both
                  profiles
                </span>
                <ErrorBox>{error ?? ''}</ErrorBox>
                <ButtonBox>
                  <Button
                    children={'Reset my password'}
                    color={'primary'}
                    disabled={isSubmitting}
                    type="submit"
                  />
                </ButtonBox>
              </>
            )}
          </RightBox>
        </LoginBox>
      </form>
    </LoginContainer>
  )
}

export default View
