import {Box, Typography} from '@mui/material'
import styled from 'styled-components'
import theme from '../../styles/theme'
import {Rnd} from 'react-rnd'

export const ExamHeader = styled(Box)`
  height: 107px;
  background-color: #07bafe;
  color: #fff;
  display: flex;
  position: sticky;
  z-index: 2;
  top: 0;
  width: 100%;
  font-family: Verdana;
`

export const LogoBox = styled(Box)`
  padding: 0 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-left: 10px solid #fff;
  border-right: 10px solid #fff;

  && img {
    height: 98px;
  }

  ${theme.breakpoints.down('md')} {
    padding: 0 2px;

    && img {
      height: 90px;
    }
  }
`

export const TitleBox = styled(Box)`
  display: flex;
  flex-flow: column;
  padding-top: 5px;
  box-sizing: border-box;
  width: 100%;
`

export const UpperBox = styled(Box)`
  display: flex;
`

export const LeftBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 8px;

  && h1 {
    font-size: 24px !important;
    line-height: 30px !important;
    font-weight: 400;
    padding-left: 10px;
    margin: 0;
  }

  && span {
    font-size: 18px;
    line-height: 24px;
    padding-left: 10px;
  }

  ${theme.breakpoints.down('md')} {
    && h1 {
      font-size: 18px !important;
      line-height: 20px !important;
    }

    && span {
      font-size: 16px;
      line-height: 20px;
    }
  }
`

export const RightBox = styled(Box)`
  margin-left: auto;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  width: 350px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    gap: 12px;
  }
`

export const ColumnBox = styled(Box)`
  display: flex;
`

export const ColumnItem = styled(Box)`
  width: 50%;
  text-align: right;
  padding-right: 20px;
  font-size: 18px;
`

export const Toolbar = styled(Box)`
  background-color: #ddd;
  box-sizing: border-box;
  padding: 0 20px;
  height: 28px;
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 32px;

  ${theme.breakpoints.down('md')} {
    justify-content: flex-end;
    gap: 24px;
  }
`

export const ToolbarItem = styled.button`
  position: relative;
  color: #07bafe;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 20px;
  background: transparent;
  border: 0;
  font-family: Verdana;

  &:hover {
    color: #000;
  }
`

export const ColoredBox = styled.div`
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 1px solid #000;
`

export const ColoredBoxBig = styled.div`
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border: 1px solid #000;
  cursor: pointer;
`

export const ColorPicker = styled.div<{$isOpen?: boolean}>`
  position: absolute;
  background-color: #80AEE1;
  border: 1px solid #000;
  padding: 10px 20px;
  display: ${({$isOpen}) => ($isOpen ? 'flex;' : 'none;')}
  flex-flow: column;
  gap: 10px;
  z-index: 10;
  cursor: default;
  top: 25px;
`

export const ToolbarSubItem = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ColorPickerRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  && span {
    color: #fff;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const PreExamBox = styled(Box)`
  padding: 80px 160px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Verdana;
  flex-flow: column;
  gap: 20px;

  ${theme.breakpoints.down('md')} {
    padding: 10px;
  }
`

export const ExamBox = styled(Box)`
  font-family: Verdana;
  font-size: 16px;
  box-sizing: border-box;
  padding: 0 15px 90px;
  position: relative;

  && table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  ${theme.breakpoints.down('md')} {
    padding: 0 10px 80px;
  }
`

export const Title = styled(Typography)`
  font-family:
    Freight Disp Pro,
    Helvetica,
    Arial,
    sans-serif;
  font-size: 36px !important;
  letter-spacing: 0.05em;
  line-height: 44px;
  color: #000 !important;
  text-transform: none !important;
  font-weight: bold !important;
`

export const CheckallRow = styled(Box)<{
  $isReviewing: boolean
  $isCorrectAnswer: boolean
  $isActive: boolean
}>`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  cursor: pointer;

  && span {
    ${({$isReviewing, $isActive, $isCorrectAnswer}) =>
      $isReviewing
        ? $isActive
          ? `font-weight: bold; ${
              $isCorrectAnswer
                ? `color:#50ad3d;`
                : `color: #f44336; text-decoration: line-through;`
            }`
          : $isCorrectAnswer
          ? `color:#50ad3d;`
          : `color: #f44336; text-decoration: line-through;`
        : null}
  }
`

export const FixedBottom = styled(Box)`
  width: -webkit-fill-available;
  width: -moz-available;
  height: 76px;
  background: #fff;
  border-top: 2px gray solid;
  position: fixed;
  bottom: 0;
  padding-left: 160px;
  box-sizing: border-box;
  font-family: Verdana;

  display: flex;
  gap: 16px;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    justify-content: flex-end;
    padding: 8px;
    flex-wrap: wrap;
    height: auto;
    gap: 8px;

    && button {
      height: 32px !important;
      width: 100% !important;
      flex: 40%;
    }
  }

  && button {
    border-radius: 0 !important;
  }
`

export const StyledLogo = styled.img`
  height: 49px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`

export const PreTitle = styled(Typography)<{$isWarning?: boolean}>`
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 40px !important;

  ${theme.breakpoints.down('md')} {
    text-align: center;
  }

  ${({$isWarning}) =>
    $isWarning
      ? `
      color: #f44336 !important
    `
      : `color:#000 !important;`}
`

export const PreBody = styled(Typography)<{$isWarning?: boolean}>`
  font-size: 16px;
  line-height: 18px !important;
  margin-top: 24px !important;
  ${({$isWarning}) =>
    $isWarning
      ? `
      color: #f44336 !important
    `
      : `color:#000 !important;`}
`

export const MarkReviewBox = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  ${theme.breakpoints.down('md')} {
    flex: 40%;
    order: 4;
  }
`

export const CheckBox = styled(Box)`
  border: black solid 1px;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SummaryTitle = styled(Typography)`
  font-family: inherit !important;
  font-size: 25px !important;
  letter-spacing: 0.05em;
  color: #000 !important;
  text-transform: none !important;
  margin-top: 36px !important;
`

export const SummaryBox = styled(Box)`
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 8px;
  margin-top: 8px;
`

export const SummaryItem = styled(Box)`
  background-color: #ddd;
  padding: 5px 10px;
  font-size: 18px;
`

export const SummaryDescription = styled(Box)`
  background-color: #eee;
  padding: 10px 35px 20px;
  font-size: 15px;
  line-height: 20px;

  && span {
    text-decoration: underline;
  }
`

export const SummaryQuestions = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  ${theme.breakpoints.down('md')} {
    flex-flow: column;
  }
`

export const SummaryQuestionsItem = styled(Box)<{$isWhite?: boolean}>`
  flex: 30%;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  gap: 8px;
  align-items: center;

  ${({$isWhite}) =>
    $isWhite
      ? `
        background-color: #fff;
    `
      : `background-color:#eee;`}
`

export const QuestionLink = styled.span`
  color: #07bafe;
  cursor: pointer;
  font-size: 15px;

  &:hover {
    text-decoration: underline;
  }
`

export const QuestionStatus = styled.span<{status: string}>`
  margin-left: auto;
  font-size: 15px;

  ${({status}) =>
    status === 'Incomplete' || status === 'Incorrect'
      ? `color: #f44336;`
      : status === 'Complete' || status === 'Correct'
      ? `color:#26cc26;`
      : `color:#000;`}
`

export const BreakBox = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: #00b8fe;
  width: 100vw;
  height: 100vh;
  color: #fff;
`

export const BreakTitle = styled(Box)`
  font-size: 80px;
`

export const BreakText = styled(Box)`
  margin-top: 40px;
  font-size: 18px;
  display: flex;
  flex-flow: column;
  gap: 10px;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    padding: 0 40px;
    text-align: center;
  }
`

export const ScoreBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 24px;
  width: 100%;
  padding-bottom: 80px;
`

export const SectionTitle = styled(Typography)`
  font-size: 20px !important;
  font-weight: 700 !important;
  font-family: Verdana !important;
`

export const SectionBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  && a {
    font-family:
      Freight Disp Pro,
      Helvetica,
      Arial,
      sans-serif;
    font-size: 18px;
  }

  ${theme.breakpoints.down('md')} {
    flex-flow: column;
    margin-bottom: 24px;
    gap: 12px;
    padding-left: 20px;
  }
`

export const SectionDescription = styled(Typography)`
  font-size: 18px !important;
  padding-left: 24px;

  ${theme.breakpoints.down('md')} {
    padding-left: 0;
  }
`

export const WhiteboardBox = styled(Rnd)`
  background-color: #f3f3f3;
  border: 1px solid black;
  box-sizing: border-box !important;
  z-index: 10;
  position: fixed !important;

  && iframe {
    width: 100%;
    height: calc(100% - 27px);
    overflow: auto;
    margin: 0;
    padding: 0;
  }
`

export const WhiteboardHeader = styled(Box)`
  font-size: 14px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;

  && span {
    cursor: pointer;
    color: #07bafe;
  }
`

export const CaseStudyLabel = styled(Typography)`
  padding-bottom: 10px;
  font-family: Verdana !important;
  font-size: 16px !important;

  && span {
    color: #07bafe !important;
    font-weight: bold !important;
  }
`

export const LeftButtonBox = styled(Box)`
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  flex-flow: column;
  gap: 8px;

  && button {
    height: 32px !important;
  }

  && span {
    font-size: 10.5px;
    color: #ccc;
    text-align: right;
  }

  ${theme.breakpoints.down('md')} {
    margin-left: 0;
    margin-right: 0;
  }
`
