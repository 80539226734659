export type ObjectType = {
  PcM: string
  PjM: string
  PA: string
  PPD: string
  PDD: string
  CE: string
}

export const studyGuideURLs: ObjectType = {
  PcM: 'https://bksps-live-public.s3.us-east-2.amazonaws.com/study_plans/BKS_Study_Guide_PCM_2023.pdf',
  PjM: 'https://bksps-live-public.s3.us-east-2.amazonaws.com/study_plans/BKS_Study_Guide_PJM_2023.pdf',
  PA: 'https://bksps-live-public.s3.us-east-2.amazonaws.com/study_plans/BKS_Study_Guide_PA_2023.pdf',
  PPD: 'https://bksps-live-public.s3.us-east-2.amazonaws.com/study_plans/BKS_Study_Guide_PPD_2023.pdf',
  PDD: 'https://bksps-live-public.s3.us-east-2.amazonaws.com/study_plans/BKS_Study_Guide_PDD_2023.pdf',
  CE: 'https://bksps-live-public.s3.us-east-2.amazonaws.com/study_plans/BKS_Study_Guide_CE_2023.pdf',
}
