import React, {useState, useEffect} from 'react'
import {Box, Typography} from '@mui/material'
import TextField from '../../../TextField'
import {FormContainer, RowContainer} from './styles'
import {Button} from '../../../button'
import {useAppDispatch} from '../../../../redux/configureStore'
import {useSelector} from 'react-redux'
import {
  getMember,
  updateMemberProfile,
  selectMember,
} from '../../../../redux/members'
import {useAuth} from '../../../AuthProvider'
import {toggleErrorDialog} from '../../../../redux/config'
import {StyledTitle} from '../../../../styles/global'

interface LabelProps {
  text: string
  required?: boolean
}

const Label = ({text, required}: LabelProps) => {
  return (
    <>
      <span>{text}</span>
      {required && <span style={{color: '#3057E1'}}> * </span>}
    </>
  )
}

const View = () => {
  const auth = useAuth()
  const member = useSelector(selectMember)
  const dispatch = useAppDispatch()
  const [isDisabled, setIsDisabled] = useState(false)
  const [successMessageStatus, setSuccessMessageStatus] = useState(false)

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    updateEmail: false,
  })

  const onFieldChange = (fieldName: string, value: string) => {
    // if email field value is different then current email, set flag to true
    if (fieldName === 'email') {
      setFormValues({
        ...formValues,
        [fieldName]: value,
        updateEmail: member && member.email != value ? true : false,
      })
    } else {
      setFormValues({...formValues, [fieldName]: value})
    }

    setSuccessMessageStatus(false)
  }

  const handleSubmit = async () => {
    try {
      const {type} = await dispatch(updateMemberProfile({auth, ...formValues}))

      if (type === updateMemberProfile.rejected.type) {
        throw new Error('Unable to update profile')
      }
      setSuccessMessageStatus(true)
      await dispatch(getMember({auth}))
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to update your profile, please retry. If issue persists please contact our support team',
        }),
      )
    }
    return
  }

  // if member data isn't available or if member data matches fields then disable submission or if any field is empty
  const validateFields = () => {
    if (
      !member ||
      (member.firstName === formValues.firstName &&
        member.lastName === formValues.lastName &&
        member.email === formValues.email) ||
      !formValues.firstName ||
      !formValues.lastName ||
      !formValues.email
    ) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }

  useEffect(() => {
    if (member) {
      setFormValues({
        firstName: member.firstName,
        lastName: member.lastName,
        email: member.email,
        updateEmail: false,
      })
    }
  }, [member])

  useEffect(() => {
    validateFields()
  })

  return (
    <Box>
      {successMessageStatus ? (
        <Typography>
          Your profile has been successfully updated! If you updated your email,
          please check your inbox to verify your new email address.
        </Typography>
      ) : null}
      <StyledTitle variant={'h2'} style={{marginBottom: '32px'}}>
        Update Profile
      </StyledTitle>

      <Typography>
        Your current name and email are listed below. You can update any of
        these fields and submit to save these changes.
      </Typography>

      <FormContainer>
        <form>
          <RowContainer>
            <TextField
              label={<Label text="First name" required={true} />}
              name={'firstName'}
              variant={'outlined'}
              fullWidth={true}
              onChange={e => onFieldChange('firstName', e.target.value)}
              value={formValues.firstName}
            />
            <TextField
              label={<Label text="Last name" required={true} />}
              name={'firstName'}
              variant={'outlined'}
              fullWidth={true}
              onChange={e => onFieldChange('lastName', e.target.value)}
              value={formValues.lastName}
            />
          </RowContainer>

          <RowContainer style={{justifyContent: 'end'}}>
            <Button
              color={'primary'}
              children={'Update Profile'}
              disabled={isDisabled}
              onClick={handleSubmit}
            />
          </RowContainer>
        </form>
      </FormContainer>
    </Box>
  )
}

export default View
