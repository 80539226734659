import React from 'react'
import {
  Caption,
  CloseButtonMobile,
  LeftBox,
  LoginBox,
  LoginContainer,
  RightBox,
  SubTitle,
} from './styles'
import CloseIcon from '@mui/icons-material/Close'
import {Link} from 'react-router-dom'
import {Divider, Typography} from '@mui/material'

const View = () => {
  return (
    <LoginContainer>
      <LoginBox>
        <LeftBox>
          <CloseButtonMobile>
            <a href="https://www.blackspectacles.com/en-us/">
              <CloseIcon fontSize="large" />
            </a>
          </CloseButtonMobile>
          <Caption>Turbocharge your way to a kickass career</Caption>
        </LeftBox>
        <RightBox sx={{gap: '20px'}}>
          <Typography variant="h1">Cap Limit Reached</Typography>
          <Divider />
          <Typography variant={'body1'}>
            Your group has reached its maximum simultaneous user limit. When one
            of your teammates logs out you will be able to log in.
          </Typography>
          <SubTitle style={{marginTop: 'auto'}}>
            <Link to="/login">Go back to login</Link>
          </SubTitle>
        </RightBox>
      </LoginBox>
    </LoginContainer>
  )
}

export default View
