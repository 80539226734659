import React, {useEffect, useCallback} from 'react'
import {useParams} from 'react-router-dom'
import View from './view'
import {useAppDispatch} from '../../redux/configureStore'
import {getCourse, selectCourse, selectCourseStatus} from '../../redux/courses'
import {toggleErrorDialog} from '../../redux/config'
import {useSelector} from 'react-redux'
import {LoadingStatuses} from '../../components/types/LoadingStatusses'
import Loading from '../../components/Loading'
import {Helmet} from 'react-helmet'
import {
  getExamAttempts,
  getExamList,
  selectExams,
  selectExamsStatus,
  selectInProgress,
} from '../../redux/exams'
import {useAuth} from '../../components/AuthProvider'

const ExamsList = () => {
  const auth = useAuth()
  const {courseId} = useParams()

  const dispatch = useAppDispatch()

  const course = useSelector(selectCourse)
  const exams = useSelector(selectExams)
  const courseStatus = useSelector(selectCourseStatus)
  const examsStatus = useSelector(selectExamsStatus)
  const inProgress = useSelector(selectInProgress)

  const loadCourse = useCallback(async () => {
    try {
      const {type, payload} = await dispatch(getCourse({courseId}))
      if (type === getCourse.rejected.type) {
        throw new Error('Unable to load course')
      } else if (type === getCourse.fulfilled.type) {
        localStorage.setItem('currentCourseTitle', payload.title)
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the exams, please retry. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch, courseId])

  const loadExams = useCallback(async () => {
    try {
      if (auth.isAuthenticated) {
        const {type} = await dispatch(
          getExamAttempts({auth, courseId: courseId!}),
        )
        if (type === getExamAttempts.rejected.type) {
          throw new Error('Unable to load exams')
        }
      } else {
        const {type} = await dispatch(getExamList({courseId: courseId!}))
        if (type === getExamAttempts.rejected.type) {
          throw new Error('Unable to load exams')
        }
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the exams, please retry. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch, courseId])

  useEffect(() => {
    loadCourse()
    loadExams()
  }, [loadCourse])

  if (
    courseStatus === LoadingStatuses.Loading ||
    courseStatus === LoadingStatuses.Idle ||
    examsStatus === LoadingStatuses.Loading ||
    examsStatus === LoadingStatuses.Idle
  ) {
    return <Loading />
  }

  return (
    <>
      {course && (
        <Helmet>
          <title>{`ARE 5.0 ${course.title} Practice Exam | Black Spectacles`}</title>
        </Helmet>
      )}
      <View course={course} exams={exams} inProgress={inProgress} />
    </>
  )
}

export default ExamsList
