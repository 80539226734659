import React from 'react'
import {Box} from '@mui/material'
import {PaymentMethodContainer, PaymentMethodIframeContainer} from './styles'

const View = () => {
  return (
    <Box>
      <PaymentMethodContainer>
        <PaymentMethodIframeContainer
          id="billing-widget-iframe"
          src={process.env.REACT_APP_UPDATE_PAYMENT_METHOD_URL}
        ></PaymentMethodIframeContainer>
      </PaymentMethodContainer>
    </Box>
  )
}

export default View
