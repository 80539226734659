import React, {ChangeEvent} from 'react'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {SearchResults, selectSearchResultsStatus} from '../../redux/search'
import {
  FlexBox,
  MainPagination,
  SearchBox,
  SearchContainer,
  StyledStack,
} from './styles'
import {LoadingStatuses} from '../../components/types/LoadingStatusses'
import Loading from '../../components/Loading'

type Props = {
  results?: SearchResults
  onChangePage: (event: ChangeEvent<unknown>, value: number) => void
  keywords: string | null
}

const View = ({results, keywords, onChangePage}: Props) => {
  const resultsStatus = useSelector(selectSearchResultsStatus)
  return (
    <SearchContainer id="content">
      <FlexBox>
        {(resultsStatus === LoadingStatuses.Loading ||
          resultsStatus === LoadingStatuses.Idle) &&
        keywords ? (
          <Loading />
        ) : (
          <>
            {results && results.results.length > 0 ? (
              <>
                <SearchBox>
                  {results?.results.map((result, key) => {
                    return (
                      <React.Fragment key={`results-${key}`}>
                        <Link
                          to={`/courses/${result.courseId}/video/${result.videoId}`}
                        >
                          {result.title}
                        </Link>
                      </React.Fragment>
                    )
                  })}
                </SearchBox>
              </>
            ) : (
              <>No results found</>
            )}
          </>
        )}
        <StyledStack spacing={2}>
          <MainPagination
            onChange={onChangePage}
            count={Math.ceil(
              results && results.total > 0 ? results.total / 10 : 0,
            )}
            variant="outlined"
            shape="rounded"
          />
        </StyledStack>
      </FlexBox>
    </SearchContainer>
  )
}

export default View
