import React, {useState} from 'react'
import View, {FormProps} from './view'
import {Helmet} from 'react-helmet'
import {useAppDispatch} from '../../redux/configureStore'
import {resendActivationEmail} from '../../redux/organizations'
import {toggleErrorDialog} from '../../redux/config'

const AccountActivationConfirm = () => {
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const dispatch = useAppDispatch()

  const handleSubmit = async (values: FormProps) => {
    try {
      const {type, payload} = await dispatch(
        resendActivationEmail({email: values.email}),
      )
      if (type === resendActivationEmail.rejected.type) {
        throw new Error('Unable to resend email')
      } else if (type === resendActivationEmail.fulfilled.type) {
        if (payload.error) {
          setErrorMessage(payload.error)
        } else {
          setIsSuccessDialogOpen(true)
          setErrorMessage('')
        }
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to acctivate your account, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>{`Account Activation | Black Spectacles`}</title>
      </Helmet>
      <View
        onSubmit={handleSubmit}
        errorMessage={errorMessage}
        isSuccessDialogOpen={isSuccessDialogOpen}
        handleChangeIsSuccessDialogOpen={setIsSuccessDialogOpen}
      />
    </>
  )
}

export default AccountActivationConfirm
