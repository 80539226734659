import React, {useCallback, useEffect, useState} from 'react'
import View, {FormProps} from './view'
import {Helmet} from 'react-helmet'
import {useQuery} from '../../hooks/useQuery'
import {useAppDispatch} from '../../redux/configureStore'
import {
  activateGroupMember,
  getMemberInvitation,
  selectInvitedMember,
} from '../../redux/organizations'
import {toggleErrorDialog} from '../../redux/config'
import {useSelector} from 'react-redux'
import SuccessView from './successView'

const AccountActivation = () => {
  const [isSuccess, setIsSuccess] = useState(false)

  const query = useQuery()
  const inviteKey = query.get('key') as string

  const dispatch = useAppDispatch()
  const member = useSelector(selectInvitedMember)

  const loadMember = useCallback(async () => {
    try {
      if (inviteKey) {
        const {type} = await dispatch(getMemberInvitation({inviteKey}))
        if (type === getMemberInvitation.rejected.type) {
          throw new Error('Unable to load data')
        }
      } else {
        throw new Error('Unable to load data')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the data, please retry. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch, inviteKey])

  const handleSubmit = async (values: FormProps) => {
    try {
      if (member) {
        const {type} = await dispatch(
          activateGroupMember({
            inviteKey,
            password: values.password,
            firstName: member.firstName,
            lastName: member.lastName,
          }),
        )
        if (type === activateGroupMember.rejected.type) {
          throw new Error('Unable to activate account')
        } else if (type === activateGroupMember.fulfilled.type) {
          setIsSuccess(true)
        }
      } else {
        throw new Error('Unable to activate account')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to acctivate your account, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  useEffect(() => {
    loadMember()
  }, [loadMember])

  return (
    <>
      <Helmet>
        <title>{`Account Activation | Black Spectacles`}</title>
      </Helmet>
      {isSuccess ? <SuccessView /> : <View onSubmit={handleSubmit} />}
    </>
  )
}

export default AccountActivation
