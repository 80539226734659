import {Box} from '@mui/material'
import styled from 'styled-components'

export const InputBox = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const StyledInput = styled.input`
  border: 1px #1377d4 solid;
  width: 100%;
  padding: 12px;
  font-family: Public Sans;
  font-style: italic;
  text-transform: none;
  max-width: 280px;
  box-sizing: border-box;
`
