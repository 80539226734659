import {Box} from '@mui/material'
import styled from 'styled-components'
import theme from '../../../styles/theme'

export const ModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1100px;
  max-width: 90%;
  background-color: #fff;
  border: 8px solid #eceff1;
  padding: 45px 25px;
  max-height: 80vh;
  overflow-y: auto;

  ${theme.breakpoints.down('sm')} {
    width: 80%;
  }
`

export const CloseContainer = styled(Box)`
  position: absolute;
  top: 20px;
  right: 20px;
`

export const FormTitle = styled(Box)`
  padding: 15px;
  border-bottom: 1px px solid #e5e5e5;
`

export const FormBody = styled(Box)`
  padding: 15px;
`
