import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Course} from '../../redux/courses'
import {CourseBox, StyledImage, SubTitle, Title} from './styles'
import {LeftBox} from '../../styles/global'
import {Box, Typography} from '@mui/material'
import {useWindowDimensions} from '../../hooks/useWindowDimensions'
import theme from '../../styles/theme'
import renderHTML from '../../utils/renderHTML'
import {practicalApplicationImages} from '../../constants/practical-application-images'
import {
  CheckArea,
  FlexBox,
  LessonBody,
  LessonBorder,
  LessonHeader,
  LessonsBox,
  StyledCheckIcon,
} from '../Courses/styles'
import {useAuth} from '../../components/AuthProvider'
import {Link} from 'react-router-dom'
import NotViewedIcon from '../../images/icons/eye-gray.svg'
import NotViewedIconHover from '../../images/icons/eye-blue.svg'
import LockIcon from '@mui/icons-material/Lock'
import {selectMemberAccess, getUpgradePrice} from '../../redux/members'
import {useAppDispatch} from '../../redux/configureStore'

type Props = {
  courses: Course[]
}

const View = ({courses}: Props) => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const access = useSelector(selectMemberAccess)

  const {width} = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  const hasAccess =
    access && Object.keys(access).length !== 0
      ? Number(access.are?.practical_apps?.value) === 1
      : false

  const handleUpgradeUrl = async (e:any) => {
    e.preventDefault();
    const resp = await dispatch(getUpgradePrice({auth}))
    console.log(resp.payload)
    window.location.href = String(resp.payload.priceId).length > 0 ? 'https://checkout.blackspectacles.com/' + String(resp.payload.priceId) : '#'
  }
  
  return (
    <CourseBox id="content">
      {courses.map((course, key) => {
        return (
          <LeftBox key={course.id} style={{width: '100%'}}>
            <StyledImage
              src={practicalApplicationImages[key]}
              alt={course.title}
            />

            <Title>
              {course.assets ? course.assets[0].shortDescription : null}
            </Title>
            <SubTitle>
              {course.videos} video(s) |{' '}
              {course.duration ? Math.ceil(course.duration / 60) : 0}m
            </SubTitle>

            <Typography
              variant="body1"
              marginTop={'40px'}
              marginBottom={'30px'}
            >
              {renderHTML(course.description)}
            </Typography>

            <LessonsBox>
              {course.assets?.map((asset, key) => {
                const [isHover, setIsHover] = useState(false)
                return (
                  <React.Fragment key={key}>
                    {key === 0 && (
                      <LessonHeader marginTop={'24px'} style={{border: 0}}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          gap={'8px'}
                          width={isMobile ? '85%' : '70%'}
                        ></Box>
                        {!isMobile && <Box width={'10%'}>Duration</Box>}
                        <Box
                          width={isMobile ? '15%' : '10%'}
                          textAlign={'center'}
                        >
                          Viewed
                        </Box>
                        {!isMobile && <Box width={'10%'}></Box>}
                      </LessonHeader>
                    )}

                    {asset.assetTypeAbbr === 'video' && (
                      <LessonBorder
                        $isAuthenticated={
                          asset.isFree === 1 ||
                          (hasAccess && auth.isAuthenticated)
                        }
                      >
                        <LessonBody
                          onMouseEnter={() => setIsHover(true)}
                          onMouseLeave={() => setIsHover(false)}
                        >
                          <FlexBox width={isMobile ? '85%' : '70%'}>
                            <Link
                              to={`/courses/${course.id}/video/${asset.id}${
                                asset.isFree ? '/free' : ''
                              }`}
                            >
                              {asset.shortDescription}
                            </Link>
                          </FlexBox>
                          {!isMobile && (
                            <FlexBox width={'10%'}>
                              <em>{asset.videoDuration}</em>
                            </FlexBox>
                          )}
                          <FlexBox
                            width={isMobile ? '15%' : '10%'}
                            justifyContent={'center'}
                          >
                            {auth.isAuthenticated || asset.isFree ? (
                              <>
                                {!hasAccess && !asset.isFree ? (
                                  <>
                                    <Link to={'#'} onClick={handleUpgradeUrl}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '2px',
                                      }}
                                    >
                                      <LockIcon /> Upgrade
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    {asset.isComplete ? (
                                      <CheckArea variant="body2">
                                        <StyledCheckIcon />
                                      </CheckArea>
                                    ) : (
                                      <img
                                        src={
                                          isHover
                                            ? NotViewedIconHover
                                            : NotViewedIcon
                                        }
                                        alt="Viewed"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <Link
                                to={'/login'}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '2px',
                                }}
                              >
                                <LockIcon style={{fontSize: '16px'}} /> Log in
                              </Link>
                            )}
                          </FlexBox>

                          {!isMobile && <Box width={'10%'}></Box>}
                        </LessonBody>
                      </LessonBorder>
                    )}
                  </React.Fragment>
                )
              })}
            </LessonsBox>
          </LeftBox>
        )
      })}
    </CourseBox>
  )
}

export default View
