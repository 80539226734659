import React from 'react'
import View from './view'

type Props = {
  updateData: (data: string) => void
}

const ApiList = ({updateData}: Props) => {
  return <View updateData={updateData} />
}

export default ApiList
