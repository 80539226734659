import React, {useState, useEffect} from 'react'
import {Box, Typography, Modal} from '@mui/material'
import {
  ModalContainer,
  FormContainer,
  FormHeader,
  FormRow,
  FormBody,
  ButtonRow,
} from './styles'
import {Button} from '../../../button'
import TextField from '../../../TextField'
import CloseIcon from '@mui/icons-material/Close'
import {useAppDispatch} from '../../../../redux/configureStore'
import {
  createCustomCard,
  updateCustomFlashcard,
  selectCustomCards,
  selectDeckId,
} from '../../../../redux/flashcard'
import {useSelector} from 'react-redux'
import {useAuth} from '../../../AuthProvider'
import {useWindowDimensions} from '../../../../hooks/useWindowDimensions'
import theme from '../../../../styles/theme'
import {toggleErrorDialog} from '../../../../redux/config'

type Props = {
  open: boolean
  closeForm: () => void
  editMode: boolean
  cardId?: string
  updateCardId: (value: string) => void
}

interface LabelProps {
  text: string
  required?: boolean
}

const style = {
  background: 'rgba(25, 191, 254, 0.9)',
  '&& .MuiModal-backdrop': {
    background: 'none',
  },
}

const Label = ({text, required}: LabelProps) => {
  return (
    <>
      <span style={{color: '#2d2d2d'}}>{text}</span>
      {required && <span style={{color: '#3057E1'}}> * </span>}
    </>
  )
}

const View = ({open, closeForm, updateCardId, editMode, cardId}: Props) => {
  const {width} = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const [formValues, setFormValues] = useState({
    front: '',
    back: '',
    tags: '',
  })
  const deckId = useSelector(selectDeckId)
  const customCards = useSelector(selectCustomCards)
  const [message, setMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleClose = () => {
    closeForm()
    updateCardId('')
    setFormValues({
      front: '',
      back: '',
      tags: '',
    })
  }

  const handleFieldChange = (key: string, value: string) => {
    setFormValues({...formValues, [key]: value})
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    if (editMode) {
      handleCustomCardEdit()
    } else {
      handleCustomCardCreation()
    }
  }

  const handleCustomCardEdit = async () => {
    if (cardId) {
      const fields = {
        auth,
        deckId,
        side1: formValues.front,
        side2: formValues.back,
        objective: formValues.tags,
        cardId,
      }

      try {
        const {type} = await dispatch(updateCustomFlashcard(fields))

        handleClose()
        setIsSubmitting(false)

        if (type === updateCustomFlashcard.rejected.type) {
          throw new Error('Unable to edit flashcard')
        }

        setMessage('Card updated!')
      } catch (e) {
        await dispatch(
          toggleErrorDialog({
            opened: true,
            error:
              'We were unable to edit flashcard, please retry. If issues persist contact our support team',
          }),
        )
      }
    }
  }

  const handleCustomCardCreation = async () => {
    const fields = {
      auth,
      deckId,
      side1: formValues.front,
      side2: formValues.back,
      objective: formValues.tags,
    }
    try {
      const {type} = await dispatch(createCustomCard(fields))

      handleClose()
      setIsSubmitting(false)

      if (type === createCustomCard.rejected.type) {
        throw new Error('Unable to create flashcard')
      }

      setMessage('Card Created!')
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to create flashcard, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  useEffect(() => {
    if (cardId && customCards) {
      const card = customCards.filter((card: any) => card.id === cardId)[0]

      if (card) {
        const fields = {
          front: card.side1,
          back: card.side2,
          tags: card.title || '',
        }
        setFormValues(fields)
      }
    } else {
      setFormValues({
        front: '',
        back: '',
        tags: '',
      })
    }
  }, [cardId])

  return (
    <Modal sx={style} className="form-modal" open={open} onClose={handleClose}>
      <ModalContainer className="modal-container">
        <FormContainer>
          <FormHeader>
            <Typography variant={'h2'} style={{color: '#2d2d2d'}}>
              {editMode ? 'Edit ' : 'Create '} Custom Flashcard
            </Typography>

            <Box
              sx={{
                position: 'absolute',
                top: '15px',
                right: '15px',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CloseIcon fontSize={isMobile ? 'small' : 'large'} />
            </Box>
          </FormHeader>

          <form>
            <FormBody>
              <FormRow>
                <TextField
                  label={<Label text="Front" required={true} />}
                  name={'front'}
                  variant={'filled'}
                  placeholder={'Ex: Term'}
                  fullWidth={true}
                  value={formValues?.front}
                  onChange={e => handleFieldChange('front', e.target.value)}
                />
              </FormRow>

              <FormRow>
                <TextField
                  label={<Label text="Back" required={true} />}
                  name={'back'}
                  variant={'filled'}
                  placeholder={'Ex: Definition'}
                  fullWidth={true}
                  value={formValues?.back}
                  multiline
                  rows={4}
                  onChange={e => handleFieldChange('back', e.target.value)}
                />
              </FormRow>

              <FormRow>
                <TextField
                  label={<Label text="Custom Tag(s)" />}
                  name={'customTags'}
                  variant={'filled'}
                  placeholder={'Ex: Consultants'}
                  fullWidth={true}
                  value={formValues?.tags}
                  onChange={e => handleFieldChange('tags', e.target.value)}
                />
              </FormRow>

              <ButtonRow>
                <Button
                  color={'primary'}
                  children={editMode ? 'Update Card' : 'Create Card'}
                  size={'large'}
                  onClick={handleSubmit}
                  disabled={
                    isSubmitting || !formValues.back || !formValues.front
                  }
                />

                <Typography>{message}</Typography>
              </ButtonRow>
            </FormBody>
          </form>
        </FormContainer>
      </ModalContainer>
    </Modal>
  )
}

export default View
