import React, {useState} from 'react'
import {FixedBottom, PreBody, PreExamBox, PreTitle, StyledLogo} from './styles'
import {Button} from '../button'
import BksLogo from '../../images/black-spectacles.svg'

type Props = {
  onStartExam: () => void
}

const PreExam = ({onStartExam}: Props) => {
  const [currentStep, setCurrentStep] = useState(0)
  const courseTitle = localStorage.getItem('currentCourseTitle')

  const renderText = () => {
    if (currentStep === 0) {
      return (
        <>
          <PreTitle variant={'h1'}>
            Black Spectacles {courseTitle} Practice Exam
          </PreTitle>
          <PreBody>
            Welcome to the Black Spectacles ARE 5.0 Practice Exam. This exam is
            designed to mimic the full experience of the actual ARE. This
            practice exam will randomly generate the same number of questions
            you would be given when taking the ARE. All of your answers and exam
            results will be available to you for review on your exam summary
            page.
            <br />
            <br />
            You will be given the same amount of exam time allotted as when
            taking the ARE. You will also have a total of 30 minutes of break
            time to use at any point by clicking the <b>Break</b> button in the
            top navigation.
            <br />
            <br />
            For the most similar experience to the ARE, we recommend using a 23"
            monitor on Internet Explorer 11 in fullscreen mode on Windows 7. For
            this practice exam, you can enable and disable fullscreen mode by
            pressing the <b>F11</b> key (Windows) or <b>Cmd + Ctrl + F</b> (Mac)
            on your keyboard.
          </PreBody>
          <PreBody>
            <b>Click the "Next" button to continue.</b>
          </PreBody>
        </>
      )
    } else if (currentStep === 1) {
      return (
        <>
          <PreTitle variant={'h1'} $isWarning={true}>
            Heads-up:
          </PreTitle>
          <PreBody $isWarning={true}>
            When taking the real exam, this page will require you to agree to
            NCARB's Candidate Agreement which is viewable on ncarb.org
          </PreBody>
        </>
      )
    } else if (currentStep === 2) {
      return (
        <>
          <PreTitle variant={'h1'}>Final Testing Reminders</PreTitle>
          <PreBody>
            Black Spectacles Practice Exams do not allow the use of default
            internet browser navigation buttons (forward, back, and keyboard
            shortcuts). These will result in unsaved responses. For best
            navigation please only use the buttons in the bottom navigation (ex:{' '}
            <b>Next, Previous</b>, and <b>Exam Summary</b>)
            <br />
            <br />
            Good luck!
          </PreBody>
          <PreTitle variant={'h1'} $isWarning={true}>
            Heads-up:
          </PreTitle>
          <PreBody $isWarning={true}>
            When taking the real exam, this page is where you would view the
            final testing reminders with instructions if you need assistance at
            any point.
          </PreBody>
        </>
      )
    }
  }

  return (
    <>
      <PreExamBox>{renderText()}</PreExamBox>
      <FixedBottom>
        <Button
          children={'NEXT'}
          onClick={() =>
            currentStep === 2 ? onStartExam() : setCurrentStep(currentStep + 1)
          }
          color="ncarbExamBlue"
          style={{fontFamily: 'Verdana !important'}}
        />

        <StyledLogo src={BksLogo} alt="Black Spectacles" />
      </FixedBottom>
    </>
  )
}

export default PreExam
