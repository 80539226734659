import React from 'react'
import {Analytics} from '@segment/analytics-next'

export interface SegmentState {
  analytics: Analytics | undefined
}

const initialState = {
  analytics: undefined,
}

const SegmentContext = React.createContext<SegmentState>(initialState)

const SegmentConsumer = SegmentContext.Consumer

export {SegmentContext, SegmentConsumer}
