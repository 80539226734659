import {Box} from '@mui/material'
import styled from 'styled-components'
import theme, {colors} from '../../../../styles/theme'

export const ModalContainer = styled(Box)``

export const FormContainer = styled(Box)`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: #fff;
  width: 703px;
  padding: 45px 25px;
  margin: 8px;
  z-index: 10;
  opacity: 1;
  border: 8px solid #eceff1;
  background: #fff;

  ${theme.breakpoints.down('md')} {
    width: calc(100% - 16px);
    box-sizing: border-box;
    padding: 10px;
    margin: 0;
    top: 10px;
  }
`

export const FormHeader = styled(Box)`
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
  text-align: center;

  && h2 {
    color: ${colors.black1};
  }
`

export const FormRow = styled(Box)`
  margin-bottom: 55px;

  && input {
    border: 1px solid ${colors.blue1};

    .Mui-focused > fieldset {
      border: 1px solid red !important;
    }
  }

  && input:focus {
    border: 2px solid ${colors.black1};
    border-radius: 5px;
  }

  && textarea {
    border: 1px solid ${colors.blue1};

    .Mui-focused > fieldset {
      border: 1px solid red !important;
    }
  }

  && textarea:focus {
    border: 2px solid ${colors.black1};
    border-radius: 5px;
  }

  && div.MuiInputBase-root::before {
    border-bottom: none !important;
  }

  && div.MuiInputBase-root::after {
    border-bottom: unset !important;
  }
`

export const FormBody = styled(Box)`
  padding: 15px;
  margin-top: 30px;
`

export const RowLabel = styled(Box)`
  margin: 10px 0;
`

export const ButtonRow = styled(Box)`
  display: flex;
  align-items: center;
  gap: 25px;
`
