import React, {useCallback, useEffect, useState} from 'react'
import View from './view'
import {Helmet} from 'react-helmet'
import {useAuth} from '../../components/AuthProvider'
import {
  getReportedExamResults,
  selectNcarbAttempts,
} from '../../redux/reportExamStatus'
import {useAppDispatch} from '../../redux/configureStore'
import {toggleErrorDialog} from '../../redux/config'
import {useSelector} from 'react-redux'
import {selectOrganization} from '../../redux/courses'
import ClaimView from './claimView'

const Guarantee = () => {
  const auth = useAuth()
  const dispatch = useAppDispatch()

  const [currentDivision, setCurrentDivision] = useState('')

  const attempts = useSelector(selectNcarbAttempts)

  const handleDataFetch = useCallback(async () => {
    if (auth.isAuthenticated) {
      try {
        const {type} = await dispatch(getReportedExamResults({auth}))
        if (type === getReportedExamResults.rejected.type) {
          throw new Error('Unable to get exam results')
        }
      } catch (e) {
        await dispatch(
          toggleErrorDialog({
            opened: true,
            error:
              'We were unable to get exam results, please retry. If issues persist contact our support team',
          }),
        )
      }
    }
  }, [])

  useEffect(() => {
    handleDataFetch()
  }, [])

  const organization = useSelector(selectOrganization)

  const courses = organization?.courses.filter(course => {
    return ['PcM', 'PjM', 'PA', 'PPD', 'PDD', 'CE'].includes(
      course.abbreviation,
    )
  })

  const filteredCourses = courses?.filter(course => {
    return (
      attempts?.some(
        attempt => attempt.courseId === course.id && attempt.passed !== null,
      ) &&
      !attempts?.some(
        attempt => attempt.courseId === course.id && attempt.passed,
      )
    )
  })

  const selectedCoure = courses?.find(
    item => item.abbreviation.toUpperCase() === currentDivision,
  )

  const handleChangeDivision = (value: string) => {
    setCurrentDivision(value)
    window.scrollTo(0, 0)
  }

  return (
    <>
      <Helmet>
        <title>{`${
          currentDivision ? 'ARE Prep Program Survey' : 'Guarantee'
        } | Black Spectacles`}</title>
      </Helmet>
      {currentDivision ? (
        <ClaimView course={selectedCoure} attempts={attempts} />
      ) : (
        <View
          courses={filteredCourses}
          onChangeDivision={handleChangeDivision}
        />
      )}
    </>
  )
}

export default Guarantee
