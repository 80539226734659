export const getSoftwareFileName = (name: string) => {
  const folder = 'project_files'

  const files = [
    {
      name: 'Exterior Renderings in 3ds Max',
      file: `${folder}/exterior-renderings-in-3ds-max.zip`,
    },
    {
      name: 'Creating an Interior Scene in Vray for 3ds Max',
      file: `${folder}/16.0006_-_Vray_for_3ds_Max_and_Photoshop.zip`,
    },
    {
      name: 'Architecture Software Crash Course',
      file: `${folder}/14.0005-Exercise_Files.zip`,
    },
    {
      name: 'Rendering Production Pt 2: Materials, Lighting & Rendering in 3ds Max Design',
      file: `${folder}/Exercise_Files_11.0009.zip`,
    },
    {
      name: 'Introduction to 3DS Max Design',
      file: `${folder}/Exercise_Files.zip`,
    },
    {
      name: 'Rendering Production Pt 3: Post Production in After Effects & Photoshop',
      file: `${folder}/Exercise_Files_11.0009.zip`,
    },
    {
      name: 'ArchiCAD For Beginners',
      file: `${folder}/17.0001_JB_ArchiCAD_Exercise_Files.zip`,
    },
    {
      name: 'ArchiCAD Intermediate Level Training',
      file: `${folder}/17.0005_JB_ArchiCAD_Project_Files.zip`,
    },
    {
      name: 'AutoCAD 2015 Tutorial Basics- Conceptual and Schematic Design',
      file: `${folder}/14.0002-Exercise_Files.zip`,
    },
    {
      name: 'The Basics of Creating Drawings in AutoCAD',
      file: `${folder}/Exercise_Files__LG_11.0001.zip`,
    },
    {
      name: 'The Basics of Computational Design in DynamoBIM',
      file: `${folder}/Black_Spectacles_Dynamo_-_Participants-exercise_files.zip`,
    },
    {
      name: 'Advanced Computational Design in Dynamo',
      file: `${folder}/advanced-computational-design-in-dynamo.zip`,
    },
    {
      name: 'Organization and Working with Data in Grasshopper',
      file: `${folder}/15.0004-Exercise_Files.zip`,
    },
    {
      name: 'Architectural Prototyping with Rhino 5 and Grasshopper',
      file: `${folder}/15.0003-Exercise_Files.zip`,
    },
    {
      name: 'Conceptual & Schematic Design in Rhino 5',
      file: `${folder}/14.0003-Exercise_Files.zip`,
    },
    {
      name: 'Introduction to Parametric Design in Grasshopper for Rhino',
      file: `${folder}/Exercise_Files_13.0002.zip`,
    },
    {
      name: 'Collaboration Techniques with Grasshopper, Python and Hoopsnake',
      file: `${folder}/12_0016_Exercise_Files.zip`,
    },
    {
      name: 'Rhino to Revit Interoperability',
      file: `${folder}/rhino-to-revit-interoperability.zip`,
    },
    {
      name: 'Surface Modeling in Grasshopper',
      file: `${folder}/12.0013_Exercise_Files.zip`,
    },
    {
      name: 'Parametric Areas with Grasshopper',
      file: `${folder}/12.0012_Exericse_Files.zip`,
    },
    {
      name: 'Dynamically add detail to your Rhino 3d model with Grasshopper',
      file: `${folder}/Exercise_Files_12.0015.zip`,
    },
    {
      name: 'Introduction to RhinoScripting in Grasshopper',
      file: `${folder}/12.0001.Exercise_Files.zip`,
    },
    {
      name: 'Portfolios and Presentations in InDesign',
      file: `${folder}/12.0008_Exercise_Files.zip`,
    },
    {
      name: 'Introduction to Maxwell Render',
      file: `${folder}/ExerciseFiles_13.0001.zip`,
    },
    {
      name: 'Photoshop for Architectural Renderings',
      file: `${folder}/17.0004_RH_Photoshop_Exercise_Files.zip`,
    },
    {
      name: 'Schematic Renderings with SketchUp & Photoshop',
      file: `${folder}/Exercise_Files_11.0002.zip`,
    },
    {
      name: 'Photography Principles for 3d Renderings in Photoshop',
      file: `${folder}/11.0010_Exercise_FIles.zip`,
    },
    {
      name: 'Introduction To Python In Rhino',
      file: `${folder}/DT_16.0007_Intro_To_Python_In_Rhino_Exercise_Files.zip`,
    },
    {
      name: 'Custom Families and Parameters in Revit 2016',
      file: `${folder}/Exercise_Files_15.0002.zip`,
    },
    {
      name: 'Revit 2015 Tutorial Basics: Conceptual and Schematic Design',
      file: `${folder}/14.0001-Exercise_Files.zip`,
    },
    {
      name: 'Revit Basics: In-Depth',
      file: `${folder}/revit-basics-in-depth.zip`,
    },
    {
      name: 'Family Basics: Making Revit Casework Families',
      file: `${folder}/Exercise_Files_BDY_11.0004.zip`,
    },
    {
      name: 'Revit Massing Tools',
      file: `${folder}/Exercise_Files_12.009.zip`,
    },
    {
      name: 'Rhino to Revit Interoperability',
      file: `${folder}/rhino-to-revit-interoperability.zip`,
    },
    {
      name: 'Revit Project Setup',
      file: `${folder}/revit-project-setup.zip`,
    },
    {
      name: 'Space Planning and Programming in Revit',
      file: `${folder}/Exercise_Files_12.009.zip`,
    },
    {
      name: 'Revit Curtain Wall Families',
      file: `${folder}/11.0008_Exercise_Files.zip`,
    },
    {
      name: 'Revit Door Families',
      file: `${folder}/Exercise_Files_11.0007.zip`,
    },
    {
      name: 'Setting Up Revit Project Templates',
      file: `${folder}/setting-up-revit-project-templates.zip`,
    },
    {
      name: 'Advanced Revit Family Tutorial',
      file: `${folder}/Exercise_Files__11.0005.zip`,
    },
    {
      name: 'Generic Model Adaptive Components in Revit',
      file: `${folder}/12.0006_Exercise_Files.zip`,
    },
    {
      name: 'Curtain Wall Adaptive Components in Revit',
      file: `${folder}/12.0007_Exercise_Files.zip`,
    },
    {
      name: 'Advanced Adaptive Components in Revit 2013',
      file: `${folder}/12.0011-Exercise_Files.zip`,
    },
    {
      name: 'Conceptual Massing Tools in Rhino 3d',
      file: `${folder}/12.0003_Exercise_Files.zip`,
    },
    {
      name: 'Surface Modeling in Rhino 3d',
      file: `${folder}/12.0005_Exercise_Files.zip`,
    },
    {
      name: 'SketchUp 2014 Conceptual and Schematic Design',
      file: `${folder}/14.0004-Exercise_Files.zip`,
    },
    {
      name: 'Rendering Production Pt 1: Building a 3d Model in SketchUp',
      file: `${folder}/Exercise_Files_11.0009.zip`,
    },
    {
      name: 'Unity for Interactive Architectural Visualization',
      file: `${folder}/unity-for-interactive-architectural-visualization.zip`,
    },
    {
      name: '3D Rendering with Vray for Rhino and Vray for Sketchup',
      file: `${folder}/Vray_for_Rhino_and_Sketchup-exercise_files.zip`,
    },
    {
      name: '3D Rendering with Vray for 3ds Max',
      file: `${folder}/15.0001-Exercise_Files.zip`,
    },
  ]

  const result = files.find(row => row.name === name)

  return result?.file || ''
}
