import {Box} from '@mui/material'
import styled from 'styled-components'
import theme from '../../../../styles/theme'

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-top: 50px;

  && div {
    padding-bottom: 15px;
  }
`

export const RowContainer = styled(Box)`
  display: flex;
  gap: 30px;

  && label {
    text-transform: uppercase;
  }

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`
