import {Box} from '@mui/material'
import styled from 'styled-components'
import {colors} from '../../../../styles/theme'

export const DescriptionContainer = styled(Box)`
  p {
    padding-bottom: 15px;
  }
`
export const DivisionsContainer = styled(Box)``

export const DivisionsHeader = styled(Box)`
  width: 100%;
  display: flex;
  padding: 15px 15px 15px 0;

  p {
    padding-right: 15px;
  }
`

export const DivisionRow = styled(Box)`
  width: 100%;
  display: flex;
  padding: 15px 15px 15px 0;
  border-bottom: 1px solid #eee;

  p {
    padding-right: 15px;
  }

  &:hover p {
    color: ${colors.blue1};
    cursor: pointer;
  }

  &:hover p.ignore {
    color: unset;
  }

  a {
    text-decoration: none !important;
  }
`

export const ARELink = styled.span`
  cursor: pointer;
  color: #1377d4;

  &:hover {
    text-decoration: underline;
  }
`
