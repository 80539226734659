import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../hooks/useApi'

//#region types
export type JWTDetails = {
  jwt: string
  valid: boolean
}
//#endregion

//#region api
type GetJWTPayload = {
  jwt: string
  id?: string
}

export const validateJWT = createAsyncThunk<any, GetJWTPayload>(
  '/validate',
  async ({jwt}) => {
    const fields = {jwt}

    return useApi(
      null,
      `${process.env.REACT_APP_CHECKOUT_API_URL}/jwt/validate`,
      {
        method: 'POST',
        body: JSON.stringify(fields),
      },
    ).then(res => ({
      jwt,
      status: res.ok,
    }))
    // response does not return anything, so res.json() would cause error and make status rejected
  },
)
//#endregion
