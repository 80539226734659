import {Dialog, Typography, Box} from '@mui/material'
import styled from 'styled-components'
import theme from '../../styles/theme'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

export const StyledDialog = styled(Dialog)`
  margin-bottom: 40px;

  && .MuiDialog-paper {
    border-radius: 0; 
  }
`

export const DialogTitle = styled(Typography)`
  font-size: 26px !important;
  color: #2d2d2d;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 30px;
  font-family: Freight Disp Pro !important;
  font-style: italic !important;

  ${theme.breakpoints.down('xs')} {
    font-size: 22px !important;
  }
`
export const DialogBox = styled(Box)`
  padding: 24px 24px 70px 24px;
  max-width: 640px;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;

  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }

  ${theme.breakpoints.down('xs')} {
    width: auto;
    padding: 24px 20px 20px 20px;
  }
`

export const StampBox = styled(Box)`
  text-align: center;
  padding: 24px 0;

  && img {
    width: 150px;
  }

  ${theme.breakpoints.down('sm')} {
    && img {
      width: 120px;
    }
  }
`

export const DialogSubtitle = styled(Typography)`
  font-size: 24px !important;
  font-weight: 300;
  line-height: 31px;
  color: #2d2d2d;
`

export const DialogText = styled(Typography)`
  font-size: 18px;
  color: #2d2d2d;
  font-weight: 700!important;
  display: flex;
  width: fit-content;
  align-items: center;
`

export const ButtonBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
`

export const QuestionContainer = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`

export const ExpiryDialogTextareaAutosize = styled(BaseTextareaAutosize) `
  padding: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  outline: none;
`
