import {Box} from '@mui/material'
import styled from 'styled-components'
import theme from '../../../../styles/theme'

export const OptionsBox = styled(Box)`
  width: 100%;
  display: flex;
  margin-top: 15px;

  button {
    width: 40%;
    margin-right: 30px;
    margin-bottom: 30px;
  }

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;

    button {
      width: 100%;
      margin-right: 0;
    }
  }
`
