import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {RootState} from './rootReducer'
import {LoadingStatuses} from '../components/types/LoadingStatusses'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
export type MemberList = {
  firstName: string
  lastName: string
  email: string
  status: string
  isAdmin: boolean
}

export type InvitedMember = {
  firstName: string
  lastName: string
  email: string
  inviteKey: string
  memberId: string
  orgId: string
}

export type Organization = {
  id: string
  groupSize: number
}

export type SubmitMemberList = {
  firstName?: string
  lastName?: string
  email?: string
  admin: boolean
  remove: boolean
}

export type Invoice = {
  invoiceId: string
  date: Date
  amount: number
  status: string
}

export type InvoiceDetails = {
  id: string
  invoiceDate: string
  periodStartDate: string
  periodEndDate: string
  orgName: string
  address: Address
  paymentDetails: PaymentDetails
  items: Item[]
  paymentStatus: string
  paymentMethod: string
  paymentType: string
}

export interface Address {
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  country: string
}

export interface PaymentDetails {
  baseAmount: number
  discount: number
  subtotal: number
  tax: number
  amount: number
}

export interface Item {
  name: string
  price: number
  discount: number
  subtotal: number
  tax: number
  amount: number
  altName: string
}

export type CardDetail = {
  lastFour: number
  brand: string
  date: string
}

export type OrgReport = {
  overall: OrgReportFlashcards
  exams: OrgReportExams
  flashcards: OrgReportFlashcards
  videos: OrgReportVideos
  workshops: OrgReportWorkshops
  chart: ChartData
  sessions: OrgReportSessions[]
  ncarb: OrgReportNcarb
}

export type OrgReportSessions = {
  name: string
  time: number
  date: string
}

export type OrgReportVideos = {
  time: number
  usersData: OrgReportUserData[]
  coursesData: OrgReportCourseData[]
}

export type OrgReportExams = {
  qtdAttemps: number
  qtdPassed: number
  time: number
  usersData: OrgReportUserData[]
}

export type OrgReportNcarb = {
  qtdAttemps: number
  qtdPassed: number
  usersData: OrgReportUserData[]
}

export type OrgReportFlashcards = {
  time: number
  usersData: OrgReportUserData[]
}

export type OrgReportWorkshops = {
  qtdWorkshops: number
  time: number
  usersData: OrgReportUserData[]
}

export type OrgReportUserData = {
  memberId: number
  name: string
  attempts?: number
  passed?: number
  workshops?: number
  time?: number
  courseData?: OrgReportCourseData[]
  moduleData?: OrgReportModuleData[]
}

export type OrgReportCourseData = {
  course: string
  time: number
  userData?: OrgReportUserData[]
}

export type OrgReportModuleData = {
  module: string
  time: number
}

export type ChartData = {
  labels: string[]
  activeMembers: number[]
  usageHours: number[]
  capLimit: number[]
  simultaneousUsers: number[]
}

export type OrgReportData = {
  memberId: number
  name: string
  time: number
  date: Date
  course?: string
  passed?: boolean
  workshopId?: number
  count?: number
}

type SliceState = {
  memberList?: MemberList[]
  memberListStatus: LoadingStatuses
  organization?: Organization
  invoices?: Invoice[]
  invoicesStatus: LoadingStatuses
  invoiceDetails?: InvoiceDetails
  invoiceDetailsStatus: LoadingStatuses
  cardDetails?: CardDetail
  cardDetailsStatus: LoadingStatuses
  invitedMember?: InvitedMember
  invitedMemberStatus: LoadingStatuses
  report?: OrgReport
  reportStatus: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type DefaultPayload = {
  auth: AuthContextInterface
}

export const getMembersList = createAsyncThunk<any, DefaultPayload>(
  'organizations/members/list',
  async ({auth}) => {
    const endpoint = `/v1/are/organizations/members/list`

    return useApi(auth, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

interface ImportMemberListPayload extends DefaultPayload {
  list: SubmitMemberList[]
}
export const importMembersList = createAsyncThunk<any, ImportMemberListPayload>(
  'organizations/members/import',
  async ({auth, list}) => {
    const endpoint = `/v1/are/organizations/members/import`

    const body = {
      list,
    }

    return useApi(auth, endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then(res => res.json())
  },
)

export const getBillingHistory = createAsyncThunk<any, DefaultPayload>(
  'organizations/billing/history',
  async ({auth}) => {
    const endpoint = `/v1/are/organizations/billing/history`

    return useApi(auth, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

export const getCardDetails = createAsyncThunk<any, DefaultPayload>(
  'organizations/billing/payment_methods',
  async ({auth}) => {
    const endpoint = `/v1/network/member/payment_methods`

    return useApi(
      auth,
      endpoint,
      {
        method: 'GET',
      },
      'network',
    ).then(res => res.json())
  },
)

interface GetReportPayload extends DefaultPayload {
  startDate: string
  endDate: string
}

export const getReport = createAsyncThunk<any, GetReportPayload>(
  'organizations/report',
  async ({auth, startDate, endDate}) => {
    const endpoint = `/v1/are/organizations/report?startDate=${startDate}&endDate=${endDate}`

    return useApi(auth, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

interface GetInvoiceDetailsPayload extends DefaultPayload {
  invoiceId: string
}

export const getInvoiceDetails = createAsyncThunk<
  any,
  GetInvoiceDetailsPayload
>('organizations/invoice/get', async ({auth, invoiceId}) => {
  const endpoint = `/v1/are/organizations//billing/invoice/${invoiceId}`

  return useApi(auth, endpoint, {
    method: 'GET',
  }).then(res => res.json())
})

interface GetMemberInvitationPayload {
  inviteKey: string
}

export const getMemberInvitation = createAsyncThunk<
  any,
  GetMemberInvitationPayload
>('organizations/member/invite', async ({inviteKey}) => {
  const endpoint = `/v1/guest/are/organizations/member/invite/${inviteKey}`

  return useApi(null, endpoint, {
    method: 'GET',
  }).then(res => res.json())
})

interface ResendActivationEmailPayload {
  email: string
}

export const resendActivationEmail = createAsyncThunk<
  any,
  ResendActivationEmailPayload
>('organizations/member/activation/resend', async ({email}) => {
  const endpoint = `/v1/guest/are/organizations/members/activate`

  return useApi(null, endpoint, {
    method: 'POST',
    body: JSON.stringify({email}),
  }).then(res => res.json())
})

interface ActivateGroupMemberPayload {
  inviteKey: string
  firstName: string
  lastName: string
  password: string
}

export const activateGroupMember = createAsyncThunk<
  any,
  ActivateGroupMemberPayload
>(
  'organizations/member/activate',
  async ({inviteKey, firstName, lastName, password}) => {
    const endpoint = `/v1/guest/are/organizations/member/activate`

    const body = {
      invite_key: inviteKey,
      first_name: firstName,
      last_name: lastName,
      password,
    }

    return useApi(
      null,
      endpoint,
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      'network',
    ).then(res => res.json())
  },
)
//#endregion

//#region slice
const initialState: SliceState = {
  memberList: [],
  memberListStatus: LoadingStatuses.Idle,
  organization: undefined,
  invoices: undefined,
  invoicesStatus: LoadingStatuses.Idle,
  invoiceDetails: undefined,
  invoiceDetailsStatus: LoadingStatuses.Idle,
  cardDetails: undefined,
  cardDetailsStatus: LoadingStatuses.Idle,
  invitedMember: undefined,
  invitedMemberStatus: LoadingStatuses.Idle,
  report: undefined,
  reportStatus: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMembersList.pending, state => {
      state.memberListStatus = LoadingStatuses.Loading
      state.memberList = []
      state.organization = undefined
    })
    builder.addCase(getMembersList.fulfilled, (state, action) => {
      state.memberListStatus = LoadingStatuses.Succeeded
      state.memberList = action.payload.list
      state.organization = action.payload.organization
    })
    builder.addCase(importMembersList.fulfilled, (state, action) => {
      if (state.memberList) {
        let members = [...state.memberList]
        const removed = action.payload.removeList
        const newMembers = action.meta.arg.list.filter(item => !item.remove)
        if (removed && removed.length > 0) {
          members = members.filter(member => !removed.includes(member.email))
        }

        newMembers.forEach(item => {
          members.push({
            firstName: item.firstName!,
            lastName: item.lastName!,
            email: item.email!,
            isAdmin: false,
            status: '',
          })
        })

        state.memberList = members
      }
    })
    builder.addCase(getBillingHistory.pending, state => {
      state.invoicesStatus = LoadingStatuses.Loading
      state.invoices = []
    })
    builder.addCase(getBillingHistory.fulfilled, (state, action) => {
      state.invoicesStatus = LoadingStatuses.Succeeded
      state.invoices = action.payload.custom
    })
    builder.addCase(getBillingHistory.rejected, state => {
      state.invoicesStatus = LoadingStatuses.Failed
      state.invoices = []
    })
    builder.addCase(getInvoiceDetails.pending, state => {
      state.invoiceDetailsStatus = LoadingStatuses.Loading
      state.invoiceDetails = undefined
    })
    builder.addCase(getInvoiceDetails.fulfilled, (state, action) => {
      state.invoiceDetailsStatus = LoadingStatuses.Succeeded
      state.invoiceDetails = action.payload
    })
    builder.addCase(getInvoiceDetails.rejected, state => {
      state.invoiceDetailsStatus = LoadingStatuses.Failed
      state.invoiceDetails = undefined
    })
    builder.addCase(getMemberInvitation.pending, state => {
      state.invitedMemberStatus = LoadingStatuses.Loading
      state.invitedMember = undefined
    })
    builder.addCase(getMemberInvitation.fulfilled, (state, action) => {
      state.invitedMemberStatus = LoadingStatuses.Succeeded
      state.invitedMember = action.payload
    })
    builder.addCase(getMemberInvitation.rejected, state => {
      state.invitedMemberStatus = LoadingStatuses.Failed
      state.invitedMember = undefined
    })
    builder.addCase(getCardDetails.pending, state => {
      state.cardDetailsStatus = LoadingStatuses.Loading
      state.cardDetails = undefined
    })
    builder.addCase(getCardDetails.fulfilled, (state, action) => {
      const payload = action.payload
      state.cardDetailsStatus = LoadingStatuses.Succeeded
      if (payload.payment_methods && payload.payment_methods.length > 0) {
        state.cardDetails = {
          lastFour: payload.payment_methods[0].last_4,
          brand: payload.payment_methods[0].brand,
          date:
            payload.payment_methods[0].exp_month +
            '/' +
            payload.payment_methods[0].exp_year,
        }
      }
    })
    builder.addCase(getCardDetails.rejected, state => {
      state.cardDetailsStatus = LoadingStatuses.Failed
      state.cardDetails = undefined
    })
    builder.addCase(getReport.pending, state => {
      state.reportStatus = LoadingStatuses.Loading
      state.report = undefined
    })
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.reportStatus = LoadingStatuses.Succeeded

      state.report = {
        overall: action.payload.overall,
        exams: action.payload.exams,
        flashcards: action.payload.flashcards,
        videos: action.payload.videos,
        workshops: action.payload.workshops,
        chart: action.payload.chart,
        ncarb: action.payload.ncarb,
        sessions: action.payload.sessions,
      }
    })
    builder.addCase(getReport.rejected, state => {
      state.reportStatus = LoadingStatuses.Failed
      state.report = undefined
    })
  },
})
//#endregion

//#region selectors
export const selectOrganization = ({organization}: RootState) =>
  organization.organization
export const selectMembersList = ({organization}: RootState) =>
  organization.memberList
export const selectMembersListStatus = ({organization}: RootState) =>
  organization.memberListStatus
export const selectCardDetails = ({organization}: RootState) =>
  organization.cardDetails
export const selectCardDetailsStatus = ({organization}: RootState) =>
  organization.cardDetailsStatus
export const selectBillingHistory = ({organization}: RootState) =>
  organization.invoices
export const selectBillingHistoryStatus = ({organization}: RootState) =>
  organization.invoicesStatus
export const selectReport = ({organization}: RootState) => organization.report
export const selectReportStatus = ({organization}: RootState) =>
  organization.reportStatus
export const selectInvoiceDetails = ({organization}: RootState) =>
  organization.invoiceDetails
export const selectInvoiceDetailsStatus = ({organization}: RootState) =>
  organization.invoiceDetailsStatus
export const selectInvitedMember = ({organization}: RootState) =>
  organization.invitedMember
export const selectInvitedMemberStatus = ({organization}: RootState) =>
  organization.invitedMemberStatus
//#endregion
