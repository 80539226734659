import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../../hooks/useApi'

export type LoginDetails = {
  email: string
  password: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  jwt?: string
  status: LoadingStatuses
  error?: string
}

type LoginPayload = {
  loginCredentials: LoginDetails
}

export const login = createAsyncThunk<any, LoginPayload>(
  '/login',
  async ({loginCredentials}) => {
    const {email, password} = loginCredentials

    const fields = {
      email: email,
      password: password,
    }

    return useApi(
      null,
      `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/guest/are/log_in`,
      {
        method: 'POST',
        body: JSON.stringify(fields),
      },
    ).then(res => res.json())
  },
)

const initialState: SliceState = {
  jwt: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.jwt = action.payload.auth_token
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(login.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
