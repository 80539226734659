import React from 'react'
import View from './view'
import {DashboardVideo} from '../../../redux/dashboard'
import {Course} from '../../../redux/courses'

type Props = {
  course: Course
  videoData?: DashboardVideo
  paData?: DashboardVideo
}

const VideoLectures = ({course, videoData, paData}: Props) => {
  return <View course={course} videoData={videoData} paData={paData} />
}

export default VideoLectures
