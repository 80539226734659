import React from 'react'
import {ActivationBox, StyledBox, StyledLinkBox} from './styles'
import BksLogo from '../../images/logo-white.svg'
import {StyledLogo} from '../../components/Hero/styles'
import {Link} from 'react-router-dom'
import {Typography} from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'

const SuccessView = () => {
  return (
    <ActivationBox>
      <Link to={'/'}>
        <StyledLogo src={BksLogo} alt="Black Spectacles" />
      </Link>
      <StyledBox textAlign={'center'}>
        <Typography variant="h1" color={'white'}>
          WELCOME TO BLACK SPECTACLES!
        </Typography>

        <Typography variant="body1" color={'white'} marginTop={3}>
          <em>
            Your password has been confirmed - you can now{' '}
            <StyledLinkBox>
              <Link to={'/login'}>
                <LockIcon /> log in
              </Link>
            </StyledLinkBox>
            !
          </em>
        </Typography>
        <Typography variant="body1" color={'white'} marginTop={3}>
          <em>
            Your username is your email address, and you just set your password.
          </em>
        </Typography>
      </StyledBox>
    </ActivationBox>
  )
}

export default SuccessView
