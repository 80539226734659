import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {RootState} from './rootReducer'
import {LoadingStatuses} from '../components/types/LoadingStatusses'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
export type SearchResults = {
  keywords: string
  offset: string
  limit: string
  total: number
  results: VideoResults[]
}

export type VideoResults = {
  videoId: string
  title: string
  courseId: string
}

type SliceState = {
  results?: SearchResults
  resultsStatus: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type SearchPayload = {
  auth: AuthContextInterface
  page: number
  keywords: string
}
export const getSearchResults = createAsyncThunk<any, SearchPayload>(
  'search/results/get',
  async ({auth, page, keywords}) => {
    page = page > 0 ? (page -= 1) : 0
    const perPage = 10
    const endpoint = `/v1/are/search/${
      page * perPage
    }/${perPage}?keywords=${keywords}`
    return useApi(auth, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)
//#endregion

//#region slice
const initialState: SliceState = {
  results: undefined,
  resultsStatus: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSearchResults.pending, state => {
      state.results = undefined
      state.resultsStatus = LoadingStatuses.Loading
    })
    builder.addCase(getSearchResults.fulfilled, (state, action) => {
      state.results = action.payload
      state.resultsStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(getSearchResults.rejected, state => {
      state.results = undefined
      state.resultsStatus = LoadingStatuses.Failed
    })
  },
})
//#endregion

//#region selectors
export const selectSearchResults = ({search}: RootState) => search.results
export const selectSearchResultsStatus = ({search}: RootState) =>
  search.resultsStatus
//#endregion
