import React, {useState} from 'react'
import {Typography, Box} from '@mui/material'
import {Course} from '../../redux/courses'
import {LessonPlan} from '../../redux/lessons'
import {useSelector} from 'react-redux'
import {
  AuthorBox,
  AuthorText,
  CheckArea,
  CourseBox,
  FlexBox,
  ImageBox,
  LessonBody,
  LessonBorder,
  LessonHeader,
  LessonTitle,
  LessonsBox,
  LockBox,
  SectionContent,
  SectionHeader,
  StyledCheckIcon,
} from './styles'
import {NCARBAPPROVEDIMAGEABBR} from '../../constants/divisions'
import {
  LectureSlides,
  ObjectType as LectureSlideObjectType,
} from '../../constants/lectureSlides'

import ResetQuizIcon from '../../images/icons/icon-reset-quiz.svg'
import {Link} from 'react-router-dom'
import {LeftBox, RightBox, StyledTitle} from '../../styles/global'
import {Button} from '../../components/button'
import {useWindowDimensions} from '../../hooks/useWindowDimensions'
import theme from '../../styles/theme'
import FollowUs from '../../components/FollowUs'
import ConfirmDialog from '../../components/ConfirmDialog'
import {useAuth} from '../../components/AuthProvider'
import LockIcon from '@mui/icons-material/Lock'
import {getSignedUrl} from '../../utils/getSignedUrl'
import {getSoftwareFileName} from '../../constants/software-files'
import hoursMinutes from '../../utils/hoursMinutes'
import {selectMemberAccess, getUpgradePrice} from '../../redux/members'
import {useAppDispatch} from '../../redux/configureStore'

import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined'
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined'

type Props = {
  course?: Course
  lesson?: LessonPlan
  onResetQuiz: (examId: string) => void
  onResetVideo: (lessonPlanId: string) => void
  isSoftware?: boolean
}

const View = ({
  course,
  lesson,
  onResetQuiz,
  onResetVideo,
  isSoftware,
}: Props) => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const {width} = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false)

  const access = useSelector(selectMemberAccess)

  let hasAccess = false
  let hasFileAccess = true
  let hasPAAccess = false
  if (isSoftware) {
    hasAccess =
      access && Object.keys(access).length !== 0
        ? Number(access.software?.videos?.value) === 1
        : false
    hasFileAccess =
      access && Object.keys(access).length !== 0
        ? Number(access.software?.files?.value) === 1
        : false
  } else {
    hasAccess =
      access && Object.keys(access).length !== 0
        ? Number(access.are?.videos?.value) === 1
        : false

    hasPAAccess =
      access && Object.keys(access).length !== 0
        ? Number(access.are?.practical_apps?.value) === 1
        : false
  }

  const renderHTML = (html: string) => {
    return React.createElement('span', {
      dangerouslySetInnerHTML: {__html: html},
    })
  }

  const renderUrl = async () => {
    if (course?.title) {
      const url = (await getSignedUrl(
        getSoftwareFileName(course.title),
      )) as string
      return url
    }

    return ''
  }

  const handleLectureSlides = async () => {
    if (isSoftware) {
      window.open(await renderUrl())
    } else {
      const url =
        LectureSlides[course?.abbreviation as keyof LectureSlideObjectType]
      window.open(url)
    }
  }

  const handleUpgradeUrl = async (e:any) => {
    e.preventDefault();
    const resp = await dispatch(getUpgradePrice({auth}))
    console.log(resp.payload)
    window.location.href = String(resp.payload.priceId).length > 0 ? 'https://checkout.blackspectacles.com/' + String(resp.payload.priceId) : '#'
  }

  let isPracticalApplication = false

  return (
    <CourseBox id="content">
      <ConfirmDialog
        title="Reset Progress"
        message="Are you sure you want to reset the progress of this course?"
        isOpen={isResetDialogOpen}
        onConfirm={() => {
          setIsResetDialogOpen(false)
          if (lesson?.id) {
            onResetVideo(lesson.id)
          }
        }}
        onClose={() => setIsResetDialogOpen(false)}
      />
      <LeftBox>
        <StyledTitle variant="h2">
          {isSoftware ? (
            <>{course?.title}</>
          ) : (
            <>ARE 5.0 {course?.title} EXAM PREP</>
          )}
        </StyledTitle>
        <AuthorBox>
          <AuthorText>{lesson?.author}</AuthorText>
          <AuthorText>|</AuthorText>
          <AuthorText>
            {lesson?.duration ? hoursMinutes(lesson.duration, true) : 0}
          </AuthorText>
          <Button
            children={'Reset Viewed Videos'}
            color="primary"
            onClick={() => setIsResetDialogOpen(true)}
          />
        </AuthorBox>
        <Typography variant="body1" marginTop={'40px'} marginBottom={'30px'}>
          {renderHTML(lesson?.description ?? '')}
        </Typography>
        <ImageBox>
          {course?.abbreviation && (
            <img
              width="146"
              src={NCARBAPPROVEDIMAGEABBR[course.abbreviation]}
            />
          )}
        </ImageBox>
        <LessonsBox>
          {lesson?.assets.map((asset, key) => {
            const [isDialogOpen, setIsDialogOpen] = useState(false)

            const lastRow = key === 0 ? undefined : lesson?.assets[key - 1]

            let headerId = ''
            if (asset.assetTypeAbbr === 'lesson_header') {
              const objective = asset.shortDescription.split(':')

              if (asset.shortDescription.includes('Practical Applications')) {
                isPracticalApplication = true
              } else {
                isPracticalApplication = false
                headerId = objective[0].replace(/[^0-9.]/g, '')
              }
            }

            return (
              <React.Fragment key={key}>
                {asset.assetTypeAbbr === 'lesson_header' && (
                  <Box id={headerId}>
                    <LessonTitle>
                      {isPracticalApplication ? '' : asset.shortDescription}
                    </LessonTitle>
                    <LessonHeader>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        gap={'8px'}
                        width={isMobile ? '85%' : '70%'}
                      >
                        {isPracticalApplication ? (
                          <>
                            <Person2OutlinedIcon fontSize="large" /> Practical
                            Applications
                            {!hasPAAccess && (
                              <>
                                <span>—</span>
                                <Link to={'#'} onClick={handleUpgradeUrl}>
                                  <LockIcon /> Upgrade
                                </Link>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <SquareFootOutlinedIcon fontSize="large" /> Course
                            Videos
                          </>
                        )}
                      </Box>
                      {!isMobile && <Box width={'10%'}>Duration</Box>}
                      <Box
                        width={isMobile ? '15%' : '10%'}
                        textAlign={'center'}
                      >
                        Viewed
                      </Box>
                      {!isMobile && <Box width={'10%'}></Box>}
                    </LessonHeader>
                  </Box>
                )}

                {asset.assetTypeAbbr === 'video' &&
                  lastRow?.assetTypeAbbr === 'quiz' && (
                    <LessonHeader marginTop={'24px'}>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        gap={'8px'}
                        width={isMobile ? '85%' : '70%'}
                      >
                        <SquareFootOutlinedIcon fontSize="large" /> Course
                        Videos
                      </Box>
                      {!isMobile && <Box width={'10%'}>Duration</Box>}
                      <Box
                        width={isMobile ? '15%' : '10%'}
                        textAlign={'center'}
                      >
                        Viewed
                      </Box>
                      {!isMobile && <Box width={'10%'}></Box>}
                    </LessonHeader>
                  )}

                {asset.assetTypeAbbr === 'video' && (
                  <LessonBorder
                    $isAuthenticated={
                      asset.isFree === 1 ||
                      (isPracticalApplication
                        ? hasPAAccess && auth.isAuthenticated
                        : hasAccess && auth.isAuthenticated)
                    }
                  >
                    <LessonBody>
                      <FlexBox width={isMobile ? '85%' : '70%'}>
                        <Link
                          to={`/courses/${course?.id}/video/${asset.id}${
                            asset.isFree ? '/free' : ''
                          }`}
                        >
                          {asset.shortDescription}
                        </Link>
                      </FlexBox>
                      {!isMobile && (
                        <FlexBox width={'10%'}>{asset.videoDuration}</FlexBox>
                      )}
                      <FlexBox
                        width={isMobile ? '15%' : '10%'}
                        justifyContent={'center'}
                      >
                        {auth.isAuthenticated || asset.isFree ? (
                          <>
                            {(isPracticalApplication
                              ? !hasPAAccess
                              : !hasAccess) && !asset.isFree ? (
                              <>
                                  <Link to={'#'} onClick={handleUpgradeUrl} 
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '2px',
                                  }}
                                  >
                                    <LockIcon /> Upgrade
                                </Link>
                              </>
                            ) : (
                              <>
                                {asset.isComplete ? (
                                  <CheckArea variant="body2">
                                    <StyledCheckIcon />
                                  </CheckArea>
                                ) : (
                                  <VisibilityOutlinedIcon fontSize="medium" />
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <Link
                            to={'/login'}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '2px',
                            }}
                          >
                            <LockIcon style={{fontSize: '16px'}} /> Log in
                          </Link>
                        )}
                      </FlexBox>

                      {!isMobile && <Box width={'10%'}></Box>}
                    </LessonBody>
                  </LessonBorder>
                )}

                {asset.assetTypeAbbr === 'quiz' && (
                  <>
                    <LessonHeader
                      borderTop={'2px #f1f4f6 solid'}
                      marginTop={'2px'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        gap={'8px'}
                        width={isMobile ? '85%' : '70%'}
                      >
                        <HistoryToggleOffOutlinedIcon fontSize="large" /> Quiz
                        Break
                      </Box>
                      {!isMobile && <Box width={'10%'}>Questions</Box>}
                      <Box
                        width={isMobile ? '15%' : '10%'}
                        textAlign={'center'}
                      >
                        Score
                      </Box>
                      {!isMobile && (
                        <Box width={'10%'} textAlign={'center'}>
                          Reset
                        </Box>
                      )}
                    </LessonHeader>
                    <LessonBorder $isAuthenticated={auth.isAuthenticated}>
                      <LessonBody>
                        <Box width={isMobile ? '85%' : '70%'}>
                          <Link to={`/courses/${course?.id}/quiz/${asset.id}`}>
                            {asset.shortDescription}
                          </Link>
                        </Box>
                        {!isMobile && (
                          <Box width={'10%'}>{asset.questions}</Box>
                        )}
                        <Box
                          width={isMobile ? '15%' : '10%'}
                          textAlign={'center'}
                        >
                          {asset.percentage ?? '-'}
                        </Box>
                        {!isMobile && (
                          <FlexBox width={'10%'} justifyContent={'center'}>
                            {asset.percentage ? (
                              <img
                                onClick={() => setIsDialogOpen(true)}
                                src={ResetQuizIcon}
                                alt="Reset Quiz"
                                style={{
                                  width: '24px',
                                  cursor: 'pointer',
                                  display: 'flex',
                                }}
                              />
                            ) : (
                              '-'
                            )}
                          </FlexBox>
                        )}
                      </LessonBody>
                    </LessonBorder>
                    <ConfirmDialog
                      title="Reset Quiz"
                      message="Are you sure you want to reset this quiz?"
                      isOpen={isDialogOpen}
                      onConfirm={() => {
                        onResetQuiz(asset.id)
                        setIsDialogOpen(false)
                      }}
                      onClose={() => setIsDialogOpen(false)}
                    />
                  </>
                )}
              </React.Fragment>
            )
          })}
        </LessonsBox>
      </LeftBox>
      <RightBox>
        {auth.isAuthenticated ? (
          <>
            {hasFileAccess ? (
              <Button
                onClick={() => handleLectureSlides()}
                color="secondary"
                children={
                  isSoftware
                    ? 'Download Exercise Files'
                    : 'Download Lecture Slides'
                }
                event="file_download"
              />
            ) : (
              <LockBox>
                <Link to={'#'} onClick={handleUpgradeUrl}>
                  <LockIcon /> Upgrade
                </Link>{' '}
                to access exercise files
              </LockBox>
            )}
          </>
        ) : (
          <LockBox>
            <Link to={'/login'}>
              <LockIcon color="secondary" /> Log in
            </Link>
            to access files
          </LockBox>
        )}
        <SectionHeader>About the author</SectionHeader>
        <SectionContent>{renderHTML(lesson?.authorBio ?? '')}</SectionContent>
        <FollowUs />
      </RightBox>
    </CourseBox>
  )
}

export default View
