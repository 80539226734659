import * as React from 'react'
import {CircularProgressProps} from '@mui/material/CircularProgress'
import {PlayIconBox, StyledCircularProgress, StyledIcon} from './styles'

function CircularProgressWithLabel(
  props: CircularProgressProps & {value: number},
) {
  return (
    <PlayIconBox>
      <StyledCircularProgress thickness={1} variant="determinate" {...props} />
      <StyledIcon />
    </PlayIconBox>
  )
}

export default function CircularWithValueLabel() {
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    let killTimer = 0
    const timer = setInterval(() => {
      setProgress(prevProgress => {
        return prevProgress < 100 ? prevProgress + 1 : 100
      })
      killTimer = killTimer < 100 ? killTimer + 1 : 100
      if (killTimer >= 100) {
        clearInterval(timer)
      }
    }, 50)
  }, [])

  return <CircularProgressWithLabel value={progress} />
}
