import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Box, Typography, Modal, Backdrop, Fade} from '@mui/material'
import {useAppDispatch} from '../../../redux/configureStore'
import {useAuth} from '../../../components/AuthProvider'
import {toggleErrorDialog} from '../../../redux/config'
import {
  FormContainer,
  CloseContainer,
  FormTitle,
  FormPrompt,
  RowContainer,
} from './styles'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '../../TextField'
import {Button} from '../../button'
import {selectWorkshops, submitWorkshopQuestion} from '../../../redux/workshop'

type Props = {
  open: boolean
  setOpen: (val: boolean) => void
}

interface LabelProps {
  text: string
  required?: boolean
  className?: string
}

type QuestionKeyProp = 'question1' | 'question2' | 'question3'

type AttachmentKeyProp =
  | 'question1Attachment'
  | 'question2Attachment'
  | 'question3Attachment'

type FileProp = {
  question1Attachment?: File
  question2Attachment?: File
  question3Attachment?: File
}

const Label = ({text, required, className}: LabelProps) => {
  return (
    <>
      <span className={className} style={{color: '#78909c'}}>
        {text}
      </span>
      {required && <span style={{color: '#3057E1'}}> * </span>}
    </>
  )
}

const initialQuestionsState = {
  question1: '',
  question2: '',
  question3: '',
}

const initialAttachmentsState = {
  question1Attachment: undefined,
  question2Attachment: undefined,
  question3Attachment: undefined,
}

const initialFormErrorsState = {
  question1: '',
  question2: '',
  question3: '',
}

const View = ({open, setOpen}: Props) => {
  const dispatch = useAppDispatch()
  const auth = useAuth()
  const [isDisabled, setIsDisabled] = useState(false)
  const workshops = useSelector(selectWorkshops)
  const [questions, setQuestions] = useState(initialQuestionsState)
  const [attachments, setAttachments] = useState<FileProp>(
    initialAttachmentsState,
  )
  const [formErrors, setFormErrors] = useState(initialFormErrorsState)

  const workshop = workshops[0]

  const handleClose = () => {
    setOpen(false)
    setQuestions(initialQuestionsState)
    setAttachments(initialAttachmentsState)
    setFormErrors(initialFormErrorsState)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    if (workshop) {
      e.preventDefault()
      setIsDisabled(true)

      let errorsFound = false

      const questionsList = Object.keys(questions).map((key: string) => {
        const question = questions[key as QuestionKeyProp]
        return {question}
      })

      const attachmentsList = Object.keys(attachments).map((key: string) => {
        const attachment = attachments[key as AttachmentKeyProp]
        return {attachment}
      })

      // submitting empty form
      if (
        questionsList.every(({question}) => question === '') &&
        attachmentsList.every(({attachment}) => attachment === undefined)
      ) {
        setFormErrors({...formErrors, question1: 'required'})
        errorsFound = true
        return
      }

      const errors: Record<QuestionKeyProp, string> = {
        question1: '',
        question2: '',
        question3: '',
      }

      const questionSubmissionList = []

      for (let i = 0; i < questionsList.length; i++) {
        // generate question submission list to submit later if no errors are found
        questionSubmissionList.push({
          question: questionsList[i].question,
          attachment: attachmentsList[i].attachment,
        })

        if (!questionsList[i].question && attachmentsList[i].attachment) {
          errors[`question${i + 1}` as QuestionKeyProp] = 'required'
          errorsFound = true
        }
      }

      setFormErrors(errors)

      if (!errorsFound) {
        try {
          const {type} = await dispatch(
            submitWorkshopQuestion({
              auth,
              workshopId: workshop.id,
              questions: questionSubmissionList,
            }),
          )

          setIsDisabled(false)

          if (type === submitWorkshopQuestion.rejected.type) {
            throw new Error('Unable to submit workshop questions')
          } else {
            handleClose()
          }
        } catch (e) {
          await dispatch(
            toggleErrorDialog({
              opened: true,
              error:
                'We were unable to submit your questions, please retry. If issue persists please contact our support team',
            }),
          )
        }
      }
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAttachments({...attachments, [e.target.name]: e.target.files[0]})
    } else {
      setQuestions({...questions, [e.target.name]: e.target.value})

      if (e.target.value) {
        const newErrorState = {...formErrors, [e.target.name]: ''}
        setFormErrors(newErrorState)

        // check if form has any errors
        // if not, then enable submit button
        if (
          Object.keys(newErrorState).every((key: string) => {
            return newErrorState[key as QuestionKeyProp] === ''
          })
        ) {
          setIsDisabled(false)
        }
      }
    }
  }

  return (
    <Box sx={open ? {overflow: 'hidden'} : {overflow: 'auto'}}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <FormContainer>
            <CloseContainer>
              <CloseIcon onClick={handleClose} style={{fontSize: '35px'}} />
            </CloseContainer>

            <FormTitle>
              <Typography variant={'h3'}>Submit Questions</Typography>
            </FormTitle>

            <FormPrompt>
              <Typography variant={'body1'}>
                These questions are emailed to the workshop instructor. Please
                submit them no later than Thursday evening if you would like
                them to be reviewed during the Q&A portion of Sunday's workshop.
              </Typography>
            </FormPrompt>

            <form onSubmit={e => handleSubmit(e)}>
              <RowContainer error={formErrors?.question1}>
                <TextField
                  label={
                    <Label text="* Question 1:" className="question-label" />
                  }
                  name={'question1'}
                  variant={'outlined'}
                  fullWidth={true}
                  type={'textarea'}
                  onChange={handleChange}
                  errorMessage={formErrors?.question1}
                ></TextField>
              </RowContainer>

              <RowContainer>
                <TextField
                  label={<Label text="Question 1 Attachment (Image/pdf):" />}
                  name={'question1Attachment'}
                  variant={'outlined'}
                  fullWidth={true}
                  type={'file'}
                  onChange={handleChange}
                ></TextField>
              </RowContainer>

              <RowContainer error={formErrors?.question2}>
                <TextField
                  label={
                    <Label text="* Question 2:" className="question-label" />
                  }
                  name={'question2'}
                  variant={'outlined'}
                  fullWidth={true}
                  type={'textarea'}
                  onChange={handleChange}
                  errorMessage={formErrors?.question2}
                ></TextField>
              </RowContainer>
              <RowContainer>
                <TextField
                  label={<Label text="Question 2 Attachment (Image/pdf):" />}
                  name={'question2Attachment'}
                  variant={'outlined'}
                  fullWidth={true}
                  type={'file'}
                  onChange={handleChange}
                ></TextField>
              </RowContainer>

              <RowContainer error={formErrors?.question3}>
                <TextField
                  label={
                    <Label text="* Question 3:" className="question-label" />
                  }
                  name={'question3'}
                  variant={'outlined'}
                  fullWidth={true}
                  type={'textarea'}
                  onChange={handleChange}
                  errorMessage={formErrors?.question3}
                ></TextField>
              </RowContainer>
              <RowContainer>
                <TextField
                  label={<Label text="Question 3 Attachment (Image/pdf):" />}
                  name={'question3Attachment'}
                  variant={'outlined'}
                  fullWidth={true}
                  type={'file'}
                  onChange={handleChange}
                ></TextField>
              </RowContainer>

              <RowContainer>
                <Button
                  color={'primary'}
                  children={'Submit'}
                  type={'submit'}
                  disabled={
                    isDisabled ||
                    (!questions.question1 &&
                      !questions.question2 &&
                      !questions.question3)
                  }
                />
              </RowContainer>
            </form>
          </FormContainer>
        </Fade>
      </Modal>
    </Box>
  )
}

export default View
