import React, {useState, useEffect, useCallback} from 'react'
import {Box, Typography, Link} from '@mui/material'
import {
  DescriptionContainer,
  DivisionsContainer,
  DivisionsHeader,
  DivisionRow,
  ARELink,
} from './styles'
import ExamStatusForm from '../../../ExamStatusForm'
import {v4 as uuidv4} from 'uuid'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../../../components/AuthProvider'
import {useSelector} from 'react-redux'
import {
  getReportedExamResults,
  selectExamReports,
  selectNcarbAttempts,
} from '../../../../redux/reportExamStatus'
import {selectOrganization} from '../../../../redux/courses'
import {useAppDispatch} from '../../../../redux/configureStore'
import {toggleErrorDialog} from '../../../../redux/config'
import ARECompleteDialog from '../../../ARECompleteDialog'
import {selectMember} from '../../../../redux/members'
import {StyledTitle} from '../../../../styles/global'
import ReferralDialog from '../../../ReferralDialog'

type Results = {
  name:
    | 'Practice Management'
    | 'Project Management'
    | 'Programming & Analysis'
    | 'Project Planning & Design'
    | 'Project Development & Documentation'
    | 'Construction & Evaluation'
  attempts: number
  status: 'Not Submitted' | 'Pass' | 'Fail' | 'Details needed' | 'Scheduled'
  abbreviation: 'pcm' | 'pjm' | 'pa' | 'ppd' | 'pdd' | 'ce'
}

const View = () => {
  const [examResults, setExamResults] = useState<Results[]>([
    {
      name: 'Practice Management',
      attempts: 0,
      status: 'Not Submitted',
      abbreviation: 'pcm',
    },
    {
      name: 'Project Management',
      attempts: 0,
      status: 'Not Submitted',
      abbreviation: 'pjm',
    },
    {
      name: 'Programming & Analysis',
      attempts: 0,
      status: 'Not Submitted',
      abbreviation: 'pa',
    },
    {
      name: 'Project Planning & Design',
      attempts: 0,
      status: 'Not Submitted',
      abbreviation: 'ppd',
    },
    {
      name: 'Project Development & Documentation',
      attempts: 0,
      status: 'Not Submitted',
      abbreviation: 'pdd',
    },
    {
      name: 'Construction & Evaluation',
      attempts: 0,
      status: 'Not Submitted',
      abbreviation: 'ce',
    },
  ])

  const {division} = useParams()

  const [open, setOpen] = useState(false)
  const [isAREDialogOpen, setIsAREDialogOpen] = useState(false)
  const auth = useAuth()
  const attempts = useSelector(selectNcarbAttempts)
  const examReports = useSelector(selectExamReports)
  const courses = useSelector(selectOrganization)?.courses
  const member = useSelector(selectMember)
  const dispatch = useAppDispatch()
  const [stateDivision, setStateDivision] = useState(division)

  const courseId = courses?.find(
    item => item.abbreviation.toLowerCase() === stateDivision,
  )?.id

  const handleCompletedARE = () => {
    setIsAREDialogOpen(true)
  }

  // pass in division details
  const handleFormOpen = (course: string) => {
    if (!open) {
      // set course id for selected exam report form
      setStateDivision(course)
      setOpen(true)
    }
  }

  const handleDataFetch = useCallback(async () => {
    try {
      const {type} = await dispatch(getReportedExamResults({auth}))
      if (type === getReportedExamResults.rejected.type) {
        throw new Error('Unable to get exam results')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to get exam results, please retry. If issues persist contact our support team',
        }),
      )
    }
  }, [])

  useEffect(() => {
    // Fetch Exam Report Data
    handleDataFetch()
    if (stateDivision) {
      setOpen(true)
    }
    // Update UI with Data
  }, [stateDivision])

  // Update Exam Reports Dashboard
  useEffect(() => {
    if (examReports) {
      const newResults = examResults.map(
        ({name, attempts, status, abbreviation}) => {
          const result = {name, attempts, status, abbreviation}
          result.attempts = examReports[name]?.attempts?.length

          if (examReports[name].attempts?.length === 0) {
            result.status = 'Not Submitted'
          } else if (examReports[name].passed) {
            result.status = 'Pass'
          } else if (
            examReports[name].scheduled ||
            examReports[name].passed == null
          ) {
            result.status = 'Scheduled'
          } else {
            result.status = 'Fail'
          }

          return result
        },
      )
      setExamResults(newResults)
    }
  }, [examReports])

  return (
    <>
      <Box>
        <StyledTitle variant={'h2'} style={{marginBottom: '32px'}}>
          Testing Activity {'&'} Progress
        </StyledTitle>

        <DescriptionContainer>
          <Typography variant={'body2'}>
            One way to keep your eye on the prize is to stay on top of your
            progress. Log your passes and fails (we’ve all been there) for each
            division in your Black Spectacles Dashboard so you can celebrate
            wins and readjust after a loss.
          </Typography>
          <Typography variant={'body2'}>
            AND, once you pass all six divisions, we have a special gift for
            you! Said gift will remain a secret until you pass every exam, but
            we promise, it’s pretty badass.
          </Typography>
          <Typography variant={'body2'}>
            Click “Update” next to the division you’d like to report a status
            change to below, and when you’ve passed all six, click to confirm
            you’ve completed the ARE at the bottom of this page. We can’t wait
            to send you your gift when you pass them all!
          </Typography>
        </DescriptionContainer>

        <StyledTitle variant={'h2'} style={{marginTop: '16px'}}>
          ARE 5.0
        </StyledTitle>

        <DivisionsContainer>
          <DivisionsHeader>
            <Typography sx={{width: '41.667%'}} variant={'body1'}>
              Exam
            </Typography>
            <Typography sx={{width: '16.667%'}} variant={'body1'}>
              Attempts
            </Typography>
            <Typography sx={{width: '25%'}} variant={'body1'}>
              Status
            </Typography>
            <Typography sx={{width: '16.667%'}} variant={'body1'}>
              Update
            </Typography>
          </DivisionsHeader>

          {examResults.map(({name, attempts, status, abbreviation}) => (
            <DivisionRow
              key={uuidv4()}
              onClick={() => handleFormOpen(abbreviation)}
            >
              <Typography sx={{width: '41.667%'}} variant={'body1'}>
                <Link>{name}</Link>
              </Typography>
              <Typography
                className="ignore"
                sx={{width: '16.667%'}}
                variant={'body1'}
              >
                {attempts}
              </Typography>
              <Typography sx={{width: '25%'}} variant={'body1'}>
                {status}
              </Typography>
              <Typography
                sx={{
                  width: '16.667%',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#90a4ae',
                }}
                variant={'body1'}
                onClick={() => handleFormOpen(abbreviation)}
              >
                update
              </Typography>
            </DivisionRow>
          ))}
        </DivisionsContainer>

        {!member?.isAREComplete ? (
          <Typography sx={{paddingTop: '50px'}} variant={'h3'}>
            <ARELink onClick={handleCompletedARE}>
              Confirm that I have completed the ARE
            </ARELink>
          </Typography>
        ) : null}
      </Box>

      <ARECompleteDialog
        open={isAREDialogOpen}
        setOpen={setIsAREDialogOpen}
        attempts={attempts}
      />

      <ExamStatusForm
        open={open}
        setOpen={setOpen}
        courseId={courseId}
        division={stateDivision!}
      />

      <ReferralDialog />
    </>
  )
}

export default View
