import {Box, Typography} from '@mui/material'
import styled from 'styled-components'
import theme from '../../../styles/theme'

export const VideoLecturesContainer = styled(Box)`
  display: flex;
  flex-flow: column;
  width: 100% !important;
  ${theme.breakpoints.down('sm')} {
  }
`

export const SubTitle = styled(Typography)`
  font-size: 18px !important;
`
