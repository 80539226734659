import React, {useState, useCallback, useEffect} from 'react'
import View from './view'
import {Helmet} from 'react-helmet'
import {useAppDispatch} from '../../redux/configureStore'
import {selectError, updatePassword, validateResetCode} from '../../redux/auth'
import {useSelector} from 'react-redux'
import RouteChangeTracker from '../../components/RouteChangeTracker'
import {useParams} from 'react-router-dom'
const ResetPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [isInvalidCode, setIsInvalidCode] = useState(false)
  const {resetCode} = useParams()

  const dispatch = useAppDispatch()

  const error = useSelector(selectError)

  const handleSubmit = async (password: string) => {
    setIsSubmitting(true)
    if (resetCode) {
      const {type} = await dispatch(updatePassword({password, resetCode}))
      if (type === updatePassword.fulfilled.type) {
        setIsComplete(true)
      }
    }
    setIsSubmitting(false)
  }

  const handleValidateCode = useCallback(async () => {
    if (resetCode) {
      const {type} = await dispatch(validateResetCode({resetCode}))
      if (type === validateResetCode.rejected.type) {
        setIsInvalidCode(true)
      }
    }
  }, [dispatch])

  useEffect(() => {
    handleValidateCode()
  }, [handleValidateCode])

  return (
    <>
      <RouteChangeTracker />
      <Helmet>
        <title>Forgot Password | Black Spectacles</title>
      </Helmet>
      <View
        onSubmit={handleSubmit}
        error={error}
        isSubmitting={isSubmitting}
        isComplete={isComplete}
        isInvalidCode={isInvalidCode}
      />
    </>
  )
}

export default ResetPassword
