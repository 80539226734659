import React from 'react'
import {useParams} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {Box} from '@mui/material'
import ExamQuestionsReview from '../../components/ExamQuestionsReview'

const ExamReview = () => {
  const {attemptId, state} = useParams()

  return (
    <>
      <Helmet>
        <title>{`Review ARE 5.0 Practice Exam`}</title>
      </Helmet>
      <Box>
        <ExamQuestionsReview attemptId={attemptId!} state={state} />
      </Box>
    </>
  )
}

export default ExamReview
