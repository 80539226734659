import React from 'react'
import {Box} from '@mui/material'
import {
  CheckBox,
  ExamBox,
  FixedBottom,
  MarkReviewBox,
  QuestionLink,
  QuestionStatus,
  StyledLogo,
  SummaryBox,
  SummaryDescription,
  SummaryItem,
  SummaryQuestions,
  SummaryQuestionsItem,
  SummaryTitle,
} from './styles'
import {Button} from '../button'
import BksLogo from '../../images/black-spectacles.svg'
import Flag from '../../images/icons/flag.png'
import {Exam, Question} from '../../redux/exams'
import {useWindowDimensions} from '../../hooks/useWindowDimensions'
import theme from '../../styles/theme'

type Props = {
  exam?: Exam
  seenQuestions: string[]
  isExamEnded: boolean
  selectForReview: (questionId: string, value: boolean) => void
  goToQuestion: (value: number) => void
  goToReview: (state: string) => void
  toggleDialog: (value: boolean) => void
  onUpdateActivePage: (value: string) => void
}

const Summary = ({
  exam,
  seenQuestions,
  isExamEnded,
  selectForReview,
  goToQuestion,
  goToReview,
  toggleDialog,
  onUpdateActivePage,
}: Props) => {
  let whiteRow = true

  const {width} = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.md

  const renderQuestionList = (
    type: 'discrete' | 'case_study',
    questionKey: number,
    questions?: Question[],
    lastQuestions?: number,
  ) => {
    return (
      <SummaryQuestions>
        {questions?.map((item, key) => {
          let questionStatus = 'Unseen'

          if (!isMobile) {
            if (key % 6 === 0) {
              whiteRow = false
            } else if (key % 3 === 0) {
              whiteRow = true
            }
          } else {
            whiteRow = !whiteRow
          }

          if (isExamEnded) {
            questionStatus = item.isAnswerCorrect ? 'Correct' : 'Incorrect'
          } else {
            if (seenQuestions.includes(item.id)) questionStatus = 'Incomplete'

            if (item.currentAnswer !== '') questionStatus = 'Complete'
          }

          const currentQuestion =
            type === 'case_study' && exam?.discreteQuestions
              ? questionKey === 0
                ? exam.discreteQuestions.length + key + 1
                : exam.discreteQuestions.length + (lastQuestions ?? 0) + key + 1
              : key + 1

          return (
            <SummaryQuestionsItem key={`list-${item.id}`} $isWhite={whiteRow}>
              {isMobile ? (
                <>
                  <QuestionLink
                    onClick={() => goToQuestion(currentQuestion - 1)}
                  >
                    Question {currentQuestion}
                  </QuestionLink>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    gap={'8px'}
                    marginLeft={'auto'}
                  >
                    <QuestionStatus status={questionStatus}>
                      {questionStatus}
                    </QuestionStatus>
                    <MarkReviewBox
                      onClick={() =>
                        selectForReview(item.id, item.forReview ? false : true)
                      }
                    >
                      <CheckBox>
                        {item.forReview ? <img src={Flag} alt="Flag" /> : null}
                      </CheckBox>
                    </MarkReviewBox>
                  </Box>
                </>
              ) : (
                <>
                  <MarkReviewBox
                    onClick={() =>
                      selectForReview(item.id, item.forReview ? false : true)
                    }
                  >
                    <CheckBox>
                      {item.forReview ? <img src={Flag} alt="Flag" /> : null}
                    </CheckBox>
                  </MarkReviewBox>
                  <QuestionLink
                    onClick={() => goToQuestion(currentQuestion - 1)}
                  >
                    Question {currentQuestion}
                  </QuestionLink>
                  <QuestionStatus status={questionStatus}>
                    {questionStatus}
                  </QuestionStatus>
                </>
              )}
            </SummaryQuestionsItem>
          )
        })}
        {exam?.questions && exam?.questions.length !== 0 ? (
          <SummaryQuestionsItem $isWhite={true} />
        ) : null}
      </SummaryQuestions>
    )
  }

  return (
    <>
      <Box position={'relative'}>
        <ExamBox>
          <SummaryTitle variant="h2">Exam Summary</SummaryTitle>
          <SummaryBox>
            <SummaryItem>Instructions</SummaryItem>
            <SummaryDescription>
              {isExamEnded ? (
                <>
                  Shown is your exam summary and question status. There are
                  multiple options in which you can review your questions:
                  <ol>
                    <li>
                      To review from the beginning at Question 1, click{' '}
                      <b>Review All</b>
                    </li>
                    <li>
                      To review any incomplete questions, beginning with the
                      first incomplete question, click <b>Review Incomplete</b>
                    </li>
                    <li>
                      To review only your marked items, click{' '}
                      <b>Review Marked</b>
                    </li>
                  </ol>
                  You also have the option to review individual questions by
                  just click on that specific item.
                </>
              ) : (
                <>
                  Below is a summary of your questions and the status of each.
                  You can review your questions in three different ways by using
                  the buttons in the lower right-hand corner of the screen.
                  <ol>
                    <li>
                      Select <b>Review All</b> to return to your first unlocked
                      question. You will be shown all Completed, Incomplete, and
                      Unseen questions on your exam.
                    </li>
                    <li>
                      Select <b>Review All Unanswered</b> to return to your
                      first Incomplete or Unseen question. You will be shown all
                      Incomplete and Unseen questions on your exam.
                    </li>
                    <li>
                      Select <b>Review Marked</b> to return to your first Marked
                      question (identified by <img src={Flag} alt="Flag" />
                      ). You will be shown all <span>unlocked</span> questions
                      that are Marked on your exam.
                    </li>
                    <li>
                      <b>Before you take a break</b>, it is recommended that you
                      complete any previously viewed questions. All questions
                      listed below as Completed or Incomplete will be Locked
                      when you return from your break. Once locked, you will not
                      be able to view or edit them.
                    </li>
                    <li>
                      If you would like to review only your Incomplete
                      questions, you may mark questions on this screen by
                      clicking the box to the left of each question number, then
                      click <b>Review Marked</b>.
                    </li>
                  </ol>
                  To end this exam, click <b>End Exam</b> in the lower left-hand
                  corner of the screen.
                </>
              )}
            </SummaryDescription>
            <SummaryItem>Discrete Items</SummaryItem>
            {renderQuestionList('discrete', 0, exam?.discreteQuestions)}
            {exam?.caseStudies.map((caseStudy, key) => {
              const lastQuestions =
                key > 0 ? exam?.caseStudies[0].questions.length : 0

              return (
                <React.Fragment key={'cs-' + key}>
                  <SummaryItem>Case Study {key + 1} Items</SummaryItem>
                  {renderQuestionList(
                    'case_study',
                    key,
                    caseStudy.questions,
                    lastQuestions,
                  )}
                </React.Fragment>
              )
            })}
          </SummaryBox>
        </ExamBox>
      </Box>

      <FixedBottom style={{paddingLeft: isMobile ? '8px' : '20px'}}>
        <Button
          children={isExamEnded ? 'SCORE SUMMARY' : 'END EXAM'}
          color="red"
          onClick={() =>
            isExamEnded ? onUpdateActivePage('score') : toggleDialog(true)
          }
        />
        {isMobile ? (
          <>
            <Button
              children={isExamEnded ? 'CORRECT' : 'MARKET'}
              onClick={() => goToReview(isExamEnded ? 'correct' : 'marked')}
              color="black"
              style={isMobile ? {order: 4} : {marginRight: '20px'}}
            />
            <Button
              children={isExamEnded ? 'INCORRECT' : 'UNANSWERED'}
              onClick={() =>
                goToReview(isExamEnded ? 'incorrect' : 'unanswered')
              }
              color="black"
              style={isMobile ? {order: 3} : {marginRight: '20px'}}
            />
            <Button
              children={'REVIEW ALL'}
              onClick={() => goToQuestion(0)}
              color="black"
              style={isMobile ? {order: 2} : {marginRight: '20px'}}
            />
          </>
        ) : (
          <Box style={{marginLeft: 'auto'}}>
            <Button
              children={isExamEnded ? 'REVIEW CORRECT' : 'REVIEW MARKED'}
              onClick={() => goToReview(isExamEnded ? 'correct' : 'marked')}
              color="black"
              style={{marginRight: '20px'}}
            />
            <Button
              children={
                isExamEnded ? 'REVIEW INCORECT' : 'REVIEW ALL UNANSWERED'
              }
              onClick={() =>
                goToReview(isExamEnded ? 'incorrect' : 'unanswered')
              }
              color="black"
              style={{marginRight: '20px'}}
            />
            <Button
              children={'REVIEW ALL'}
              onClick={() => goToQuestion(0)}
              color="black"
              style={{marginRight: '20px'}}
            />
          </Box>
        )}
        <StyledLogo src={BksLogo} alt="Black Spectacles" />
      </FixedBottom>
    </>
  )
}

export default Summary
