import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../hooks/useApi'

//#region types
export type Activity = {
  activity: string
  type: string
  participants: number
  price: number
  link: string
  key: string
  accessibility: number
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  data?: Activity
  status: LoadingStatuses
  error?: string
}
//#endregion

//#region api
type getActivityPayload = {
  id?: string
  participant?: number
}

export const getTestData = createAsyncThunk<any, getActivityPayload>(
  '/test',
  async () => {
    return useApi(null, 'https://www.boredapi.com/api/activity?', {
      method: 'GET',
    }).then(res => res.json())
  },
)
//#endregion

//#region slice
const initialState: SliceState = {
  data: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'testGetAPI',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTestData.fulfilled, (state, action) => {
        state.data = action.payload
        state.status = LoadingStatuses.Succeeded
        state.error = undefined
      })
      .addCase(getTestData.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
//#endregion
