import React, {useEffect, useCallback} from 'react'
import View from './view'
import {useAppDispatch} from '../../redux/configureStore'
import {useSelector} from 'react-redux'
import {
  CourseLesson,
  getCourse,
  selectCourse,
  selectCourseStatus,
} from '../../redux/courses'
import {
  getGuestLessons,
  getLessons,
  resetExam,
  resetVideoProgress,
  selectLesson,
  selectLessonStatus,
} from '../../redux/lessons'
import Loading from '../../components/Loading'
import {LoadingStatuses} from '../../components/types/LoadingStatusses'
import {useLocation, useParams} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {clearVideoState} from '../../redux/videos'
import {toggleErrorDialog} from '../../redux/config'
import {useAuth} from '../../components/AuthProvider'

const Courses = () => {
  const auth = useAuth()
  const {courseId} = useParams()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const course = useSelector(selectCourse)
  const courseStatus = useSelector(selectCourseStatus)
  const lesson = useSelector(selectLesson)
  const lessonStatus = useSelector(selectLessonStatus)

  const softwareTags = course?.tags?.filter(
    tag => tag.category === 'SOFTWARE_COURSE',
  )

  const isSoftware = softwareTags && softwareTags?.length > 0

  const resetQuiz = async (examId: string) => {
    try {
      const {type} = await dispatch(resetExam({auth, examId}))
      if (type === resetExam.rejected.type) {
        throw new Error('Unable to reset quiz')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to reset the quiz, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const resetVideo = async (lessonPlanId: string) => {
    try {
      const {type} = await dispatch(resetVideoProgress({auth, lessonPlanId}))
      if (type === resetVideoProgress.rejected.type) {
        throw new Error('Unable to reset course progress')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to reset course progress, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const loadCourse = useCallback(async () => {
    await dispatch(clearVideoState())
    try {
      const {type, payload} = await dispatch(getCourse({courseId}))
      if (type === getCourse.fulfilled.type) {
        const courseLessons = payload.lessons.filter(
          (item: CourseLesson) => item.active,
        )

        courseLessons.sort((a: any, b: any) => b.version - a.version)
        const lessonId = courseLessons[0].id

        if (auth.isAuthenticated) {
          const lessonData = await dispatch(getLessons({auth, lessonId}))

          if (lessonData.type === getLessons.rejected.type) {
            throw new Error('Unable to get lessons')
          }
        } else {
          const lessonData = await dispatch(getGuestLessons({lessonId}))

          if (lessonData.type === getGuestLessons.rejected.type) {
            throw new Error('Unable to get lessons')
          }
        }
      } else if (type === getCourse.rejected.type) {
        throw new Error('Unable to get course')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the course, please retry reloading the page. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch, courseId])

  useEffect(() => {
    loadCourse()
  }, [loadCourse])

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById(location.hash.slice(1))
        ?.scrollIntoView({behavior: 'smooth'})
    }, 500)
  }, [location.hash])

  if (
    courseStatus === LoadingStatuses.Loading ||
    courseStatus === LoadingStatuses.Idle ||
    lessonStatus === LoadingStatuses.Idle ||
    lessonStatus === LoadingStatuses.Loading
  ) {
    return <Loading />
  }

  return (
    <>
      {course && (
        <Helmet>
          {isSoftware ? (
            <title>{`${course.title} | Black Spectacles`}</title>
          ) : (
            <title>{`ARE 5.0 ${course.title} Exam Prep | Black Spectacles`}</title>
          )}
        </Helmet>
      )}
      <View
        course={course}
        lesson={lesson}
        isSoftware={isSoftware}
        onResetQuiz={resetQuiz}
        onResetVideo={resetVideo}
      />
    </>
  )
}

export default Courses
