import {Box, Typography} from '@mui/material'
import styled from 'styled-components'
import theme from '../../styles/theme'

export const FooterBox = styled(Box)`
  background-color: #242424;
  padding: 40px 0;

  ${theme.breakpoints.down('md')} {
    padding: 0;
  }
`

export const Content = styled(Box)`
  display: flex;
  width: 100%;
  gap: 30%;
`

export const LeftBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 40px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    gap: 0;
  }
`

export const RightBox = styled(Box)`
  display: flex;
  gap: 32px;

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`

export const StyledLogo = styled.img`
  width: 214px;

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`

export const SocialBox = styled(Box)`
  background-color: #242424;
  display: flex;
  justify-content: flex-start;
  gap: 8px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    height: 97px;
    justify-content: center;
    align-items: center;
  }
`

export const TextBox = styled(Box)`
  ${theme.breakpoints.down('md')} {
    background: black;
    padding: 12px 20px;
    width: 100%;
    box-sizing: border-box;
  }
`

export const Text = styled(Typography)`
  color: #666 !important;
  font-family:
    'Public' Sans,
    'Times New Roman',
    Times,
    serif !important;
  font-size: 14px !important;
`

export const LinkBox = styled(Box)`
  && a {
    color: #fff !important;
    font-size: 14px !important;
    font-family: Public Sans !important;

    &:hover {
      color: #1377d4 !important;
      text-decoration: none;
    }
  }
`

export const List = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 12px;
`
