import React, {useEffect, useCallback} from 'react'
import View from './view'
import {useParams} from 'react-router-dom'
import {useAppDispatch} from '../../redux/configureStore'
import {
  CourseLesson,
  getCourse,
  selectCourse,
  selectCourseStatus,
} from '../../redux/courses'
import {
  getGuestLessons,
  getLessons,
  resetExam,
  selectLesson,
  selectLessonStatus,
} from '../../redux/lessons'
import {useSelector} from 'react-redux'
import {toggleErrorDialog} from '../../redux/config'
import {LoadingStatuses} from '../../components/types/LoadingStatusses'
import Loading from '../../components/Loading'
import {Helmet} from 'react-helmet'
import {useAuth} from '../../components/AuthProvider'

const QuizzesList = () => {
  const {courseId} = useParams()
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const course = useSelector(selectCourse)
  const lesson = useSelector(selectLesson)
  const courseStatus = useSelector(selectCourseStatus)
  const lessonStatus = useSelector(selectLessonStatus)

  const resetQuiz = async (examId: string) => {
    try {
      const {type} = await dispatch(resetExam({auth, examId}))
      if (type === getCourse.rejected.type) {
        throw new Error('Unable to reset quiz')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to reset the quiz, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const loadCourse = useCallback(async () => {
    try {
      const {type, payload} = await dispatch(getCourse({courseId}))
      if (type === getCourse.fulfilled.type) {
        const courseLessons = payload.lessons.filter(
          (item: CourseLesson) => item.active,
        )

        courseLessons.sort((a: any, b: any) => b.version - a.version)
        const lessonId = courseLessons[0].id

        if (auth.isAuthenticated) {
          await dispatch(getLessons({auth, lessonId}))
        } else {
          await dispatch(getGuestLessons({lessonId}))
        }
      } else if (type === getCourse.rejected.type) {
        throw new Error('Unable to get quizzes')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the quizzes, please retry reloading the page. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch, courseId])

  useEffect(() => {
    loadCourse()
  }, [loadCourse])

  if (
    courseStatus === LoadingStatuses.Loading ||
    courseStatus === LoadingStatuses.Idle ||
    lessonStatus === LoadingStatuses.Idle ||
    lessonStatus === LoadingStatuses.Loading
  ) {
    return <Loading />
  }

  return (
    <>
      {course && (
        <Helmet>
          <title>{`ARE 5.0 ${course.title} Exam Prep | Black Spectacles`}</title>
        </Helmet>
      )}
      <View course={course!} lesson={lesson!} onResetQuiz={resetQuiz} />
    </>
  )
}

export default QuizzesList
