import styled from 'styled-components'
import {Box, Typography} from '@mui/material'

export const CourseBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-flow: column;
  gap: 80px;
`

export const Title = styled(Typography)`
  color: #1377d4 !important;
  font-family: Freight Disp Pro !important;
  font-weight: 300 !important;
  font-size: 24px !important;
  line-height: 1.2 !important;
  text-transform: uppercase !important;
  margin-top: 12px !important;
`

export const SubTitle = styled(Typography)`
  font-style: italic !important;
  margin-top: 12px !important;
`

export const StyledImage = styled.img`
  width: 100%;
  max-width: 800px;
`
