import styled from 'styled-components'
import {Box, Typography} from '@mui/material'
import {Field} from 'formik'

export const Title = styled(Typography)`
  font-size: 32px !important;
  color: #2d2d2d !important;
`

export const UsersBox = styled(Box)`
  margin-bottom: 48px;
`

export const UsersHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 2px #96e0fc solid;
  padding: 15px 0;
`

export const UserTitle = styled(Box)`
  color: #2d2d2d;
  font-size: 16px;
`

export const SubTitle = styled(Typography)`
  font-size: 14px !important;
  color: #000 !important;
  font-weight: 700 !important;
  font-style: italic !important;
  padding: 20px 0;
`

export const UsersGroup = styled(Box)`
  margin-bottom: 20px;
  width: 100%;
`

export const UserItem = styled(Box)<{$bgColor: string}>`
  padding: 10px 10px 10px 0;
  font-family: Public Sans;
  font-size: 16px;
  background-color: ${({$bgColor}) => $bgColor};
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;

  && input {
    cursor: pointer;
  }
`

export const AddMemberSubtitle = styled(Typography)`
  font-size: 14px !important;
  color: #000 !important;
  font-family: Public Sans !important;
  padding: 20px 0;
`

export const AddMemberHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 2px #96e0fc solid;
  padding: 15px 0;
`

export const AddMemberHeaderItem = styled(Box)`
  color: #2d2d2d;
  font-size: 20px;
  width: 33%;
  text-align: center;
`

export const AddMemberItem = styled(Box)`
  display: flex;
  gap: 15px;
  margin-top: 10px;
`

export const StyledInput = styled(Field)`
  width: 100%;
  padding: 12px;
  margin: 3px 0;
  border: 1px #d4d4d4 solid;
  box-sizing: border-box;
`

export const ImportBox = styled(Box)`
  margin-top: 48px;
`

export const ImportTitle = styled(Typography)`
  font-size: 16px !important;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const ButtonsBox = styled(Box)`
  margin-top: 48px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`
