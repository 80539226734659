import Pusher from 'pusher-js'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY ?? '', {
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER ?? '',
  channelAuthorization: {
    endpoint:
      process.env.REACT_APP_BKS_PLATFORM_SERVICES_URL + '/v1/are/pusher/auth',
    headers: {
      Authorization:
        'Bearer ' +
        cookies.get(process.env.REACT_APP_TOKEN_NAME || 'local_bks_token'),
    },
    transport: 'ajax',
  },
  forceTLS: true,
})

export const PusherChannels = {
  presenceOrgChannel: (orgId: number) => `presence-org-${orgId}`,
}
