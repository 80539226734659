import React from 'react'
import View from './view'
import {Helmet} from 'react-helmet'
import RouteChangeTracker from '../../components/RouteChangeTracker'

const CapReached = () => {
  return (
    <>
      <RouteChangeTracker />
      <Helmet>
        <title>Cap Reached | Black Spectacles</title>
      </Helmet>
      <View />
    </>
  )
}

export default CapReached
