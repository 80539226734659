import styled from 'styled-components'
import {Box, Link, Typography} from '@mui/material'

export const Title = styled(Typography)`
  font-family: Public Sans !important;
  text-transform: none !important;
  font-size: 20px !important;
  color: #2d2d2d !important;
`

export const CardBox = styled(Box)`
  font-family: Public Sans !important;
  display: flex;
  flex-flow: column;
  gap: 8px;
  margin-top: 16px;
`

export const LinkBox = styled(Box)`
  margin-top: 32px;
  font-size: 16px;
  font-family: Public Sans !important;
  font-style: italic !important;
  font-weight: bold;
`

export const InvoiceItem = styled(Box)<{$bgColor: string; $isLink?: boolean}>`
  padding: 10px 10px 10px 0;
  font-family: Public Sans 
  font-style: italic;
  font-size: 16px;
  background-color: ${({$bgColor}) => $bgColor};
  display: flex;
  width: 100%;
  box-sizing: border-box;

  ${({$isLink}) =>
    $isLink
      ? `
		&:hover {
			cursor: pointer;
			background-color: #96e0fc;
		}
	`
      : null}
`

export const InvoicesHeader = styled(Box)`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 2px #96e0fc solid;
  padding: 15px 0;
`

export const StyledLink = styled(({...props}) => <Link {...props} />)`
  &:hover {
    color: #23527c !important;
  }
  && svg {
    display: flex;
    align-self: flex-end;
    margin-left: auto;
  }
`
