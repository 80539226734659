import React from 'react'
import {Stepper, Step, StepLabel, Box} from '@mui/material'
import pluralize from 'pluralize'
import {LeftBox, CourseTitle, StepsBox, StepsTitle} from './styles'
import {VideoGroup} from '../../redux/videos'
import {Link} from 'react-router-dom'
import {Course} from '../../redux/courses'
import {selectMemberAccess} from '../../redux/members'
import {useSelector} from 'react-redux'

import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined'
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined'

type Props = {
  id: string
  course: Course
  currentGroup?: VideoGroup
  previousGroup?: VideoGroup
  nextGroup?: VideoGroup
  mobileOpen: boolean
  currentIndex?: number
}

const View = ({
  id,
  course,
  currentGroup,
  previousGroup,
  nextGroup,
  mobileOpen,
  currentIndex,
}: Props) => {
  let menuGroup = 0

  const access = useSelector(selectMemberAccess)

  const software = course?.tags?.find(
    item => item.category === 'SOFTWARE_COURSE',
  )

  const isSoftware = software ? true : false

  const practicalApplication = course?.tags?.find(
    item => item.category === 'PRACTICAL_APPLICATION_COURSE',
  )

  const isPracticalApplication = practicalApplication ? true : false

  const practicalApplicationsLessons = currentGroup?.lessons.filter(
    item => item.isPracticalApplication,
  )
  const regularLessons = currentGroup?.lessons.filter(
    item => !item.isPracticalApplication,
  )

  const hasPAAccess =
    access && Object.keys(access).length !== 0
      ? Number(access.are?.practical_apps?.value) === 1
      : false

  return (
    <LeftBox $isOpen={mobileOpen}>
      <CourseTitle>
        <Link
          to={
            course?.abbreviation
              ? `/courses/${currentGroup?.courseId}`
              : isSoftware
              ? `/courses/${course?.id}`
              : '/practical-applications'
          }
        >
          {isSoftware ? (
            <>{course?.title}</>
          ) : (
            <>
              {isPracticalApplication ? (
                <>Practical Applications</>
              ) : (
                <>{currentGroup?.title}</>
              )}
            </>
          )}
        </Link>
      </CourseTitle>
      {previousGroup && (
        <CourseTitle>
          Previous Chapter:
          <br />
          <span>
            <Link
              to={`/courses/${previousGroup?.courseId}/${
                previousGroup.lessons[0].assetTypeAbbr
              }/${previousGroup.lessons[0].id}${
                previousGroup.lessons[0].isFree ? '/free' : ''
              }`}
            >
              {previousGroup?.currentlyViewing}
            </Link>
          </span>
        </CourseTitle>
      )}
      <CourseTitle>
        Currently Viewing:
        <br />
        <span>{currentGroup?.currentlyViewing}</span>
      </CourseTitle>
      <StepsBox>
        <StepsTitle>
          <SquareFootOutlinedIcon fontSize="large" style={{color: '#fff'}} />{' '}
          Course Videos
        </StepsTitle>
        <Stepper orientation="vertical">
          {regularLessons?.map((lesson, key) => {
            let className = 'PendingVideo'
            if (id === lesson.id) className = 'CurrentVideo'
            else if (currentIndex ? key < currentIndex : false)
              className = 'CompletedVideo'

            const Component = (
              <Step
                key={key}
                className={className}
                active={id === lesson.id}
                completed={currentIndex ? key < currentIndex : false}
                index={menuGroup}
                style={{
                  marginBottom: lesson.assetTypeAbbr === 'quiz' ? '20px' : 0,
                }}
              >
                <StepLabel>
                  <Link
                    to={`/courses/${currentGroup?.courseId}/${
                      lesson.assetTypeAbbr
                    }/${lesson.id}${lesson.isFree ? '/free' : ''}`}
                  >
                    {lesson.shortDescription}
                  </Link>
                  <br />
                  {lesson.assetTypeAbbr === 'video' && (
                    <span>{lesson.videoDuration}</span>
                  )}
                  {lesson.assetTypeAbbr === 'quiz' && lesson.questions && (
                    <span>{pluralize('Question', lesson.questions, true)}</span>
                  )}
                </StepLabel>
              </Step>
            )

            menuGroup = lesson.assetTypeAbbr === 'quiz' ? 0 : menuGroup + 1

            return Component
          })}
        </Stepper>

        {practicalApplicationsLessons &&
          practicalApplicationsLessons.length > 0 && (
            <Box style={{marginTop: '30px'}}>
              <StepsTitle>
                <Person2OutlinedIcon fontSize="large" style={{color: '#fff'}} />{' '}
                Practical Applications
                {!hasPAAccess && (
                  <>
                    <span>—</span>
                    <Link to={'/profile/settings/manage'}>UPGRADE TO PRO</Link>
                  </>
                )}
              </StepsTitle>
              <Stepper orientation="vertical">
                {practicalApplicationsLessons?.map((lesson, key) => {
                  const fixedKey = regularLessons
                    ? key + regularLessons?.length
                    : 0

                  let className = 'PendingVideo'
                  if (id === lesson.id) className = 'CurrentVideo'
                  else if (currentIndex ? fixedKey < currentIndex : false)
                    className = 'CompletedVideo'

                  const Component = (
                    <Step
                      key={`pa-${key}`}
                      className={className}
                      active={id === lesson.id}
                      completed={currentIndex ? fixedKey < currentIndex : false}
                      index={key}
                      style={{
                        marginBottom:
                          lesson.assetTypeAbbr === 'quiz' ? '20px' : 0,
                      }}
                    >
                      <StepLabel>
                        <Link
                          to={`/courses/${currentGroup?.courseId}/${
                            lesson.assetTypeAbbr
                          }/${lesson.id}${lesson.isFree ? '/free' : ''}`}
                        >
                          {lesson.shortDescription}
                        </Link>
                        <br />
                        {lesson.assetTypeAbbr === 'video' && (
                          <span>{lesson.videoDuration}</span>
                        )}
                        {lesson.assetTypeAbbr === 'quiz' &&
                          lesson.questions && (
                            <span>
                              {pluralize('Question', lesson.questions, true)}
                            </span>
                          )}
                      </StepLabel>
                    </Step>
                  )

                  return Component
                })}
              </Stepper>
            </Box>
          )}
      </StepsBox>
      {nextGroup && (
        <CourseTitle>
          Up Next:
          <br />
          <span>
            <Link
              to={`/courses/${nextGroup?.courseId}/${
                nextGroup.lessons[0].assetTypeAbbr
              }/${nextGroup.lessons[0].id}${
                nextGroup.lessons[0].isFree ? '/free' : ''
              }`}
            >
              {nextGroup?.currentlyViewing}
            </Link>
          </span>
        </CourseTitle>
      )}
    </LeftBox>
  )
}

export default View
