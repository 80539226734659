import styled from 'styled-components'
import {Box, Typography, Link} from '@mui/material'

import FacebookHover from '../images/icons/social/meta.svg'
import Facebook from '../images/icons/social/meta-white.svg'
import InstagramHover from '../images/icons/social/instagram.svg'
import Instagram from '../images/icons/social/instagram-white.svg'
import XHover from '../images/icons/social/x.svg'
import X from '../images/icons/social/x-white.svg'
import YoutubeHover from '../images/icons/social/youtube.svg'
import Youtube from '../images/icons/social/youtube-white.svg'
import LinkedinHover from '../images/icons/social/linkedin.svg'
import Linkedin from '../images/icons/social/linkedin-white.svg'
import theme, {colors} from './theme'

export const MainContainer = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow-x: clip;
  height: 100%;
`

export const WrapperBox = styled(Box)<{$removeMarginTop?: boolean}>`
  width: 83.333%;
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 0;

  gap: 8%;
  flex-flow: row;

  ${({$removeMarginTop}) =>
    $removeMarginTop
      ? null
      : `
    margin-top: 30px;
  `}

  ${theme.breakpoints.down('md')} {
    width: 100%;
    flex-flow: column;
    padding: 15px;
    box-sizing: border-box;
  }
`

export const CompleteTitle = styled(Typography)`
  color: #37474f !important;
  font-size: 26px !important;
  margin-bottom: 30px !important;
  text-align: center !important;
  font-style: italic;

  ${theme.breakpoints.down('md')} {
    margin-top: 80px !important;
    margin-bottom: -80px !important;
  }
`

export const FacebookIcon = styled(Box)<{$isSmall?: boolean}>`
  ${({$isSmall}) =>
    $isSmall
      ? `
      width: 26px;
      height: 26px;
      background-size: 12px 12px !important;
      border-radius: 2px;
    `
      : `
      width: 50px;
      height: 50px;
    `}

  box-sizing: border-box;
  background: url(${Facebook}) center no-repeat;
  background-size: 42%;
  background-color: #000;
  border: 2px solid #000;
  cursor: pointer;

  &: hover {
    background: url(${FacebookHover}) center no-repeat;
    background-size: 42%;
    background-color: #fff;
    border: 2px solid #000;
    transition: 0.3s;
  }

  ${theme.breakpoints.down('md')} {
    height: 37px !important;
    width: 37px !important;
    background-size: 18px 18px !important;
  }
`

export const TwitterIcon = styled(Box)<{$isSmall?: boolean}>`
  ${({$isSmall}) =>
    $isSmall
      ? `
    width: 26px;
    height: 26px;
    border-radius: 2px;
    background-size: 12px 12px !important;
  `
      : `
    width: 50px;
    height: 50px;
  `}

  box-sizing: border-box;
  background: url(${X}) center no-repeat;
  background-size: 42%;
  background-color: #000;
  border: 2px solid #000;
  cursor: pointer;

  &: hover {
    background: url(${XHover}) center no-repeat;
    background-size: 42%;
    background-color: #fff;
    border: 2px solid #000;
    transition: 0.3s;
  }

  ${theme.breakpoints.down('md')} {
    height: 37px !important;
    width: 37px !important;
    background-size: 18px 18px !important;
  }
`

export const InstagramIcon = styled(Box)<{$isSmall?: boolean}>`
  ${({$isSmall}) =>
    $isSmall
      ? `
    width: 26px;
    height: 26px;
    border-radius: 2px;
    background-size: 12px 12px !important;
  `
      : `
    width: 50px;
    height: 50px;
  `}

  box-sizing: border-box;
  background: url(${Instagram}) center no-repeat;
  background-size: 42%;
  background-color: #000;
  border: 2px solid #000;
  cursor: pointer;

  &: hover {
    background: url(${InstagramHover}) center no-repeat;
    background-size: 42%;
    background-color: #fff;
    border: 2px solid #000;
    transition: 0.3s;
  }

  ${theme.breakpoints.down('md')} {
    height: 37px !important;
    width: 37px !important;
    background-size: 18px 18px !important;
  }
`

export const YoutubeIcon = styled(Box)<{$isSmall?: boolean}>`
  ${({$isSmall}) =>
    $isSmall
      ? `
    width: 26px;
    height: 26px;
    border-radius: 2px;
    background-size: 16px 12px !important;
  `
      : `
    width: 50px;
    height: 50px;
  `}

  box-sizing: border-box;
  background: url(${Youtube}) center no-repeat;
  background-size: 42%;
  background-color: #000;
  border: 2px solid #000;
  cursor: pointer;

  &: hover {
    background: url(${YoutubeHover}) center no-repeat;
    background-size: 42%;
    background-color: #fff;
    border: 2px solid #000;
    transition: 0.3s;
  }

  ${theme.breakpoints.down('md')} {
    height: 37px !important;
    width: 37px !important;
    background-size: 18px 18px !important;
  }
`

export const LinkedinIcon = styled(Box)<{$isSmall?: boolean}>`
  ${({$isSmall}) =>
    $isSmall
      ? `
    width: 26px;
    height: 26px;
    border-radius: 2px;
    background-size: 12px 12px !important;
  `
      : `
    width: 50px;
    height: 50px;
  `}

  box-sizing: border-box;
  background: url(${Linkedin}) center no-repeat;
  background-size: 42%;
  background-color: #000;
  border: 2px solid #000;
  cursor: pointer;

  &: hover {
    background: url(${LinkedinHover}) center no-repeat;
    background-size: 42%;
    background-color: #fff;
    border: 2px solid #000;
    transition: 0.3s;
  }

  ${theme.breakpoints.down('md')} {
    height: 37px !important;
    width: 37px !important;
    background-size: 18px 18px !important;
  }
`

export const LinkBox = styled(({...props}) => <Link {...props} />)`
  text-decoration: none;

  &:active {
    user-select: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const LinkButton = styled(({...props}) => <Link {...props} />)`
  width: 100%;
  text-align: left;
  margin-bottom: 2px !important;
  display: flex;
  justify-content: start;
  text-decoration: none !important;
  padding: 12px;
  font-size: 14px !important;
  letter-spacing: 0.05em;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;

  ${({$isActive}) =>
    $isActive
      ? `
    background-color: #1377D4 !important;
    color: #FFFFFF!important;
    text-decoration: none !important;
  `
      : `
    background-color: #ECEFF1;
    color: #221F1F!important;
  `}

  &: hover {
    background-color: #1377d4 !important;
    color: #ffffff !important;
    text-decoration: none !important;
  }
`

export const BlueOutlineLinkButton = styled(({...props}) => (
  <Link {...props} />
))`
  width: 100%;
  text-align: left;
  margin-bottom: 2px !important;
  display: flex;
  justify-content: start;
  text-decoration: none !important;
  padding: 12px;
  font-size: 14px !important;
  letter-spacing: 0.05em;
  border-radius: 3px;
  box-sizing: border-box;
  border: 2px solid ${colors.blue1} !important;
  background-color: #fff;
  color: ${colors.blue1} !important;

  &: hover {
    background-color: ${colors.blue1};
    color: #ffffff !important;
    text-decoration: none !important;
  }
`

export const LeftBox = styled(Box)`
  width: 60%;

  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }
`

export const RightBox = styled(Box)`
  width: 25%;

  && button {
    width: 100% !important;
    margin-bottom: 16px;
  }

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`

export const SectionHeader = styled(Box)`
  color: 2d2d2d;
  border-radius: 2px;
  width: 100%;
  padding: 12px 0;
  font-size: 20px;
`

export const SectionContent = styled(Typography)`
  font-size: 14px !important;
  line-height: 22px !important;
  margin-bottom: 30px !important;
  font-family: Public Sans !important;
`

export const SocialBox = styled(Box)`
  display: flex;
  gap: 8px;
`
export const PrimaryLinkButton = styled(({...props}) => <Link {...props} />)`
  font-family: Public Sans !important;
  width: 250px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none !important;
  padding: 12px;
  font-size: 14px !important;
  letter-spacing: 0.05em;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #fff;
  color: #1377d4 !important;
  margin-left: auto !important;
  border: 2px solid #1377d4;
  transition: 0.2s;

  &: hover {
    background-color: #1377d4;
    color: #ffffff !important;
    text-decoration: none !important;
    border: 2px solid #1377d4;
  }
`

export const StyledTitle = styled(Typography)`
  color: #2d2d2d !important;
  font-size: 32px !important;
`
