import React from 'react'
import View from './view'
import {Course} from '../../../redux/courses'

type Props = {
  course: Course
}

const StudyMaterials = ({course}: Props) => {
  return <View course={course} />
}

export default StudyMaterials
