import React, {useRef} from 'react'
import {Box, Typography, Modal, Backdrop, Fade} from '@mui/material'
import {CloseContainer, FormTitle, ModalContainer, FormBody} from './styles'
import CloseIcon from '@mui/icons-material/Close'
import {PlayerBox} from '../../../pages/Videos/styles'
import ReactPlayerLoader from '@brightcove/react-player-loader'

type Props = {
  demoModalOpen: boolean
  setDemoModalOpen: (val: boolean) => void
}

const View = ({demoModalOpen, setDemoModalOpen}: Props) => {
  const playerRef: any = useRef()

  const handleClose = () => {
    setDemoModalOpen(false)
  }

  return (
    <Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={demoModalOpen}
        closeAfterTransition
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={demoModalOpen}>
          <ModalContainer>
            <CloseContainer>
              <CloseIcon onClick={handleClose} style={{fontSize: '35px'}} />
            </CloseContainer>

            <FormTitle>
              <Typography variant={'h3'}>
                Getting Started with Virtual Workshops
              </Typography>
            </FormTitle>

            <FormBody>
              <PlayerBox>
                <ReactPlayerLoader
                  ref={playerRef}
                  id={'player'}
                  accountId="5454530067001"
                  playerId={process.env.REACT_APP_BRIGHTCOVE_ID}
                  videoId={
                    process.env.REACT_APP_VIRTUAL_WORKSHOP_VIDEO_BRIGHTCOVE_ID
                  }
                  options={{
                    responsive: true,
                    autoplay: false,
                    muted: false,
                    aspectRatio: '4:3',
                    playbackRates: [0.5, 1, 1.5, 2],
                  }}
                />
              </PlayerBox>
            </FormBody>
          </ModalContainer>
        </Fade>
      </Modal>
    </Box>
  )
}

export default View
