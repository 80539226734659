import styled from 'styled-components'
import {Box, Typography} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

export const CourseBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 80px;
`

export const LessonsBox = styled(Box)`
  margin-top: -20px;
`

export const AuthorBox = styled(Box)`
  margin-top: 20px;
  display: flex;
  gap: 12px;
  align-items: center;

  && button {
    height: 40px !important;
    font-weight: bold !important;
  }
`

export const AuthorText = styled(Box)`
  line-height: 16px !important;
  font-size: 16px !important;
  color: #666 !important;
  font-family: Public Sans !important;
`

export const LessonTitle = styled(Typography)`
  font-size: 20px !important;
  letter-spacing: 1px !important;
  color: #2d2d2d !important;
  margin-top: 60px !important;
`

export const LessonHeader = styled(Box)`
  border-bottom: 2px #1377d4 solid;
  font-size: 16px;
  color: #2d2d2d;
  width: 100%;
  display: flex;
  padding: 12px 8px;
  align-items: center;
  box-sizing: border-box;

  && img {
    width: 25px;
  }
`

export const LessonBody = styled(Box)`
  width: 100%;
  padding: 16px 8px;
  font-family: Public Sans;
  font-size: 14px;
  display: flex;
  box-sizing: border-box;
  && img {
    width: 30px;
  }
`

export const LessonBorder = styled(Box)<{$isAuthenticated?: boolean}>`
  border-bottom: 2px #f1f4f6 solid;

  &:hover {
    color: #1377d4;

    background: ${({$isAuthenticated}) =>
      $isAuthenticated ? '#ccf1ff' : null};
  }

  ${({$isAuthenticated}) =>
    !$isAuthenticated
      ? `
    && a {
      color:#c2c2c2 !important;
      text-decoration: none !important;
    }
    
    && em {
      color:#c2c2c2 !important;
    }
  `
      : null}
`

export const ImageBox = styled(Box)`
  padding-top: 20px;
`

export const HiddenBox = styled(Box)<{$isExpanded: boolean}>`
  display: ${({$isExpanded}) => ($isExpanded ? 'block' : 'none')};
  color: #000;
`

export const Quote = styled.p`
  font-family: Public Sans;
  line-height: 30px;
  font-style: italic;
  &:before {
    content: open-quote;
    color: #ccc;
    font-size: 3em;
    line-height: 0.1em;
    vertical-align: -0.4em;
    margin-right: 0.15em;
  }

  &:after {
    content: close-quote;
    color: #ccc;
    font-size: 3em;
    line-height: 0.1em;
    vertical-align: -0.4em;
  }
`

export const SectionHeader = styled(Box)`
  color: 2d2d2d;
  border-radius: 2px;
  width: 100%;
  padding: 12px 0;
  font-size: 20px;
`

export const SectionContent = styled(Typography)`
  font-size: 14px !important;
  line-height: 22px !important;
  margin-bottom: 30px !important;
  font-family: Public Sans !important;
`

export const SocialBox = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 8px;
`

export const CheckArea = styled(Typography)`
  padding: 0;
  display: flex;
  justify-content: center;
  position: absolute;
`

export const StyledCheckIcon = styled(CheckIcon)`
  color: #1377d4;
  width: 40px !important;
  height: 40px !important;
`

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`

export const LockBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-bottom: 32px;
  gap: 4px;
  font-family: Public Sans !important;

  && svg {
    font-size: 14px;
  }

  && a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
`
