import React, {useEffect, useState} from 'react'
import {
  ButtonBox,
  DialogBox,
  DialogSubtitle,
  DialogText,
  DialogTitle,
  ExpiryDialogTextareaAutosize,
  QuestionContainer,
  StyledDialog,
} from './styles'
import {Button} from '../button'
import CloseIcon from '@mui/icons-material/Close'
import {Divider} from '@mui/material'
import {useAppDispatch} from '../../redux/configureStore'
import {
  getExpireSurveyQuestions,
  ignoreExpireSurvey,
  LoadingStatuses,
  submitExpireSurvey,
} from '../../redux/expireSurvey'
import {useAuth} from '../AuthProvider'
import {useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'
import Loading from '../Loading'

type Props = {
  open: boolean
  setOpen: (value: boolean) => void
}

const View = ({open, setOpen}: Props) => {
  const dispatch = useAppDispatch()
  const auth = useAuth()
  const {questions: reduxQuestions, status} = useSelector(
    (state: RootState) => state.expireSurvey,
  )

  const [questions, setQuestions] = useState<any[]>(reduxQuestions)

  const handleClose = () => {
    dispatch(ignoreExpireSurvey({auth}))
    setOpen(false)
  }

  const handleSubmit = () => {
    dispatch(submitExpireSurvey({auth, questions}))
    setOpen(false)
  }

  useEffect(() => {
    dispatch(getExpireSurveyQuestions({auth}))
  }, [])

  useEffect(() => {
    if (reduxQuestions && reduxQuestions.length > 0) {
      setQuestions(JSON.parse(JSON.stringify(reduxQuestions)))
    }
  }, [reduxQuestions])

  const isButtonDisabled = () => {
    if (questions && questions.length > 0) {
      return questions.every(
        question =>
          question.answer !== undefined &&
          question.answer !== null &&
          question.answer !== '',
      )
    }

    return false
  }

  const renderQuestions = () => {
    if (status === LoadingStatuses.Loading || status === LoadingStatuses.Idle) {
      return (
        <div>
          <Loading />
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        {questions?.map((question: any, index) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              <div style={{display: 'flex', alignItems: 'start', gap: '4px'}}>
                <QuestionContainer>{index + 1}.</QuestionContainer>
                <QuestionContainer>{question.question}</QuestionContainer>
              </div>
              <ExpiryDialogTextareaAutosize
                minRows={6}
                maxRows={6}
                placeholder={'Answer'}
                value={questions[index].answer}
                onChange={e => {
                  const questionsCopy = [...questions]
                  questionsCopy[index].answer = e.target.value

                  setQuestions(questionsCopy)
                }}
              />
            </div>
          )
        })}
        <ButtonBox>
          <Button
            onClick={handleSubmit}
            disabled={!isButtonDisabled()}
            children={'Send Feedback'}
            color={'primary'}
            size={'medium'}
          />
        </ButtonBox>
      </div>
    )
  }

  return (
    <StyledDialog maxWidth={'lg'} onClose={handleClose} open={open}>
      <DialogBox>
        <DialogTitle>
          <CloseIcon
            onClick={handleClose}
            className="x-button"
            style={{
              height: 32,
              width: 32,
              cursor: 'pointer',
            }}
          />
        </DialogTitle>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '30px',
            paddingLeft: '30px',
            gap: '32px',
          }}
        >
          <DialogSubtitle>
            We’ve found that it’s important to regularly share feedback with
            firm leadership about how beneficial you find having a free resource
            like Black Spectacles.
          </DialogSubtitle>
          <Divider />
          <DialogText>
            <span>
              Please take a moment to share your thoughts, and we will advocate
              on your behalf.
            </span>
          </DialogText>
          {renderQuestions()}
        </div>
      </DialogBox>
    </StyledDialog>
  )
}

export default View
