import {Box} from '@mui/material'
import styled from 'styled-components'
import theme, {colors} from '../../styles/theme'

export const FormContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 703px;
  background-color: #fff;
  border: 8px solid #eceff1;
  padding: 45px 25px;
  max-height: 80vh;
  overflow-y: auto;

  &:focus {
    outline: none;
  }

  ${theme.breakpoints.down('sm')} {
    width: 80%;
  }
`

export const CloseContainer = styled(Box)`
  position: absolute;
  top: 8px;
  right: 20px;
`

export const FormTitle = styled(Box)`
  padding: 15px;
`

export const Row = styled(Box)`
  border-bottom: 2px solid #f1f4f6;
  padding: 0 15px 20px;

  && .radioRow {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 0;
  }

  && .MuiFormLabel-root {
    margin-top: 20px;
  }

  && .Mui-checked {
    color: #1377d4;
  }

  && input[type='radio'] {
    width: 20px;
    height: 20px;
  }
`

export const DateField = styled(Box)`
  && h2 {
    color: #37474f;
    font-size: 26px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
`

export const ResourcesField = styled(Box)`
  margin-top: 20px;

  && .MuiFormGroup-root {
    flex-direction: row !important;
    align-items: center;
  }

  && label {
    width: 50%;
    margin-right: 0;
  }

  && input {
    width: 20px;
    height: 20px;
  }

  && input[type='checkbox'] {
  }

  && p {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  ${theme.breakpoints.down('sm')} {
    && .MuiFormGroup-root {
      // flex-direction: column !important;
    }

    && label {
      width: 100%;
      margin-right: 0;
    }

    && .surveyResourceGroup {
      width: 100%;
    }
  }
`

export const ResourcesOptionsContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 8px;
`

export const ButtonRow = styled(Box)`
  padding: 0 15px;
  border-bottom: 8px solid #f1f4f6;

  && button {
    margin-top: 40px;
    margin-bottom: 50px;
  }
`

export const SubmittedAttemptsSection = styled(Box)`
  margin-top: 40px;

  && h2 {
    color: #37474f;
    font-size: 26px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 15px;
  }
`

export const AttemptRow = styled(Box)`
  border-bottom: 2px solid #f1f4f6;
  padding: 10px 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  && button {
    color: ${colors.blue1};
  }

  ${theme.breakpoints.down('md')} {
    flex-flow: column;
    gap: 16px;
  }
`

export const ResourcesList = styled(Box)`
  flex: 50%;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 0;
`

export const RadioBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 16px;
`

export const ButtonBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`
