import React, {useState, useCallback, useEffect} from 'react'
import View from './view'
import {useAppDispatch} from '../../redux/configureStore'
import {
  endBreak,
  endExam,
  getExamDetails,
  markReview,
  selectAttemptId,
  selectExam,
  startBreak,
  startExam,
  startTimer,
  submitAnswer,
} from '../../redux/exams'
import {toggleErrorDialog} from '../../redux/config'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../AuthProvider'

type Props = {
  examId: string
}

const ExamQuestions = ({examId}: Props) => {
  const auth = useAuth()

  const [answers, setAnswers] = useState<any[]>([])
  const [seenQuestions, setSeenQuestions] = useState<string[]>(
    localStorage.getItem('examSeenQuestions')
      ? JSON.parse(localStorage.getItem('examSeenQuestions')!)
      : [],
  )
  const [currentQuestion, setCurrentQuestion] = useState(
    Number(localStorage.getItem('examCurrentQuestion')) ?? 0,
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [reviewState, setReviewState] = useState(
    localStorage.getItem('examReviewState') ?? 'all',
  )
  const [activePage, setActivePage] = useState(
    localStorage.getItem('examActivePage') ?? 'question',
  )

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const exam = useSelector(selectExam)
  const attemptId = useSelector(selectAttemptId)

  const currentAttemptId = attemptId
    ? attemptId
    : localStorage.getItem('currentExamAttemptId')

  const loadExam = useCallback(async () => {
    try {
      if (currentAttemptId) {
        const {type, error}: any = await dispatch(
          getExamDetails({auth, attemptId: currentAttemptId}),
        )
        if (type === getExamDetails.rejected.type) {
          const authError = error.message.includes('authorization failed')
          throw new Error(
            authError ? 'Auth token missing' : 'Unable to load exam',
          )
        }
      }
    } catch (error: any) {
      const authError = String(error).includes('Auth token missing')
      await dispatch(
        toggleErrorDialog({
          opened: true,
          login: authError,
          error:
            'We were unable to load the exam, please retry. If issues persist contact our support team',
        }),
      )
    }
  }, [])

  const handleStartExam = async () => {
    setIsSubmitting(true)
    try {
      const {type, payload, error}: any = await dispatch(
        startExam({auth, examId, origin: 'exam'}),
      )
      if (type === startExam.fulfilled.type) {
        const details = await dispatch(
          getExamDetails({auth, attemptId: payload.attempt}),
        )
        localStorage.setItem(
          'examSeenQuestions',
          JSON.stringify([...seenQuestions, details.payload.questions[0].id]),
        )
        setSeenQuestions([...seenQuestions, details.payload.questions[0].id])

        const timer = await dispatch(
          startTimer({auth, attemptId: payload.attempt}),
        )
        if (timer.type === startTimer.fulfilled.type) {
          setIsSubmitting(false)
        }
      } else if (type === startExam.rejected.type) {
        const authError = error.message.includes('authorization failed')
        throw new Error(
          authError ? 'Auth token missing' : 'Unable to start exam',
        )
      }
    } catch (error) {
      const authError = String(error).includes('Auth token missing')
      await dispatch(
        toggleErrorDialog({
          opened: true,
          login: authError,
          error:
            'We were unable to start the exam, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const handleEndExam = async (source: string) => {
    setIsSubmitting(true)
    try {
      const {type, error}: any = await dispatch(
        endExam({auth, attemptId: currentAttemptId!}),
      )
      if (type === endExam.fulfilled.type) {
        setIsSubmitting(false)
        navigate(`/practice-exams/review/${currentAttemptId}/${source}`, {
          replace: true,
        })
        localStorage.clear()
      } else if (type === endExam.rejected.type) {
        const authError = error.message.includes('authorization failed')
        throw new Error(authError ? 'Auth token missing' : 'Unable to end exam')
      }
    } catch (error) {
      const authError = String(error).includes('Auth token missing')
      await dispatch(
        toggleErrorDialog({
          opened: true,
          login: authError,
          error:
            'We were unable to end the exam, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const handleStartBreak = async () => {
    try {
      const {type, error}: any = await dispatch(
        startBreak({auth, attemptId: currentAttemptId!}),
      )
      if (type === startBreak.rejected.type) {
        const authError = error.message.includes('authorization failed')
        throw new Error(
          authError ? 'Auth token missing' : 'Unable to start break',
        )
      }
    } catch (error) {
      const authError = String(error).includes('Auth token missing')
      await dispatch(
        toggleErrorDialog({
          opened: true,
          login: authError,
          error:
            'We were unable to start the break, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const handleEndBreak = async () => {
    try {
      const breakId = localStorage.getItem('examBreakId')
      if (breakId) {
        const {type, error}: any = await dispatch(
          endBreak({auth, attemptId: currentAttemptId!, breakId}),
        )
        if (type === endBreak.rejected.type) {
          const authError = error.message.includes('authorization failed')
          throw new Error(
            authError ? 'Auth token missing' : 'Unable to end break',
          )
        }
      }
    } catch (error) {
      const authError = String(error).includes('Auth token missing')
      await dispatch(
        toggleErrorDialog({
          opened: true,
          login: authError,
          error:
            'We were unable to end the break, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const handleMarkForReview = async (questionId: string, value: boolean) => {
    try {
      const {type, error}: any = await dispatch(
        markReview({
          auth,
          attemptId: currentAttemptId!,
          questionId,
          isMarked: value,
        }),
      )

      if (type === markReview.rejected.type) {
        const authError = error.message.includes('authorization failed')
        throw new Error(
          authError ? 'Auth token missing' : 'Unable to mark for review',
        )
      }
    } catch (error) {
      const authError = String(error).includes('Auth token missing')
      await dispatch(
        toggleErrorDialog({
          opened: true,
          login: authError,
          error:
            'We were unable to mark for review, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const handleSubmit = async (questionId: string) => {
    setIsSubmitting(true)
    try {
      if (currentAttemptId) {
        const {type, error}: any = await dispatch(
          submitAnswer({
            auth,
            attemptId: currentAttemptId,
            questionId,
            payload: answers[currentQuestion] ? answers[currentQuestion] : '',
          }),
        )
        if (type === submitAnswer.fulfilled.type) {
          setIsSubmitting(false)
        } else if (type === submitAnswer.rejected.type) {
          setIsSubmitting(false)
          handleUpdateCurrentQuestion(currentQuestion)
          const authError = error.message.includes('authorization failed')
          throw new Error(
            authError ? 'Auth token missing' : 'Unable to submit the answer',
          )
        }
      }
    } catch (error) {
      setIsSubmitting(false)
      const authError = String(error).includes('Auth token missing')
      await dispatch(
        toggleErrorDialog({
          opened: true,
          login: authError,
          error:
            'We were unable to submit your answer, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const handleChangeCurrentQuestion = (
    value: number,
    orientation?: string,
    newState?: string,
  ) => {
    let questionNumber = value

    if (
      (reviewState === 'marked' && newState !== 'all') ||
      newState === 'marked'
    ) {
      const forReview: number[] = []

      exam?.questions.forEach((item: any, key: number) => {
        if (item.forReview) {
          forReview.push(key)
        }
      })

      if (orientation === 'first') {
        questionNumber = forReview[0]
      } else if (orientation === 'next') {
        forReview.some(row => {
          if (row > currentQuestion) {
            questionNumber = row
            return true
          } else {
            questionNumber = 999
            return false
          }
        })
      } else if (orientation === 'previous') {
        const currentIndex = forReview.findIndex(
          item => item === currentQuestion,
        )

        if (forReview[currentIndex] === forReview[0]) {
          handleUpdateActivePage('summary')
        } else {
          questionNumber = forReview[currentIndex - 1]
        }
      }
    } else if (
      (reviewState === 'unanswered' && newState !== 'all') ||
      newState === 'unanswered'
    ) {
      const unanswered: number[] = []

      exam?.questions.forEach((item: any, key: number) => {
        if (!item.currentAnswer) {
          unanswered.push(key)
        }
      })

      if (orientation === 'first') {
        questionNumber = unanswered[0]
      } else if (orientation === 'next') {
        unanswered.some(row => {
          if (row > currentQuestion) {
            questionNumber = row
            return true
          } else {
            questionNumber = 999
            return false
          }
        })
      } else if (orientation === 'previous') {
        const currentIndex = unanswered.findIndex(
          item => item === currentQuestion,
        )

        if (unanswered[currentIndex] === unanswered[0]) {
          handleUpdateActivePage('summary')
        } else {
          questionNumber = unanswered[currentIndex - 1]
        }
      }
    } else {
      if (orientation === 'next') {
        questionNumber = questionNumber + 1
      } else if (orientation === 'previous') {
        questionNumber = questionNumber - 1
      }
    }

    if (exam?.questions && questionNumber >= exam?.questions.length) {
      handleUpdateActivePage('summary')
      handleUpdateCurrentQuestion(0)
    } else {
      handleUpdateCurrentQuestion(questionNumber)

      const id = exam?.questions[questionNumber]?.id
      if (id && !seenQuestions.includes(id)) {
        localStorage.setItem(
          'examSeenQuestions',
          JSON.stringify([...seenQuestions, id]),
        )
        setSeenQuestions([...seenQuestions, id])
      }
    }
  }

  const handleChangeAnswers = (value: any) => {
    setAnswers(value)
  }

  const handleChangeForReview = (questionId: string, value: boolean) => {
    handleMarkForReview(questionId, value)
  }

  const handleUpdateActivePage = (value: string) => {
    window.scrollTo(0, 0)
    setActivePage(value)
    localStorage.setItem('examActivePage', value)
  }

  const handleUpdateReviewState = (value: string) => {
    localStorage.setItem('examReviewState', value)
    setReviewState(value)
  }

  const handleChangeIsExamEnded = (value: string) => {
    handleEndExam(value)
  }

  const handleUpdateCurrentQuestion = (page: number) => {
    window.scrollTo(0, 0)
    setCurrentQuestion(page)
    localStorage.setItem('examCurrentQuestion', String(page))
  }

  useEffect(() => {
    if (currentAttemptId) {
      loadExam()
    }
  }, [loadExam])

  return (
    <View
      onStartExam={handleStartExam}
      exam={exam}
      currentQuestion={currentQuestion}
      onChangeCurrentQuestion={handleChangeCurrentQuestion}
      answers={answers}
      onChangeAnswers={handleChangeAnswers}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
      onChangeIsExamEnded={handleChangeIsExamEnded}
      onChangeForReview={handleChangeForReview}
      seenQuestions={seenQuestions}
      onUpdateReviewState={handleUpdateReviewState}
      onUpdateActivePage={handleUpdateActivePage}
      activePage={activePage}
      onStartBreak={handleStartBreak}
      onEndBreak={handleEndBreak}
      attemptId={currentAttemptId}
    />
  )
}

export default ExamQuestions
