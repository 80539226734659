import {Box} from '@mui/material'
import styled from 'styled-components'
import theme, {colors} from '../../styles/theme'

export const SideNavContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  margin: 0 -15px;

  && div {
    width: 100%;
    min-width: 160px;
    max-width: 280px;
  }

  && button {
    width: 100%;
    text-align: left;
    background-color: ${colors.gray};
    color: ${colors.black1} !important;
    margin-bottom: 2px;
    display: flex;
    justify-content: start;
  }

  && button:hover {
    background-color: ${colors.blue1} !important;
    color: ${colors.white} !important;
  }

  && button.selected {
    color: ${colors.white} !important;
  }

  && div > h1 {
    margin-bottom: 15px;
    padding-left: 0.25em;
  }

  ${theme.breakpoints.down('sm')} {
    width: 100%;
    align-items: center;
  }
`

export const SideNavWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 160px;
  max-width: 280px;

  && .logout-btn {
    color: #fff !important;
    margin-top: 15px;
  }

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`
