import {Box} from '@mui/material'
import styled from 'styled-components'
import theme from '../../../../styles/theme'

export const List = styled(Box)`
  ${theme.breakpoints.down('sm')} {
  }
`

export const ListHeader = styled(Box)`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 15px;
`

export const DataContainer = styled(Box)`
  .billing-row:nth-child(even) {
    background-color: #fcfcfc;
  }
`

export const DataRow = styled(Box)`
  width: 100%;
  display: flex;
  padding: 15px;
`
