import {Box} from '@mui/material'
import styled from 'styled-components'
import theme from '../../../styles/theme'

export const VirtualWorkShopContainer = styled(Box)`
  width: 100% !important;

  h2 {
    margin-bottom: 30px;
  }

  ${theme.breakpoints.down('sm')} {
    text-align: center;
  }
`

export const DetailsContainer = styled(Box)`
  width: 100% !important;
  box-sizing: border-box;
  padding-right: 32px;
`

export const PreRequisiteContainer = styled(Box)`
  display: flex;
  border: 1px #eee solid;
  padding: 1em;
  margin-top: 16px;
  width: 100% !important;
  box-sizing: border-box;

  p:not(.ignore) {
    margin-bottom: 15px;
    letter-spacing: 0;
  }

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    margin-top: 30px;

    .ignore {
      text-align: left;
    }
  }
`

export const ButtonContainer = styled(Box)`
  display: flex;
  flex-flow: column;

  button {
    width: 250px;
    margin-bottom: 15px;
  }

  @media only screen and (max-width: 1150px) {
    && button {
      width: 180px !important;
    }
  }

  ${theme.breakpoints.down('md')} {
    width: 50% !important;
    align-self: center;

    && button {
      width: 100% !important;
    }
  }

  ${theme.breakpoints.down('sm')} {
    width: 100% !important;

    && button {
      width: 100% !important;
    }
  }
`
export const UpcomingVirtualWorkshopContainer = styled(Box)`
  width: 100%;
  margin-top: 30px;

  div {
    width: 100% !important;
    margin-bottom: 15px;
  }
`

export const DateBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;

  && img {
    width: 24px;
    height: 24px;
  }

  ${theme.breakpoints.down('md')} {
    justify-content: center;
  }
`
