import {Box, Button, Toolbar, Menu, Link} from '@mui/material'
import styled, {keyframes, css} from 'styled-components'
import theme, {colors} from '../../styles/theme'
import Logo from '../../images/icons/compass-closed.svg'

type Props = {
  background?: keyof typeof colors
}

type SearchProps = {
  $isSearchBoxOpen: boolean
}

export const StyledIcon = styled(({...props}) => <Link {...props} />)`
  background: url(${Logo});
  transform: rotate(270deg);
  height: 100px;
  width: 36px;
  position: absolute;
  top: -32px;
`

export const FlexBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`

export const LeftBox = styled(Box)`
  display: flex;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  padding: 0 25px;
`

export const NavButton = styled(Button)<Props>`
  background-color: ${props =>
    props.background ? colors[props.background] : '#2d2d2d'} !important;
  color: ${colors.white} !important;
  height: 40px;
  padding: 11px 22px !important;
  border-radius: 0 !important;
  font-size: 14px !important;

  && a {
    color: #fff !important;

    &:hover {
      text-decoration: none !important;
    }
  }
`
export const searchBarSlideDownAnimation = keyframes`
  0% {
    transform: translateY(-76px);
  }
  100% {
    transform: translateY(0);
  }
`

export const searchBarSlideUpAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-76px);
  }
`
export const SearchBoxContainer = styled(Box)<SearchProps>`
  width: 100%;
  position: absolute;

  ${props =>
    props.$isSearchBoxOpen === true
      ? css`
          animation: ${searchBarSlideDownAnimation} 0.25s ease-in-out forwards;
        `
      : ''};

  ${props =>
    props.$isSearchBoxOpen === false
      ? css`
          animation: ${searchBarSlideUpAnimation} 0.25s ease-in-out forwards;
        `
      : ''};

  ${props => (props.$isSearchBoxOpen === null ? 'top:-76px' : '')};
`
export const navSlideDownAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(76px);
  }
`

export const navSlideUpAnimation = keyframes`
  0% {
    transform: translateY(76px);
  }
  100% {
    transform: translateY(0);
  }
`

export const NavbarContainer = styled(Toolbar)<SearchProps>`
  min-height: 40px !important;
  padding-right: 0 !important;
  position: absolute;
  background-color: #fff;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  ${props =>
    props.$isSearchBoxOpen === true
      ? css`
          animation: ${navSlideDownAnimation} 0.25s ease-in-out forwards;
        `
      : ''};

  ${props =>
    props.$isSearchBoxOpen === false
      ? css`
          animation: ${navSlideUpAnimation} 0.25s ease-in-out forwards;
        `
      : ''};

  ${props => (props.$isSearchBoxOpen === null ? '' : '')};

  ${theme.breakpoints.down('sm')} {
    padding-left: 0 !important;
  }
`

export const MobileNavMenu = styled(Menu)`
  display: none;

  ${theme.breakpoints.down('sm')} {
    display: block;

    && .MuiPopover-paper {
      width: 100%;
      background-color: ${colors.black1};
      top: 0px !important;
      left: 16px !important;
      margin-top: 16px;
      box-shadow: none;

      p {
        color: white;
        text-transform: uppercase;
        padding: 10px;
      }

      li {
        justify-content: center;
      }
    }
  }
`

export const StyledLink = styled.a`
  font-family: Public Sans !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  color: #000 !important;
  letter-spacing: 0.05em;

  &:hover {
    color: #1377d4 !important;
    text-decoration: none !important;
  }
`

export const SettingsItem = styled.div`
  font-family: Public Sans;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  padding: 10px;
  display: flex;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: #fff;
    color: #000;
  }
`

export const SettingsLink = styled(({...props}) => <Link {...props} />)`
  width: 100% !important;

  &:active {
    user-select: none;
  }

  &:hover {
    text-decoration: none !important;
  }
`

export const LinkButton = styled(({...props}) => <Link {...props} />)`
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: start;
  text-decoration: none !important;
  text-transform: uppercase;
  padding: 12px;
  letter-spacing: 0.05em;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #000 !important;
  color: #ffffff !important;
  text-decoration: none !important;
`
