import {Box, Typography} from '@mui/material'
import styled from 'styled-components'
import theme from '../../styles/theme'
import {getRandomBackground} from '../../utils/getRandomBackground'
import CheckIcon from '@mui/icons-material/Check'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import QuizBreak from '../../images/icons/icon-quiz-break-white.svg'
import QuizBreakHover from '../../images/icons/icon-quiz-break-blue.svg'

export const VideoEndedBox = styled(Box)`
  padding-top: 56.25%;
  top: 0;
  position: relative;
  width: 100%;
  background: url(${getRandomBackground()}) no-repeat;
  background-size: cover;
`

export const VideoInternalBox = styled(Box)`
  display: flex;
  position: absolute;
  top: 25%;
  left: 28%;
  color: #fff;
  gap: 24px;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    top: 5%;
    left: 5%;
  }
`

export const CourseTitle = styled(Typography)`
  color: #78909c !important;
  font-size: 14px !important;
  font-family:
    Freight Disp Pro,
    Helvetica,
    Arial,
    sans-serif !important;
  text-transform: uppercase !important;
  letter-spacing: 0.05em !important;
`

export const VideoProgress = styled(Typography)`
  font-family: Public Sans !important;
  font-size: 16px !important;
  color: #fff !important;
`

export const UpNext = styled(Typography)`
  margin-top: 24px !important;
  font-family:
    Freight Disp Pro,
    Helvetica,
    Arial,
    sans-serif !important;
  font-size: 18px !important;
  color: #fff !important;
  text-transform: uppercase;
  line-height: 30px !important;

  ${theme.breakpoints.down('sm')} {
    font-size: 20px !important;
    line-height: 24px !important;
  }
`

export const ProgressBox = styled(Box)`
  && a {
    color: #fff !important;
  }
`

export const BottomBox = styled(Box)`
  display: flex;
  position: absolute;
  align-items: center;
  left: 2%;
  bottom: 5%;
  gap: 16px;
  height: 25px;

  && span {
    color: #666;
    font-size: 18px;
    line-height: 15px;
  }

  ${theme.breakpoints.down('sm')} {
    font-size: 14px;
  }
`

export const LinkButton = styled(Box)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const LinkButton2 = styled(Box)`
  color: #1377d4 !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const AutoplayBox = styled(Box)`
  display: flex;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
`

export const CheckBox = styled.span<{$isActive: boolean}>`
  display: flex;
  min-width: 25px;
  width: 25px;
  height: 25px;
  margin: -1px 4px 0 0;
  cursor: pointer;
  border-radius: 2px;
  margin-right: 10px;
  position: relative;
  line-height: 22px;
  align-items: center;
  justify-content: center;

  ${({$isActive}) =>
    $isActive
      ? `
    color: #fff !important;
    background-color: #1377D4;
    text-decoraion: none !important;
  `
      : `
    background-color: #eceff1;
  `}

  ${theme.breakpoints.down('sm')} {
    width: 20px;
    height: 20px;
  }
`

export const NextButton = styled(Box)`
  box-sizing: border-box;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  color: #fff;
  border: 3px solid #666;
  display: flex;
  align-items: center;
  justify-content: center;

  &: hover {
    background-color: #1377d4;
  }

  ${theme.breakpoints.down('sm')} {
    width: 80px;
    height: 80px;
  }
`

export const ActionsFlexBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 32px;
`

export const ActionsBox = styled(Box)`
  display: flex;
  gap: 16px;
`

export const ContentBox = styled(Box)`
  display: flex;
  flex-flow: column;
  justify-content: center;
`

export const IconBox = styled(Box)<{$hover: boolean}>`
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #1377d4;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({$hover}) =>
    $hover
      ? `
    cursor: pointer; 
    &:hover {
      background-color:#1377D4;
    }
  `
      : null}
`

export const ContentLink = styled(Box)`
  color: #fff;
  font-size: 24px;
  font-style: italic;
  text-decoration: underline;
  font-family:
    'Public Sans',
    times new roman,
    Times,
    serif;
  cursor: pointer;

  &: hover {
    color: #1377d4;
    transition: 0.2s;
    text-decoration: none;
  }

  ${theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`

export const Title = styled(Box)`
  color: #78909c;
  font-size: 14px;
  text-decoration: none !important;
  font-style: none !important;
`

export const StyledCheckIcon = styled(CheckIcon)`
  color: #1377d4;
  width: 40px !important;
  height: 40px !important;

  ${theme.breakpoints.down('sm')} {
    width: 24px !important;
    height: 24px !important;
  }
`

export const StyledPlayArrowIcon = styled(PlayArrowIcon)`
  color: #fff;
  width: 40px !important;
  height: 40px !important;

  ${theme.breakpoints.down('sm')} {
    width: 24px !important;
    height: 24px !important;
  }
`

export const QuizBreakIcon = styled(Box)`
  width: 150px;
  height: 150px;
  background: url(${QuizBreak});

  &:hover {
    background: url(${QuizBreakHover});
  }

  ${theme.breakpoints.down('sm')} {
    width: 80px;
    height: 80px;
  }
`
