import {
  Box,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from '@mui/material'
import styled from 'styled-components'
import theme, {colors} from '../../../../styles/theme'

type Props = {
  background?: string
}

export const SideMenuContainer = styled(Box)`
  width: 25%;
  display: flex;
  flex-direction: column;
  background: #111;

  && .MuiFormControl-root {
    width: 100%;
    background: #fff;

    div.MuiInputBase-root {
      border-radius: 0;
    }

    fieldset {
      border: none;
    }

    input.MuiInputBase-input {
      background: #111;
      color: ${colors.gray};
    }
  }

  ${theme.breakpoints.down('md')} {
    width: 100%;
    background: #151515;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 100;
    margin: 70px -25px 0;
    padding-left: 25px;
    max-width: unset;

    .align-text-center {
      text-align: center;
    }
  }
`

export const SideMenuSection = styled(Box)<Props>`
  padding: 20px;
  background-color: ${props => (props.background ? props.background : '#111')};
  border-bottom: 2px #444 solid;

  && p {
    color: #959595;
  }

  && .label {
    padding-bottom: 7px;
  }

  && label {
    color: #fff;
    margin-bottom: 5px;
  }

  && .MuiFormControl-root {
    border: 1px solid ${colors.blue1} !important;
  }

  && input {
    color: #fff !important;
  }
`

export const FlexBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 12px;
`

export const SectionOptionBold = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff !important;
  cursor: pointer !important;
  font-family: Public Sans !important;
  font-size: 12px !important;
  font-weight: bold !important;

  && input {
    margin-top: 0;
    cursor: pointer !important;
  }

  && label {
    margin-bottom: 0;
    cursor: pointer !important;
  }
`

export const SectionOption = styled(Typography)`
  color: #fff !important;
  cursor: pointer;
  font-family: Public Sans !important;
  font-size: 14px !important;
  position: relative;

  &:hover {
    text-decoration: underline;
  }
`

export const Container = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  height: 35px;
`

export const HelpIcon = styled.span`
  background: rgba(255, 255, 255, 0.175);
  border-radius: 50%;
  width: 1.6em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 0.75em;
  color: #aaa;
  right: -2em;
  top: 0.075em;
  line-height: 1;
  font-family: Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  cursor: help;
`

export const BootstrapTooltip = styled(
  ({className, ...props}: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{popper: className}}
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
              {
                marginBottom: '0px',
              },
          },
        },
      }}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'grey',
    background: '#111 !important',
  },
}))
