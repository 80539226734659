import AWS from 'aws-sdk'
import AWS_CONFIG from '../constants/aws-config'

AWS.config.update(AWS_CONFIG)

interface SignedUrlParams {
  Bucket: string
  Key: string
  Expires: number
  ResponseContentType?: string
}

export const getSignedUrl = async (key: string, type?: string) => {
  const s3 = new AWS.S3(AWS_CONFIG)

  let params: SignedUrlParams = {
    Bucket: 'bksps-live-public',
    Key: key,
    Expires: 500,
  }

  if (type === 'pdf') {
    params = {...params, ResponseContentType: 'application/pdf'}
  }

  // Retornar a URL assinada como uma Promise
  return new Promise((resolve, reject) => {
    s3.getSignedUrl('getObject', params, (err, url) => {
      if (err) {
        reject(err)
      } else {
        resolve(url)
      }
    })
  })
}
