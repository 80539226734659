import {Box} from '@mui/material'
import styled from 'styled-components'

export const ReferencesBox = styled(Box)`
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border-top: 1px solid #000;
  overflow-x: hidden;
  overflow-y: auto;
`

export const Header = styled(Box)`
  display: flex;
  gap: 5px;
`

export const HeaderItem = styled(Box)<{$isActive?: boolean}>`
  cursor: pointer;
  background: #333;
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;

  ${({$isActive}) =>
    $isActive
      ? `
      
  background: #fff;
  color: #333;
  border-top: 1px solid #333;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  min-height: 35px;
  padding-top: 5px;
    `
      : `
      align-items: center;
      min-height: 30px;
  background: #333;
  color: #fff;`}
`

export const Content = styled(Box)`
  width: 100%;
  border: 1px solid #333;
  padding: 20px;
  box-sizing: border-box;
`
