import styled from 'styled-components'
import theme from '../../styles/theme'
import {Box, Typography} from '@mui/material'

export const StepLink = styled(Box)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const LeftBox = styled(Box)<{$isOpen: boolean}>`
  background: #151515;
  width: 35%;
  box-sizing: border-box;

  && .MuiStepLabel-label {
    color: #fff;
    font-family: Public Sans;
  }

  && .MuiSvgIcon-root {
    color: #444;
  }

  && .CompletedVideo a,
  .PendingVideo a {
    color: #fff !important;
    text-decoration: underline !important;

    &:hover {
      color: #1377d4 !important;
      transition: 0.2s;
    }
  }

  && .CurrentVideo a {
    color: #1377d4 !important;
    text-decoration: none !important;

    &:hover {
      color: #1377d4 !important;
      transition: 0.2s;
    }
  }

  && .Mui-active {
    color: #1377d4;
  }

  && .CompletedVideo span,
  .PendingVideo span {
    color: #fff !important;
  }

  && .Mui-completed {
    color: #1377d4 !important;
  }

  ${theme.breakpoints.down('md')} {
    display: ${({$isOpen}) => ($isOpen ? 'block' : 'none')};
    width: 100%;
    position: absolute;
    z-index: 2;
    margin-top: 60px;
  }
`

export const CourseTitle = styled(Box)`
  padding: 24px 20px;
  border-bottom: 2px #444 solid;
  color: #fff;
  font-size: 16px;

  && span {
    font-size: 14px;
  }

  && a {
    color: #fff !important;
    text-decoration: underline !important;
    text-transform: none;

    &:hover {
      color: #1377d4 !important;
      transition: 0.2s;
    }
  }
`

export const StepsBox = styled(Box)`
  padding: 20px 20px;
  border-bottom: 2px #444 solid;
`

export const StepsTitle = styled(Typography)`
  font-size: 16px !important;
  color: #fff !important;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 12px;
`
