import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../../hooks/useApi'

//#region types
export type CreditCardInfo = {
  payment_methods: PaymentMethod[]
  phone: string
  phone_ext?: string
}

type PaymentMethod = {
  id: string
  brand: string
  last_4: string
  exp_month: string
  exp_year: string
  address?: AddressDetails
}

type AddressDetails = {
  address_1: string
  address_2: string
  city: string
  country: string
  postal_code: string
  state: string
}

//#endregion

//#region api
type getActiveSubscriptionsPayload = {
  id?: string
  pmId: string
}

export const getPaymentMethodDetails = createAsyncThunk<
  any,
  getActiveSubscriptionsPayload
>('/getPaymentMethodDetails', async ({pmId}) => {
  return useApi(
    null,
    `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/guest/payment/payment_method/${pmId}`,
    {
      method: 'GET',
    },
  ).then(res => res.json())
})
