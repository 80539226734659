import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../../hooks/useApi'
import {Product} from '../guest/product'

export type PaymentDetails = {
  address1: string
  address2?: string
  city: string
  state: string
  postalCode: string
  country: string
  phoneNumber: string
  phoneExtension?: string
}

//#region api
type CreateSubscriptionPayload = {
  jwt: string
  paymentDetails: any
  products: Product[]
  coupon?: string
  taxId: string | undefined
}

export const networkCreateSubscription = createAsyncThunk<
  any,
  CreateSubscriptionPayload
>(
  '/createSubscription',
  async ({jwt, paymentDetails, products, coupon, taxId}) => {
    const filteredProducts: any = []

    products.forEach(product =>
      filteredProducts.push({price_id: product.id, qty: 1}),
    )

    const fields = {
      customer: {
        phone: paymentDetails.phoneNumber,
        phone_ext: paymentDetails.phoneExtension
          ? paymentDetails.phoneExtension
          : null,
        address: {
          address_id: '',
          address_1: paymentDetails.address1,
          address_2: paymentDetails.address2 ? paymentDetails.address2 : null,
          city: paymentDetails.city,
          state: paymentDetails.state,
          postal_code: paymentDetails.postalCode,
          country: paymentDetails.country,
        },
      },
      products: filteredProducts,
      coupon: coupon ? coupon.toUpperCase() : '',
      txr: taxId,
    }

    return useApi(
      null,
      `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/payment/subscription`,
      {
        method: 'POST',
        headers: {Authorization: `Bearer ${jwt}`},
        body: JSON.stringify(fields),
      },
    ).then(res => res.json())
  },
)
