import React, {useState, useCallback, useEffect} from 'react'
import View from './view'
import {useAppDispatch} from '../../redux/configureStore'
import {
  getExamReviewDetails,
  selectExamReview,
  selectExamReviewStatus,
} from '../../redux/exams'
import {toggleErrorDialog} from '../../redux/config'
import {useSelector} from 'react-redux'
import {LoadingStatuses} from '../types/LoadingStatusses'
import Loading from '../Loading'
import {useAuth} from '../AuthProvider'

type Props = {
  attemptId: string
  state?: string
}

const ExamQuestionsReview = ({attemptId, state}: Props) => {
  const auth = useAuth()

  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [reviewState, setReviewState] = useState('all')
  const [activePage, setActivePage] = useState(state ? state : 'score')

  const dispatch = useAppDispatch()

  const exam = useSelector(selectExamReview)
  const examStatus = useSelector(selectExamReviewStatus)

  const loadExam = useCallback(async () => {
    try {
      if (attemptId) {
        const {type} = await dispatch(getExamReviewDetails({auth, attemptId}))
        if (type === getExamReviewDetails.rejected.type) {
          throw new Error('Unable to load exam')
        }
      }
    } catch (error) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to load the exam, please retry. If issues persist contact our support team',
        }),
      )
    }
  }, [])

  const handleChangeCurrentQuestion = (
    value: number,
    orientation?: string,
    newState?: string,
  ) => {
    let questionNumber = value

    if (
      (reviewState === 'incorrect' && newState !== 'all') ||
      newState === 'incorrect'
    ) {
      const incorrect: number[] = []

      exam?.questions.forEach((item: any, key: number) => {
        if (!item.isAnswerCorrect) {
          incorrect.push(key)
        }
      })

      if (orientation === 'first') {
        questionNumber = incorrect[0]
      } else if (orientation === 'next') {
        incorrect.some(row => {
          if (row > currentQuestion) {
            questionNumber = row
            return true
          } else {
            questionNumber = 999
            return false
          }
        })
      } else if (orientation === 'previous') {
        const currentIndex = incorrect.findIndex(
          item => item === currentQuestion,
        )

        if (incorrect[currentIndex] === incorrect[0]) {
          handleUpdateActivePage('summary')
        } else {
          questionNumber = incorrect[currentIndex - 1]
        }
      }
    } else if (
      (reviewState === 'correct' && newState !== 'all') ||
      newState === 'correct'
    ) {
      const correct: number[] = []

      exam?.questions.forEach((item: any, key: number) => {
        if (item.isAnswerCorrect) {
          correct.push(key)
        }
      })

      if (orientation === 'first') {
        questionNumber = correct[0]
      } else if (orientation === 'next') {
        correct.some(row => {
          if (row > currentQuestion) {
            questionNumber = row
            return true
          } else {
            questionNumber = 999
            return false
          }
        })
      } else if (orientation === 'previous') {
        const currentIndex = correct.findIndex(item => item === currentQuestion)

        if (correct[currentIndex] === correct[0]) {
          handleUpdateActivePage('summary')
        } else {
          questionNumber = correct[currentIndex - 1]
        }
      }
    } else {
      if (orientation === 'next') {
        questionNumber = questionNumber + 1
      } else if (orientation === 'previous') {
        questionNumber = questionNumber - 1
      }
    }

    if (exam?.questions && questionNumber >= exam?.questions.length) {
      handleUpdateActivePage('summary')
      handleUpdateCurrentQuestion(0)
    } else {
      handleUpdateCurrentQuestion(questionNumber)
    }
  }

  const handleUpdateActivePage = (value: string) => {
    window.scrollTo(0, 0)
    setActivePage(value)
  }

  const handleUpdateReviewState = (value: string) => {
    setReviewState(value)
  }

  const handleUpdateCurrentQuestion = (page: number) => {
    window.scrollTo(0, 0)
    setCurrentQuestion(page)
  }

  useEffect(() => {
    loadExam()
  }, [loadExam])

  if (
    examStatus === LoadingStatuses.Loading ||
    examStatus === LoadingStatuses.Idle
  ) {
    return <Loading />
  }

  return (
    <View
      exam={exam}
      currentQuestion={currentQuestion}
      onChangeCurrentQuestion={handleChangeCurrentQuestion}
      onUpdateReviewState={handleUpdateReviewState}
      onUpdateActivePage={handleUpdateActivePage}
      activePage={activePage}
    />
  )
}

export default ExamQuestionsReview
