import React, {useState} from 'react'
import {Box, Checkbox, FormControlLabel, Typography} from '@mui/material'
import * as Yup from 'yup'
import {GuaranteeBox} from './styles'
import {Button} from '../../components/button'
import {Course} from '../../redux/courses'
import {Attempt} from '../../redux/reportExamStatus'
import {Form, Formik, FormikHelpers} from 'formik'
import {useSelector} from 'react-redux'
import {selectMember} from '../../redux/members'
import TextFieldFormik from '../../components/TextFieldFormik'
import {useAuth} from '../../components/AuthProvider'
import {useAppDispatch} from '../../redux/configureStore'
import {claimGuarantee} from '../../redux/guarantee'
import {toggleErrorDialog} from '../../redux/config'
import {SuccessDialog} from '../../components/SuccessDialog'
import {useNavigate} from 'react-router-dom'
import TextField from '../../components/TextField'
import {notifySlackChannel} from '../../redux/notification'

type Props = {
  course?: Course
  attempts?: Attempt[]
}

export type NcarbForm = {
  ncarbId: string
  scoreReport: string
  clear: boolean
  firstName?: string
  lastName?: string
  email?: string
  examDate?: string
}

const ClaimView = ({course, attempts}: Props) => {
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false)
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const member = useSelector(selectMember)

  const validationSchema = Yup.object().shape({
    ncarbId: Yup.string().required('Required'),
    scoreReport: Yup.string().required('Required'),
    clear: Yup.bool().oneOf([true], 'Required'),
  })

  const handleSubmit = async (
    values: NcarbForm,
    actions: FormikHelpers<NcarbForm>,
  ) => {
    try {
      if (course?.lessons) {
        const lessons = [...course.lessons]
        lessons?.sort((a: any, b: any) => {
          return Number(b.version) - Number(a.version)
        })

        const {type} = await dispatch(
          claimGuarantee({
            auth,
            examDate: values.examDate!,
            registrationId: values.ncarbId,
            scoreReport: values.scoreReport,
            lessonPlanId: lessons[0].id,
          }),
        )
        actions.setSubmitting(false)
        if (type === claimGuarantee.rejected.type) {
          throw new Error('Unable to claim expert guarantee')
        } else if (type === claimGuarantee.fulfilled.type) {
          messageSlackChannel(values.examDate!, values.ncarbId)
          setIsSuccessDialogOpen(true)
        }
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to claim your expert guarantee, please retry. If issues persist contact our support team',
        }),
      )
    }
  }

  const messageSlackChannel = async (
    examDate: string,
    registrationId: string,
  ) => {
    const message = `${member?.firstName} ${member?.lastName} (${member?.email}) have successfully filed a claim with his NCARB registration ID (${registrationId}) for the exam taken on ${examDate}. Please verify the eligiblity using the registration ID submitted.`

    await dispatch(notifySlackChannel({auth, channel: 'guarantee', message}))
  }

  const handleCloseSuccessDialog = () => {
    setIsSuccessDialogOpen(false)

    navigate(`/`, {
      replace: true,
    })
  }

  const courseAttempts = attempts?.filter(
    attempt => attempt.courseId === course?.id,
  )

  courseAttempts?.sort(
    (a, b) => new Date(b.examDate).getTime() - new Date(a.examDate).getTime(),
  )

  const initialValues: NcarbForm = {
    ncarbId: '',
    scoreReport: '',
    clear: false,
    firstName: member?.firstName,
    lastName: member?.lastName,
    email: member?.email,
    examDate: courseAttempts
      ? new Date(courseAttempts[0].examDate).toLocaleDateString('en-US')
      : '',
  }

  return (
    <GuaranteeBox id="content">
      <Typography variant="body1">
        <em>Let’s get your claim started.</em>
      </Typography>
      <Typography variant="body1" marginTop={2}>
        To see if this round is on us, please fill out the fields below,
        including, and most importantly, your NCARB Registration ID. If you meet
        all of our eligibility requirements then congrats - your retake is on
        us! You will receive an email within three days which outlines how to
        redeem your seat credit.
      </Typography>
      <Typography variant="body1" marginTop={2}>
        Where do I find my NCARB Registration ID? Click the icon in the box to
        see how to locate this vital bit of information. If all fields below are
        not filled out accurately, and we cannot confirm the information, you
        may not be eligible for the seat credit. We know, but we have to be
        sticklers.
      </Typography>
      <Typography variant="body1" marginTop={2}>
        *ALL FIELDS ARE REQUIRED
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {formik => (
          <Form>
            <Box display={'flex'} gap={'12px'} marginTop={6}>
              <TextFieldFormik
                name="ncarbId"
                variant="outlined"
                label="NCARB Registration ID"
                fullWidth
              />

              <TextFieldFormik
                name="examDate"
                variant="outlined"
                label="EXAM DATE"
                disabled={true}
                fullWidth
              />
            </Box>

            <Box marginTop={2}>
              <a
                href="https://bksps-live-public.s3.us-east-2.amazonaws.com/samples/Sample_Score_Report-Registration_Circled.pdf"
                target="_blank"
              >
                Where to find NCARB Registration ID
              </a>
            </Box>

            <Box marginTop={6}>
              <TextField
                name="scoreReport"
                variant="outlined"
                type="file"
                fullWidth
                onChange={(e: any) =>
                  formik.setFieldValue('scoreReport', e.currentTarget.files[0])
                }
              />
            </Box>
            <Box marginTop={2}>
              <a
                href="https://bksps-live-public.s3.us-east-2.amazonaws.com/samples/Sample_Score_Report.pdf"
                target="_blank"
              >
                Example of what to upload
              </a>
            </Box>

            <Box display={'flex'} gap={'12px'} marginTop={6}>
              <TextFieldFormik
                name="firstName"
                variant="outlined"
                label="FIRST NAME"
                disabled={true}
                fullWidth
              />

              <TextFieldFormik
                name="lastName"
                variant="outlined"
                label="LAST NAME"
                disabled={true}
                fullWidth
              />
            </Box>

            <Box marginTop={6}>
              <TextFieldFormik
                name="email"
                variant="outlined"
                label="EMAIL (Black Spectacles username)"
                disabled={true}
                fullWidth
              />
            </Box>

            <Box marginTop={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.clear}
                    name="clear"
                    onChange={() =>
                      formik.setFieldValue('clear', !formik.values.clear)
                    }
                  />
                }
                label="In order to track your progress, we have to clear your dashboard. By clicking here, you consent to us clearing your dashboard for this division and starting from scratch."
              />
            </Box>

            <Box marginTop={2}>
              <Button
                children="SUBMIT"
                color="primary"
                style={{width: '250px'}}
                type="submit"
                disabled={
                  formik.isSubmitting || !formik.isValid || !formik.dirty
                }
              />
            </Box>
          </Form>
        )}
      </Formik>
      {isSuccessDialogOpen && (
        <SuccessDialog
          isOpen={isSuccessDialogOpen}
          onClose={handleCloseSuccessDialog}
          title="THANK YOU FOR YOUR CLAIM SUBMISSION"
          message="We will verify the data and get back to you."
        />
      )}
    </GuaranteeBox>
  )
}

export default ClaimView
