export type ObjectType = {
  PcM: string
  PjM: string
  PA: string
  PPD: string
  PDD: string
  CE: string
}

export const LectureSlides: ObjectType = {
  PcM: 'https://bksps-live-public.s3.us-east-2.amazonaws.com/lecture_slides/PcM_Lecture_Slides.pdf',
  PjM: 'https://bksps-live-public.s3.us-east-2.amazonaws.com/lecture_slides/PjM_Lecture_Slides.pdf',
  PA: 'https://bksps-live-public.s3.us-east-2.amazonaws.com/lecture_slides/PA_Lecture_Slides.pdf',
  PPD: 'https://bksps-live-public.s3.us-east-2.amazonaws.com/lecture_slides/PPD_Lecture_Slides.zip',
  PDD: 'https://bksps-live-public.s3.us-east-2.amazonaws.com/lecture_slides/PDD_Lecture_Slides.zip',
  CE: 'https://bksps-live-public.s3.us-east-2.amazonaws.com/lecture_slides/CE_Lecture_Slides.pdf',
}
