import React from 'react'
import View from './view'
import {DashboardExams} from '../../../redux/dashboard'
import {Course} from '../../../redux/courses'

type Props = {
  course: Course
  quizData?: DashboardExams
}

const Quizzes = ({course, quizData}: Props) => {
  return <View course={course} quizData={quizData} />
}

export default Quizzes
