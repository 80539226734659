import React, {useState, useEffect} from 'react'

import SideNav from '../../components/SideNav'
import {useLocation} from 'react-router-dom'
import Loading from '../../components/Loading'
import DashboardContent from '../../components/DashboardContent'
import DashboardSoftwareContent from '../../components/DashboardSoftwareContent'
import ExpireSurveyDialog from '../../components/ExpireSurveyDialog'
import {useSelector} from 'react-redux'
import {selectMember} from '../../redux/members'

const View = () => {
  const {pathname} = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const member = useSelector(selectMember)
  const [isOpen, setIsOpen] = useState(member?.showExpireSurvey)

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => setIsLoading(false), 500)
  }, [pathname])

  if (isLoading) return <Loading />

  const isSoftware = pathname.includes('software/topics')

  return (
    <>
      <SideNav />
      {isSoftware ? <DashboardSoftwareContent /> : <DashboardContent />}
      {isOpen && <ExpireSurveyDialog open={true} setOpen={setIsOpen} />}
    </>
  )
}

export default View
