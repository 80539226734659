export type ObjectType = {
  PcM: string
  PjM: string
  PA: string
  PPD: string
  PDD: string
  CE: string
}

export const communityUrls: ObjectType = {
  PcM: 'https://community.blackspectacles.com/c/practice-management/6',
  PjM: 'https://community.blackspectacles.com/c/project-management/7',
  PA: 'https://community.blackspectacles.com/c/programming-analysis/8',
  PPD: 'https://community.blackspectacles.com/c/project-planning-design/9',
  PDD: 'https://community.blackspectacles.com/c/project-development-documentation/10',
  CE: 'https://community.blackspectacles.com/c/construction-evaluation/11',
}
