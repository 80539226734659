import styled from 'styled-components'
import {Box} from '@mui/material'

export const CourseBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 80px;
`

export const LessonsBox = styled(Box)`
  margin-top: -10px;
`

export const LessonHeader = styled(Box)`
  border-bottom: 2px #1377d4 solid;
  margin-top: 32px;
  font-size: 16px;
  color: #2d2d2d;
  width: 100%;
  display: flex;
  padding: 12px 8px;
  align-items: center;
  box-sizing: border-box;

  && img {
    width: 25px;
  }
`

export const LessonBody = styled(Box)<{$isAuthenticated: boolean}>`
  border-bottom: 2px #f1f4f6 solid;
  width: 100%;
  padding: 16px 8px;
  font-family: Public Sans;
  font-size: 14px;
  display: flex;
  box-sizing: border-box;
  && img {
    width: 30px;
  }

  ${({$isAuthenticated}) =>
    !$isAuthenticated
      ? `
    && a {
      color:#c2c2c2 !important;
      text-decoration: none !important;
    }
    
    && em {
      color:#c2c2c2 !important;
    }
  `
      : null}
`

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`
