import React, {useContext, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {SegmentContext} from '../../context'

const RouteChangeTracker = () => {
  const location = useLocation()

  const {analytics} = useContext(SegmentContext)

  useEffect(() => {
    if (analytics) {
      const timeoutId = setTimeout(() => {
        analytics.page()
      }, 1000)

      return () => clearTimeout(timeoutId)
    }
  }, [location])

  return <></>
}

export default RouteChangeTracker
