import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {RootState} from './rootReducer'
import {LoadingStatuses} from '../components/types/LoadingStatusses'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
export type Dashboard = {
  video: DashboardVideo
  practicalApplications: DashboardVideo
  quizzes: DashboardExams
  exams: DashboardExams
  flashcards: DashboardFlashcards
}

export type DashboardExams = {
  averagePercentage: number
  attempts: DashboardAttempts[]
}

export type DashboardAttempts = {
  attemptId: string
  examId: string
  completedAt?: Date
  correct: number
  incorrect: number
  percentage: number
}

export type DashboardVideo = {
  totalTimeViewed: string
  totalTimeViewedSec: number
  totalVideoTime: string
  totalVideoTimeSec: number
  totalCompletes: number
  totalVideosAvailable: number
  nextVideoId: string
}

export type DashboardFlashcards = {
  mastered: number
  total: number
}

type SliceState = {
  dashboard?: Dashboard
  dashboardStatus: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type GetDashboardPayload = {
  auth: AuthContextInterface
  courseId: string
}
export const getDashboard = createAsyncThunk<any, GetDashboardPayload>(
  'dashboard/get',
  async ({auth, courseId}) => {
    const endpoint = `/v1/are/dashboard/course/${courseId}`

    return useApi(auth, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

type GetGuestDashboardPayload = {
  courseId: string
}
export const getGuestDashboard = createAsyncThunk<
  any,
  GetGuestDashboardPayload
>('guest/dashboard/get', async ({courseId}) => {
  const endpoint = `/v1/guest/are/dashboard/course/${courseId}`

  return useApi(null, endpoint, {
    method: 'GET',
  }).then(res => res.json())
})
//#endregion

//#region slice
const initialState: SliceState = {
  dashboard: undefined,
  dashboardStatus: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDashboard.pending, state => {
      state.dashboard = undefined
      state.dashboardStatus = LoadingStatuses.Loading
    })
    builder.addCase(getDashboard.fulfilled, (state, action) => {
      state.dashboard = action.payload
      state.dashboardStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(getDashboard.rejected, state => {
      state.dashboard = undefined
      state.dashboardStatus = LoadingStatuses.Failed
    })
    builder.addCase(getGuestDashboard.pending, state => {
      state.dashboard = undefined
      state.dashboardStatus = LoadingStatuses.Loading
    })
    builder.addCase(getGuestDashboard.fulfilled, (state, action) => {
      state.dashboard = action.payload
      state.dashboardStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(getGuestDashboard.rejected, state => {
      state.dashboard = undefined
      state.dashboardStatus = LoadingStatuses.Failed
    })
  },
})
//#endregion

//#region selectors
export const selectDashboard = ({dashboard}: RootState) => dashboard.dashboard
export const selectDashboardStatus = ({dashboard}: RootState) =>
  dashboard.dashboardStatus
//#endregion
