import styled from 'styled-components'
import {Box} from '@mui/material'

export const FlexBox = styled(Box)`
  display: flex;
  flex-flow: column;

  && .rdrDefinedRangesWrapper {
    display: none !important;
  }
`
