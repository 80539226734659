import React, {useState} from 'react'
import {Box} from '@mui/material'
import {
  MatrixContainer,
  ColumnHeader,
  ColumnContent,
  PriceText,
  SavingsText,
  List,
  Note,
  ContentContainer,
  ButtonContainer,
} from './styles'
import {Button} from '../../button'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, {SelectChangeEvent} from '@mui/material/Select'

const View = () => {
  const matrixData = [
    {
      name: 'Premium',
      monthlyPrice: '$179/mo',
      yearlyPrice: '$1,790/mo',
      savingAmount: '$358',
      bulletPoints: [
        'Online Video Lectures and slides for all 6 ARE 5.0 Divisions - 120+ hours',
        'Online Practice Exams with 3 different tests for each of the 6 divisions',
      ],
    },
    {
      name: 'Pro',
      monthlyPrice: '$249/mo',
      yearlyPrice: '$2,490/mo',
      savingAmount: '$498',
      bulletPoints: [
        'Online Video Lectures and slides for all 6 ARE 5.0 Divisions - 120+ hours',
        'Online Practice Exams with 3 different tests for each of the 6 divisions',
        'Digital Flashcards - access to over 3,000 digital flashcards for all 6 divisions',
        'Online real-life Practical Application videos presented by practicing architects to help bring the ideas home',
        'Study guides for all 6 divisions - monthly day-by-day roadmap to studying',
      ],
    },
    {
      name: 'Expert',
      monthlyPrice: '$329/mo',
      yearlyPrice: '$3,290/mo',
      savingAmount: '$658',
      bulletPoints: [
        'Online Video Lectures and slides for all 6 ARE 5.0 Divisions - 120+ hours',
        'Online Practice Exams with 3 different tests for each of the 6 divisions',
        'Digital Flashcards - access to over 3,000 digital flashcards for all 6 divisions',
        'Online real-life Practical Application videos presented by practicing architects to help bring the ideas home',
        'Study guides for all 6 divisions - monthly day-by-day roadmap to studying',
        'Virtual Workshops - live, online workshops, with focused exercises and live Q&A led by a licensed expert every Sunday for 2 hours, for all 6 divisions',
      ],
    },
  ]
  const [displayYearly, setDisplayYearlyToggle] = useState(true)
  const [subscriptionDisplay, setSubscriptionDisplay] = useState('')

  const handleChange = (event: SelectChangeEvent) => {
    setSubscriptionDisplay(event.target.value)
    setDisplayYearlyToggle(!displayYearly)
  }

  const handleClick = (subscriptionType: string, frequency: string) => {
    switch (subscriptionType) {
      case 'Premium':
        location.href =
          frequency === '1'
            ? process.env.REACT_APP_1_MONTH_PREMIUM_URL ||
              'https://www.blackspectacles.com/pricing'
            : process.env.REACT_APP_12_MONTH_PREMIUM_URL ||
              'https://www.blackspectacles.com/pricing'
        return
      case 'Pro':
        location.href =
          frequency === '1'
            ? process.env.REACT_APP_1_MONTH_PRO_URL ||
              'https://www.blackspectacles.com/pricing'
            : process.env.REACT_APP_12_MONTH_PRO_URL ||
              'https://www.blackspectacles.com/pricing'
        return
      case 'Expert':
        location.href =
          frequency === '1'
            ? process.env.REACT_APP_1_MONTH_EXPERT_URL ||
              'https://www.blackspectacles.com/pricing'
            : process.env.REACT_APP_12_MONTH_EXPERT_URL ||
              'https://www.blackspectacles.com/pricing'
        return
      default:
        location.href = 'https://www.blackspectacles.com/pricing'
        return
    }
  }

  return (
    <MatrixContainer>
      {matrixData.map(
        ({name, monthlyPrice, yearlyPrice, bulletPoints, savingAmount}) => (
          <Box className="subscription-column">
            <ColumnHeader variant={'h1'}>{name}</ColumnHeader>

            <ContentContainer>
              <ColumnContent>
                <PriceText variant={'h2'}>
                  {displayYearly ? yearlyPrice : monthlyPrice}
                </PriceText>

                <SavingsText variant={'body2'}>
                  {displayYearly
                    ? "YOU'RE GETTING 12 MONTHS FOR THE COST OF 10!"
                    : `UPGRADE TO A 12-MONTH SUBSCRIPTION AND SAVE ${savingAmount}!`}
                </SavingsText>

                <FormControl fullWidth style={{marginBottom: 16}}>
                  <Select
                    value={subscriptionDisplay}
                    label="subscriptionDisplay"
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">12-MO SUBSCRIPTION</MenuItem>
                    <MenuItem value={'1'}>1-MO SUBSCRIPTION</MenuItem>
                  </Select>
                </FormControl>

                <List>
                  {bulletPoints.map(note => (
                    <li className="custom-marker">
                      <Note variant="body2">{note}</Note>
                      {/* {note} */}
                    </li>
                  ))}
                </List>
              </ColumnContent>

              <ButtonContainer>
                <Button
                  color={'primary'}
                  children="SELECT AND REVIEW"
                  onClick={() => handleClick(name, subscriptionDisplay)}
                ></Button>
              </ButtonContainer>
            </ContentContainer>
          </Box>
        ),
      )}
    </MatrixContainer>
  )
}

export default View
