import React from 'react'
import View from './view'
import {Flashcard} from '../../../../redux/flashcard'
import {Course} from '../../../../redux/courses'

type Props = {
  course?: Course
  deck: Flashcard[]
  filterBySelection: string
  toggleForm: (value: boolean) => void
  toggleEditMode: (value: boolean) => void
  updateCardId: (value: string) => void
  flipCard: (cardPosition: number) => void
  hasAccess: boolean
  setFlashCardNumber: (value: number) => void
  currentFlashCardNumber: number
  searchInput: string
}

const FlashCardContent = ({
  course,
  deck,
  filterBySelection,
  toggleForm,
  toggleEditMode,
  updateCardId,
  flipCard,
  hasAccess,
  setFlashCardNumber,
  currentFlashCardNumber,
  searchInput,
}: Props) => {
  return (
    <View
      course={course}
      deck={deck}
      filterBySelection={filterBySelection}
      toggleForm={toggleForm}
      toggleEditMode={toggleEditMode}
      updateCardId={updateCardId}
      setFlashCardNumber={setFlashCardNumber}
      flipCard={flipCard}
      hasAccess={hasAccess}
      currentFlashCardNumber={currentFlashCardNumber}
      searchInput={searchInput}
    />
  )
}

export default FlashCardContent
