import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js'
import {Line} from 'react-chartjs-2'
import {ChartData} from '../../redux/organizations'
import {Box} from '@mui/material'
import {BottomLegend, LeftLegend, RightLegend} from './styles'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
)

type Props = {
  data: ChartData
}

const Chart = ({data}: Props) => {
  const options: any = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        min: 0,
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        min: 0,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  }

  const labels = data?.labels

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Active Members',
        data: data?.activeMembers,
        borderColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgba(0, 0, 0)',
        yAxisID: 'y',
      },
      {
        label: 'Max Simultaneous Users',
        data: data?.simultaneousUsers,
        borderColor: 'rgba(244,67,54,255)',
        backgroundColor: 'rgba(244,67,54,255)',
        yAxisID: 'y',
      },
      // {
      //   label: 'Cap Limit',
      //   data: data?.capLimit,
      //   borderColor: 'rgb(252,164,156)',
      //   backgroundColor: 'rgb(252,164,156)',
      //   yAxisID: 'y',
      // },
      {
        label: 'Usage Hours',
        data: data?.usageHours,
        borderColor: 'rgb(19, 119, 212)',
        backgroundColor: 'rgb(19, 119, 212)',
        yAxisID: 'y1',
      },
    ],
  }

  return (
    <Box position={'relative'}>
      <LeftLegend>Number of Users</LeftLegend>
      <RightLegend>Usage Hours</RightLegend>
      <Line options={options} data={chartData} />
      <BottomLegend>Date</BottomLegend>
    </Box>
  )
}

export default Chart
