import styled from 'styled-components'
import {Box} from '@mui/material'
import theme from '../../styles/theme'

export const GuaranteeBox = styled(Box)`
  width: 60%;
  padding-bottom: 80px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }

  && li {
    margin-top: 16px;
  }
`

export const StyledImage = styled.img`
  width: 338px;
`
