import React, {useCallback, useEffect, useState} from 'react'
import View from './view'
import {Helmet} from 'react-helmet'
import {useAppDispatch} from '../../redux/configureStore'
import {useAuth} from '../../components/AuthProvider'
import {useSelector} from 'react-redux'
import {
  getReport,
  selectReport,
  selectReportStatus,
} from '../../redux/organizations'
import {toggleErrorDialog} from '../../redux/config'

export type DateFilter = {
  startDate: Date
  endDate: Date
}

const GroupReporting = () => {
  const dispatch = useAppDispatch()
  const auth = useAuth()

  const startDate = new Date()
  startDate.setDate(startDate.getDate() - 7)

  const endDate = new Date()
  const [dateFilter, setDateFilter] = useState<DateFilter>({startDate, endDate})

  const report = useSelector(selectReport)
  const reportStatus = useSelector(selectReportStatus)

  const loadBillingHistory = useCallback(async () => {
    try {
      const startDate = dateFilter.startDate.toISOString().split('T')[0]
      const endDate = dateFilter.endDate.toISOString().split('T')[0]

      const {type} = await dispatch(getReport({auth, startDate, endDate}))
      if (type === getReport.rejected.type) {
        throw new Error('Unable to get billing history')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to get the report, please retry. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch, dateFilter])

  useEffect(() => {
    loadBillingHistory()
  }, [loadBillingHistory])

  const handleUpdateDatefilter = ({startDate, endDate}: DateFilter) => {
    setDateFilter({startDate, endDate})
  }

  return (
    <>
      <Helmet>
        <title>Group Reporting | Black Spectacles</title>
      </Helmet>
      <View
        report={report}
        reportStatus={reportStatus}
        onUpdateDateFilter={handleUpdateDatefilter}
        dateFilter={dateFilter}
      />
    </>
  )
}

export default GroupReporting
