import React, {useState, useEffect} from 'react'
import {Box} from '@mui/material'
import {VideoGroup} from '../../redux/videos'
import {
  ActionsBox,
  ActionsFlexBox,
  AutoplayBox,
  BottomBox,
  CheckBox,
  ContentBox,
  ContentLink,
  CourseTitle,
  IconBox,
  LinkButton,
  LinkButton2,
  NextButton,
  ProgressBox,
  QuizBreakIcon,
  StyledCheckIcon,
  StyledPlayArrowIcon,
  Title,
  UpNext,
  VideoEndedBox,
  VideoInternalBox,
  VideoProgress,
} from './styles'
import {useNavigate} from 'react-router-dom'
import CircularWithValueLabel from '../CircularProgress'
import Cookies from 'universal-cookie'
import {StyledIcon} from '../CircularProgress/styles'

type Props = {
  currentGroup: VideoGroup
  nextGroup?: VideoGroup
  currentIndex: number
  onChangeVideoEnded: (value: boolean) => void
  loadVideo: () => void
}

const View = ({
  currentGroup,
  nextGroup,
  currentIndex,
  onChangeVideoEnded,
  loadVideo,
}: Props) => {
  const navigate = useNavigate()
  const cookies = new Cookies()
  const nextLesson = currentGroup?.lessons[currentIndex + 1]
  const currentVideo = currentGroup?.lessons[currentIndex]

  const videoId = currentVideo?.id
  const videos = currentGroup?.lessons.filter(
    lesson => lesson.assetTypeAbbr === 'video',
  )

  const currentVideoIndex = videos?.findIndex(lesson => lesson.id === videoId)

  const [autoplay, setAutoplay] = useState(
    nextLesson &&
      nextLesson.assetTypeAbbr !== 'quiz' &&
      cookies.get('bks-autoplay')
      ? cookies.get('bks-autoplay')
      : false,
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      if (autoplay) {
        goToNext(
          `/courses/${currentGroup?.courseId}/${
            nextLesson.assetTypeAbbr
          }/${nextLesson?.id}${nextLesson?.isFree ? '/free' : ''}`,
        )
      }

      clearTimeout(timer)
    }, 5500)

    return () => {
      clearTimeout(timer)
    }
  }, [autoplay])

  const handleChangeAutoplay = () => {
    const newValue = !autoplay

    setAutoplay(newValue)

    cookies.set('bks-autoplay', newValue, {
      path: '/',
    })
  }

  const goToNext = (url: string, reloadVideo?: boolean) => {
    onChangeVideoEnded(false)
    navigate(url, {
      replace: true,
    })

    if (reloadVideo) loadVideo()
  }

  const nextLessonLink = `/courses/${currentGroup?.courseId}/${nextLesson?.assetTypeAbbr}/${nextLesson?.id}${
    nextLesson?.isFree ? '/free' : ''
  }`

  const nextGroupLink = `/courses/${currentGroup?.courseId}/${nextGroup
    ?.lessons[0]?.assetTypeAbbr}/${nextGroup?.lessons[0].id}${
    nextGroup?.lessons[0].isFree ? '/free' : ''
  }`

  const currentGroupLink = `/courses/${currentGroup?.courseId}/${currentGroup
    ?.lessons[0]?.assetTypeAbbr}/${currentGroup?.lessons[0].id}${
    currentGroup?.lessons[0].isFree ? '/free' : ''
  }`

  return (
    <VideoEndedBox>
      <VideoInternalBox>
        {nextLesson ? (
          <>
            <ProgressBox>
              {nextLesson.assetTypeAbbr === 'quiz' ? (
                <LinkButton onClick={() => goToNext(nextLessonLink)}>
                  <QuizBreakIcon />
                </LinkButton>
              ) : (
                <LinkButton onClick={() => goToNext(nextLessonLink)}>
                  {autoplay ? (
                    <CircularWithValueLabel />
                  ) : (
                    <NextButton>
                      <StyledIcon />
                    </NextButton>
                  )}
                </LinkButton>
              )}
            </ProgressBox>
            <Box>
              <CourseTitle>{currentGroup?.currentlyViewing}</CourseTitle>
              <VideoProgress>{`${currentVideoIndex + 1}/${
                videos.length
              } videos completed`}</VideoProgress>
              <UpNext>
                up next:
                <br />
                <LinkButton2 onClick={() => goToNext(nextLessonLink)}>
                  {nextLesson?.shortDescription}
                </LinkButton2>
              </UpNext>
            </Box>
          </>
        ) : (
          <ActionsFlexBox>
            <ActionsBox>
              <IconBox $hover={false}>
                <StyledCheckIcon style={{width: '40px', height: '40px'}} />
              </IconBox>
              <ContentBox>
                <Title>JUST COMPLETED:</Title>
                <ContentLink onClick={() => goToNext(currentGroupLink)}>
                  {currentGroup?.currentlyViewing}
                </ContentLink>
              </ContentBox>
            </ActionsBox>
            {nextGroup && (
              <ActionsBox>
                <IconBox $hover={true} onClick={() => goToNext(nextGroupLink)}>
                  <StyledPlayArrowIcon />
                </IconBox>
                <ContentBox>
                  <Title>UP NEXT:</Title>
                  <ContentLink onClick={() => goToNext(nextGroupLink)}>
                    {nextGroup?.currentlyViewing}
                  </ContentLink>
                </ContentBox>
              </ActionsBox>
            )}
          </ActionsFlexBox>
        )}
      </VideoInternalBox>
      <BottomBox>
        {nextLesson && nextLesson.assetTypeAbbr !== 'quiz' && (
          <>
            <AutoplayBox onClick={handleChangeAutoplay}>
              <CheckBox $isActive={autoplay}>{autoplay && '✓'}</CheckBox>
              Autoplay next{' '}
              {videos.length - (currentVideoIndex + 1) === 1
                ? 'video'
                : `${videos.length - (currentVideoIndex + 1)} videos`}
            </AutoplayBox>
            <span>|</span>
          </>
        )}
        <LinkButton2
          onClick={() =>
            goToNext(
              `/courses/${currentGroup?.courseId}/${currentVideo?.assetTypeAbbr}/${currentVideo?.id}${
                currentVideo?.isFree ? '/free' : ''
              }`,
              true,
            )
          }
        >
          REPLAY
        </LinkButton2>
        <span>|</span>
        {nextGroup ? (
          <LinkButton2
            onClick={() =>
              goToNext(
                `/courses/${currentGroup?.courseId}/${
                  nextLesson
                    ? nextLesson?.assetTypeAbbr
                    : nextGroup.lessons[0].assetTypeAbbr
                }/${nextLesson ? nextLesson?.id : nextGroup.lessons[0].id}${
                  nextLesson
                    ? nextLesson?.isFree
                      ? '/free'
                      : ''
                    : nextGroup.lessons[0].isFree
                }`,
              )
            }
          >
            NEXT
          </LinkButton2>
        ) : (
          <LinkButton2
            onClick={() => goToNext(`/courses/${currentGroup?.courseId}`)}
          >
            BACK TO COURSE
          </LinkButton2>
        )}
      </BottomBox>
    </VideoEndedBox>
  )
}

export default View
