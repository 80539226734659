const hoursMinutes = (seconds: number, smallFormat?: boolean) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  const hoursString =
    hours > 0
      ? hours +
        (hours === 1
          ? smallFormat
            ? 'h'
            : ' hour'
          : smallFormat
          ? 'h'
          : ' hours')
      : ''
  const minutesString =
    minutes > 0
      ? minutes +
        (minutes === 1
          ? smallFormat
            ? 'm'
            : ' minute'
          : smallFormat
          ? 'm'
          : ' minutes')
      : ''

  const separator = hoursString && minutesString ? ' ' : ''

  return hoursString + separator + minutesString
}

export default hoursMinutes
