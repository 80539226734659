import React from 'react'
import {CircularProgress} from '@mui/material'
import BksIcon from '../../images/icons/bks-black-icon.svg'
import {LoadingBox} from './styles'

const Loading = () => {
  return (
    <LoadingBox>
      <img src={BksIcon} alt="Loading" />
      <CircularProgress color="inherit" />
    </LoadingBox>
  )
}

export default Loading
