import {Box, Typography} from '@mui/material'
import styled from 'styled-components'
import theme, {colors} from '../../../styles/theme'

export const MatrixContainer = styled(Box)`
  display: flex;
  justify-content: center;

  && .subscription-column {
    width: 25%;
    text-align: center;
    border: 2px solid ${colors.blue2};
    padding-bottom: 15px;
  }

  && .subscription-column:nth-child(2) {
    border-left: 0px solid ${colors.blue2};
    border-right: 0px solid ${colors.blue2};
  }

  && legend {
    display: none;
  }

  && fieldset {
    border-color: #96e0fc;
    border-width: 2px;
  }

  ${theme.breakpoints.down('md')} {
    flex-wrap: wrap;

    && .subscription-column {
      width: 50%;
      border-left: 2px solid ${colors.blue2};
      border-right: 2px solid ${colors.blue2};
    }

    && .subscription-column:nth-child(2) {
      border-left: 2px solid ${colors.blue2};
      border-right: 2px solid ${colors.blue2};
      border-top: none;
      border-bottom: none;
    }
  }
`
export const ColumnHeader = styled(Typography)`
  padding: 0.75em 0;
  color: black !important;
  border-bottom: 2px solid ${colors.blue2};
  text-transform: none !important;
`

export const ColumnContent = styled(Box)`
  margin-top: 40px;
  padding: 0 20px;
`

export const PriceText = styled(Typography)`
  margin-bottom: 15px !important;
`

export const SavingsText = styled(Typography)`
  color: #666;
  margin-bottom: 24px !important;
`

export const Note = styled(Typography)`
  border-bottom: 1px #eee solid;
  margin: 1em;
  padding: 1em;
  text-align: left;
`

export const List = styled('ul')`
  .custom-marker::marker {
    list-style: none;
    content: '\203A';
    color: red !important;
  }
`

// To be updated
export const ContentContainer = styled(Box)``

export const ButtonContainer = styled(Box)`
  padding: 0 20px !important;

  && button {
    width: 100%;
  }
`
