import React, {useState} from 'react'
import {
  ButtonBox,
  StyledDialog,
  DialogBox,
  DialogSubtitle,
  DialogText,
  DialogTitle,
  StampBox,
} from './styles'
import {Button} from '../button'
import {CheckBox} from '../VideoEnded/styles'
import CloseIcon from '@mui/icons-material/Close'
import {Alert} from '@mui/material'
import {Attempt} from '../../redux/reportExamStatus'
import Samp from '../../images/icons/architecture-stamp.png'

type Props = {
  open: boolean
  setOpen: (value: boolean) => void
  attempts?: Attempt[]
  handleSubmit: () => void
  isComplete: boolean
}

const View = ({open, setOpen, attempts, handleSubmit, isComplete}: Props) => {
  const [isConfirmed, setIsConfirmed] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const checkIfAllExamsAreComplete = () => {
    if (!attempts) return false

    const passed: string[] = []

    attempts.forEach(attempt => {
      if (
        attempt.passed &&
        attempt.courseTitle &&
        !passed.includes(attempt.courseTitle)
      ) {
        passed.push(attempt.courseTitle)
      }
    })

    return passed.length === 6
  }

  return (
    <StyledDialog maxWidth={'md'} onClose={handleClose} open={open}>
      <DialogBox>
        <DialogTitle>
          {`${
            isComplete ? `You've gone LEGEND!` : `Confirm your ARE completion!`
          }`}

          <CloseIcon
            onClick={handleClose}
            className="x-button"
            style={{
              height: 32,
              width: 32,
              cursor: 'pointer',
            }}
          />
        </DialogTitle>
        {isComplete ? (
          <>
            <StampBox>
              <img src={Samp} alt="Stamp" />
            </StampBox>
            <DialogText>
              Congratulations on passing the ARE! We would cheers you, but
              that’s hard to do through the computer. Instead, we have a
              surprise that we know you’ll find most useful. You’ll be receiving
              an email shortly with instructions on how to cash in on your Black
              Spectacles gift. Alright, now go celebrate!
            </DialogText>
            <ButtonBox>
              <Button
                onClick={handleClose}
                children={'Close'}
                color={'primary'}
                size={'medium'}
              />
            </ButtonBox>
          </>
        ) : (
          <>
            <DialogSubtitle>CONFIRM COMPLETION</DialogSubtitle>
            <DialogText
              style={{cursor: 'pointer'}}
              onClick={() => setIsConfirmed(!isConfirmed)}
            >
              <CheckBox $isActive={isConfirmed}>{isConfirmed && '✓'}</CheckBox>
              <span>
                I have completed the ARE and agree to the terms and privacy
                policies of Black Spectacles
              </span>
            </DialogText>
            <ButtonBox>
              <Button
                onClick={handleSubmit}
                disabled={!isConfirmed || !checkIfAllExamsAreComplete()}
                children={'Mark as Complete'}
                color={'primary'}
                size={'medium'}
              />
            </ButtonBox>
            {!checkIfAllExamsAreComplete() && (
              <Alert style={{marginTop: '24px'}} severity="error">
                Please update the status of the exams individually to reflect
                passing the ARE before marking it as complete.
              </Alert>
            )}
          </>
        )}
      </DialogBox>
    </StyledDialog>
  )
}

export default View
