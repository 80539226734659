import React from 'react'
import View from './view'

type Props = {
  toggleSearchBox: () => void
}

const SearchBar = ({toggleSearchBox}: Props) => {
  return <View toggleSearchBox={toggleSearchBox} />
}

export default SearchBar
