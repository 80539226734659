import React from 'react'
import {Routes, Route} from 'react-router-dom'
import {MainContainer} from '../styles/global'
import Postman from '../pages/Postman'
import Dashboard from '../pages/Dashboard'
import Settings from '../pages/Settings'
import Health from '../pages/Health'
import Wrapper from '../components/wrapper'
import FlashCards from '../pages/FlashCards'
import Courses from '../pages/Courses'
import Videos from '../pages/Videos'
import Quizzes from '../pages/Quizzes'
import QuizzesList from '../pages/QuizzesList'
import ExamsList from '../pages/ExamsList'
import Exams from '../pages/Exams'
import ExamReview from '../pages/ExamReview'
import Login from '../pages/Login'
import PracticalApplications from '../pages/PracticalApplications'
import Whiteboard from '../pages/Whiteboard'
import GroupManage from '../pages/GroupManage'
import GroupReporting from '../pages/GroupReporting'
import GroupBilling from '../pages/GroupBilling'
import Topics from '../pages/Topics'
import InvoiceDetails from '../pages/InvoiceDetails'
import SearchResults from '../pages/SearchResults'
import AccountActivation from '../pages/AccountActivation'
import Guarantee from '../pages/Guarantee'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import Community from '../pages/Community'
import AccountActivationConfirm from '../pages/AccountActivationConfirm'
import CapReached from '../pages/CapReached'

export const DefaultRoutes = () => {
  return (
    <MainContainer>
      <Routes>
        <Route
          path="/"
          element={
            <Wrapper showHero={true} showCompleteText={true}>
              {<Dashboard />}
            </Wrapper>
          }
        />
        <Route
          path="/profile/activation"
          element={<Wrapper>{<AccountActivation />}</Wrapper>}
        />
        <Route
          path="/profile/activation/confirm"
          element={<Wrapper>{<AccountActivationConfirm />}</Wrapper>}
        />
        <Route
          path="/profile/dashboard"
          element={
            <Wrapper showHero={true} showCompleteText={true}>
              {<Dashboard />}
            </Wrapper>
          }
        />
        <Route
          path="/profile/dashboard/software/topics"
          element={<Wrapper showHero={true}>{<Dashboard />}</Wrapper>}
        />
        <Route
          path="/profile/dashboard/:courseId"
          element={
            <Wrapper showHero={true} showCompleteText={true}>
              {<Dashboard />}
            </Wrapper>
          }
        />
        <Route
          path="/profile/settings/:setting/:division?"
          element={<Wrapper showHero={true}>{<Settings />}</Wrapper>}
        />
        <Route
          path="/profile/settings/:setting"
          element={<Wrapper showHero={true}>{<Settings />}</Wrapper>}
        />
        <Route
          path="/profile/settings"
          element={<Wrapper showHero={true}>{<Settings />}</Wrapper>}
        />
        <Route
          path="/topics/:topic"
          element={<Wrapper showHero={true}>{<Topics />}</Wrapper>}
        />
        <Route
          path="/courses/:courseId"
          element={<Wrapper showHero={true}>{<Courses />}</Wrapper>}
        />
        <Route
          path="/quizzes/:courseId"
          element={<Wrapper showHero={true}>{<QuizzesList />}</Wrapper>}
        />
        <Route
          path="/courses/:courseId/video/:videoId/:status?"
          element={<Wrapper>{<Videos />}</Wrapper>}
        />
        <Route
          path="/courses/:courseId/quiz/:examId"
          element={<Wrapper>{<Quizzes />}</Wrapper>}
        />
        <Route
          path="/practice-exams/:courseId"
          element={<Wrapper showHero={true}>{<ExamsList />}</Wrapper>}
        />
        <Route
          path="/practice-exams/questions/:examId"
          element={
            <Wrapper hideNavbar={true} hideFooter={true} hideIntercom={true}>
              {<Exams />}
            </Wrapper>
          }
        />
        <Route
          path="/practice-exams/review/:attemptId/:state?"
          element={
            <Wrapper hideNavbar={true} hideFooter={true} hideIntercom={true}>
              {<ExamReview />}
            </Wrapper>
          }
        />
        <Route
          path="/profile/deck/flashcards/:courseId/:state?"
          element={<Wrapper>{<FlashCards />}</Wrapper>}
        />
        <Route
          path="/practical-applications"
          element={
            <Wrapper showHero={true}>{<PracticalApplications />}</Wrapper>
          }
        />
        <Route
          path="/group/manage"
          element={<Wrapper showHero={true}>{<GroupManage />}</Wrapper>}
        />
        <Route
          path="/group/reporting"
          element={<Wrapper showHero={true}>{<GroupReporting />}</Wrapper>}
        />
        <Route
          path="/group/billing"
          element={<Wrapper showHero={true}>{<GroupBilling />}</Wrapper>}
        />
        <Route
          path="/search/results"
          element={<Wrapper showHero={true}>{<SearchResults />}</Wrapper>}
        />
        <Route
          path="/guarantee"
          element={<Wrapper showHero={true}>{<Guarantee />}</Wrapper>}
        />
        <Route path="/invoice/:invoiceId" element={<InvoiceDetails />} />
        <Route path="/whiteboard/:attemptId" element={<Whiteboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cap-limit" element={<CapReached />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/forgot-password/:resetCode" element={<ResetPassword />} />
        <Route path="/postman" element={<Postman />} />
        <Route path="/health" element={<Health />} />
        <Route path="/discourse/sso" element={<Community />} />
      </Routes>
    </MainContainer>
  )
}
