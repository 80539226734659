import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {AuthContextInterface} from '../components/AuthProvider'

type SlackNotificationPayload = {
  auth: AuthContextInterface
  channel: string
  message: string
}

export const notifySlackChannel = createAsyncThunk<
  any,
  SlackNotificationPayload
>('/notifySlack', async ({auth, channel, message}) => {
  const endpoint = `/v1/are/member/slack_notification`

  return useApi(auth, endpoint, {
    method: 'POST',
    body: JSON.stringify({channel, message}),
  }).then(res => res.text())
})
