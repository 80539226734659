import {createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../../../../hooks/useApi'

export type CartDetails = {
  items: Item[]
  tax: number
  total: number
  address: AddressDetails
  discount: number
  sub_total: number
  tax_rate_id?: string
}

export type Item = {
  id: string
  price: string
  qty: string
  name: string
}

export type AddressDetails = {
  address1?: string
  city?: string
  state?: string
  postal_code?: string
  country?: string
}

type UpdatePricingPayload = {
  items: Item[]
  coupon?: string
  address?: AddressDetails
}

export const guestReprice = createAsyncThunk<any, UpdatePricingPayload>(
  '/reprice',
  async ({items, coupon, address}) => {
    const fields = {
      items,
      coupon: coupon ? coupon?.toUpperCase() : '',
      address,
      no_cache: true,
    }

    return useApi(
      null,
      `${process.env.REACT_APP_CHECKOUT_API_URL}/v1/guest/payment/cart/reprice`,
      {
        method: 'POST',
        body: JSON.stringify(fields),
      },
    ).then(async res => {
      const response = await res.json()
      const data = {...response, address}
      return data
    })
  },
)
