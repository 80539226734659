import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {RootState} from './rootReducer'
import {LoadingStatuses} from '../components/types/LoadingStatusses'
import {Lesson} from './lessons'
import {AuthContextInterface} from '../components/AuthProvider'

//#region types
export type Video = {
  id: string
  title: string
  description?: string
  duration: string
  srtCaptionContent: string
  transcript: string
  transcriptFormatted: string
  brightcoveId: string
  free: boolean
}

export type VideoGroup = {
  courseId: string
  title: string
  currentlyViewing: string
  lessons: Lesson[]
}

type SliceState = {
  video?: Video
  videoStatus: LoadingStatuses
  videoGroup?: VideoGroup[]
  videoGroupStatus: LoadingStatuses
  error: string | null | undefined
}
//#endregion

//#region api
type DefaultPayload = {
  auth: AuthContextInterface
}

interface GetVideoPayload extends DefaultPayload {
  videoId?: string
  isSoftware: boolean
}
export const getVideo = createAsyncThunk<any, GetVideoPayload>(
  'videos/get',
  async ({auth, videoId, isSoftware}) => {
    const endpoint = `/v1/are/video/${videoId}${isSoftware ? '/software' : ''}`

    return useApi(auth, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

type GetGuestVideoPayload = {
  videoId?: string
}
export const getGuestVideo = createAsyncThunk<any, GetGuestVideoPayload>(
  'guest/videos/get',
  async ({videoId}) => {
    const endpoint = `/v1/guest/are/video/${videoId}`

    return useApi(null, endpoint, {
      method: 'GET',
    }).then(res => res.json())
  },
)

//#region api
interface TrackProgressPayload extends DefaultPayload {
  videoId?: string
  ts: number
  lessonPlanId?: string
}
export const trackProgress = createAsyncThunk<any, TrackProgressPayload>(
  'videos/progress/get',
  async ({auth, videoId, ts, lessonPlanId}) => {
    const endpoint = `/v1/are/video/progress/${ts}/${videoId}/website`

    const body = {lessonPlanId}

    return useApi(auth, endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then(res => res.text())
  },
)

type GetVideoGroupData = {
  lessons: Lesson[]
  courseId?: string
  title: any
}
export const getVideoGroup = createAsyncThunk<any, GetVideoGroupData>(
  'videoGroup/get',
  () => {
    return ''
  },
)

export const clearVideoState = createAsyncThunk<any>('clerVideo/get', () => {
  return ''
})

//#endregion

//#region slice
const initialState: SliceState = {
  video: undefined,
  videoStatus: LoadingStatuses.Idle,
  videoGroup: undefined,
  videoGroupStatus: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: 'video',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearVideoState.fulfilled, state => {
      state.video = undefined
      state.videoStatus = LoadingStatuses.Idle
    })
    builder.addCase(getVideo.pending, state => {
      state.video = undefined
      state.videoStatus = LoadingStatuses.Loading
    })
    builder.addCase(getVideo.fulfilled, (state, action) => {
      state.video = action.payload
      state.videoStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(getVideo.rejected, state => {
      state.video = undefined
      state.videoStatus = LoadingStatuses.Failed
    })
    builder.addCase(getGuestVideo.pending, state => {
      state.video = undefined
      state.videoStatus = LoadingStatuses.Loading
    })
    builder.addCase(getGuestVideo.fulfilled, (state, action) => {
      state.video = action.payload
      state.videoStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(getGuestVideo.rejected, state => {
      state.video = undefined
      state.videoStatus = LoadingStatuses.Failed
    })
    builder.addCase(getVideoGroup.pending, state => {
      state.videoGroup = undefined
      state.videoGroupStatus = LoadingStatuses.Loading
    })
    builder.addCase(getVideoGroup.fulfilled, (state, action) => {
      const lessons = action.meta.arg.lessons
      const courseId = action.meta.arg.courseId!
      const title = action.meta.arg.title

      const videoGroup: VideoGroup[] = []

      let currentHeader = ''
      let tempLessons: Lesson[] = []
      let isPracticalApplication = false

      lessons.map((lesson, key) => {
        if (
          lesson.assetTypeAbbr === 'lesson_header' &&
          currentHeader !== lesson.shortDescription
        ) {
          isPracticalApplication = lesson.shortDescription.includes(
            'Practical Applications',
          )
          if (!isPracticalApplication) {
            if (currentHeader !== '') {
              const tempVideo = {
                courseId,
                title: `ARE 5.0 ${title} Exam Prep`,
                lessons: tempLessons,
                currentlyViewing: currentHeader,
              }
              videoGroup.push(tempVideo)
            }

            currentHeader = lesson.shortDescription
            tempLessons = []
          }
        }

        if (lesson.assetTypeAbbr !== 'lesson_header') {
          tempLessons.push({...lesson, isPracticalApplication})
        }

        if (lessons.length - 1 === key) {
          const tempVideo = {
            courseId,
            title: `ARE 5.0 ${title} Exam Prep`,
            lessons: tempLessons,
            currentlyViewing: currentHeader,
          }
          videoGroup.push(tempVideo)
        }
      })

      state.videoGroup = videoGroup
      state.videoGroupStatus = LoadingStatuses.Succeeded
    })
    builder.addCase(getVideoGroup.rejected, state => {
      state.videoGroup = undefined
      state.videoGroupStatus = LoadingStatuses.Failed
    })
  },
})
//#endregion

//#region selectors
export const selectVideo = ({video}: RootState) => video.video
export const selectVideoGroup = ({video}: RootState) => video.videoGroup
export const selectVideoStatus = ({video}: RootState) => video.videoStatus
export const selectVideoGroupStatus = ({video}: RootState) =>
  video.videoGroupStatus
//#endregion
