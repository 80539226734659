import styled from 'styled-components'
import theme from '../../styles/theme'

export const Radio = styled.div<{$isActive: boolean}>`
  display: flex;
  width: 25px;
  height: 25px;
  margin: -1px 4px 0 0;
  cursor: pointer;
  border-radius: 2px;
  margin-right: 10px;
  position: relative;
  line-height: 22px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${({$isActive}) =>
    $isActive
      ? `
    color: #fff !important;
    background-color: #1377D4;
  `
      : `
    background-color: #eceff1;
  `}

  ${theme.breakpoints.down('sm')} {
    width: 20px;
    height: 20px;
  }
`
