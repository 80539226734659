import React from 'react'
import {Typography, Box} from '@mui/material'
import {
  PreExamBox,
  ScoreBox,
  SectionBox,
  SectionDescription,
  SectionTitle,
  Title,
} from '../ExamQuestions/styles'
import {Exam} from '../../redux/exams'
import {Link} from 'react-router-dom'

type Props = {
  exam: Exam
}

const Score = ({exam}: Props) => {
  const sectionList: any = []

  const correctQuestions = exam.questions.filter(item => item.isAnswerCorrect)

  exam.discreteQuestions.forEach(question => {
    if (question.objectives && question.objectives[0]) {
      const objective = question.objectives[0]
      const objectiveNumber = objective.shortDescription.replace(/[^0-9.]/g, '')
      const section = objectiveNumber[0]

      if (!sectionList[section]) {
        sectionList[section] = []
        sectionList[section][objectiveNumber[2]] = {
          objectiveNumber,
          description: objective.description,
          correctQuestions: question.isAnswerCorrect ? 1 : 0,
          totalQuestions: 1,
        }
      } else {
        if (sectionList[section][objectiveNumber[2]]) {
          sectionList[section][objectiveNumber[2]] = {
            objectiveNumber,
            description: objective.description,
            correctQuestions: question.isAnswerCorrect
              ? sectionList[section][objectiveNumber[2]].correctQuestions + 1
              : sectionList[section][objectiveNumber[2]].correctQuestions,
            totalQuestions:
              sectionList[section][objectiveNumber[2]].totalQuestions + 1,
          }
        } else {
          sectionList[section][objectiveNumber[2]] = {
            objectiveNumber,
            description: objective.description,
            correctQuestions: question.isAnswerCorrect ? 1 : 0,
            totalQuestions: 1,
          }
        }
      }
    }
  })

  return (
    <PreExamBox paddingTop={'40px'}>
      <Title variant="h2">
        Score:{' '}
        {Math.floor((correctQuestions.length / exam.questions.length) * 100)}%
      </Title>
      <Typography
        variant="h3"
        style={{
          fontFamily: 'Freight Disp Pro, Helvetica, Arial, sans-serif',
        }}
      >
        {correctQuestions.length} / {exam.questions.length}
      </Typography>
      <ScoreBox>
        {sectionList.map((section: any, key: number) => {
          let totalQuestions = 0
          let correctQuestions = 0
          section.map((item: any) => {
            totalQuestions = totalQuestions + item.totalQuestions
            correctQuestions = correctQuestions + item.correctQuestions
          })

          return (
            <Box key={key}>
              <SectionTitle variant="h4">
                Section {key}: {correctQuestions}/{totalQuestions} ({' '}
                {Math.floor((correctQuestions / totalQuestions) * 100)}% )
              </SectionTitle>
              {section.map((item: any) => {
                return (
                  <SectionBox key={item.objectiveNumber}>
                    <SectionDescription>
                      Objective {item.objectiveNumber}: {item.description} -{' '}
                      <b>
                        {item.correctQuestions}/{item.totalQuestions}
                      </b>
                    </SectionDescription>
                    <Link
                      to={`/courses/${exam.courseId}#${item.objectiveNumber}`}
                      target="_blank"
                    >
                      Review {item.objectiveNumber} Study Material
                    </Link>
                  </SectionBox>
                )
              })}
            </Box>
          )
        })}

        {exam.caseStudies.map((caseStudy, key) => {
          const totalQuestions = caseStudy.questions.length
          let correctQuestions = 0
          caseStudy.questions.map(item => {
            correctQuestions = item.isAnswerCorrect
              ? correctQuestions + 1
              : correctQuestions
          })

          return (
            <SectionTitle key={`case-study-${key}`} variant="h4">
              Case Study {key + 1}: {correctQuestions}/{totalQuestions} ({' '}
              {Math.floor((correctQuestions / totalQuestions) * 100)}% )
            </SectionTitle>
          )
        })}
      </ScoreBox>
    </PreExamBox>
  )
}

export default Score
