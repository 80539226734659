import React, {useEffect, useCallback} from 'react'
import View from './view'
import {useAuth} from '../../components/AuthProvider'
import {useAppDispatch} from '../../redux/configureStore'
import {
  getMembersList,
  selectMembersList,
  selectMembersListStatus,
  selectOrganization,
} from '../../redux/organizations'
import {toggleErrorDialog} from '../../redux/config'
import {useSelector} from 'react-redux'
import {Helmet} from 'react-helmet'

const GroupManage = () => {
  const auth = useAuth()
  const dispatch = useAppDispatch()

  const members = useSelector(selectMembersList)
  const organization = useSelector(selectOrganization)
  const status = useSelector(selectMembersListStatus)

  const loadMembers = useCallback(async () => {
    try {
      const {type} = await dispatch(getMembersList({auth}))
      if (type === getMembersList.rejected.type) {
        throw new Error('Unable to get group Data')
      }
    } catch (e) {
      await dispatch(
        toggleErrorDialog({
          opened: true,
          error:
            'We were unable to get the group data, please retry. If issues persist contact our support team',
        }),
      )
    }
  }, [dispatch])

  useEffect(() => {
    loadMembers()
  }, [loadMembers])

  return (
    <>
      <Helmet>
        <title>Group Membership Admin Portal | Black Spectacles</title>
      </Helmet>
      <View members={members} status={status} organization={organization} />
    </>
  )
}

export default GroupManage
