import React, {useState} from 'react'
import View from './view'
import {Helmet} from 'react-helmet'
import {useAppDispatch} from '../../redux/configureStore'
import {forgotPassword, selectError} from '../../redux/auth'
import {useSelector} from 'react-redux'
import RouteChangeTracker from '../../components/RouteChangeTracker'
const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const dispatch = useAppDispatch()

  const error = useSelector(selectError)

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const {type} = await dispatch(forgotPassword({email}))
    if (type === forgotPassword.fulfilled.type) {
      setIsComplete(true)
    }
    setIsSubmitting(false)
  }

  return (
    <>
      <RouteChangeTracker />
      <Helmet>
        <title>Forgot Password | Black Spectacles</title>
      </Helmet>
      <View
        onChangeEmail={(value: string) => setEmail(value)}
        onSubmit={handleSubmit}
        error={error}
        isSubmitting={isSubmitting}
        isComplete={isComplete}
      />
    </>
  )
}

export default ForgotPassword
