import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {useApi} from '../hooks/useApi'
import {AuthContextInterface} from '../components/AuthProvider'

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export type Question = {
  questionId: number
  question: string
  answer?: string
}

type SliceState = {
  questions: Question[]
  status: LoadingStatuses
  error?: string
}

//#endregion

//#region api
type getQuestionsPayload = {
  auth: AuthContextInterface
}

type submitQuestionsPayload = {
  auth: AuthContextInterface
  questions: any[]
}

export const getExpireSurveyQuestions = createAsyncThunk<
  any,
  getQuestionsPayload
>('/getExpireSurveyQuestions', async ({auth}) => {
  const endpoint = `/v1/are/expire/survey/questions`

  return useApi(auth, endpoint, {
    method: 'GET',
  }).then(res => res.json())
})

export const ignoreExpireSurvey = createAsyncThunk<any, getQuestionsPayload>(
  '/ignoreExpireSurvey',
  async ({auth}) => {
    const endpoint = `/v1/are/expire/survey/ignore`

    return useApi(auth, endpoint, {
      method: 'POST',
    }).then(res => res.json())
  },
)

export const submitExpireSurvey = createAsyncThunk<any, submitQuestionsPayload>(
  '/ignoreExpireSurvey',
  async ({auth, questions}) => {
    const endpoint = `/v1/are/expire/survey/submit`

    return useApi(auth, endpoint, {
      method: 'POST',
      body: JSON.stringify({
        questions: questions,
      }),
    }).then(res => res.json())
  },
)
//#endregion

//#region slice
const initialState: SliceState = {
  questions: [],
  status: LoadingStatuses.Idle,
}

export default createSlice({
  name: 'expireSurvey',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getExpireSurveyQuestions.fulfilled, (state, action) => {
        state.questions = action.payload.questions
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(getExpireSurveyQuestions.pending, state => {
        state.status = LoadingStatuses.Loading
      })
      .addCase(getExpireSurveyQuestions.rejected, state => {
        state.status = LoadingStatuses.Failed
        state.error = 'Failed getting questions'
      })
  },
})
//#endregion
