import {Box} from '@mui/material'
import styled from 'styled-components'
import theme from '../../../styles/theme'

type Props = {
  error?: string
}

export const FormContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 703px;
  background-color: #fff;
  border: 8px solid #eceff1;
  padding: 45px 25px;
  max-height: 80vh;
  overflow-y: auto;

  ${theme.breakpoints.down('sm')} {
    width: 80%;
  }
`

export const CloseContainer = styled(Box)`
  position: absolute;
  top: 8px;
  right: 20px;
`

export const FormTitle = styled(Box)`
  padding: 15px;
`

export const FormPrompt = styled(Box)`
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
`

export const RowContainer = styled(Box)<Props>`
  display: flex;
  margin: 85px 15px 0px;

  && label {
    text-transform: uppercase;
  }

  input[type='textarea'] {
    background-color: ${props => (props.error ? '#fff' : '#fff')};
  }

  input[type='textarea'] + fieldset {
    height: 100px;
    background-color: ${props =>
      props.error ? 'rgba(244, 76, 73, 0.08)' : 'transparent'};
  }

  input[type='textarea'] + fieldset,
  input[type='file'] + fieldset {
    border: 2px solid #1377d4;
  }

  p {
    position: relative;
    top: 50px;
  }

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`
