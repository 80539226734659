import {useState, useEffect} from 'react'

const getWindowDimensions = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
})

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

  const [isMobile, setIsMobile] = useState(windowDimensions.width < 550)

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
      setIsMobile(getWindowDimensions().width < 550)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {...windowDimensions, isMobile}
}
