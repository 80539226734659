import styled from 'styled-components'
import {Dialog, Box} from '@mui/material'
import theme from '../../styles/theme'

export const ErrorDialog = styled(Dialog)`
  min-height: 100vh;
  border-radius: 0;
`

export const DialogTitle = styled(Box)`
  font-size: 20px !important;
  color: #2d2d2d !important;
  border-bottom: 0.5px solid #dbdbdb;
  height: 64px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  line-height: 27px;

  ${theme.breakpoints.down('xs')} {
    font-size: 16px;
    padding: 0 20px;
  }
`

export const DialogBox = styled(Box)`
  padding: 24px;
  width: 750px;
  overflow-x: hidden;

  ${theme.breakpoints.down('sm')} {
    width: 450px;
  }

  ${theme.breakpoints.down('xs')} {
    width: auto;
    padding: 24px 20px 20px 20px;
  }
`

export const ExpandedBox = styled(Box)`
  border: 1px solid #ececec;
  padding: 10px;
  margin: 10px;
  border-radius: 3px;
`

export const ExpandedHeader = styled(Box)`
  display: flex;
  width: 100%;
`

export const ExpandedTitle = styled(Box)`
  font-size: 14px;
  font-weight: bold;
`

export const ExpandedText = styled(Box)`
  font-size: 14px;
`
