import {
  Button,
  Dialog as MUIDialog,
  DialogActions as MUIDialogActions,
  DialogContent as MUIDialogContent,
  IconButton,
} from '@mui/material'
import {styled} from 'styled-components'

import theme from '../../styles/theme'

export const Dialog = styled(MUIDialog)`
  & > div > div {
    width: 480px;
  }
`

export const CloseButton = styled(IconButton)`
  && {
    color: ${theme.palette.grey[500]};
    position: absolute;
    right: 8px;
    top: 8px;
  }
`

export const DialogContent = styled(MUIDialogContent)`
  && {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(3)};
    justify-content: center;
    padding: ${theme.spacing(8)} ${theme.spacing(4)} 0;
    text-align: center;
  }
`

export const IconImage = styled.img`
  margin: 0 auto;
`

export const DialogActions = styled(MUIDialogActions)`
  && {
    display: flex;
    justify-content: center;
    padding: ${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(8)};
  }
`

export const CTAButton = styled(Button)`
  && {
    background-color: #1377d4;
    border-radius: 2px;
    font-family: unset;
    font-weight: 700;
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    text-transform: unset;

    &:hover {
      background-color: #1377d4;
    }
  }
`
