export const formatDate = (date: Date) => {
  const abbr = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ]
  return `${abbr[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

export const formatDateReport = (data: Date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const days = data.getDate()
  const suffixes = ['th', 'st', 'nd', 'rd']
  const suffix =
    days % 10 < 4 && (days % 100 < 10 || days % 100 >= 20)
      ? suffixes[days % 10]
      : suffixes[0]
  return `${months[data.getMonth()]} ${days}${suffix}`
}
